import { cn } from '@/lib/utils';
import { Check, ChevronDown, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { RefCallBack } from 'react-hook-form';
import CommandInput from 'src/components/core/atoms/CommandInput';
import { Button } from 'src/components/shad-base/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList
} from 'src/components/shad-base/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'src/components/shad-base/popover';
import { getListItemKey } from 'src/utils/format';

export function MultiSelect({
  options,
  checked,
  setChecked,
  prefixText = 'Items: ',
  chained = false,
  element = 'button',
  onBlur,
  formRef,
  placeholder = 'Select an option...'
}: {
  options: { key: string; label: string }[];
  checked: string[];
  setChecked: (checked: string[]) => void;
  prefixText?: string;
  chained?: boolean;
  element?: 'button' | 'input';
  onBlur?: () => void;
  formRef?: RefCallBack;
  name?: string;
  placeholder?: string;
}) {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const filteredOptions = options.filter((option) => {
    return option.label
      .toLowerCase()
      .includes(searchValue.toLowerCase());
  });

  const totalOptionsLength = Object.values(options || {}).length;
  const checkedOptionsLength = checked?.length || 0;

  useEffect(() => {
    setSearchValue('');
  }, [open]);

  const checkedLabels = checked.map(
    (key) => options.find((o) => o.key === key)?.label
  );

  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        {element === 'button' ? (
          // Standard Component
          <Button
            variant="outline"
            size="sm"
            className={chained ? 'h-6 border-0' : ''}
          >
            {prefixText}{' '}
            <span className="text-primary">
              {totalOptionsLength === checkedOptionsLength
                ? 'All'
                : checkedOptionsLength}
            </span>
          </Button>
        ) : (
          // Input Component
          <div className="flex cursor-pointer flex-nowrap rounded-md border hover:bg-accent">
            <div
              className="flex min-h-10 w-full items-center p-sm px-md"
              ref={formRef}
              onBlur={onBlur}
            >
              <p
                className={
                  'w-full max-w-full overflow-x-auto ' +
                  (checkedLabels?.length == 0 ? 'text-muted' : '')
                }
              >
                {checkedLabels?.length > 0
                  ? checkedLabels.join(', ')
                  : placeholder}
              </p>
            </div>
            {checkedOptionsLength > 0 && (
              <div className="flex items-center">
                <Button
                  variant="outline"
                  size="icon"
                  className="mx-sm flex items-center"
                  onClick={() => setChecked([])}
                >
                  <X className="h-icon w-icon" />
                </Button>
              </div>
            )}
            <div className="flex items-center px-sm">
              <ChevronDown className="h-5 w-5" />
            </div>
          </div>
        )}
      </PopoverTrigger>
      <PopoverContent className="w-56 overflow-y-auto p-0">
        <Command loop>
          <CommandList>
            {/* Add Searchbar if more than 5 options */}
            {totalOptionsLength > 5 && (
              <div className="mb-sm flex items-center justify-between border-b">
                <CommandInput
                  placeholder={'Search...'}
                  searchValue={searchValue}
                  setSearchValue={(value) => setSearchValue(value)}
                />
                {checkedOptionsLength !== totalOptionsLength &&
                  totalOptionsLength > 5 && (
                    <Button
                      variant="outline"
                      size="sm"
                      className="my-1 mr-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        setChecked(
                          options.map((option) => option.key)
                        );
                      }}
                    >
                      Select all
                    </Button>
                  )}
              </div>
            )}
            <CommandEmpty>No items.</CommandEmpty>
            <CommandGroup>
              {filteredOptions.map((option, index) => {
                const key = option.key;
                return (
                  <CommandItem
                    key={getListItemKey(index)}
                    onDoubleClick={() => {
                      // Set every option to false except this one
                      setChecked([key]);
                    }}
                    value={key}
                    onSelect={() => {
                      // Toggle the checked state of the option
                      setChecked(
                        checked.includes(key)
                          ? checked.filter((item) => item !== key)
                          : [...checked, key]
                      );
                    }}
                  >
                    <div className="flex w-full items-center justify-between px-1">
                      <p className="body2">{option.label}</p>
                      <Check
                        className={cn(
                          'ml-2 h-4 w-4',
                          checked.includes(option.key)
                            ? 'opacity-100'
                            : 'opacity-0'
                        )}
                      />
                    </div>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
