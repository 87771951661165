import { Button } from 'src/components/shad-base/button';
import {
  Dialog,
  DialogContent,
  DialogFooter
} from 'src/components/shad-base/dialog';

export default function BeforeYouContinueDialog({
  open,
  onOpenChange,
  message,
  onContinue
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  message: React.ReactNode;
  onContinue: () => void;
}) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <div className="flex flex-col flex-nowrap">
          <p className="subtitle1 mb-lg">Before you continue...</p>
          <p>{message}</p>
        </div>
        <DialogFooter className="mt-md">
          <Button
            onClick={() => onOpenChange(false)}
            className="mr-sm"
          >
            Cancel
          </Button>
          <Button variant="outline" onClick={onContinue}>
            Continue Anyway
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
