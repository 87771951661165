import GeneralIcon from 'src/components/core/atoms/GeneralIcon';
import { ColumnType } from 'src/components/core/organisms/PaginatedTable';
import { Button } from 'src/components/shad-base/button';

export default function TableRowGroupRow<RowType, RowGroupType>({
  columns,
  rowData,
  parentRowIdentifier,
  parentRowHighlighted,
  onDeleteRowGroupRow,
  idx
}: {
  columns: ColumnType<RowType, RowGroupType>;
  rowData: RowGroupType;
  isDisabled?: boolean;
  parentRowIdentifier: string;
  parentRowHighlighted?: boolean;
  onDeleteRowGroupRow?: (
    rowIdentifier: string,
    parentRowIdentifier: string
  ) => void;
  idx: number;
}) {

  return (
    <div
      className={
        'relative mb-[-1px] flex h-row max-h-row flex-nowrap items-center border-l border-r bg-background ' +
        (parentRowHighlighted ? 'bg-background/5' : '') + (idx !== 0 ? 'border-t' : '')
      }
    >
      <div className="w-full px-sm">
        <div
          className="grid h-full max-h-full w-full flex-nowrap items-center"
          style={{
            gridTemplateColumns: `${Object.keys(columns)
              .map((key) => columns[key]?.gridTemplateFr)
              .join(' ')}`
          }}
        >
          {Object.keys(columns).map((key, idx) => {
            return (
              <div
                className={
                  'w-full px-sm ' + (idx === 0 ? 'pl-[50px]' : '')
                }
                key={idx}
              >
                <div
                  className={
                    'flex w-full flex-nowrap items-center ' +
                    (columns[key]?.type == 'number'
                      ? 'justify-end'
                      : 'justify-start')
                  }
                >
                  {columns[key]?.renderRowGroupCellData(rowData)}
                </div>
              </div>
            );
          })}
          <div className="absolute right-0 flex h-full flex-nowrap items-center pr-sm">
            {onDeleteRowGroupRow ? (
              <Button
                variant="outline"
                size="icon"
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteRowGroupRow(
                    rowData['identifier'],
                    parentRowIdentifier
                  );
                }}
              >
                <GeneralIcon type="delete" mode="grey" size="xs" />
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
