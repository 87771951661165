import { SlidersHorizontal } from 'lucide-react';
import { Fragment } from 'react';
import { MultiSelect } from 'src/components/core/molecules/MultiSelect';
import { getListItemKey } from 'src/utils/format';
import DateFilters from 'src/components/core/molecules/DateFilters';
import { OptionType } from 'src/components/core/molecules/SingleSelect';

export type FilterType = {
  key: string;
  label: string;
  options: OptionType[];
  checked: string[];
  setChecked: (checked: string[]) => void;
};

export default function Filterbar({
  filters,
  includeDateFilter = false
}: {
  filters: FilterType[];
  includeDateFilter?: boolean;
}) {
  return (
    <div className="flex h-small-button flex-nowrap items-center rounded-md border bg-background px-sm">
      <div className="mr-sm border-r">
        <SlidersHorizontal className="mr-sm h-3 w-3" />
      </div>
      {includeDateFilter && (
        <>
          <div className="max-h-small-button">
            <DateFilters isJoinable />
          </div>
          {filters.length > 0 && (
            <div className="mx-sm w-px bg-border py-sm" />
          )}
        </>
      )}
      {/* Filters */}
      {filters.map((filter, idx) => {
        return (
          <Fragment key={getListItemKey(idx)}>
            {idx !== 0 && (
              <div className="mx-sm w-px bg-border py-sm" />
            )}
            <MultiSelect
              chained
              options={filter.options}
              checked={filter.checked}
              setChecked={filter.setChecked}
              prefixText={filter.label}
              element="button"
            />
          </Fragment>
        );
      })}
    </div>
  );
}
