import { gql } from '@apollo/client';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  CreateEmissionFactorPayload,
  GhgProtocolsEnum,
  RegionsEnum,
  UnitTypesEnum
} from 'src/__apolloGenerated__/graphql';
import { getDecimalScopeFromGhgProtocol } from 'src/backend/@types';
import { Button } from 'src/components/shad-base/button';
import {
  Dialog,
  DialogContent,
  DialogFooter
} from 'src/components/shad-base/dialog';
import useMeasureStore from 'src/hooks/store/useMeasureStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import CSVMode from './CSVMode';
import DescriptionMode from './DescriptionMode';
import { ROUTES } from 'src/config';
import { useRouter } from 'next/router';
import { useToast } from 'src/hooks/shad-base/useToast';

export type ModeType = 'description' | 'image' | 'csv' | null;

export type NewEmissionFactorDialogVariablesType = {
  name: string;
  description: string;
  rate: string;
  unit: string | null;
  ghgProtocol: GhgProtocolsEnum | null;
  referenceSource: string;
  referenceOrganization: string;
  referenceRegion: RegionsEnum | null;
  referenceYear: string | null;
  referenceUrl: string;
  unspscPath: string;
  isPublic: boolean;
};

export default function AddNewEmissionFactorDialog({
  open,
  onOpenChange,
  rerouteOnSuccess = true,
  onSuccess,
  unitType
}: {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
  rerouteOnSuccess?: boolean;
  onSuccess?: (data: CreateEmissionFactorPayload) => void;
  unitType?: UnitTypesEnum;
}) {
  const { setActiveView } = useMeasureStore();

  const [mode, setMode] = useState<ModeType>('description');
  const [files, setFiles] = useState<File>(null);

  const emptyVariables = {
    name: '',
    description: '',
    rate: '',
    unit: null,
    ghgProtocol: null,
    referenceSource: '',
    referenceOrganization: '',
    referenceRegion: null,
    referenceYear: null,
    referenceUrl: '',
    unspscPath: '',
    isPublic: false
  };
  const router = useRouter();
  const [variables, setVariables] =
    useState<NewEmissionFactorDialogVariablesType>(emptyVariables);

  useEffect(() => {
    if (!open) {
      setVariables(emptyVariables);
      setMode('description');
    }
    setFiles(null);
  }, [open]);

  const onClose = () => {
    onOpenChange(false);
    setMode('description');
    setFiles(null);
    setVariables(emptyVariables);
  };

  const {
    mutate: createEmissionFactor,
    loading: creatingEmissionFactor
  } = useBackendMutation({
    mutation: gql`
      mutation CreateEmissionFactor(
        $input: CreateEmissionFactorInput!
      ) {
        createEmissionFactor(input: $input) {
          ok
          errors {
            ...ErrorsFragment
          }
          emissionFactor {
            ...EmissionFactorFragment
          }
        }
      }
    `,
    callbacks: {
      onSuccess: (data: CreateEmissionFactorPayload) => {
        onClose();
        onSuccess && onSuccess(data);
        if (rerouteOnSuccess) {
          setActiveView('Factors');
          router.push(ROUTES.INVENTORY);
        }
      }
    }
  });
  const { toast } = useToast();
  const { mutate: generateEmissionFactorsFromCsvFile } =
    useBackendMutation({
      mutation: gql`
        mutation GenerateEmissionFactorsFromCsvFile(
          $input: GenerateEmissionFactorsFromCsvFileInput!
        ) {
          generateEmissionFactorsFromCsvFile(input: $input) {
            ok
            errors {
              ...ErrorsFragment
            }
            emissionFactors {
              ...EmissionFactorFragment
            }
          }
        }
      `,
      variables: {
        input: {
          csvFile: files
        }
      },
      callbacks: {
        onSuccess: () => {
          toast({
            title: 'Success.',
            description: 'Emission factors updated.',
            variant: 'success'
          });
          onOpenChange(false);
          setActiveView('Factors');
          router.push(ROUTES.INVENTORY);
        }
      }
    });

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={'max-w-screen-md'}>
        <div className="w-full min-w-full max-w-full">
          <div className="flex flex-col flex-nowrap">
            {/* Content */}
            {mode === 'csv' ? (
              <CSVMode
                setMode={setMode}
                handleSubmit={(file) => {
                  generateEmissionFactorsFromCsvFile({
                    variables: { input: { csvFile: file } },
                    refetchQueries: [
                      'MeasureViewCustomEmissionFactorsQuery'
                    ]
                  });
                }}
              />
            ) : (
              <DescriptionMode
                variables={variables}
                setVariables={setVariables}
                unitType={unitType}
              />
            )}
          </div>
        </div>

        <DialogFooter>
          {mode === 'description' && (
            <div className="mt-md flex w-full flex-nowrap items-center justify-between">
              <Button
                variant="outline"
                onClick={() => setMode('csv')}
              >
                Bulk upload from CSV
              </Button>
              <Button
                loading={creatingEmissionFactor}
                disabled={
                  !variables.name ||
                  !variables.rate ||
                  !variables.unit ||
                  !variables.ghgProtocol
                }
                onClick={() => {
                  const decimalScope = getDecimalScopeFromGhgProtocol(
                    variables.ghgProtocol
                  );
                  createEmissionFactor({
                    variables: {
                      input: {
                        emissionFactor: {
                          name: variables.name,
                          description: variables.description,
                          rate: variables.rate,
                          unit: variables.unit,
                          scope: decimalScope,
                          reference: {
                            source: variables.referenceSource,
                            organization:
                              variables.referenceOrganization,
                            region: variables.referenceRegion,
                            year: variables.referenceYear
                              ? Number(variables.referenceYear)
                              : null,
                            url: variables.referenceUrl
                          },
                          unspscPath: variables.unspscPath
                        },
                        file: null,
                        isPublic: variables.isPublic
                      }
                    },
                    refetchQueries: rerouteOnSuccess
                      ? ['MeasureViewCustomEmissionFactorsQuery']
                      : ['CustomEmissionFactorsQuery']
                  });
                }}
              >
                Create Emission Factor
              </Button>
            </div>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
