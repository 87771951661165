import {
  Tooltip as TooltipBase,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from 'src/components/shad-base/tooltip';

export default function TooltipIcon({
  trigger,
  text,
  side = 'top'
}: {
  trigger: React.ReactNode;
  text: string;
  side?: 'left' | 'right' | 'top' | 'bottom';
}) {
  return (
    <TooltipProvider>
      <TooltipBase delayDuration={100}>
        <TooltipTrigger asChild>
          <div className="cursor-pointer">{trigger}</div>
        </TooltipTrigger>
        <TooltipContent side={side}>{text}</TooltipContent>
      </TooltipBase>
    </TooltipProvider>
  );
}
