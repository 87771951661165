import { useEffect, useState } from 'react';
import Input from 'src/components/core/atoms/Input';
import Skeleton from 'src/components/core/atoms/Skeleton';

export default function NoteSection({
  loading,
  canEdit,
  onBlur,
  initialNote
}: {
  loading: boolean;
  canEdit: boolean;
  onBlur: (value: string) => void;
  initialNote: string;
}) {
  const [note, setNote] = useState(initialNote);
  useEffect(() => {
    setNote(initialNote);
  }, [initialNote]);

  return (
    <>
      {loading ? (
        <Skeleton height={150} />
      ) : canEdit ? (
        <Input
          noBorder
          multiline
          placeholder="Add a note..."
          className="px-lg py-lg "
          value={note}
          onChange={(e) => setNote(e.target.value)}
          onBlur={() => {
            onBlur(note);
          }}
        />
      ) : (
        <p>{note}</p>
      )}
    </>
  );
}
