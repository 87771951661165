import { useCallback, useState } from 'react';

import Papa from 'papaparse';
import { useEffect } from 'react';

import FileDropzone from 'src/components/core/atoms/FileDropzone';
import CSVColumnMatcher from 'src/components/core/organisms/CSVFileUploader/CSVColumnMatcher';
import { CellDataType } from 'src/components/core/organisms/PaginatedTable';
import StickyTable from 'src/components/core/organisms/StickyTable';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from 'src/components/shad-base/accordion';
import { Button } from 'src/components/shad-base/button';
import { Switch } from 'src/components/shad-base/switch';
import { useToast } from 'src/hooks/shad-base/useToast';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import { getPalette } from 'src/theme/palette';
import typography from 'src/theme/typography';
import { getListItemKey } from 'src/utils/format';

export type HeaderIndicesType = {
  [key: string]: number;
};

export type ExpectedHeaderType = {
  key: string;
  label: string;
  optional?: boolean;
};

export default function CSVFileUploader({
  onSubmit,
  expectedHeaders = [],
  extraStep,
  uploading,
  templateDownloadUrl,
  templateName
}: {
  onSubmit: (
    file: File,
    headerIndices: HeaderIndicesType,
    headerRow: number
  ) => void;
  expectedHeaders: ExpectedHeaderType[];
  extraStep?: {
    title: string;
    description: string;
    buttonDisabled?: boolean;
    includePreview?: boolean;
    renderChildren: () => JSX.Element;
  };
  uploading?: boolean;
  templateDownloadUrl?: string;
  templateName?: string;
}) {
  const { themeMode }: SettingsStoreType = useSettingsStore();
  const palette = getPalette({ themeMode });
  const [activeStep, setActiveStep] = useState(0);
  const [hidePreview, setHidePreview] = useState(false);

  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [parsedFileData, setParsedFileData] = useState(null);
  const [uploadError, setUploadError] = useState(false);
  const { toast } = useToast();

  // Assume headers are in the first row of the file
  const fileHeaders: string[] = Object.values(
    parsedFileData?.data?.[0] || []
  );
  const handleDownload = (url, name) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = name;
    link.click();
  };

  const initialHeaderIndices = {};
  expectedHeaders.forEach((header, index) => {
    if (fileHeaders[index]) {
      initialHeaderIndices[header.key] = index;
    } else {
      initialHeaderIndices[header.key] = null;
    }
  });

  const [headerIndices, setHeaderIndices] =
    useState<HeaderIndicesType>(initialHeaderIndices);

  useEffect(() => {
    setHeaderIndices(initialHeaderIndices);
  }, []);

  const onDrop = useCallback(
    (files: File[]) => {
      setParsedFileData(null);
      setUploadError(false);
      const file = files[0];
      if (file.type !== 'text/csv') {
        setUploadError(true);
        return;
      }
      setCsvFile(file);
      Papa.parse(file, {
        preview: 20,
        skipEmptyLines: true,
        error: () => {
          setUploadError(true);
        },
        complete: (
          data: {
            errors: string[];
            data: string[];
            meta: unknown;
          } /* , file */
        ) => {
          if (data.errors.length > 0 || data.data.length > 1000) {
            setUploadError(true);
          } else {
            setParsedFileData(data);
            setActiveStep(1);
          }
        }
      });
    },
    [csvFile]
  );

  // Row data to be displayed in the table, with the first row removed
  const rows = parsedFileData?.data
    ?.slice(1)
    .map((row, idx) => {
      const rowObject: { [key: string]: string } = {
        identifier: getListItemKey(idx)
      };
      for (let i = 0; i < fileHeaders.length; i++) {
        rowObject[fileHeaders[i]] = row[i] || '';
      }
      return rowObject;
    })
    .filter((row) => {
      return Object.values(row).some((value) => value !== '');
    });

  // Columns to be displayed in the table, essentially formatted FILE_HEADERS
  const columns = [];
  for (let i = 0; i < fileHeaders.length; i++) {
    columns.push({
      key: getListItemKey(i),
      isLocked: i === 0,
      type: 'string' as CellDataType,
      label: fileHeaders[i],
      gridTemplateFr: '1fr',
      renderRowCell: (row) => (
        <p
          style={{ ...typography.body2 }}
          className="whitespace-nowrap"
        >
          {row[fileHeaders[i]]}
        </p>
      ),
      renderColumnCell: (label) => {
        return (
          <p
            style={{ ...typography.body2 }}
            className="whitespace-nowrap"
          >
            {label}
          </p>
        );
      },
      width: 200
    });
  }

  const CSVPreviewTable = () => {
    return (
      <Accordion
        type="single"
        collapsible
        className="mt-4 w-full bg-transparent py-0"
      >
        <AccordionItem value={!hidePreview ? 'open' : 'closed'}>
          <AccordionTrigger className="w-full bg-transparent p-0">
            <div className="w-full max-w-full">
              <div className="flex items-center justify-between">
                <div>
                  <p>{csvFile?.name}</p>
                </div>
                <div className="flex flex-nowrap items-center">
                  <p className="caption">Show preview</p>
                  <Switch
                    checked={!hidePreview}
                    onCheckedChange={() =>
                      setHidePreview(!hidePreview)
                    }
                    className="mx-sm"
                  />
                </div>
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent className="p-0">
            <div className="relative w-full max-w-full overflow-x-auto">
              {/* check if first label in columns is '' */}
              {columns.length > 0 && columns[0].label !== '' ? (
                <StickyTable
                  noSpacer
                  columns={columns}
                  getRowIsDisabled={() => false}
                  rows={rows.slice(0, 5)}
                  getRowIdFromRowData={(row) => row['identifier']}
                />
              ) : (
                <p
                  style={{
                    ...typography.body2,
                    color: palette.error.main
                  }}
                  className="my-md"
                >
                  Could not map CSV rows to headers. Please ensure the
                  first row of your CSV file contains the column
                  headers.
                </p>
              )}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  };
  // Returns true if all required columns are matched
  const getValidHeaderIndices = () => {
    let valid = true;
    Object.keys(headerIndices || {}).forEach((header) => {
      // If no match, check if header is optional
      if (headerIndices[header] === -1) {
        const expectedHeader = expectedHeaders.find(
          (expectedHeader) => expectedHeader.key === header
        );
        if (expectedHeader?.optional !== true) {
          valid = false;
        }
      }
    });
    return valid;
  };

  return (
    <div className="w-full">
      <div className="flex w-full flex-col">
        {/* Progress */}
        <div className="mb-sm w-full flex-nowrap">
          <p color="text.primaryAlt2">
            {activeStep + 1} of {extraStep ? '3' : '2'}
          </p>
        </div>
        {/* Upload file */}
        {activeStep === 0 ? (
          <>
            <div>
              <p>File Upload</p>
            </div>
            <div>
              <p>Please select a CSV file.</p>
              <p
                className="mt-sm cursor-pointer text-primary underline"
                onClick={() =>
                  handleDownload(templateDownloadUrl, templateName)
                }
              >
                Download template
              </p>
            </div>

            <div className="mt-md">
              <FileDropzone
                onDrop={onDrop}
                supportedFileTypes={['csv']}
              />
            </div>
            {uploadError && (
              <div className="my-md">
                <p
                  style={{
                    ...typography.body2,
                    color: palette.error.main
                  }}
                >
                  Unable to upload file. Please ensure the file is a
                  CSV, less than 1000 lines, and try again. If issues
                  persist contact support@emitiq.com
                </p>
              </div>
            )}
          </>
        ) : activeStep === 1 ? (
          <>
            <div>
              <p>Column Matching</p>
            </div>
            <div>
              <p style={{ ...typography.body2 }}>
                Please match the columns in your CSV file to the
                expected columns.
              </p>
            </div>
            {/* Preview table */}
            <div className="max-w-full">
              <CSVPreviewTable />
            </div>

            <div className="mt-6 w-full">
              <CSVColumnMatcher
                expectedHeaders={expectedHeaders}
                fileHeaders={fileHeaders}
                headerIndicesState={[headerIndices, setHeaderIndices]}
              />
            </div>
            <div className="mt-6">
              <div className="flex w-full justify-end">
                <div>
                  <Button
                    disabled={Object.keys(headerIndices).length === 0}
                    onClick={() => {
                      const isValid = getValidHeaderIndices();
                      if (!isValid) {
                        toast({
                          title: 'Error.',
                          description:
                            'Please match all required columns.',
                          variant: 'destructive'
                        });
                        return;
                      } else if (extraStep) {
                        setActiveStep(2);
                      } else {
                        onSubmit(csvFile, headerIndices, 1);
                      }
                    }}
                  >
                    {extraStep ? 'Continue' : 'Upload'}
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : extraStep && activeStep === 2 ? (
          <>
            <div>
              <p>{extraStep.title}</p>
            </div>
            <div>
              <p style={{ ...typography.body2 }}>
                {extraStep.description}
              </p>
            </div>
            {extraStep.includePreview ? <CSVPreviewTable /> : null}
            <div className="mt-md">{extraStep.renderChildren()}</div>
            <div className="mt-md">
              <div className="flex w-full flex-row justify-end">
                <Button
                  disabled={
                    columns.length == 0 ||
                    columns[0].label == '' ||
                    extraStep.buttonDisabled
                  }
                  onClick={() => {
                    onSubmit(csvFile, headerIndices, 1);
                  }}
                  loading={uploading}
                >
                  Upload
                </Button>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
