import { useDropzone } from 'react-dropzone';
import { SupportedFileTypesType } from 'src/components/core/molecules/FileUploader';

import { Button } from 'src/components/shad-base/button';
import useDevice from 'src/hooks/useDevice';
import PlusIcon from 'src/icons/plus';

export default function FileDropzone({
  supportedFileTypes,
  onDrop
}: {
  onDrop: (acceptedFiles: File[]) => void;
  supportedFileTypes?: SupportedFileTypesType[];
}) {
  const { isDesktop } = useDevice();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  });

  const fileTypes = [];
  if (supportedFileTypes?.includes('csv')) fileTypes.push('csv');
  if (supportedFileTypes?.includes('image'))
    fileTypes.push(['jpg', 'jpeg', 'png', 'pdf']);
  if (supportedFileTypes?.includes('excel')) fileTypes.push('xlsx');
  if (supportedFileTypes?.includes('json')) fileTypes.push('json');
  if (supportedFileTypes?.includes('doc')) fileTypes.push('docx');

  return (
    <div className="h-full min-h-full">
      {isDesktop ? (
        <div className="flex h-full items-center justify-center">
          <div
            className="h-full w-full cursor-pointer rounded-lg border border-dashed border-border p-6"
            {...getRootProps()}
          >
            <input {...getInputProps()} />

            <div className="flex flex-col items-center">
              {isDragActive ? (
                <PlusIcon />
              ) : (
                <>
                  <p className="body2 text-center">
                    Drag and drop files to upload
                  </p>
                  <p className="body2 mt-md text-center">OR</p>
                </>
              )}
              <Button variant="outline" className="mt-md">
                Browse Files
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Button variant="outline" className="w-full">
            Browse files
          </Button>
        </div>
      )}

      {supportedFileTypes && fileTypes.length > 0 && (
        <div className="mt-sm">
          <p className="caption text-muted">
            Supported file types: {fileTypes.join(', ')}
          </p>
        </div>
      )}
    </div>
  );
}
