import { ScopeType } from 'src/@types/emissions';
import {
  Scope1DataCalculatorsFragmentFragment,
  Scope2DataCalculatorsFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import { DetailView } from 'src/components/carbon/organisms/DataCalculatorManager/DetailView';
import ListView from 'src/components/carbon/organisms/DataCalculatorManager/ListView';
import { Breadcrumbs } from 'src/components/core/atoms/Breadcrumbs';
import useDataCalculatorStore, {
  DataCalculatorStoreType
} from 'src/hooks/store/useDataCalculatorStore';
import CollapsiblePanelLayout from 'src/layouts/CollapsiblePanelLayout';

export default function DataCalculatorManager({
  scope,
  dataCalculators,
  bottomNavButtonText,
  bottomNavOnClick,
  loading
}: {
  scope: ScopeType;
  dataCalculators:
    | Scope1DataCalculatorsFragmentFragment
    | Scope2DataCalculatorsFragmentFragment;
  bottomNavButtonText: string;
  bottomNavOnClick: () => void;
  loading: boolean;
}) {
  const { activeAsset, clearCurrentActive } = useDataCalculatorStore(
    (store: DataCalculatorStoreType) => ({
      activeAsset: store.activeAsset,
      clearCurrentActive: store.clearCurrentActive
    })
  );
  return (
    <CollapsiblePanelLayout
      forceClose
      includePadding={false}
      getSubnav={() => {
        return activeAsset ? (
          <div className="px-md">
            <Breadcrumbs
              items={[
                {
                  key: `Scope ${scope} emissions`,
                  label: `Calculate Scope ${scope} Emissions`,
                  onClick: () => {
                    clearCurrentActive();
                  }
                },
                {
                  key: 'Asset',
                  label: activeAsset?.name
                }
              ]}
            />
          </div>
        ) : null;
      }}
      bottomNavButtonText={bottomNavButtonText}
      bottomNavOnClick={bottomNavOnClick}
    >
      <div className="flex h-full max-h-full w-full flex-col flex-nowrap">
        <div className="w-full p-xl">
          <div className="flex w-full flex-col">
            {activeAsset ? (
              <DetailView />
            ) : (
              <ListView
                dataCalculators={dataCalculators}
                loading={loading}
                scope={scope}
              />
            )}
          </div>
        </div>
      </div>
    </CollapsiblePanelLayout>
  );
}
