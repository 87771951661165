import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';
import Skeleton from 'src/components/core/atoms/Skeleton';
import TableHeader from 'src/components/core/molecules/TableHeader';
import TableRow from 'src/components/core/molecules/TableRow';
import { getListItemKey } from 'src/utils/format';
import PaginatedTableFooter from './PaginatedTableFooter';

export type CellDataType =
  | 'number'
  | 'string'
  | 'boolean'
  | 'date'
  | 'time'
  | 'datetime'
  | 'currency'
  | 'image'
  | 'email'
  | 'phone'
  | 'url'
  | 'text'
  | 'button'
  | 'custom';

export type ColumnType<RowType, RowGroupType> = {
  key: string;
  type: CellDataType;
  label: string | JSX.Element;
  renderRowCell: (
    cellData: RowType,
    isDisabled: boolean,
    isAddNew?: boolean
  ) => ReactNode | void;
  isLocked?: boolean;
  renderColumnCell?: (label: string | JSX.Element) => ReactNode;
  disabled?: boolean;
  gridTemplateFr?: string;
  width?: number;
  renderRowGroupCellData?: (
    cellData: RowGroupType,
    isDisabled: boolean
  ) => ReactNode | void;
  cellSx?: Record<string, string | number>;
}[];

export default function PaginatedTable<RowType, RowGroupType = null>({
  columns,
  maxTableHeight,
  data = null,
  load,
  totalCount,
  paginationState,
  setPaginationState,
  selectedRows,
  setSelectedRows,
  setActiveRowId,
  activeRowId,
  expandableRows = false,
  onRowClick,
  getRowGroupData = () => null,
  isTableLoading = false,
  disableHoverEffects = false
}: {
  columns: ColumnType<RowType, RowGroupType>;
  maxTableHeight?: string;
  height?: number;
  tableContainerSx?: object;
  setActiveRowId?: (arg0: string) => void;
  selectedRows?: string[];
  setSelectedRows?: (rows: string[]) => void;
  onRowClick?: (rowIdentifier: string) => void;
  activeRowId?: string;
  data?: { edges: { node?: RowType }[] };
  load: (startIndex: number, stopIndex: number) => void;
  totalCount: number;
  paginationState: {
    startIndex: number;
    stopIndex: number;
    pageNumber: number;
    rowsPerPage: number;
  };
  setPaginationState: Dispatch<
    SetStateAction<{
      startIndex: number;
      stopIndex: number;
      pageNumber: number;
      rowsPerPage: number;
    }>
  >;
  expandableRows?: boolean;
  getRowGroupData?: (parentRowIdentifier: string) => RowGroupType[];
  isTableLoading?: boolean;
  disableHoverEffects?: boolean;
}) {
  const tableContainerRef = useRef(null);

  const [hoveringRowId, setHoveringRowId] = useState<string | false>(
    false
  );

  useEffect(() => {
    load(paginationState.startIndex, paginationState.rowsPerPage);
    if (setActiveRowId) {
      setActiveRowId(null);
    }
  }, [paginationState]);

  // for each edge, get the node
  const pageData = ((data) => {
    if (!data) return [];
    const edges = data.edges || [];
    return edges.map((edge) => edge.node);
  })(data);

  return isTableLoading ? (
    <Skeleton count={4} height={100} style={{ borderRadius: 0 }} />
  ) : (
    <div
      className="flex h-full w-full min-w-[900px] flex-col flex-nowrap overflow-hidden pb-sm"
      ref={tableContainerRef}
    >
      <TableHeader columns={columns} />
      <div
        className="relative z-10 pb-px"
        style={{
          maxHeight: maxTableHeight
        }}
      >
        {pageData?.length > 0 ? (
          pageData?.map((row, index) => {
            return (
              <TableRow<RowType, RowGroupType>
                key={getListItemKey(index)}
                rowData={row as RowType}
                columns={columns}
                rowMenuItems={[]}
                isDisabled={false}
                isSelected={selectedRows?.includes(row['identifier'])}
                onRowClick={(rowIdentifier) => {
                  setSelectedRows &&
                    setSelectedRows(
                      selectedRows
                        ? selectedRows.includes(rowIdentifier)
                          ? selectedRows.filter(
                              (id) => id !== rowIdentifier
                            )
                          : [...selectedRows, rowIdentifier]
                        : [rowIdentifier]
                    );
                  onRowClick && onRowClick(rowIdentifier);
                }}
                activeRowId={activeRowId}
                isHoveringState={[hoveringRowId, setHoveringRowId]}
                expandableRows={expandableRows}
                getRowGroupData={getRowGroupData}
                disableHoverEffects={disableHoverEffects}
              />
            );
          })
        ) : (
          <div className="mb-[-1px] flex h-row items-center border p-sm pl-md">
            <p>No row data.</p>
          </div>
        )}
      </div>
      {pageData.length > 0 && (
        <PaginatedTableFooter
          paginationState={paginationState}
          setPaginationState={setPaginationState}
          totalCount={totalCount}
          fetchCount={pageData.length}
        />
      )}
    </div>
  );
}
