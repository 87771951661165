import { Calendar } from 'src/components/shad-base/calendar';

import { cn } from '@/lib/utils';
import { CalendarIcon, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Button } from 'src/components/shad-base/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'src/components/shad-base/popover';
import { fLocalDateShort } from 'src/utils/format';

type DateRangeType = {
  startDate: Date;
  endDate: Date;
};
interface DatePickerProps {
  date?: Date;
  dateRange?: DateRangeType;
  setDateRange?: (range: DateRangeType) => void;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  setDate?: (date: Date) => void;
  setStartDate?: (date: Date) => void;
  setEndDate?: (date: Date) => void;
  placeholder?: string;
  renderCustomAnchorEl?: () => React.ReactNode;
  size?: 'sm' | 'default' | 'lg' | 'xl' | 'icon';
  required?: boolean;
  isRange?: boolean;
  noDateRangeText?: string;
}

function ValidateProps(props: DatePickerProps) {
  if (props.isRange && !props.setDateRange) {
    throw new Error('setDateRange is required when isRange is true');
  } else if (!props.isRange && !props.setDate) {
    throw new Error('date/setDate is required when isRange is false');
  }
}

export function DatePicker(props: DatePickerProps) {
  ValidateProps(props);
  const {
    date,
    dateRange,
    setDate,
    setDateRange,
    minDate,
    maxDate,
    disabled = false,
    placeholder = 'Select a date',
    renderCustomAnchorEl = null,
    size = 'default',
    label,
    required = false,
    isRange = false,
    noDateRangeText = 'Select a date range...'
  } = props;

  const startDate = dateRange?.startDate;
  const endDate = dateRange?.endDate;

  const [open, setOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: startDate,
    endDate: endDate
  });

  // Reset selected date range when popover is closed
  useEffect(() => {
    if (!open) {
      setSelectedDateRange({
        startDate: startDate,
        endDate: endDate
      });
    }
  }, [open, startDate, endDate]);

  const fontClass =
    size === 'sm' ? 'body2' : size === 'xl' ? 'subtitle2' : 'body1';

  const startAndNotEnd =
    Boolean(dateRange?.startDate) && !dateRange?.endDate;
  const endAndNotStart =
    Boolean(dateRange?.endDate) && !dateRange?.startDate;
  const noDateRange = !dateRange?.startDate && !dateRange?.endDate;

  const showClearButton = isRange ? !noDateRange : Boolean(date);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger disabled={disabled} className="w-full">
        {renderCustomAnchorEl ? (
          renderCustomAnchorEl()
        ) : (
          <Button
            variant={'outline'}
            className={cn(
              'w-full min-w-[250px] justify-start text-left font-normal ',
              !date && 'text-muted-foreground'
            )}
            size={size}
            disabled={disabled}
          >
            <div className="w-full min-w-[250px]">
              <div className="flex w-full items-center justify-between">
                <div className="flex flex-nowrap items-center">
                  {required && (
                    <p className="text-destructive">*&nbsp;</p>
                  )}
                  {label ? (
                    <p className={'mr-sm ' + fontClass}>{label}</p>
                  ) : (
                    <CalendarIcon className="mr-2 h-4 w-4" />
                  )}
                  {isRange ? (
                    <p
                      className={
                        (noDateRange ? 'text-muted ' : '') + fontClass
                      }
                    >
                      {noDateRange
                        ? noDateRangeText
                        : startAndNotEnd
                          ? `From ${fLocalDateShort(dateRange?.startDate)}`
                          : endAndNotStart
                            ? `Up to ${fLocalDateShort(dateRange?.endDate)}`
                            : `${fLocalDateShort(dateRange?.startDate)} - ${fLocalDateShort(dateRange?.endDate)}`}
                    </p>
                  ) : date ? (
                    <p className={fontClass}>
                      {fLocalDateShort(date)}
                    </p>
                  ) : (
                    <span className={'text-muted ' + fontClass}>
                      {placeholder || 'Select a date'}
                    </span>
                  )}
                </div>
                {showClearButton && (
                  <div className="ml-sm">
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        isRange
                          ? setDateRange({
                              startDate: null,
                              endDate: null
                            })
                          : setDate(null);
                      }}
                    >
                      <X className="h-icon w-icon" />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        {isRange ? (
          <div className="w-full p-md">
            <div className="flex flex-col flex-nowrap">
              <div className="flex flex-nowrap">
                <Calendar
                  minDate={minDate}
                  selected={selectedDateRange.startDate}
                  maxDate={selectedDateRange.endDate || maxDate}
                  onSelect={(value) => {
                    setSelectedDateRange({
                      ...selectedDateRange,
                      startDate: value
                    });
                  }}
                  mode="single"
                  initialFocus
                  captionLayout="dropdown-buttons"
                />
                <Calendar
                  selected={selectedDateRange.endDate}
                  minDate={selectedDateRange.startDate || minDate}
                  maxDate={maxDate}
                  onSelect={(value) =>
                    setSelectedDateRange({
                      ...selectedDateRange,
                      endDate: value
                    })
                  }
                  mode="single"
                  initialFocus
                  captionLayout="dropdown-buttons"
                />
              </div>

              <div className="mt-sm flex w-full justify-end">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    setSelectedDateRange({
                      startDate: null,
                      endDate: null
                    });
                  }}
                  className="mr-sm"
                >
                  Clear
                </Button>
                <Button
                  size="sm"
                  disabled={
                    required &&
                    !selectedDateRange.startDate &&
                    !selectedDateRange.endDate
                  }
                  onClick={() => {
                    setDateRange(selectedDateRange);
                    setOpen(false);
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <Calendar
            mode="single"
            minDate={minDate}
            maxDate={maxDate}
            selected={date}
            captionLayout="dropdown-buttons"
            onSelect={(date) => {
              setDate(date);
              setOpen(false);
            }}
            initialFocus
          />
        )}
      </PopoverContent>
    </Popover>
  );
}
