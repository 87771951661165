import Checkbox from 'src/components/core/atoms/Checkbox';
import { ColumnType } from 'src/components/core/organisms/PaginatedTable';
import useDevice from 'src/hooks/useDevice';

export default function TableHeader<RowType, RowGroupType>({
  columns,
  setMasterCheckbox,
  masterCheckbox,
  isMasterCheckboxDisabled = false,
  onMasterCheckboxClick,
  isRoundedHeader = true
}: {
  columns: ColumnType<RowType, RowGroupType>;
  setMasterCheckbox?: (isChecked: boolean) => void;
  masterCheckbox?: boolean;
  isMasterCheckboxDisabled?: boolean;
  onMasterCheckboxClick?: (isChecked: boolean) => void; //isChecked represents the state it is transitioning into
  isRoundedHeader?: boolean;
}) {
  const { isMobile } = useDevice();
  return (
    <div
      className="mb-[-1px] flex h-row min-h-row border bg-background"
      style={
        isRoundedHeader && {
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px'
        }
      }
    >
      <div className="w-full">
        <div
          className="grid h-full max-h-full w-full flex-nowrap items-center  "
          style={{
            textOverflow: isMobile ? 'none' : 'ellipsis',
            gridTemplateColumns: `${Object.keys(columns)
              .map((key) => columns[key]?.gridTemplateFr)
              .join(' ')}`
          }}
        >
          {Object.keys(columns).map((key, idx) => {
            return (
              <div
                className="relative h-full max-h-full w-full overflow-hidden text-ellipsis px-md "
                key={idx}
                style={{
                  gridTemplateColumns: columns[key]?.gridTemplateFr
                }}
              >
                <div className="flex h-full flex-nowrap items-center">
                  <div className="w-full">
                    <div className="flex flex-nowrap items-center justify-between">
                      <div className="w-full">
                        <div
                          className={
                            'flex items-center ' +
                            (columns[key]?.type == 'number'
                              ? 'justify-end'
                              : 'justify-start')
                          }
                        >
                          {idx == 0 && setMasterCheckbox && (
                            <>
                              <Checkbox
                                selected={masterCheckbox}
                                disabled={isMasterCheckboxDisabled}
                                onClick={() => {
                                  setMasterCheckbox(!masterCheckbox);
                                  if (onMasterCheckboxClick) {
                                    onMasterCheckboxClick(
                                      !masterCheckbox
                                    );
                                  }
                                }}
                              />
                              <div className="mx-sm" />
                            </>
                          )}
                          {columns[key]?.renderHeaderLabel ? (
                            columns[key]?.renderHeaderLabel()
                          ) : (
                            <p className="text-nowrap">
                              {columns[key]?.label}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
