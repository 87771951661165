import { gql } from '@apollo/client';
import { ArrowLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { MeasurementFragmentFragment } from 'src/__apolloGenerated__/graphql';
import CreateSubsequentMeasurementDialog from 'src/components/carbon/organisms/CreateSubsequentMeasurementDialog';
import UseExistingMeasurementDialog from 'src/components/carbon/organisms/UseExistingMeasurementDialog';
import { Button } from 'src/components/shad-base/button';
import {
  Dialog,
  DialogContent
} from 'src/components/shad-base/dialog';
import { useToast } from 'src/hooks/shad-base/useToast';
import useBackendMutation from 'src/hooks/useBackendMutation';
import CompleteMissingDataStepper from 'src/layouts/Layout/SidebarNav/MissingMeasurementDataDialog/CompleteMissingDataStepper';
import EditInputFrequencyDialog from 'src/layouts/Layout/SidebarNav/MissingMeasurementDataDialog/EditInputFrequencyDialog';
import ListView from 'src/layouts/Layout/SidebarNav/MissingMeasurementDataDialog/ListView';

export default function MissingMeasurementDataDialog({
  open,
  onOpenChange,
  loading,
  missingMeasurements,
  refetchMissingMeasurementsData
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  loading: boolean;
  missingMeasurements: MeasurementFragmentFragment[];
  refetchMissingMeasurementsData: () => void;
}) {
  const { toast } = useToast();

  const [selectedMeasurement, setSelectedMeasurement] =
    useState<MeasurementFragmentFragment | null>(null);

  const [
    openCreateSubsequentMeasurementDialog,
    setOpenCreateSubsequentMeasurementDialog
  ] = useState(false);

  const [
    editInputFrequencyDialogOpen,
    setEditInputFrequencyDialogOpen
  ] = useState(false);

  const [
    useExistingMeasurementDialogOpen,
    setUseExistingMeasurementDialogOpen
  ] = useState(false);

  useEffect(() => {
    if (open) {
      refetchMissingMeasurementsData();
    }
  }, [open]);

  const {
    mutate: updatePreviousMeasurements,
    loading: updatingPreviousMeasurements
  } = useBackendMutation({
    mutation: gql`
      mutation UpdatePreviousMeasurementsMutation(
        $input: EditMeasurementInput!
      ) {
        editMeasurement(input: $input) {
          ok
          errors {
            ...ErrorsFragment
          }
        }
      }
    `,
    callbacks: {
      onSuccess: () => {
        refetchMissingMeasurementsData();
        toast({
          title: 'Success.',
          description: 'Existing measurements added.'
        });
        setSelectedMeasurement(null);
        onOpenChange(true);
        setUseExistingMeasurementDialogOpen(false);
      }
    }
  });

  return (
    <>
      {/* Missing Measurement Data Dialog */}
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="max-w-screen-md">
          <div className="relative flex flex-col flex-nowrap items-center">
            {selectedMeasurement && (
              <div className="absolute left-0 top-0">
                <Button
                  onClick={() => setSelectedMeasurement(null)}
                  variant="outline"
                  size="icon"
                  className="mr-md"
                >
                  <ArrowLeft />
                </Button>
              </div>
            )}
            <p className="subtitle1">
              {selectedMeasurement
                ? 'Update Recurring Measurement'
                : 'Complete Missing Measurement Data'}
            </p>

            {!selectedMeasurement && (
              <p className="text-muted">
                Update your recurring measurements.
              </p>
            )}

            <div className="w-full">
              {selectedMeasurement ? (
                <CompleteMissingDataStepper
                  selectedMeasurement={selectedMeasurement}
                  onOpenChange={onOpenChange}
                  setSelectedMeasurement={setSelectedMeasurement}
                  setOpenCreateSubsequentMeasurementDialog={
                    setOpenCreateSubsequentMeasurementDialog
                  }
                  setMeasurementSearchDialogOpen={
                    setUseExistingMeasurementDialogOpen
                  }
                  setEditInputFrequencyDialogOpen={
                    setEditInputFrequencyDialogOpen
                  }
                />
              ) : (
                <ListView
                  setSelectedMeasurement={setSelectedMeasurement}
                  missingMeasurements={missingMeasurements}
                  loadingMissingMeasurementsData={loading}
                  onOpenChange={onOpenChange}
                />
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* Create a new subsequent measurement */}
      <CreateSubsequentMeasurementDialog
        open={openCreateSubsequentMeasurementDialog}
        onOpenChange={setOpenCreateSubsequentMeasurementDialog}
        selectedMeasurement={selectedMeasurement}
        onBackButtonClick={() => {
          onOpenChange(true);
          setOpenCreateSubsequentMeasurementDialog(false);
        }}
        onCreateSuccess={() => {
          // Close create subsequent measurement dialog
          setOpenCreateSubsequentMeasurementDialog(false);

          // Reopen missing measurement data dialog and return to list view
          onOpenChange(true);
          setSelectedMeasurement(null);
          refetchMissingMeasurementsData();
        }}
      />
      {/* Use existing measurement */}
      <UseExistingMeasurementDialog
        open={useExistingMeasurementDialogOpen}
        onOpenChange={setUseExistingMeasurementDialogOpen}
        buttonIsLoading={updatingPreviousMeasurements}
        selectedMeasurement={selectedMeasurement}
        onSelectMeasurements={(measurementIdentifiersToUse) => {
          updatePreviousMeasurements({
            variables: {
              input: {
                branchIdentifier:
                  selectedMeasurement?.branch?.identifier,
                measurementIdentifier:
                  selectedMeasurement?.identifier,
                addSubsequentMeasurementIdentifiers:
                  measurementIdentifiersToUse,
              }
            }
          });
        }}
        onBackArrowClick={() => {
          setUseExistingMeasurementDialogOpen(false);
          onOpenChange(true);
        }}
      />
      {/* Edit input frequency */}
      <EditInputFrequencyDialog
        selectedMeasurement={selectedMeasurement}
        open={editInputFrequencyDialogOpen}
        onOpenChange={setEditInputFrequencyDialogOpen}
        onEditSuccess={() => {
          setEditInputFrequencyDialogOpen(false);
          onOpenChange(true);
          refetchMissingMeasurementsData();
          setSelectedMeasurement(null);
        }}
        onBackArrowClick={() => {
          onOpenChange(true);
          setEditInputFrequencyDialogOpen(false);
        }}
      />
    </>
  );
}
