import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import Skeleton from 'src/components/core/atoms/Skeleton';
import { ColumnType } from 'src/components/core/organisms/PaginatedTable';
import {
  ACTIVE_ROW_CLASSNAME,
  DEFAULT_COLUMN_WIDTH,
  DEFAULT_COLUMN_WIDTH_PX,
  DEFAULT_ROW_CLASSNAME,
  FOOTPRINT_TREE_ROW_HEIGHT,
  updateRowClassName
} from 'src/config';
import { RIGHT_MARGIN_PX } from 'src/hooks/store/useMeasureStore';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import { getListItemKey } from 'src/utils/format';
import Checkbox from '../../atoms/Checkbox';
import StickyRow from './StickyRow';

export default function StickyTable<RowType, RowGroupType = null>({
  columns,
  rows,
  activeRowId,
  getRowIdFromRowData,
  checkedRows,
  setCheckedRows,
  onRowClick,
  onDeleteRow,
  getRowIsDisabled,
  onAddNew,
  noSpacer = false,
  loading
}: {
  columns: ColumnType<RowType, RowGroupType>;
  lockedColumnKey?: string;
  rows: RowType[];
  onRowClick?: (
    row: RowType,
    isDisabled: boolean,
    isAddNew: boolean
  ) => void;
  activeRowId?: string;
  getRowIdFromRowData: (rowData: RowType) => string;
  checkedRows?: { [rowIdentifier: string]: boolean };
  setCheckedRows?: (checkedRows: {
    [rowIdentifier: string]: boolean;
  }) => void;
  selectedRowsState?: [
    selectedRows: { [rowIdentifier: string]: boolean },
    setSelectedRows: Dispatch<
      SetStateAction<{ [rowIdentifier: string]: boolean }>
    >
  ];
  selectedRows?: string[];
  onDeleteRow?: (row: RowType) => void;
  getRowIsDisabled?: (rowData: RowType) => boolean;
  onAddNew?: () => void;
  noSpacer?: boolean;
  loading?: boolean;
}) {
  onDeleteRow; // TODO implement me
  const tableContainerRef = useRef(null);
  const lockedColumns = columns.filter((column) => column.isLocked);
  const unlockedColumns = columns.filter(
    (column) => !column.isLocked
  );

  const emptyLockedRow = lockedColumns.reduce((acc, column) => {
    acc[column.key] = 'Add new...';
    return acc;
  }, {});

  const emptyUnlockedRow = unlockedColumns.reduce((acc, column) => {
    acc[column.key] = '';
    return acc;
  }, {});

  // console.log('rows', rows, emptyUnlockedRow);
  const lockedColumnsRowsRef = useRef(null);

  const lockedColumnsWidth = lockedColumns.reduce((acc, column) => {
    return acc + (column.width || DEFAULT_COLUMN_WIDTH);
  }, 0);

  const { themeMode }: SettingsStoreType = useSettingsStore();

  const rowRefs = useRef({});

  useEffect(() => {
    if (activeRowId && rowRefs.current[activeRowId]) {
      rowRefs.current[activeRowId]?.['locked']?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
      updateRowClassName({
        rowRefs: rowRefs,
        nodeId: activeRowId,
        className: ACTIVE_ROW_CLASSNAME
      });
    } else {
      updateRowClassName({
        rowRefs: rowRefs,
        nodeId: activeRowId,
        className: DEFAULT_ROW_CLASSNAME
      });
    }
  }, [activeRowId]);

  useEffect(() => {
    if (!tableContainerRef.current) return; // wait for the elementRef to be available

    // reinitialize refs with correct boxShadow (if necessary)
    if (tableContainerRef.current.scrollLeft > 3) {
      lockedColumnsRowsRef.current.style.opacity = 0;
    }

    // add a box shadow to the locked columns if the unlocked columns
    // are scrolled to the left
    const handleScroll = (event) => {
      if (event.target.scrollLeft > 3) {
        lockedColumnsRowsRef.current.style.opacity = 0.4;
        lockedColumnsRowsRef.current.style.transition =
          'transform ease 0.3s, opacity ease 0.3s';
      } else if (lockedColumnsRowsRef.current?.style) {
        lockedColumnsRowsRef.current.style.opacity = 0;
        lockedColumnsRowsRef.current.style.transition =
          'transform ease 0.3s, opacity ease 0.3s';
      }
    };

    tableContainerRef.current.addEventListener(
      'scroll',
      handleScroll
    );
    return () => {
      tableContainerRef.current?.removeEventListener(
        'scroll',
        handleScroll
      );
    };
  }, [themeMode]);

  return (
    <div
      className="flex h-full w-full flex-nowrap overflow-x-auto overflow-y-auto pb-sm"
      ref={tableContainerRef}
    >
      {/* Separator */}
      <div
        className="absolute bottom-0 top-0 z-50 w-px bg-border"
        style={{ left: lockedColumnsWidth - 1 }}
      >
        <div
          className="absolute bottom-0 left-0 top-0 z-50 w-[8px] opacity-0 transition-all"
          ref={lockedColumnsRowsRef}
        />
      </div>

      {/* Locked Columns */}
      <div
        className="sticky left-0 z-20"
        style={{
          maxWidth: lockedColumnsWidth
        }}
      >
        <div className="relative flex flex-col flex-nowrap">
          {/* Locked Column Headers */}
          <div className="sticky top-0 z-20 max-h-row min-h-full w-full border-r bg-background">
            <div className="relative flex min-h-full flex-1 flex-nowrap items-center">
              <div className="min-h-full w-full">
                <div className="flex flex-nowrap items-center">
                  {/* Column Headers */}
                  <div className="w-full">
                    {checkedRows !== undefined && (
                      <div
                        className="relative flex min-h-full w-[64px] items-center justify-center px-sm"
                        style={{
                          height:
                            FOOTPRINT_TREE_ROW_HEIGHT - 1.5 + 'px'
                        }}
                      >
                        <Checkbox
                          disabled={
                            Object.keys(checkedRows || {}).length ===
                            0
                          }
                          selected={
                            !(
                              Object.values(checkedRows || {}).filter(
                                (x) => x === false
                              ).length > 0
                            ) &&
                            Object.keys(checkedRows || {}).length > 0
                          }
                          onClick={() => {
                            setCheckedRows({
                              ...(rows?.reduce((acc, row) => {
                                acc[getRowIdFromRowData(row)] = true;
                                return acc;
                              }, {}) || {})
                            });
                          }}
                        />
                      </div>
                    )}
                    {lockedColumns.map((column, index) => {
                      return (
                        <div
                          className="flex h-row max-h-row min-h-full w-full flex-nowrap items-center px-sm"
                          style={{
                            minWidth: column.width
                          }}
                          key={index}
                        >
                          {column?.renderColumnCell &&
                            column?.renderColumnCell(column?.label)}
                          {!column?.renderColumnCell && (
                            <>{column?.label}</>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div
              className="flex flex-nowrap items-center"
              style={{
                height: FOOTPRINT_TREE_ROW_HEIGHT + 'px',
                minHeight: FOOTPRINT_TREE_ROW_HEIGHT
              }}
            >
              {columns.map((column, idx) => {
                return (
                  <div
                    key={getListItemKey(idx)}
                    className="max-h-row items-center p-sm"
                    style={{
                      minWidth: column.width
                    }}
                  >
                    <Skeleton height={40} count={3} />
                  </div>
                );
              })}
            </div>
          ) : rows?.length === 0 ? (
            <div className="h-px w-full bg-border" />
          ) : (
            rows?.map((row, index) => {
              return (
                <StickyRow
                  checkedRows={checkedRows}
                  setCheckedRows={setCheckedRows}
                  getRowIdFromRowData={getRowIdFromRowData}
                  rowRefs={rowRefs}
                  key={getListItemKey(index)}
                  rowData={row}
                  isDisabled={getRowIsDisabled?.(row)}
                  columns={lockedColumns}
                  rowMenuItems={[]}
                  onRowClick={onRowClick}
                  activeRowId={null}
                  isHoveringState={[null, null]}
                  isLocked={true}
                  hideCheckbox={true}
                />
              );
            })
          )}
          {onAddNew && (
            <StickyRow
              checkedRows={checkedRows}
              setCheckedRows={setCheckedRows}
              getRowIdFromRowData={() => 'addNew'}
              rowRefs={rowRefs}
              rowData={emptyLockedRow}
              isDisabled={false}
              columns={lockedColumns}
              rowMenuItems={[]}
              onRowClick={onRowClick}
              activeRowId={null}
              isHoveringState={[null, null]}
              isAddNew={true}
              isLocked={true}
              hideCheckbox={checkedRows === undefined}
            />
          )}
        </div>
      </div>

      {/* Unlocked Columns */}
      <div>
        <div className="relative flex flex-col flex-nowrap">
          {/* Unlocked Column Headers */}
          <div className="sticky top-0 z-10 max-h-row w-full bg-background">
            {/* Column Headers */}
            <div className="flex flex-nowrap">
              {unlockedColumns.map((column, index) => {
                return (
                  <div
                    className={
                      'flex h-row max-h-row min-h-full flex-nowrap items-center px-sm ' +
                      (index !== 0 && ' border-l ') +
                      (index === unlockedColumns.length - 1
                        ? ' border-r'
                        : '')
                    }
                    key={index}
                    style={{
                      width: column?.width || DEFAULT_COLUMN_WIDTH_PX
                    }}
                  >
                    {column?.renderColumnCell &&
                      column?.renderColumnCell(column?.label)}
                    {!column?.renderColumnCell && (
                      <>{column?.label}</>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {rows?.length === 0 ? (
            <div className="h-px w-full bg-border" />
          ) : (
            !loading &&
            rows?.map((row, index) => {
              return (
                <StickyRow
                  checkedRows={checkedRows}
                  setCheckedRows={setCheckedRows}
                  getRowIdFromRowData={getRowIdFromRowData}
                  rowRefs={rowRefs}
                  key={getListItemKey(index)}
                  rowData={row}
                  isDisabled={getRowIsDisabled?.(row)}
                  columns={unlockedColumns}
                  rowMenuItems={[]}
                  onRowClick={onRowClick}
                  activeRowId={null}
                  isHoveringState={[null, null]}
                  isLocked={false}
                />
              );
            })
          )}
        </div>
        {onAddNew && (
          <StickyRow
            checkedRows={checkedRows}
            setCheckedRows={setCheckedRows}
            getRowIdFromRowData={() => 'addNew'}
            rowRefs={rowRefs}
            rowData={emptyUnlockedRow}
            isDisabled={false}
            columns={unlockedColumns}
            rowMenuItems={[]}
            onRowClick={onRowClick}
            activeRowId={null}
            isHoveringState={[null, null]}
            isAddNew={true}
            isLocked={false}
          />
        )}
      </div>

      {/* Spacer */}
      {!noSpacer && (
        <div
          className="sticky top-px h-row border-b bg-background"
          style={{
            minWidth: RIGHT_MARGIN_PX,
            width: RIGHT_MARGIN_PX
          }}
        />
      )}
    </div>
  );
}
