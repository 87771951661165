import {
  GhgProtocolsEnum,
  RegionsEnum,
  UnitTypesEnum,
  UnitsEnum
} from 'src/__apolloGenerated__/graphql';
import GhgCategorySingleSelect from 'src/components/carbon/atoms/GhgCategorySingleSelect';
import UnitSingleSelect from 'src/components/carbon/atoms/UnitSingleSelect';
import FilterChip from 'src/components/core/atoms/FilterChip';
import Input from 'src/components/core/atoms/Input';
import SingleSelect from 'src/components/core/molecules/SingleSelect';
import { fEnum } from 'src/utils/format';
import { NewEmissionFactorDialogVariablesType } from '.';

export default function DescriptionMode({
  variables,
  setVariables,
  unitType
}: {
  variables: NewEmissionFactorDialogVariablesType;
  setVariables: (
    variables: NewEmissionFactorDialogVariablesType
  ) => void;
  unitType: UnitTypesEnum;
}) {
  return (
    <>
      <div className="flex w-full flex-col overflow-clip">
        <div className="flex w-full flex-nowrap items-center justify-between">
          {/* Title */}
          <Input
            noBorder
            autoFocus
            required
            placeholder={`Emission Factor Name...`}
            value={variables.name}
            onChange={(e) => {
              setVariables({
                ...variables,
                name: e.target.value
              });
            }}
            className="subtitle1 border-0 bg-transparent px-0 py-0 shadow-none"
          />
          {unitType && (
            <div className="ml-sm mr-md">
              <FilterChip
                prefix="Unit Type: "
                text={fEnum(unitType)}
              />
            </div>
          )}
        </div>
        {/* Description */}
        <div className="mb-sm mt-sm">
          <Input
            noBorder
            value={variables.description}
            placeholder={`Enter a description for your emission factor...`}
            onChange={(e) => {
              setVariables({
                ...variables,
                description: e.target.value
              });
            }}
            className="border-0 bg-transparent px-0 py-0 shadow-none"
          />
        </div>
        {/* <p className="mb-lg text-muted">
          "E.g. Eliminate avoidable emissions by 100% by 2025"
        </p> */}
        <div className="mt-md">
          <div className="mb-sm flex">
            {/* Rate */}
            <div className="mr-sm">
              <Input
                noBorder
                required
                startAdornment={<p className="body2">Rate: </p>}
                endAdornment={
                  <p className="body2 text-muted">kgCO2e</p>
                }
                placeholder="Enter Rate..."
                size="sm"
                value={String(variables.rate)}
                onChange={(e) => {
                  setVariables({
                    ...variables,
                    rate: e.target.value
                  });
                }}
                type="number"
              />
            </div>

            {/* Unit */}
            <div>
              <UnitSingleSelect
                required
                value={variables.unit as UnitsEnum}
                setValue={(value: UnitsEnum) => {
                  setVariables({
                    ...variables,
                    unit: value
                  });
                }}
                filterByUnitType={unitType}
                prefixText={'Unit: '}
                disableClear
              />
            </div>
            {/* Public/Private */}
            <div className="ml-sm">
              <SingleSelect
                required
                value={
                  variables.isPublic === true ? 'public' : 'private'
                }
                setValue={(value) => {
                  setVariables({
                    ...variables,
                    isPublic: value === 'public' ? true : false
                  });
                }}
                options={[
                  {
                    key: 'public',
                    label: 'Public'
                  },
                  {
                    key: 'private',
                    label: 'Private'
                  }
                ]}
                disableClear
              />
            </div>
          </div>
          {/* GHG Protocol */}
          <div className="mt-sm">
            <GhgCategorySingleSelect
              required
              value={variables.ghgProtocol}
              setValue={(value) => {
                setVariables({
                  ...variables,
                  ghgProtocol: value as GhgProtocolsEnum
                });
              }}
              disableClear
            />
          </div>
          <div className="mt-lg">
            <p className="caption">Reference organization</p>
          </div>
          <div className="mt-sm">
            <div className="flex flex-nowrap items-center">
              <div>
                <Input
                  noBorder
                  value={variables.referenceOrganization}
                  onChange={(e) => {
                    setVariables({
                      ...variables,
                      referenceOrganization: e.target.value
                    });
                  }}
                  placeholder={'E.g. "EXIOBASE"'}
                  className="border-0 bg-transparent px-0 py-0 shadow-none"
                />
              </div>
            </div>
          </div>
          <div className="mt-lg">
            <p className="caption">Reference region</p>
          </div>
          <div className="mt-sm">
            <SingleSelect
              required
              value={variables.referenceRegion}
              setValue={(value) => {
                setVariables({
                  ...variables,
                  referenceRegion: value.replaceAll(
                    ' ',
                    '_'
                  ) as RegionsEnum
                });
              }}
              options={Object.values(RegionsEnum).map((value) => {
                return {
                  key: value,
                  label: value.replaceAll('_', ' ')
                };
              })}
              disableClear
            />
          </div>
          <div className="mt-lg">
            <p className="caption">Reference year</p>
          </div>
          <div className="mt-sm">
            <Input
              noBorder
              value={variables.referenceYear}
              type="number"
              fullWidth
              onChange={(e) => {
                setVariables({
                  ...variables,
                  referenceYear: e.target.value
                });
              }}
              placeholder={'E.g. "2021"'}
              className="border-0 bg-transparent px-0 py-0 shadow-none"
            />
          </div>
          <div className="mt-lg">
            <p className="caption">Reference URL</p>
          </div>
          <div className="mt-sm">
            <div>
              <Input
                noBorder
                value={variables.referenceUrl}
                onChange={(e) => {
                  setVariables({
                    ...variables,
                    referenceUrl: e.target.value
                  });
                }}
                placeholder={`E.g. "https://www.exiobase.eu/"`}
                className="border-0 bg-transparent px-0 py-0 shadow-none"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
