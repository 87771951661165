import { create } from 'zustand';

export type AssetTypeType = 'location' | 'vehicle' | 'equipment';

export type AssetStoreType = {
  activeAssetType: AssetTypeType;
  setActiveAssetType: (type: AssetTypeType) => void;
  activeAssetIdentifier: string | null;
  setActiveAssetIdentifier: (identifier: string | null) => void;
  clearActive: () => void;
};

const useAssetStore = create<AssetStoreType>((set) => ({
  activeAssetType: null,
  setActiveAssetType: (type) => set({ activeAssetType: type }),
  activeAssetIdentifier: null,
  setActiveAssetIdentifier: (identifier) =>
    set({ activeAssetIdentifier: identifier }),
  clearActive: () =>
    set({ activeAssetType: null, activeAssetIdentifier: null })
}));

export default useAssetStore;
