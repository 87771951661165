import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { useState } from 'react';
import { ScopeType } from 'src/@types/emissions';
import {
  GhgProtocolsEnum,
  MeasurementFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import DataCalculatorIcon from 'src/components/core/atoms/IconCircle';
import { Button } from 'src/components/shad-base/button';
import useBackendMutation from 'src/hooks/useBackendMutation';

type Step =
  | 'add-measurement'
  | 'edit-input-frequency'
  | 'mark-as-avoided';

export default function CompleteMissingDataStepper({
  selectedMeasurement,
  setSelectedMeasurement,
  onOpenChange,
  setOpenCreateSubsequentMeasurementDialog,
  setMeasurementSearchDialogOpen,
  setEditInputFrequencyDialogOpen
}: {
  selectedMeasurement: MeasurementFragmentFragment;
  setSelectedMeasurement: (
    measurement: MeasurementFragmentFragment
  ) => void;
  onOpenChange: (open: boolean) => void;
  setOpenCreateSubsequentMeasurementDialog: (open: boolean) => void;
  setMeasurementSearchDialogOpen: (open: boolean) => void;
  setEditInputFrequencyDialogOpen: (open: boolean) => void;
}) {
  const [selectedStep, setSelectedStep] = useState<
    'add-measurement' | 'edit-input-frequency' | 'mark-as-avoided'
  >('add-measurement');

  const { mutate: markAsAvoided, loading: markingAsAvoided } =
    useBackendMutation({
      mutation: gql`
        mutation MarkMeasurementAsAvoided(
          $input: EditMeasurementInput!
        ) {
          editMeasurement(input: $input) {
            ok
            errors {
              ...ErrorsFragment
            }
          }
        }
      `,
      callbacks: {
        onSuccess: () => {
          setSelectedMeasurement(null);
        }
      }
    });
  return (
    <div className="mt-md flex flex-col flex-nowrap items-center">
      <div className="flex flex-nowrap items-center">
        <div className="mr-sm">
          <DataCalculatorIcon
            calculatorType={selectedMeasurement?.calculatorType}
            scope={Number(selectedMeasurement?.scope) as ScopeType}
            scope3Category={
              selectedMeasurement?.ghgCategory as GhgProtocolsEnum
            }
          />
        </div>
        <div className="flex flex-col flex-nowrap">
          <p className="mt-sm font-bold">
            {selectedMeasurement?.name}
          </p>
          <p className="text-muted ">
            Last updated:{' '}
            {dayjs(selectedMeasurement?.dateOfMeasurement).format(
              'MMMM DD, YYYY'
            )}
          </p>
        </div>
      </div>
      {/* Content */}
      <p className="mb-md mt-lg">
        Please select the option that applies to you.
      </p>
      {[
        'add-measurement',
        'edit-input-frequency',
        'mark-as-avoided'
      ].map((step, idx) => {
        const text = {
          'add-measurement': 'I have measurement data to add.',
          'edit-input-frequency': 'My input frequency has changed.',
          'mark-as-avoided': 'I have avoided this emission source.'
        };
        return (
          <Button
            variant={selectedStep === step ? 'default' : 'outline'}
            onClick={() => setSelectedStep(step as Step)}
            className={
              'w-full max-w-screen-sm ' + (idx !== 0 && 'mt-sm')
            }
          >
            {text[step]}
          </Button>
        );
      })}
      <div className="mt-xl flex w-full items-center justify-end">
        <div className="flex flex-nowrap items-center">
          {selectedStep === 'add-measurement' && (
            <Button
              variant="outline"
              className="mr-sm"
              onClick={() => {
                onOpenChange(false);
                setMeasurementSearchDialogOpen(true);
              }}
            >
              Use existing measurement(s)
            </Button>
          )}
          {selectedStep === 'add-measurement' ? (
            <Button
              onClick={() => {
                onOpenChange(false);
                setOpenCreateSubsequentMeasurementDialog(true);
              }}
            >
              Create new measurement(s)
            </Button>
          ) : selectedStep === 'edit-input-frequency' ? (
            <Button
              onClick={() => {
                setEditInputFrequencyDialogOpen(true);
                onOpenChange(false);
              }}
            >
              Edit input frequency
            </Button>
          ) : selectedStep === 'mark-as-avoided' ? (
            <Button
              variant="success"
              loading={markingAsAvoided}
              onClick={() => {
                markAsAvoided({
                  variables: {
                    input: {
                      branchIdentifier:
                        selectedMeasurement?.branch?.identifier,
                      measurementIdentifier:
                        selectedMeasurement?.identifier,
                      measurement: {
                        avoidedAfterEndDate: true
                      }
                    }
                  },
                  refetchQueries: [
                    'RecurringMeasurementsMissingSubsequentDataQuery'
                  ]
                });
              }}
            >
              Mark as avoided
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
