export default function FilterChip({
  prefix,
  text
}: {
  prefix: string;
  text: string;
}) {
  return (
    <div className="flex h-small-button items-center rounded-md border border-primary p-sm">
      <p className="body2 text-nowrap text-primary">
        {prefix} {text}
      </p>
    </div>
  );
}
