import { Plus } from 'lucide-react';
import MoreActionsMenu from 'src/components/core/molecules/MoreActionsMenu';
import { ColumnType } from 'src/components/core/organisms/PaginatedTable';
import { Button } from 'src/components/shad-base/button';
import {
  ACTIVE_ROW_CLASSNAME,
  DEFAULT_COLUMN_WIDTH_PX,
  DEFAULT_ROW_CLASSNAME,
  updateRowClassName
} from 'src/config';
import Checkbox from '../../atoms/Checkbox';

export default function StickyRow<RowType>({
  rowData,
  getRowIdFromRowData,
  isDisabled,
  columns,
  rowMenuItems,
  onRowClick,
  checkedRows = {},
  setCheckedRows,
  // activeRowId,
  rowRefs,
  isAddNew = false,
  isLocked = false,
  hideCheckbox
}: {
  rowData: RowType;
  getRowIdFromRowData: (rowData: RowType) => string;
  isDisabled: boolean;
  columns: ColumnType<RowType, null>;
  rowMenuItems: {
    key: string;
    label: string;
    onClick: (key: string) => void;
  }[];
  checkedRows: Record<string, boolean>;
  setCheckedRows: (checkedRows: Record<string, boolean>) => void;
  onRowClick: (
    row: RowType,
    isDisabled: boolean,
    isAddNew: boolean
  ) => void;
  activeRowId: string;
  rowRefs: React.MutableRefObject<object>;
  isHoveringState?: [
    string | false,
    (hovering: string | false) => void
  ];
  isAddNew?: boolean;
  isLocked?: boolean;
  hideCheckbox?: boolean;
}) {
  const rowId = getRowIdFromRowData(rowData);
  // const isActiveRow = activeRowId === rowId;

  return (
    <div
      className={
        DEFAULT_ROW_CLASSNAME + (onRowClick ? 'cursor-pointer' : '')
      }
      onClick={() => {
        onRowClick ? onRowClick(rowData, isDisabled, isAddNew) : null;
      }}
      onMouseEnter={
        onRowClick
          ? () => {
              updateRowClassName({
                rowRefs: rowRefs,
                nodeId: rowId,
                className: ACTIVE_ROW_CLASSNAME
              });
            }
          : null
      }
      onMouseLeave={
        onRowClick
          ? () => {
              updateRowClassName({
                rowRefs: rowRefs,
                nodeId: rowId,
                className: DEFAULT_ROW_CLASSNAME
              });
            }
          : null
      }
      ref={(el) => {
        // check if rowId in rowRefs
        if (!rowRefs.current[rowId]) {
          // if not, add it
          rowRefs.current[rowId] = {};
        }
        const key = isLocked ? 'locked' : 'unlocked';
        if (rowRefs.current?.[rowId]?.[key] === el) {
          return;
        } else {
          // if not, add it
          rowRefs.current[rowId][key] = el;
        }
      }}
    >
      <div className="flex h-full max-h-full w-full flex-nowrap items-center">
        {isLocked && !isAddNew && !hideCheckbox && (
          <div className="mx-md flex h-full max-h-full w-[64px] items-center justify-center overflow-hidden px-sm">
            <Checkbox
              selected={checkedRows[rowId]}
              onClick={() => {
                setCheckedRows({
                  ...checkedRows,
                  [rowId]: !checkedRows[rowId]
                });
              }}
            />
          </div>
        )}
        {isLocked && isAddNew && (
          <div className="relative flex h-full max-h-full max-w-[64px] items-center justify-center overflow-hidden px-sm">
            <Button variant="outline" size="icon">
              <Plus className="h-icon w-icon" />
            </Button>
          </div>
        )}
        {Object.keys(columns).map((key, idx) => {
          return (
            <div
              key={idx}
              className={
                'relative flex h-full max-h-full overflow-hidden text-ellipsis px-sm ' +
                (idx !== columns.length - 1 ? ' border-r ' : '') +
                (isAddNew && idx !== columns.length - 1
                  ? 'border-transparent '
                  : 'border-border ')
              }
              style={{
                width: columns[key]?.width || DEFAULT_COLUMN_WIDTH_PX,
                minWidth:
                  columns[key]?.width || DEFAULT_COLUMN_WIDTH_PX
              }}
            >
              <div
                className={
                  'flex items-center ' +
                  (columns[key]?.type === 'number'
                    ? 'justify-end'
                    : 'justify-start')
                }
              >
                {columns[key]?.renderRowCell(
                  rowData,
                  isDisabled,
                  isAddNew
                )}
              </div>
            </div>
          );
        })}
        <div className="absolute right-0 flex h-full max-h-full flex-nowrap items-center pr-sm">
          {rowMenuItems?.length > 0 && !isDisabled ? (
            <MoreActionsMenu menuItems={rowMenuItems} />
          ) : null}
        </div>
      </div>
    </div>
  );
}
