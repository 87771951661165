import _ from 'lodash';
import {
  Dialog,
  DialogContent
} from 'src/components/shad-base/dialog';
import { AssetTypeType } from 'src/hooks/store/useAssetStore';

export default function CreateAssetDialog({
  open,
  onOpenChange,
  type,
  children
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  type: AssetTypeType;
  children: React.ReactNode;
}) {
  const descriptions = {
    location:
      'This includes physical places you rent, lease, and own.',
    vehicle:
      'This includes any vehicles used for business operations.',
    equipment:
      'This includes any equipment with an external power source used for business operations.'
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-screen-md">
        <div className="flex flex-col flex-nowrap items-center ">
          <div className="pt-md">
            <p className="subtitle1">
              {type === 'equipment'
                ? 'Add Equipment'
                : `Add a ${_.startCase(type)}`}
            </p>
          </div>

          <p className="max-w-paragraph text-center text-muted">
            {descriptions[type]}
          </p>
          <div className="mb-md ml-md mt-md">
            {/* <LearnButton /> */}
          </div>
          <div className="mt-lg w-full">{children}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
