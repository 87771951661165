import dayjs from 'dayjs';
import { ArrowLeft, ArrowRight, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import MultiSwitch from 'src/components/carbon/atoms/MultiSwitch';
import { Button } from 'src/components/shad-base/button';
import { Calendar } from 'src/components/shad-base/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'src/components/shad-base/popover';
import useMeasureStore from 'src/hooks/store/useMeasureStore';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';

export default function DateFilters({
  isJoinable = false
}: {
  isJoinable?: boolean;
}) {
  const [open, setOpen] = useState(false);

  const {
    selectedRangeType,
    setSelectedRangeType,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate
  } = useMeasureStore((store) => {
    return {
      selectedRangeType: store.selectedRangeType,
      setSelectedRangeType: store.setSelectedRangeType,
      selectedEndDate: store.selectedEndDate,
      selectedStartDate: store.selectedStartDate,
      setSelectedStartDate: store.setSelectedStartDate,
      setSelectedEndDate: store.setSelectedEndDate
    };
  });

  const [activeOption, setActiveOption] = useState<
    'Month' | 'Quarter' | 'Year' | 'Custom'
  >('Month');

  const thisYear = dayjs().year();

  const [selectedYear, setSelectedYear] = useState(thisYear);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedQuarter, setSelectedQuarter] = useState(null);
  const [selectedCustomStartDate, setSelectedCustomStartDate] =
    useState(dayjs().subtract(1, 'year').toDate());
  const [selectedCustomEndDate, setSelectedCustomEndDate] = useState(
    dayjs().toDate()
  );

  const startAndEnd =
    selectedStartDate !== null && selectedEndDate !== null;
  const startNotEnd =
    selectedStartDate !== null && selectedEndDate === null;
  const endNotStart =
    selectedStartDate === null && selectedEndDate !== null;
  const dateRangeExists = startAndEnd || startNotEnd || endNotStart;

  const formattedStartDate =
    dayjs(selectedStartDate).format('MMM DD, YYYY');
  const formattedEndDate =
    dayjs(selectedEndDate).format('MMM DD, YYYY');

  useEffect(() => {
    if (dateRangeExists) {
      // Initialize the date range picker with the currently selected date range
      setActiveOption(selectedRangeType);
      setSelectedYear(dayjs(selectedEndDate).year());
      if (selectedRangeType === 'Month') {
        setSelectedMonth(dayjs(selectedEndDate).month());
        setSelectedQuarter(null);
      } else if (selectedRangeType === 'Quarter') {
        setSelectedQuarter(
          Math.floor(dayjs(selectedEndDate).month() / 3)
        );
        setSelectedMonth(null);
      } else if (selectedRangeType === 'Year') {
        setSelectedMonth(null);
        setSelectedQuarter(null);
      } else {
        setSelectedCustomStartDate(selectedStartDate);
        setSelectedCustomEndDate(selectedEndDate);
        setSelectedMonth(null);
        setSelectedQuarter(null);
        setSelectedYear(null);
      }
      // If not date range
    } else {
      setSelectedYear(thisYear);
      setSelectedMonth(null);
      setSelectedQuarter(null);
      setSelectedCustomEndDate(null);
      setSelectedCustomStartDate(null);
      setActiveOption('Month');
    }
  }, [selectedEndDate, selectedStartDate, selectedRangeType]);

  const {
    activeReportingPeriodStartDate,
    activeReportingPeriodEndDate
  }: SettingsStoreType = useSettingsStore();

  useEffect(() => {
    if (
      !dateRangeExists &&
      activeReportingPeriodStartDate &&
      activeReportingPeriodEndDate
    ) {
      setSelectedStartDate(activeReportingPeriodStartDate);
      setSelectedEndDate(activeReportingPeriodEndDate);
    }
  }, [activeReportingPeriodStartDate, activeReportingPeriodEndDate]);

  const months = [...Array(12).keys()].map((key) =>
    dayjs()
      .month(key)
      .toDate()
      .toLocaleString('en', { month: 'short' })
  );

  const quarters = [
    'January - March',
    'April - June',
    'July - September',
    'October - December'
  ];
  const years = [...Array(51).keys()]
    .map((key) => thisYear + 30 - key)
    .reverse();

  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild className="w-full">
        <div
          className={
            'flex h-small-button cursor-pointer flex-nowrap items-center ' +
            (isJoinable ? '' : 'pr-md')
          }
          style={{
            ...(isJoinable && {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRight: 0
            })
          }}
        >
          <Button
            variant="outline"
            size="sm"
            className={
              isJoinable ? 'h-6 border-0 bg-transparent' : ''
            }
          >
            {startAndEnd
              ? formattedStartDate + ' - ' + formattedEndDate
              : startNotEnd
                ? 'From ' + formattedStartDate
                : endNotStart
                  ? 'Up to ' + formattedEndDate
                  : 'All dates'}
          </Button>
          {dateRangeExists && (
            <X
              className="ml-sm h-4 w-4"
              onClick={(e) => (
                e.stopPropagation(),
                setSelectedEndDate(null),
                setSelectedStartDate(null)
              )}
            />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <div className="flex flex-col flex-nowrap p-sm">
          <div>
            <div className="flex flex-nowrap items-center justify-between">
              <div>
                <MultiSwitch
                  options={[
                    { key: 'Month', label: 'Month' },
                    { key: 'Quarter', label: 'Quarter' },
                    { key: 'Year', label: 'Year' },
                    { key: 'Custom', label: 'Custom' }
                  ]}
                  activeOption={activeOption}
                  setActiveOption={setActiveOption}
                />
              </div>
              {activeOption === 'Custom' && (
                <div className="flex flex-nowrap items-center">
                  <Button
                    variant="outline"
                    size="sm"
                    className="mr-sm"
                    disabled={
                      selectedCustomStartDate === null &&
                      selectedCustomEndDate === null
                    }
                    onClick={() => {
                      setSelectedCustomEndDate(null);
                      setSelectedCustomStartDate(null);
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    size="sm"
                    disabled={
                      !selectedCustomEndDate &&
                      !selectedCustomStartDate
                    }
                    onClick={() => {
                      setSelectedEndDate(selectedCustomEndDate);
                      setSelectedStartDate(selectedCustomStartDate);
                      setSelectedRangeType('Custom');
                      setOpen(false);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              )}
            </div>
          </div>

          {activeOption !== 'Year' && (
            <div className="mt-sm">
              <div
                className="
              flex flex-nowrap items-center justify-between"
              >
                <div>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => {
                      if (selectedYear > 1990) {
                        setSelectedYear(selectedYear - 1);
                      }
                    }}
                  >
                    <ArrowLeft className="h-icon w-icon" />
                  </Button>
                </div>
                <div>
                  <p className="body2">{selectedYear}</p>
                </div>
                <div>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => {
                      if (selectedYear < 2050) {
                        setSelectedYear(selectedYear + 1);
                      }
                    }}
                  >
                    <ArrowRight className="h-icon w-icon" />
                  </Button>
                </div>
              </div>
            </div>
          )}
          <div className="mt-sm">
            {activeOption === 'Month' ? (
              <div className="grid grid-cols-4">
                {months.map((month, index) => (
                  <div
                    className={
                      'mb-sm flex h-[40px] w-full cursor-pointer items-center justify-center rounded-sm hover:bg-primary/10 ' +
                      (index === selectedMonth
                        ? 'bg-primary/10'
                        : 'bg-background')
                    }
                    key={month}
                    onClick={() => {
                      setSelectedStartDate(
                        dayjs()
                          .year(selectedYear)
                          .month(index)
                          .startOf('month')
                          .toDate()
                      );
                      setSelectedEndDate(
                        dayjs()
                          .year(selectedYear)
                          .month(index)
                          .endOf('month')
                          .toDate()
                      );
                      setSelectedRangeType('Month');
                      setOpen(false);
                    }}
                  >
                    <p className="body2">{month}</p>
                  </div>
                ))}
              </div>
            ) : activeOption === 'Quarter' ? (
              <div className="flex flex-col flex-nowrap">
                {quarters.map((quarter, idx) => {
                  return (
                    <div
                      className={
                        'flex h-[40px] cursor-pointer items-center justify-start rounded-sm px-sm hover:bg-primary/10 ' +
                        (idx === selectedQuarter
                          ? 'bg-primary/10'
                          : 'bg-background')
                      }
                      onClick={() => {
                        const startDate = dayjs()
                          .year(selectedYear)
                          .month(idx * 3)
                          .startOf('month')
                          .toDate();
                        const endDate = dayjs()
                          .year(selectedYear)
                          .month(idx * 3 + 2)
                          .endOf('month')
                          .toDate();
                        setSelectedStartDate(startDate);
                        setSelectedEndDate(endDate);
                        setSelectedRangeType('Quarter');
                        setOpen(false);
                      }}
                    >
                      <p className="body2">{quarter}</p>
                    </div>
                  );
                })}
              </div>
            ) : activeOption === 'Year' ? (
              <div className="grid max-h-[210px] max-w-[350px] grid-cols-4 overflow-y-auto">
                {years.map((year) => {
                  return (
                    <div
                      className={
                        'mb-sm flex h-[40px] w-full cursor-pointer items-center justify-center rounded-sm hover:bg-primary/10 ' +
                        (year === selectedYear
                          ? 'bg-primary/10'
                          : 'bg-background')
                      }
                      key={year}
                      onClick={() => {
                        setSelectedStartDate(
                          dayjs().year(year).startOf('year').toDate()
                        );
                        setSelectedEndDate(
                          dayjs().year(year).endOf('year').toDate()
                        );
                        setSelectedRangeType('Year');
                        setOpen(false);
                      }}
                    >
                      <p className="body2">{year}</p>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex flex-nowrap">
                <Calendar
                  selected={
                    selectedCustomStartDate
                      ? dayjs(selectedCustomStartDate).toDate()
                      : null
                  }
                  maxDate={selectedCustomEndDate}
                  onSelect={(value) => {
                    setSelectedCustomStartDate(value);
                  }}
                  mode="single"
                  initialFocus
                  captionLayout="dropdown-buttons"
                />
                <Calendar
                  selected={
                    selectedCustomEndDate
                      ? dayjs(selectedCustomEndDate).toDate()
                      : null
                  }
                  minDate={selectedCustomStartDate}
                  onSelect={(value) =>
                    setSelectedCustomEndDate(value)
                  }
                  mode="single"
                  initialFocus
                  captionLayout="dropdown-buttons"
                />
              </div>
            )}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
