import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Search } from 'lucide-react';
import React, { useState } from 'react';
import {
  DataCalculatorTypesEnum,
  GhgProtocolsEnum,
  MeasurementFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import { DatePicker } from 'src/components/core/atoms/DatePicker';
import DataCalculatorIcon from 'src/components/core/atoms/IconCircle';
import Input from 'src/components/core/atoms/Input';
import Skeleton from 'src/components/core/atoms/Skeleton';
import { MultiSelect } from 'src/components/core/molecules/MultiSelect';
import SingleSelect from 'src/components/core/molecules/SingleSelect';
import PaginatedTable, {
  CellDataType
} from 'src/components/core/organisms/PaginatedTable';
import { Badge } from 'src/components/shad-base/badge';
import { Button } from 'src/components/shad-base/button';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import { fEnum } from 'src/utils/format';
import { getFormattedMeasurementInterval } from 'src/views/MeasureView/detail/MeasurementDetailView/MeasurementDetailViewPanelContent';
import { dataCalculatorResponseFragment } from 'src/views/Scope1View';

type MeasurementColumnType =
  | 'name'
  | 'kgCo2e'
  | 'ghgCategory'
  | 'calculatorType'
  | 'scope'
  | 'ghgCategory'
  | 'unit'
  | 'dateOfMeasurement'
  | 'dateRange'
  | 'avoidedAfterEndDate'
  | 'interval'
  | 'strategy'
  | 'state';

type MeasurementFiltersType = {
  ghgCategories?: GhgProtocolsEnum[];
  calculatorType?: DataCalculatorTypesEnum;
  minDateOfMeasurement?: Date;
  maxDateOfMeasurement?: Date;
  isRecurring?: boolean;
  nameContains?: string;
  branchNameContains?: string;
  descriptionContains?: string;
};

const measurementColumns = {
  name: {
    key: 'name',
    label: 'Name',
    type: 'string' as CellDataType,
    renderRowCell: (cellData) => {
      return (
        <p className="body2 truncate text-nowrap">{cellData.name}</p>
      );
    },
    gridTemplateFr: '.7fr'
  },
  ghgCategory: {
    key: 'ghgCategory',
    label: 'GHG Category',
    type: 'string' as CellDataType,
    renderRowCell: (cellData) => {
      return (
        <p className="body2 truncate text-nowrap">
          {fEnum(cellData.ghgCategory)}
        </p>
      );
    },
    gridTemplateFr: '.7fr'
  },
  kgCo2e: {
    key: 'kgCo2e',
    label: 'kg CO2e',
    type: 'number' as CellDataType,
    renderRowCell: (cellData) => {
      return (
        <p className="body2 truncate text-nowrap">
          {cellData.kgCo2e}
        </p>
      );
    },
    gridTemplateFr: '.5fr'
  },
  dateOfMeasurement: {
    key: 'dateOfMeasurement',
    label: 'Date',
    type: 'date' as CellDataType,
    renderRowCell: (cellData) => {
      return cellData?.startDate && cellData?.endDate ? null : (
        <p className="body2 truncate text-nowrap">
          {dayjs(cellData.dateOfMeasurement).format('MMM DD, YYYY')}
        </p>
      );
    },
    gridTemplateFr: '.5fr'
  },
  dateRange: {
    key: 'dateRange',
    label: 'Date Range',
    type: 'date' as CellDataType,
    renderRowCell: (cellData) => {
      return cellData?.startDate && cellData?.endDate ? (
        <p className="body2 truncate text-nowrap">
          {dayjs(cellData.startDate).format('MMM DD, YYYY')} -{' '}
          {dayjs(cellData.endDate).format('MMM DD, YYYY')}
        </p>
      ) : null;
    },
    gridTemplateFr: '.5fr'
  },
  calculatorType: {
    key: 'calculatorType',
    label: 'Emission Source',
    type: 'string' as CellDataType,
    renderRowCell: (cellData) => {
      return (
        <div className="flex flex-row flex-nowrap items-center">
          <DataCalculatorIcon
            size="sm"
            calculatorType={cellData.calculatorType}
            scope={cellData.scope}
            scope3Category={cellData.ghgCategory as GhgProtocolsEnum}
          />
          <p className="body2 ml-sm truncate text-nowrap">
            {cellData.calculatorType
              ? fEnum(cellData.calculatorType)
              : 'Custom'}
          </p>
        </div>
      );
    },
    gridTemplateFr: '.5fr'
  },
  interval: {
    key: 'interval',
    label: 'Input Frequency',
    type: 'string' as CellDataType,
    renderRowCell: (cellData) => {
      const formattedInterval = getFormattedMeasurementInterval(
        cellData.intervalValue,
        cellData.intervalUnit
      );
      return <Badge>Every {formattedInterval}</Badge>;
    },
    gridTemplateFr: '.5fr'
  },
  scope: {
    key: 'scope',
    label: 'Scope',
    type: 'string' as CellDataType,
    renderRowCell: (cellData) => {
      return (
        <p className="body2 truncate text-nowrap">{cellData.scope}</p>
      );
    },
    gridTemplateFr: '.5fr'
  }
};
export default function MeasurementSearcher({
  initialFilters,
  initialColumns = [
    'name',
    'ghgCategory',
    'kgCo2e',
    'dateOfMeasurement'
  ],
  singleSelectOnly = false,
  onSelectMeasurements,
  buttonIsLoading,
  searchPlaceholder = 'Search for a measurement...',
  renderDynamicButtonContent
}: {
  initialFilters?: MeasurementFiltersType;
  initialColumns?: MeasurementColumnType[];
  singleSelectOnly?: boolean;
  onSelectMeasurements?: (
    measurementIdentifiers: string[],
    measurements?: MeasurementFragmentFragment
  ) => void;
  buttonIsLoading?: boolean;
  searchPlaceholder?: string;
  renderDynamicButtonContent?: (
    selectedMeasurements: MeasurementFragmentFragment[]
  ) => React.ReactNode;
}) {
  const { activeReportingPeriodId }: SettingsStoreType =
    useSettingsStore();
  const [searchValue, setSearchValue] = useState('');
  const [searchBy, setSearchBy] = useState<
    | 'nameContains'
    | 'branchNameContains'
    | 'descriptionContains'
    | null
  >('nameContains');
  const [filters, setFilters] = useState<MeasurementFiltersType>({
    ghgCategories:
      initialFilters?.ghgCategories ??
      Object.values(GhgProtocolsEnum),
    calculatorType: initialFilters?.calculatorType ?? null,
    minDateOfMeasurement:
      initialFilters?.minDateOfMeasurement ?? null,
    maxDateOfMeasurement:
      initialFilters?.maxDateOfMeasurement ?? null,
    isRecurring: initialFilters?.isRecurring ?? null
  });

  const defaultRowsPerPage = 10;
  const [paginationState, setPaginationState] = useState({
    startIndex: 0,
    stopIndex: defaultRowsPerPage,
    pageNumber: 0,
    rowsPerPage: defaultRowsPerPage
  });

  const [
    selectedMeasurementIdentifiers,
    setSelectedMeasurementIdentifiers
  ] = useState<string[]>([]);
  const [selectedColumns, setSelectedColumns] =
    useState<MeasurementColumnType[]>(initialColumns);

  const columns = selectedColumns.map(
    (column) => measurementColumns[column]
  );

  const [
    searchMeasurements,
    {
      data: measurementSearchResults,
      loading: loadingSearchResults,
      refetch: refetchSearchResults
    }
  ] = useLazyQuery(
    gql`
      ${dataCalculatorResponseFragment}
      query MeasurementSearchQuery(
        $branchNameContains: String
        $nameContains: String
        $descriptionContains: String
        $scope: Int
        $dataCalculatorType: DataCalculatorTypesEnum
        $ghgCategories: [GHGProtocolsEnum]
        $minKgCo2e: Decimal
        $maxKgCo2e: Decimal
        $unit: UnitsEnum
        $minDateOfMeasurement: DateTime
        $maxDateOfMeasurement: DateTime
        $minStartDate: DateTime
        $maxEndDate: DateTime
        $isRecurring: Boolean
        $avoidedAfterEndDate: Boolean
        $belongsToAStrategy: Boolean
        $states: [MeasurementStatesEnum]
        $startDate: DateTime
        $endDate: DateTime
        $offset: Int
        $before: String
        $after: String
        $first: Int
        $last: Int
        $reportingPeriodIdentifier: String
      ) {
        measurementSearch {
          ok
          data {
            measurements(
              branchNameContains: $branchNameContains
              nameContains: $nameContains
              descriptionContains: $descriptionContains
              scope: $scope
              dataCalculatorType: $dataCalculatorType
              ghgCategories: $ghgCategories
              minKgCo2e: $minKgCo2e
              maxKgCo2e: $maxKgCo2e
              unit: $unit
              minDateOfMeasurement: $minDateOfMeasurement
              maxDateOfMeasurement: $maxDateOfMeasurement
              minStartDate: $minStartDate
              maxEndDate: $maxEndDate
              isRecurring: $isRecurring
              avoidedAfterEndDate: $avoidedAfterEndDate
              belongsToAStrategy: $belongsToAStrategy
              states: $states
              offset: $offset
              before: $before
              after: $after
              first: $first
              last: $last
            ) {
              totalCount
              edges {
                node {
                  ...MeasurementFragment
                  location {
                    ...LocationFragment
                    dataCalculators(
                      reportingPeriodIdentifier: $reportingPeriodIdentifier
                    ) {
                      ...DataCalculatorResponseFragment
                    }
                  }
                  vehicle {
                    ...VehicleFragment
                    dataCalculators(
                      reportingPeriodIdentifier: $reportingPeriodIdentifier
                    ) {
                      ...DataCalculatorResponseFragment
                    }
                  }
                  equipment {
                    ...EquipmentFragment
                    dataCalculators(
                      reportingPeriodIdentifier: $reportingPeriodIdentifier
                    ) {
                      ...DataCalculatorResponseFragment
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only'
    }
  );

  const measurements =
    measurementSearchResults?.measurementSearch?.data?.measurements;

  const handleSearch = () => {
    searchMeasurements({
      variables: {
        reportingPeriodIdentifier: activeReportingPeriodId,
        first: paginationState?.rowsPerPage ?? defaultRowsPerPage,
        offset: paginationState?.startIndex ?? 0,
        nameContains:
          searchBy === 'nameContains' ? searchValue : undefined,
        branchNameContains:
          searchBy === 'branchNameContains' ? searchValue : undefined,
        descriptionContains:
          searchBy === 'descriptionContains'
            ? searchValue
            : undefined,
        ghgCategories: filters.ghgCategories,
        dataCalculatorType: filters.calculatorType
          ? filters.calculatorType
          : undefined,
        minDateOfMeasurement: filters.minDateOfMeasurement
          ? filters.minDateOfMeasurement
          : undefined,
        maxDateOfMeasurement: filters.maxDateOfMeasurement
          ? filters.maxDateOfMeasurement
          : undefined,
        isRecurring: filters.isRecurring
          ? filters.isRecurring
          : undefined
      }
    });
  };
  const selectedMeasurements = measurements?.edges
    .map((edge) => edge.node)
    .filter((measurement) =>
      selectedMeasurementIdentifiers.includes(measurement.identifier)
    );

  return (
    <div
      className="flex flex-col flex-nowrap"
      onKeyDown={(e) => {
        if (e.code === 'Enter' && searchValue) {
          handleSearch();
        }
      }}
    >
      {/* Search input */}
      <div className="mt-lg w-full">
        <div className="flex flex-row flex-nowrap items-center">
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={searchPlaceholder}
            startAdornment={
              <SingleSelect
                shrinkToContentSize
                disableClear
                className="rounded-none border-none bg-muted/10 "
                options={[
                  {
                    key: 'nameContains',
                    label: 'Name Contains:'
                  },
                  {
                    key: 'descriptionContains',
                    label: 'Description Contains:'
                  },
                  {
                    key: 'branchNameContains',
                    label: 'Branch Name Contains:'
                  }
                ]}
                value={searchBy}
                setValue={(value) =>
                  setSearchBy(
                    value as
                      | 'nameContains'
                      | 'branchNameContains'
                      | 'descriptionContains'
                      | null
                  )
                }
              />
            }
          />
          <Button
            onClick={() => handleSearch()}
            loading={loadingSearchResults}
            className="ml-sm"
            disabled={!searchValue}
          >
            <div className="flex flex-row flex-nowrap items-center">
              <Search className="mr-sm h-icon w-icon" />
              Search
            </div>
          </Button>
        </div>
      </div>
      {/* Filters */}
      <div className="mt-sm w-full">
        <div className="flex flex-row flex-nowrap items-center">
          <div>
            <MultiSelect
              prefixText="GHG Categories: "
              options={Object.values(GhgProtocolsEnum).map(
                (ghgCategory) => ({
                  key: ghgCategory,
                  label: fEnum(ghgCategory)
                })
              )}
              checked={filters.ghgCategories}
              setChecked={(checked: string[]) => {
                setFilters({
                  ...filters,
                  ghgCategories: checked as GhgProtocolsEnum[]
                });
              }}
            />
          </div>
          <div className="ml-sm">
            <SingleSelect
              options={Object.values(DataCalculatorTypesEnum).map(
                (calculatorType) => ({
                  key: calculatorType,
                  label: fEnum(calculatorType)
                })
              )}
              prefixText="Emission Source: "
              value={filters.calculatorType}
              setValue={(value: DataCalculatorTypesEnum) => {
                setFilters({ ...filters, calculatorType: value });
              }}
            />
          </div>
          <div className="ml-sm">
            <DatePicker
              size="sm"
              isRange
              minDate={initialFilters?.minDateOfMeasurement || null}
              maxDate={initialFilters?.maxDateOfMeasurement || null}
              dateRange={{
                startDate: filters.minDateOfMeasurement,
                endDate: filters.maxDateOfMeasurement
              }}
              setDateRange={(value) => {
                setFilters({
                  ...filters,
                  minDateOfMeasurement: value.startDate || null,
                  maxDateOfMeasurement: value.endDate || null
                });
              }}
              noDateRangeText="Filter by date range..."
            />
          </div>
        </div>
      </div>
      {/* Search results */}
      <div className="mt-md flex w-full flex-col flex-nowrap">
        <div className="mb-md flex flex-row flex-nowrap items-center justify-between">
          <p className="text-muted">
            Search results{' '}
            {loadingSearchResults
              ? null
              : measurements?.totalCount
                ? `(${measurements?.totalCount})`
                : null}
          </p>
          {measurements?.totalCount ? (
            <div className="ml-md">
              <MultiSelect
                prefixText="Columns: "
                options={Object.keys(measurementColumns).map(
                  (column) => ({
                    key: column,
                    label: measurementColumns[column].label
                  })
                )}
                checked={selectedColumns}
                setChecked={(checked: string[]) => {
                  // Sort checked columns by the order they appear in the measurementColumns object
                  const sortedCheckedColumns = checked.sort(
                    (a, b) =>
                      Object.keys(measurementColumns).indexOf(a) -
                      Object.keys(measurementColumns).indexOf(b)
                  );
                  setSelectedColumns(
                    sortedCheckedColumns as MeasurementColumnType[]
                  );
                }}
              />
            </div>
          ) : null}
        </div>
        {loadingSearchResults ? (
          <Skeleton count={3} />
        ) : measurements?.totalCount > 0 ? (
          <PaginatedTable<MeasurementFragmentFragment>
            columns={columns}
            data={measurements}
            onRowClick={(measurementIdentifier) => {
              if (singleSelectOnly) {
                setSelectedMeasurementIdentifiers([
                  measurementIdentifier
                ]);
              } else {
                if (
                  selectedMeasurementIdentifiers.includes(
                    measurementIdentifier
                  )
                ) {
                  // remove from selectedMeasurements
                  setSelectedMeasurementIdentifiers(
                    selectedMeasurementIdentifiers.filter(
                      (id) => id !== measurementIdentifier
                    )
                  );
                } else {
                  // add to selectedMeasurements
                  setSelectedMeasurementIdentifiers([
                    ...selectedMeasurementIdentifiers,
                    measurementIdentifier
                  ]);
                }
              }
            }}
            selectedRows={selectedMeasurementIdentifiers}
            setSelectedRows={setSelectedMeasurementIdentifiers}
            totalCount={measurements?.totalCount}
            load={(startIndex, rowsPerPage) => {
              refetchSearchResults({
                first: rowsPerPage,
                offset: startIndex
              });
            }}
            paginationState={paginationState}
            setPaginationState={setPaginationState}
          />
        ) : (
          <p>
            No measurements found. Try a different search value or
            adjust your filters.
          </p>
        )}
      </div>
      <div className="mt-lg flex w-full flex-row flex-nowrap justify-end">
        {selectedMeasurementIdentifiers?.length > 0 && (
          <Button
            variant="outline"
            className="mr-sm"
            onClick={() => setSelectedMeasurementIdentifiers([])}
          >
            Clear Selected
          </Button>
        )}
        <Button
          disabled={selectedMeasurementIdentifiers.length === 0}
          loading={buttonIsLoading}
          onClick={() => {
            onSelectMeasurements(
              selectedMeasurementIdentifiers,
              selectedMeasurements
            );
          }}
        >
          {renderDynamicButtonContent
            ? renderDynamicButtonContent(selectedMeasurements)
            : selectedMeasurementIdentifiers?.length === 0
              ? 'Select Measurements'
              : `Select Measurements (${selectedMeasurementIdentifiers.length})`}
        </Button>
      </div>
    </div>
  );
}
