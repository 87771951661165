import { type ClassValue, clsx } from 'clsx';
import { useEffect, useState } from 'react';
import {
  DataCalculatorResponseFragmentFragment,
  MeasurementFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}

export function sortDataCalculatorResponses(
  dataCalculatorResponses: DataCalculatorResponseFragmentFragment[]
) {
  const calculators = [...dataCalculatorResponses];
  calculators.sort((a, b) => {
    if (a.measurements.length > 0 && !(b.measurements.length > 0)) {
      return 1;
    } else if (
      !(a.measurements.length > 0) &&
      b.measurements.length > 0
    ) {
      return -1;
    } else {
      return 0;
    }
  });
  return calculators;
}

export function sortMeasurements(
  measurements: MeasurementFragmentFragment[]
) {
  const allMeasurements = [...measurements];
  allMeasurements.sort((a, b) => {
    if (a.startDate > b.startDate || a.startDate > b.endDate) {
      return 1;
    } else if (b.startDate > a.startDate || b.startDate > a.endDate) {
      return -1;
    } else {
      return 0;
    }
  });
  return allMeasurements;
}
