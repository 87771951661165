/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ArrowLeftToLine,
  ArrowRightToLine,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import { Dispatch, SetStateAction } from 'react';
import SingleSelect from 'src/components/core/molecules/SingleSelect';
import { Button } from 'src/components/shad-base/button';
import useDevice from 'src/hooks/useDevice';

export default function PaginatedTableFooter({
  totalCount,
  fetchCount,
  paginationState,
  setPaginationState
}: {
  totalCount: number;
  fetchCount: number;
  paginationState: {
    startIndex: number;
    stopIndex: number;
    pageNumber: number;
    rowsPerPage: number;
  };
  setPaginationState: Dispatch<
    SetStateAction<{
      startIndex: number;
      stopIndex: number;
      pageNumber: number;
      rowsPerPage: number;
    }>
  >;
}) {
  const { isMobile } = useDevice();
  const rowsPerPageOptions = {
    '5': '5',
    '10': '10',
    '25': '25',
    '100': '100'
  };

  const handlePageChange = (event, requestedPage) => {
    const requestedStartIndex =
      requestedPage * paginationState.rowsPerPage;
    setPaginationState({
      ...paginationState,
      startIndex: requestedStartIndex,
      stopIndex: requestedStartIndex + paginationState.rowsPerPage,
      pageNumber: requestedPage
    });
  };

  return (
    <div className="mt-md w-full">
      <div
        className={
          'flex w-full flex-nowrap items-center ' +
          (isMobile ? 'justify-start' : 'justify-end')
        }
      >
        {/* Rows per page */}
        {!isMobile && (
          <div className="w-full">
            <div className="flex w-full flex-nowrap items-center justify-end ">
              <div>
                <SingleSelect
                  prefixText="Rows per page: "
                  disableClear
                  value={String(paginationState?.rowsPerPage)}
                  setValue={(value) => {
                    setPaginationState({
                      ...paginationState,
                      rowsPerPage: parseInt(value)
                    });
                  }}
                  options={['5', '10', '25', '100'].map((num) => {
                    return {
                      key: num,
                      label: num
                    };
                  })}
                />
              </div>
            </div>
          </div>
        )}

        {/* Num rows */}
        <div className="ml-sm">
          <div className="flex flex-nowrap items-center">
            <p className={'body2 text-nowrap'}>
              {`${paginationState?.startIndex + 1} - ${Math.min(
                paginationState?.startIndex +
                  paginationState?.rowsPerPage,
                totalCount
              )}`}
              &nbsp;
            </p>
            <p className={'body2 text-nowrap'}>of&nbsp;</p>
            <p className={'body2 text-nowrap'}>{totalCount}</p>
          </div>
        </div>

        {/* Page navigation */}
        <div className="ml-sm">
          <div className="flex flex-nowrap">
            <Button
              variant="outline"
              size="icon"
              disabled={paginationState?.pageNumber === 0}
              onClick={(event) => {
                handlePageChange(event, 0);
              }}
            >
              <ArrowLeftToLine className="h-icon w-icon" />
            </Button>
            <Button
              disabled={paginationState?.pageNumber === 0}
              variant="outline"
              size="icon"
              onClick={(event) => {
                handlePageChange(
                  event,
                  paginationState?.pageNumber - 1
                );
              }}
            >
              <ChevronLeft className="h-icon w-icon" />
            </Button>
            <Button
              disabled={
                paginationState?.startIndex + fetchCount >= totalCount
              }
              variant="outline"
              size="icon"
              onClick={(event) => {
                handlePageChange(
                  event,
                  paginationState?.pageNumber + 1
                );
              }}
            >
              <ChevronRight className="h-icon w-icon" />
            </Button>
            <Button
              disabled={
                paginationState?.startIndex + fetchCount >= totalCount
              }
              variant="outline"
              size="icon"
              onClick={(event) => {
                handlePageChange(
                  event,
                  Math.floor(
                    totalCount / paginationState?.rowsPerPage
                  )
                );
              }}
            >
              <ArrowRightToLine className="h-icon w-icon" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
