import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';
import { ChevronLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import {
  DataCalculatorTypesEnum,
  EditMeasurementInput,
  EditMeasurementPayload,
  EmissionFactorFragmentFragment,
  EquipmentFragmentFragment,
  GhgProtocolsEnum,
  LocationFragmentFragment,
  MeasurementDetailViewQueryQuery,
  MeasurementEditInputObject,
  MeasurementFragmentFragment,
  MeasurementStatesEnum,
  SupportingDocumentFragmentFragment,
  SupportingDocumentParentTypesEnum,
  UnitsEnum,
  VehicleFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import { getUnitTypeFromUnit } from 'src/backend/@types';
import GhgCategorySingleSelect from 'src/components/carbon/atoms/GhgCategorySingleSelect';
import StrategySingleSelect from 'src/components/carbon/atoms/StrategySingleSelect';
import UnitSingleSelect from 'src/components/carbon/atoms/UnitSingleSelect';
import EmissionFactorSelector from 'src/components/carbon/organisms/EmissionFactorSelector';
import { Breadcrumbs } from 'src/components/core/atoms/Breadcrumbs';
import Input from 'src/components/core/atoms/Input';
import Link from 'src/components/core/atoms/Link';
import Skeleton from 'src/components/core/atoms/Skeleton';
import { Button } from 'src/components/shad-base/button';
import { NODE_TYPES } from 'src/config';
import useMeasureStore, {
  MeasureStoreType
} from 'src/hooks/store/useMeasureStore';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import {
  EDIT_MEASUREMENT,
  UPDATE_MEASUREMENT_FACTOR
} from 'src/hooks/useBackendMutation/mutations';
import { fEnum, fUnit } from 'src/utils/format';
import SupportingDocumentsSection from 'src/views/ActionsView/SupportingDocumentsSection';
import DetailViewLayout from 'src/views/MeasureView/detail/DetailViewLayout';
import MeasurementDetailViewPanelContent from 'src/views/MeasureView/detail/MeasurementDetailView/MeasurementDetailViewPanelContent';
import MeasurementLineageSection from 'src/views/MeasureView/detail/MeasurementDetailView/MeasurementLineageSection';
import SourceSection from 'src/views/MeasureView/detail/MeasurementDetailView/SourceSection';
import NoteSection from 'src/views/MeasureView/detail/NoteSection';
import TitleSection from 'src/views/MeasureView/detail/TitleSection';

export const MEASUREMENT_STATE_COLOURS = {
  [MeasurementStatesEnum.Recorded]: 'bg-success',
  [MeasurementStatesEnum.Public]: 'bg-primary',
  [MeasurementStatesEnum.ToReview]: 'bg-warning',
  [MeasurementStatesEnum.Archived]: 'bg-destructive',
  [MeasurementStatesEnum.Failed]: 'bg-destructive',
  [MeasurementStatesEnum.Excluded]: 'bg-muted'
};

type MeasurementPropertiesType = {
  unit: UnitsEnum;
  ghgProtocol: GhgProtocolsEnum;
  value: string;
};

const getMeasurementPath = (branchIdentifier, branches) => {
  const branch = branches?.find(
    (branch) => branch?.identifier === branchIdentifier
  );
  const pathArray = branch?.path.split('.');

  let parentPath = pathArray?.length > 0 ? pathArray[0] + '.' : '';
  const parents = [];

  for (let i = 0; i < pathArray?.length - 1; i++) {
    const parentBranch = branches?.find(
      (branch) => branch?.path === parentPath
    );
    if (
      parentBranch &&
      parentBranch?.identifier !== branch?.identifier
    ) {
      parents.push({
        name: parentBranch.name,
        identifier: parentBranch.identifier,
        path: parentBranch.path
      });
    }
    parentPath += pathArray[i + 1] + '.';
  }

  // Add the direct parent branch
  parents.push({
    name: branch?.name,
    identifier: branch?.identifier,
    path: branch?.path
  });

  return parents;
};

export default function MeasurementDetailView() {
  const { activeReportingPeriodId }: SettingsStoreType =
    useSettingsStore();

  const {
    activeNode,
    setActiveNode,
    setIsDetailViewExpanded
  }: MeasureStoreType = useMeasureStore();
  const {
    data: measurementData,
    loading: loadingMeasurement,
    refetch: refetchMeasurement
  } = useQuery<MeasurementDetailViewQueryQuery>(
    gql`
      query MeasurementDetailViewQuery(
        $measurementIdentifier: String!
        $startDate: DateTime
        $endDate: DateTime
        $states: [MeasurementStatesEnum!]
        $reportingPeriodIdentifier: String
      ) {
        measurement(measurementIdentifier: $measurementIdentifier) {
          ok
          errors {
            ...ErrorsFragment
          }
          data {
            ...MeasurementFragment
            calculatorType
            location {
              ...LocationFragment
            }
            vehicle {
              ...VehicleFragment
            }
            equipment {
              ...EquipmentFragment
            }
          }
        }
        tree {
          data {
            branches {
              identifier
              name
              path
            }
          }
        }
      }
    `,
    {
      variables: {
        measurementIdentifier: activeNode.nodeId,
        reportingPeriodIdentifier: activeReportingPeriodId
      }
    }
  );
  const branches = measurementData?.tree?.data?.branches;
  const measurement = measurementData?.measurement
    ?.data as MeasurementFragmentFragment & {
    calculatorType: DataCalculatorTypesEnum;
    location: LocationFragmentFragment;
    vehicle: VehicleFragmentFragment;
    equipment: EquipmentFragmentFragment;
  };

  const emissionFactor = measurement?.emissionFactor;
  const reference = emissionFactor?.reference;
  const noReferenceAvailable =
    !reference ||
    (!reference?.organization &&
      !reference?.source &&
      !reference?.url &&
      !reference?.country &&
      !reference?.year);
  const permissions = measurement?.branch?.userPermissions;

  const measurementPath = getMeasurementPath(
    measurement?.branch?.identifier,
    branches
  );

  const [isEditingEmissionFactor, setIsEditingEmissionFactor] =
    useState(false);

  const [note, setNote] = useState(measurement?.note?.trim() || '');
  const [emissionFactorEditObject, setEmissionFactorEditObject] =
    useState<{
      factor: EmissionFactorFragmentFragment;
    }>({
      factor: null
    });

  const initialProperties = {
    unit: measurement?.valueUnit,
    ghgProtocol: measurement?.ghgCategory as GhgProtocolsEnum,
    value: measurement?.value
  };
  const [properties, setProperties] =
    useState<MeasurementPropertiesType>(initialProperties);

  useEffect(() => {
    setNote(measurement?.note?.trim() || '');
    setProperties(initialProperties);
  }, [measurement]);

  const { mutate: editMeasurementMutation } = useBackendMutation<
    { input: EditMeasurementInput },
    EditMeasurementPayload
  >({
    mutation: EDIT_MEASUREMENT
  });

  const {
    mutate: updateEmissionFactor,
    loading: updatingEmissionFactor
  } = useBackendMutation({
    mutation: UPDATE_MEASUREMENT_FACTOR,
    callbacks: {
      onSuccess: () => {
        setIsEditingEmissionFactor(false);
      }
    }
  });

  const editMeasurement = (
    key: keyof MeasurementEditInputObject,
    value: string | number | Date
  ) => {
    if (value === measurement[key]) return;
    editMeasurementMutation({
      variables: {
        input: {
          branchIdentifier: measurement?.branch?.identifier,
          measurementIdentifier: measurement?.identifier,
          measurement: {
            [key]: value
          }
        }
      }
    });
  };
  const {
    mutate: editMeasurementStrategy,
    loading: editingMeasurementStrategy
  } = useBackendMutation<
    {
      input: EditMeasurementInput;
      startDate: Date;
      endDate: Date;
      states: MeasurementStatesEnum[];
    },
    EditMeasurementPayload
  >({
    mutation: EDIT_MEASUREMENT
  });

  const getDataCalculatorSource = () => {
    if (!measurement?.calculatorType) {
      return null;
    } else {
      return {
        asset:
          measurement?.location ||
          measurement?.vehicle ||
          measurement?.equipment,
        calculatorType: measurement?.calculatorType,
        assetType: measurement?.location
          ? 'location'
          : measurement?.vehicle
            ? 'vehicle'
            : 'equipment'
      };
    }
  };

  const dataCalculatorSource = getDataCalculatorSource();
  const editingDisabled = dataCalculatorSource ? true : false;

  return (
    <DetailViewLayout
      getSubnav={() => (
        <div className="flex flex-nowrap items-center px-sm ">
          <Button
            variant="outline"
            size="icon"
            onClick={() => setIsDetailViewExpanded(false)}
            className="mr-md"
          >
            <ChevronLeft className="h-6 w-6" />
          </Button>
          <Breadcrumbs
            loading={loadingMeasurement}
            items={[
              ...measurementPath.map((branch) => {
                return {
                  key: branch.identifier,
                  label: branch.name,
                  onClick: () => {
                    setActiveNode({
                      nodeId: branch.identifier,
                      type: NODE_TYPES.BRANCH,
                      path: branch.path
                    });
                  }
                };
              }),
              {
                key: measurement?.identifier,
                label: measurement?.name,
                onClick: () => null
              }
            ]}
          />
        </div>
      )}
      getPanelContent={() => {
        return (
          <MeasurementDetailViewPanelContent
            measurement={measurement}
            editingDisabled={editingDisabled}
            loadingMeasurement={loadingMeasurement}
          />
        );
      }}
    >
      <div className="flex flex-col flex-nowrap p-lg">
        {/* Title */}
        <TitleSection
          kgCo2e={measurement?.kgCo2e}
          initialDescription={measurement?.description?.trim()}
          initialName={measurement?.name?.trim()}
          loading={loadingMeasurement}
          onTitleBlur={(field, value) => {
            editMeasurement(
              field as keyof MeasurementEditInputObject,
              value
            );
          }}
          onDescriptionBlur={(field, value) => {
            editMeasurement(
              field as keyof MeasurementEditInputObject,
              value
            );
          }}
          canEdit={permissions?.edit}
        />
        <div className="mt-md">
          {/* Strategy */}
          {editingDisabled ? null : loadingMeasurement ||
            editingMeasurementStrategy ? (
            <Skeleton height={30} />
          ) : (
            <div className="mb-sm">
              <StrategySingleSelect
                value={measurement?.strategy?.identifier}
                setValue={(value) => {
                  editMeasurementStrategy({
                    variables: {
                      input: {
                        measurementIdentifier:
                          measurement?.identifier,
                        branchIdentifier:
                          measurement?.branch?.identifier,
                        strategyIdentifier: value,
                        measurement: {
                          name: measurement?.name
                        }
                      }
                    }
                  });
                }}
              />
            </div>
          )}
          {/* Value */}
          <div className="flex items-center ">
            <div className={editingDisabled ? 'mr-md' : 'w-1/3'}>
              {loadingMeasurement ? (
                <Skeleton height={30} />
              ) : editingDisabled ? (
                <p>
                  <span className="mr-sm text-muted">Value: </span>
                  {measurement?.value} {fUnit(measurement?.valueUnit)}
                </p>
              ) : (
                <Input
                  noBorder
                  className="h-small-button "
                  type="number"
                  size="sm"
                  value={properties.value}
                  onChange={(e) => {
                    setProperties({
                      ...properties,
                      value: e.target.value
                    });
                  }}
                  onBlur={() => {
                    editMeasurement('value', properties.value);
                  }}
                  startAdornment={<p className="body2">Value: </p>}
                  endAdornment={
                    <p className="body2 text-muted">
                      {fUnit(measurement?.valueUnit)}
                    </p>
                  }
                />
              )}
            </div>
            {/* Unit */}
            {editingDisabled ? null : (
              <div className="ml-sm w-1/3">
                {loadingMeasurement ? (
                  <Skeleton height={30} />
                ) : (
                  <UnitSingleSelect
                    value={measurement?.valueUnit}
                    setValue={(value) => {
                      if (value === measurement?.valueUnit) return;
                      editMeasurement('unit', value);
                    }}
                    filterByUnitType={
                      emissionFactor
                        ? emissionFactor?.unitInfo?.type
                        : null
                    }
                    disableClear
                  />
                )}
              </div>
            )}
            {/* GHG Category */}
            <div className={editingDisabled ? '' : ' ml-sm w-1/2'}>
              {loadingMeasurement ? (
                <Skeleton height={30} />
              ) : editingDisabled ? (
                <p>
                  <span className="mr-sm text-muted">
                    GHG Category:{' '}
                  </span>
                  {fEnum(measurement?.ghgCategory)}
                </p>
              ) : (
                <GhgCategorySingleSelect
                  prefixText="GHG Category: "
                  value={measurement?.ghgCategory}
                  setValue={(value) => {
                    editMeasurement('ghgCategory', value);
                  }}
                  disableClear
                  filterByScopes={[String(measurement?.scope)]}
                />
              )}
            </div>
          </div>
        </div>
        {/* Separator */}
        <div className="mb-xl mt-lg h-[1px] bg-border" />
        {/* Measurement Lineage Section */}
        {measurement?.isRecurring && (
          <div className="mb-xl">
            <MeasurementLineageSection
              measurement={measurement}
              refetchMeasurement={refetchMeasurement}
            />
          </div>
        )}
        {/* Data Calculator Source */}
        {loadingMeasurement ? (
          <Skeleton height={180} />
        ) : dataCalculatorSource ? (
          <div className="mb-xl">
            <SourceSection
              asset={dataCalculatorSource?.asset}
              calculatorType={dataCalculatorSource?.calculatorType}
              assetType={
                dataCalculatorSource?.assetType as
                  | 'location'
                  | 'vehicle'
                  | 'equipment'
              }
              measurement={measurement}
            />
          </div>
        ) : null}

        {/* Emission Factor */}
        {loadingMeasurement ? (
          <Skeleton height={180} />
        ) : (
          <div className="rounded-md border p-lg">
            <div className="flex flex-col flex-nowrap">
              {/* Title */}
              <div className="mb-md w-full">
                <div className="flex flex-nowrap items-center justify-between">
                  <p className="">Emission Factor</p>
                  {permissions?.edit && (
                    <div className="flex flex-nowrap items-center">
                      {isEditingEmissionFactor ? (
                        <>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() =>
                              setIsEditingEmissionFactor(false)
                            }
                          >
                            Cancel
                          </Button>
                          <Button
                            size="sm"
                            className="ml-sm"
                            loading={updatingEmissionFactor}
                            disabled={
                              !emissionFactorEditObject.factor
                            }
                            onClick={() => {
                              const newEmissionFactor =
                                emissionFactorEditObject?.factor;

                              updateEmissionFactor({
                                variables: {
                                  input: {
                                    branchIdentifier:
                                      measurement?.branch?.identifier,
                                    measurementIdentifier:
                                      measurement?.identifier,
                                    emissionFactorIdentifier:
                                      newEmissionFactor?.identifier
                                  }
                                }
                              });
                            }}
                          >
                            Save
                          </Button>
                        </>
                      ) : editingDisabled ? null : (
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() =>
                            setIsEditingEmissionFactor(true)
                          }
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/* Details */}
              {isEditingEmissionFactor ? (
                <div className="items-nowrap mt-md flex flex-col">
                  <EmissionFactorSelector
                    prefixText="New Emission Factor: "
                    selectedFactor={emissionFactorEditObject.factor}
                    setSelectedFactor={(factor) => {
                      setEmissionFactorEditObject({
                        ...emissionFactorEditObject,
                        factor
                      });
                    }}
                    unitType={getUnitTypeFromUnit(
                      measurement?.valueUnit
                    )}
                    defaultAccordionOpen={true}
                    placeholder="None"
                  />
                </div>
              ) : emissionFactor?.rate ? (
                <div className="mt-sm w-full">
                  <div className="flex items-center justify-between">
                    <div className="flex flex-nowrap items-center">
                      <p className="font-bold">
                        {emissionFactor?.name}
                      </p>
                      {emissionFactor?.unitInfo?.type &&
                        emissionFactor?.unitInfo?.unit && (
                          <p className="body2 ml-md text-muted">
                            {_.startCase(
                              emissionFactor?.unitInfo?.type.toLocaleLowerCase()
                            )}{' '}
                            [{fUnit(emissionFactor?.unitInfo?.unit)}]
                          </p>
                        )}
                    </div>
                    {emissionFactor?.rate &&
                      emissionFactor?.unitInfo?.unit && (
                        <div>
                          <p>
                            {Number(emissionFactor?.rate)} kgCO2e /{' '}
                            {fUnit(emissionFactor?.unitInfo?.unit)}
                          </p>
                        </div>
                      )}
                  </div>
                  {noReferenceAvailable ? (
                    <div className="mt-sm">
                      <p className="text-muted">
                        No reference available.
                      </p>
                    </div>
                  ) : (
                    <>
                      <div className="mt-sm">
                        <p>
                          {reference?.organization}{' '}
                          {reference?.country &&
                            `(${reference?.country})`}
                          {reference?.year && `, ${reference?.year}`}
                        </p>
                      </div>
                      <div>
                        <p className="mt-sm">
                          {reference?.url && (
                            <Link
                              variant="body1"
                              href={reference?.url}
                              target="_blank"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              {reference?.url}
                            </Link>
                          )}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="mt-sm">
                  <p className="text-muted">
                    No emission factor applied.
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
        {/* Supporting Documents */}
        <div className="mt-xl">
          <SupportingDocumentsSection
            canEdit={true}
            documents={
              (measurement?.supportingDocuments as SupportingDocumentFragmentFragment[]) ||
              []
            }
            parentIdentifier={measurement?.identifier}
            parentType={SupportingDocumentParentTypesEnum.Measurement}
            refetchDocuments={refetchMeasurement}
          />
        </div>

        {/* Notes */}
        <div className="mt-xl">
          <NoteSection
            loading={loadingMeasurement}
            canEdit={permissions?.edit}
            onBlur={(value) => {
              editMeasurement('note', value);
            }}
            initialNote={note}
          />
        </div>
      </div>
    </DetailViewLayout>
  );
}
