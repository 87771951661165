import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { ArrowLeft } from 'lucide-react';
import { ScopeType } from 'src/@types/emissions';
import {
  GhgProtocolsEnum,
  MeasurementFragmentFragment,
  MeasurementIntervalUnitsEnum
} from 'src/__apolloGenerated__/graphql';
import DataCalculatorIcon from 'src/components/core/atoms/IconCircle';
import Form, {
  FormQuestionTypeType
} from 'src/components/core/organisms/Form';
import { Button } from 'src/components/shad-base/button';
import {
  Dialog,
  DialogContent
} from 'src/components/shad-base/dialog';
import useBackendMutation from 'src/hooks/useBackendMutation';
import { fEnum } from 'src/utils/format';

export default function EditInputFrequencyDialog({
  selectedMeasurement,
  open,
  onOpenChange,
  onBackArrowClick,
  onEditSuccess
}: {
  selectedMeasurement: MeasurementFragmentFragment;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onBackArrowClick: () => void;
  onEditSuccess: () => void;
}) {
  const {
    mutate: editInputFrequency,
    loading: editingInputFrequency
  } = useBackendMutation({
    mutation: gql`
      mutation EditMeasurementInputFrequency(
        $input: EditMeasurementInput!
      ) {
        editMeasurement(input: $input) {
          ok
          errors {
            ...ErrorsFragment
          }
        }
      }
    `,
    callbacks: {
      onSuccess: () => {
        onEditSuccess?.();
      }
    }
  });
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-screen-md">
        <div className="relative flex flex-col flex-nowrap items-center">
          {onBackArrowClick && (
            <div className="absolute left-0 top-0">
              <Button
                onClick={onBackArrowClick}
                variant="outline"
                size="icon"
                className="mr-md"
              >
                <ArrowLeft />
              </Button>
            </div>
          )}
          <p className="subtitle1">Edit Input Frequency</p>
          <p className="text-muted">
            Edit the input interval for this recurring measurement.
          </p>
          <div className="mt-lg w-full">
            <div className="flex flex-nowrap items-center">
              <div className="mr-sm">
                <DataCalculatorIcon
                  calculatorType={selectedMeasurement?.calculatorType}
                  scope={
                    Number(selectedMeasurement?.scope) as ScopeType
                  }
                  scope3Category={
                    selectedMeasurement?.ghgCategory as GhgProtocolsEnum
                  }
                />
              </div>
              <div className="flex flex-col flex-nowrap">
                <p className="mt-sm font-bold">
                  {selectedMeasurement?.name}
                </p>
                <p className="text-muted ">
                  Last updated:{' '}
                  {dayjs(
                    selectedMeasurement?.dateOfMeasurement
                  ).format('MMMM DD, YYYY')}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-lg w-full">
            <Form
              submitButtonText="Edit input frequency"
              isSubmitting={editingInputFrequency}
              onSubmit={(formValues) => {
                const intervalValue = formValues.measurementInterval
                  ? Number(formValues.measurementInterval)
                  : undefined;
                const intervalUnit =
                  formValues.measurementIntervalUnit
                    ? formValues.measurementIntervalUnit
                    : undefined;

                const interval =
                  intervalValue && intervalUnit
                    ? {
                        value: intervalValue,
                        unit: intervalUnit
                      }
                    : undefined;
                editInputFrequency({
                  variables: {
                    input: {
                      branchIdentifier:
                        selectedMeasurement?.branch?.identifier,
                      measurementIdentifier:
                        selectedMeasurement?.identifier,
                      measurement: {
                        interval
                      }
                    }
                  }
                });
              }}
              rows={[
                {
                  questions: [
                    {
                      key: 'measurementInterval',
                      type: 'text' as FormQuestionTypeType,
                      text: 'How often do you expect this measurement to occur?',
                      defaultValue: String(
                        selectedMeasurement?.intervalValue
                      ),
                      required: true,
                      placeholder: 'E.g. 2',
                      embeddedSingleSelect: {
                        key: 'measurementIntervalUnit',
                        defaultValue:
                          selectedMeasurement?.intervalUnit,
                        options: Object.values(
                          MeasurementIntervalUnitsEnum
                        ).map((value) => ({
                          key: value,
                          label: fEnum(value)
                        }))
                      }
                    }
                  ]
                }
              ]}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
