import { SearchIcon } from 'lucide-react';
import { ReactElement } from 'react';
import Input from 'src/components/core/atoms/Input';
import {
  Command,
  CommandItem,
  CommandList
} from 'src/components/shad-base/command';
import { HookReturn } from 'use-places-autocomplete';

export default function GooglePlacesAutocomplete({
  setPlace,
  hookReturn,
  startAdornment = (
    <div>
      <SearchIcon className="h-icon w-icon text-primary" />
    </div>
  ),
  distanceRequired = false,
  placeholder = 'Search for a city...'
}: {
  setPlace: (place: any) => void;
  hookReturn: HookReturn;
  distanceRequired?: boolean;
  startAdornment?: ReactElement;
  placeholder?: string;
}) {
  const handleSelect = async (place) => {
    setValue(place.description, false);
    setPlace(place);
    clearSuggestions();
  };

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions
  } = hookReturn;

  const suggestionsByDistance = data?.map((suggestion) => {
    return suggestion?.distance_meters && suggestion;
  });
  const suggestions = distanceRequired ? suggestionsByDistance : data;

  return (
    <Command loop>
      <Input
        disabled={!ready}
        placeholder={placeholder}
        startAdornment={startAdornment}
        size="default"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        noBorder
      />
      <CommandList
        className={
          suggestionsByDistance?.length === 0 ? 'p-0' : 'border p-sm '
        }
      >
        {status === 'OK' &&
          suggestions?.length > 0 &&
          suggestions?.map((suggestion) => {
            return (
              <CommandItem
                key={suggestion?.place_id}
                onSelect={() => handleSelect(suggestion)}
              >
                <p>{suggestion?.description}</p>
              </CommandItem>
            );
          })}
      </CommandList>
    </Command>
  );
}
