import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { createRef, useState } from 'react';

function dataURLtoFile(dataurl: string, filename: string): File {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export type ImageCropperOptionsType = {
  initialAspectRatio?: number;
};

export function ImageCropper({
  fileState,
  options
}: {
  fileState: [
    file: File,
    setFile: React.Dispatch<React.SetStateAction<File>>
  ];
  options?: ImageCropperOptionsType;
}) {
  const [file, setFile] = fileState;
  const [imageSrc, setImageSrc] = useState(URL.createObjectURL(file));
  setImageSrc;
  // const [cropData, setCropData] = useState('#');
  const cropperRef = createRef<ReactCropperElement>();

  // call this func on CropImage action/click
  // const getCropData = () => {
  //   if (typeof cropperRef.current?.cropper !== 'undefined') {
  //     setCropData(
  //       cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
  //     );
  //   }
  // };
  // useEffect(() => {
  //   if (cropData && cropData != '#') {
  //     if (file.type.split('/')[0] == 'image') {
  //       setFile(dataURLtoFile(cropData, file.name));
  //     }
  //   }
  // }, [cropData]);

  return (
    <Cropper
      ref={cropperRef}
      style={{ height: 400, width: '100%' }}
      zoomTo={0} // float
      initialAspectRatio={options?.initialAspectRatio || 1}
      //      preview=".img-preview" // className selector for a div that will contain preview image
      src={imageSrc}
      viewMode={1}
      minCropBoxHeight={10}
      minCropBoxWidth={10}
      background={false}
      responsive={true}
      autoCropArea={1}
      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
      guides={true}
      cropend={() => {
        // getCropData();
        // URL.revokeObjectURL(imageSrc);
        if (typeof cropperRef.current?.cropper !== 'undefined') {
          const croppedData = cropperRef.current?.cropper
            .getCroppedCanvas()
            .toDataURL(file.type);
          if (croppedData && croppedData != '#') {
            if (file.type.split('/')[0] == 'image') {
              setFile(dataURLtoFile(croppedData, file.name));
            }
          }
        }
      }}
    />
  );
}

// Overview -> ImageBox -> UploadImageDialog(showImageCropper).sendImageButton -> FileUploader
// -> FileDropzone(onDrop) -> input
// -> ImageCropper
