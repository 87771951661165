import { Ellipsis } from 'lucide-react';
import DropdownMenu, {
  ItemType
} from 'src/components/core/atoms/DropdownMenu';
import { Button } from 'src/components/shad-base/button';

export default function EllipsisDropdownMenu({
  items
}: {
  items: ItemType[];
}) {
  return (
    <DropdownMenu
      trigger={
        <div>
          <Button
            variant="outline"
            size="icon"
            onClick={(e) => e.stopPropagation()}
          >
            <Ellipsis className="h-5 w-5" />
          </Button>
        </div>
      }
      items={items}
    />
  );
}
