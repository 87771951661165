import {
  CSSProperties,
  InputHTMLAttributes,
  ReactElement,
  useEffect,
  useState
} from 'react';
import { Textarea } from 'src/components/shad-base/textarea';
import { Input as BaseInput } from 'src/components/shad-base/input';
import { cn } from '@/lib/utils';

export default function Input({
  value,
  onChange,
  placeholder,
  className,
  multiline = false,
  charLimit = 0,
  size = 'default',
  prefixText = null,
  startAdornment = null,
  fitContent = false,
  backgroundColor = ' bg-background ',
  containerClassName,
  containerStyle,
  noBorder = false,
  ...props
}: {
  value?: InputHTMLAttributes<
    HTMLInputElement | HTMLTextAreaElement
  >['value'];
  onChange?: (e) => void;
  placeholder?: string;
  className?: string;
  charLimit?: number;
  multiline?: boolean;
  size?: 'default' | 'sm';
  prefixText?: string;
  startAdornment?: ReactElement;
  fitContent?: boolean;
  backgroundColor?: string;
  containerClassName?: string;
  containerStyle?: CSSProperties;
  noBorder?: boolean;
  [key: string]: unknown;
}) {
  const [charLimitReached, setCharLimitReached] = useState(false);
  value = value ? String(value) : '';

  useEffect(() => {
    if (typeof value == 'string') {
      setCharLimitReached(charLimit > 0 && value?.length > charLimit);
    }
  }, [value]);

  const inputClassName = cn(
    backgroundColor,
    'shadow-none',
    className,
    charLimitReached ? 'border-destructive' : '',
    size === 'sm' ? 'h-small-button body2' : ''
  );

  const isTextarea = multiline;

  const inputProps = {
    value,
    onChange,
    placeholder,
    ...(!isTextarea && {
      startAdornment: prefixText ? (
        <p className={cn('text-nowrap', size === 'sm' && 'body2')}>
          {prefixText}
        </p>
      ) : startAdornment ? (
        startAdornment
      ) : undefined
    }),
    className: inputClassName,
    charlimit: charLimit,
    multiline: multiline ? multiline.toString() : undefined,
    ...(isTextarea && { fitContent }),
    ...props
  };

  return (
    <div
      className={cn(
        'inline-flex w-full flex-col flex-nowrap rounded-lg border',
        noBorder || startAdornment ? 'border-none' : '',
        containerClassName
      )}
      style={containerStyle}
    >
      {isTextarea ? (
        <Textarea {...inputProps} />
      ) : (
        <BaseInput {...inputProps} />
      )}
      {charLimitReached && (
        <p className="caption mt-sm text-destructive">
          Character limit reached (max: {charLimit})
        </p>
      )}
    </div>
  );
}
