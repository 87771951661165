import { Plus } from 'lucide-react';
import DropdownMenu, {
  GroupType,
  ItemType
} from 'src/components/core/atoms/DropdownMenu';
import { Button } from 'src/components/shad-base/button';

export default function AddNewDropdownMenu({
  items,
  groups,
  focused = false,
  menuHeader
}: {
  items?: ItemType[];
  groups?: GroupType[];
  focused?: boolean;
  menuHeader?: string;
}) {
  return (
    <DropdownMenu
      trigger={
        <div className={focused ? 'ring-4 ring-offset-4' : ''}>
          <Button variant="default" className="px-2">
            <Plus className="scale-90" />
          </Button>
        </div>
      }
      items={items}
      groups={groups}
      menuHeader={menuHeader || 'Create'}
    />
  );
}
