import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  CarbonLocationAreaUnitChoices,
  CountryNamesEnum,
  CreateLocationInput,
  CreateLocationPayload,
  EditLocationInput,
  EditLocationPayload,
  LocationCategoriesEnum,
  LocationFragmentFragment,
  LocationSubCategoriesEnum
} from 'src/__apolloGenerated__/graphql';

import CreateAssetDialog from 'src/components/carbon/organisms/AssetManager/CreateAssetDialog';
import Form from 'src/components/core/organisms/Form';
import { useToast } from 'src/hooks/shad-base/useToast';
import useAssetStore, {
  AssetStoreType
} from 'src/hooks/store/useAssetStore';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import { fEnum } from 'src/utils/format';

export default function CreateLocationDialog({
  open,
  onOpenChange,
  entityIdentifier,
  initialLocation
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess?: (data: CreateLocationPayload) => void;
  entityIdentifier: string;
  initialLocation?: LocationFragmentFragment;
}) {
  const { activeReportingPeriodId }: SettingsStoreType = useSettingsStore();

  const { setActiveAssetIdentifier } = useAssetStore(
    (store: AssetStoreType) => ({
      setActiveAssetIdentifier: store.setActiveAssetIdentifier
    })
  );

  const [latlng, setLatLng] = useState({
    lat: initialLocation?.latitude || null,
    lng: initialLocation?.longitude || null
  });

  // Reset lat lng when dialog closes
  useEffect(() => {
    if (!open) {
      setLatLng({
        lat: initialLocation?.latitude || null,
        lng: initialLocation?.longitude || null
      });
    }
  }, [open]);
  const { toast } = useToast();
  const { mutate: createLocation, loading: creatingLocation } =
    useBackendMutation<
      { input: CreateLocationInput },
      CreateLocationPayload
    >({
      mutation: gql`
        mutation CreateLocation(
          $input: CreateLocationInput!
          $reportingPeriodIdentifier: String
        ) {
          createLocation(input: $input) {
            ok
            errors {
              ...ErrorsFragment
            }
            location {
              ...LocationFragment
            }
            user {
              ... on UserObject {
                id
                identifier
              }
            }
          }
        }
      `,
      callbacks: {
        onSuccess: () => {
          onOpenChange(false);
          setActiveAssetIdentifier(null);
          toast({
            title: 'Success.',
            description: 'Location created.',
            variant: 'success'
          });
        }
      }
    });

  const { mutate: editLocation, loading: editingLocation } =
    useBackendMutation<
      { input: EditLocationInput },
      EditLocationPayload
    >({
      mutation: gql`
        mutation EditLocation(
          $input: EditLocationInput!
          $reportingPeriodIdentifier: String
        ) {
          editLocation(input: $input) {
            ok
            errors {
              ...ErrorsFragment
            }
            location {
              ...LocationFragment
            }
          }
        }
      `,
      callbacks: {
        onSuccess: () => {
          onOpenChange(false);
          toast({
            title: 'Success.',
            description: 'Location updated.',
            variant: 'success'
          });
        }
      }
    });

  const getDefaultUtilities = (
    initialLocation: LocationFragmentFragment
  ) => {
    if (!initialLocation) {
      return [];
    }
    const utilities = [];
    if (initialLocation.hasElectricity) {
      utilities.push('electricity');
    }
    if (initialLocation.hasNaturalGas) {
      utilities.push('natural-gas');
    }
    if (initialLocation.hasHeatOrSteam) {
      utilities.push('heat-steam');
    }
    if (initialLocation.hasPurchasedCooling) {
      utilities.push('purchased-cooling');
    }
    return utilities;
  };

  const iconOptions = [
    {
      key: '/static/icons/assets/locations/store.png',
      label: 'Store'
    },
    {
      key: '/static/icons/assets/locations/pub.png',
      label: 'Pub'
    },
    {
      key: '/static/icons/assets/locations/house.png',
      label: 'House'
    },
    {
      key: '/static/icons/assets/locations/diner.png',
      label: 'Diner'
    },
    {
      key: '/static/icons/assets/locations/church.png',
      label: 'Church'
    },
    {
      key: '/static/icons/assets/locations/mosque.png',
      label: 'Mosque'
    },
    {
      key: '/static/icons/assets/locations/apartment.png',
      label: 'Apartment'
    },
    {
      key: '/static/icons/assets/locations/school.png',
      label: 'School'
    },
    {
      key: '/static/icons/assets/locations/hospital.png',
      label: 'Hospital'
    },
    {
      key: '/static/icons/assets/locations/police.png',
      label: 'Police'
    },
    {
      key: '/static/icons/assets/locations/stadium.png',
      label: 'Stadium'
    },
    {
      key: '/static/icons/assets/locations/hotel.png',
      label: 'Stadium'
    },
    {
      key: '/static/icons/assets/locations/skyscraper.png',
      label: 'Skyscraper'
    },
    {
      key: '/static/icons/assets/locations/warehouse.png',
      label: 'Warehouse'
    },
    {
      key: '/static/icons/assets/locations/oil-refinery.png',
      label: 'Oil'
    }
  ];
  const defaultValues = {
    name: initialLocation?.name || '',
    utilities: getDefaultUtilities(initialLocation),
    area: initialLocation?.area || '',
    areaUnit:
      initialLocation?.areaUnit || CarbonLocationAreaUnitChoices.SqFt,
    category: initialLocation?.category || '',
    subcategory: initialLocation?.subcategory || '',
    fullAddress: {
      street: initialLocation?.addressLine1 || '',
      city: initialLocation?.city || '',
      state: initialLocation?.state || '',
      zip: initialLocation?.postalCode || '',
      country: initialLocation?.country || '',
      apt: initialLocation?.addressLine2 || ''
    },
    icon: initialLocation?.icon || iconOptions[0].key
  };

  return (
    <>
      <CreateAssetDialog
        open={open}
        onOpenChange={onOpenChange}
        type="location"
      >
        <div className="px-md">
          <Form
            setLatLng={setLatLng}
            rows={[
              {
                questions: [
                  {
                    key: 'name',
                    text: 'What is the name of this location?',
                    description:
                      'Give this location a name to make it easier to identify later.',
                    type: 'text',
                    defaultValue: defaultValues.name,
                    required: true,
                    errorMessage: 'Please enter a name',
                    placeholder: 'E.g. Seattle Office'
                  }
                ]
              },
              {
                questions: [
                  {
                    key: 'full-address',
                    text: 'Search for the address of this location or enter it manually below:',
                    type: 'address',
                    defaultValue: defaultValues.fullAddress,
                    required: false
                  }
                ]
              },
              {
                questions: [
                  {
                    key: 'utilities',
                    text: 'What kind of utilities does this location use? (select multiple)',
                    type: 'multi-select',
                    defaultValue: defaultValues.utilities,
                    required: true,
                    options: [
                      {
                        key: 'electricity',
                        label: 'Electricity'
                      },
                      {
                        key: 'natural-gas',
                        label: 'Natural Gas'
                      },
                      {
                        key: 'heat-steam',
                        label: 'Heat and/or Steam'
                      },
                      {
                        key: 'purchased-cooling',
                        label: 'Purchased Cooling'
                      }
                    ]
                  }
                ]
              },
              {
                questions: [
                  {
                    key: 'area',
                    type: 'number',
                    text: 'What is the gross area of this location?',
                    defaultValue: defaultValues.area,
                    required: true,
                    placeholder: 'Area',
                    embeddedSingleSelect: {
                      key: 'area-unit',
                      defaultValue: defaultValues.areaUnit,
                      options: Object.values(
                        CarbonLocationAreaUnitChoices
                      ).map((value) => ({
                        key: value,
                        label:
                          value === CarbonLocationAreaUnitChoices.SqFt
                            ? 'sqft - square feet'
                            : 'm² - square meters'
                      })),
                      helperText: 'Must select a unit'
                    }
                  }
                ]
              },
              {
                questions: [
                  {
                    key: 'category',
                    text: 'What is the primary use of this location?',
                    required: true,
                    defaultValue: defaultValues.category,
                    type: 'single-select',
                    options: Object.values(
                      LocationCategoriesEnum
                    ).map((value) => ({
                      key: value,
                      label: fEnum(value)
                    }))
                  }
                ]
              },
              {
                questions: [
                  {
                    key: 'subcategory',
                    text: '',
                    required: true,
                    type: 'single-select',
                    defaultValue: defaultValues.subcategory,
                    dependsOnQuestionKey: 'category',
                    getQuestion: (formValues) => {
                      const category = formValues['category'];
                      return {
                        text: `What kind of ${fEnum(category as string)} location is this?`,
                        options: Object.values(
                          LocationSubCategoriesEnum
                        ).map((value) => ({
                          key: value,
                          label: fEnum(value)
                        }))
                      };
                    }
                  }
                ]
              },
              {
                questions: [
                  {
                    key: 'icon',
                    text: 'Select an icon to represent this location',
                    type: 'icon',
                    required: false,
                    defaultValue: defaultValues.icon,
                    options: iconOptions
                  }
                ]
              }
            ]}
            isSubmitting={creatingLocation || editingLocation}
            onSubmit={(values) => {
              const input = {
                name: values['name'],
                addressLine1: values['street'],
                city: values['city'],
                usState:
                  values['country'] == CountryNamesEnum.Canada
                    ? undefined
                    : values['state'],
                canadianProvince:
                  values['country'] == CountryNamesEnum.UnitedStates
                    ? undefined
                    : values['state'],
                postalCode: values['zip'],
                country: values['country'],
                area: values['area'] ? String(values['area']) : null,
                areaUnit: values['area-unit'],
                hasElectricity:
                  values['utilities'].includes('electricity'),
                hasNaturalGas:
                  values['utilities'].includes('natural-gas'),
                hasHeatOrSteam:
                  values['utilities'].includes('heat-steam'),
                hasPurchasedCooling: values['utilities'].includes(
                  'purchased-cooling'
                ),
                icon: values['icon'],
                category: values['category'],
                subcategory: values['subcategory'],
                latitude: latlng.lat ? String(latlng.lat) : null,
                longitude: latlng.lng ? String(latlng.lng) : null
              };

              if (initialLocation) {
                editLocation({
                  variables: {
                    input: {
                      locationIdentifier: initialLocation.identifier,
                      ...input
                    },
                    reportingPeriodIdentifier: activeReportingPeriodId
                  }
                });
              } else {
                createLocation({
                  variables: {
                    input: {
                      entityIdentifier,
                      ...input
                    },
                    reportingPeriodIdentifier: activeReportingPeriodId
                  },
                  refetchQueries: ['LocationsViewQuery']
                });
              }
            }}
          />
        </div>
      </CreateAssetDialog>
    </>
  );
}
