import { Ellipsis } from 'lucide-react';
import { CSSProperties } from 'react';
import IconButton from '../atoms/IconButton';
import DropdownMenu from '../atoms/DropdownMenu';

export default function MoreActionsMenu({
  menuItems,
  onClose = null,
  disabled = false,
  style = {},
  className
}: {
  menuItems: {
    key: string;
    label: string;
    onClick: (key: string) => void;
  }[];
  disabled?: boolean;
  onClose?: () => void;
  style?: CSSProperties;
  className?: string;
}) {
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  // const handleMenuOpen = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    onClose && onClose();
    // setAnchorEl(null);
  };

  return (
    <>
      <DropdownMenu
        trigger={
          <IconButton
            disabled={disabled}
            // onClick={(e) => {
            //   e.stopPropagation();
            //   handleMenuOpen(e);
            // }}
            className={className}
            style={style}
          >
            <Ellipsis />
          </IconButton>
        }
        items={menuItems.map((item) => ({
          name: item.label,
          onSelect: (e) => {
            e.stopPropagation();
            item.onClick(item.key);
            handleClose();
          }
        }))}
      />
      {/* <IconButton
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          handleMenuOpen(e);
        }}
        className={className}
        style={style}
      >
        <Ellipsis />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems.map((item, idx) => {
          return (
            <li
              key={getListItemKey(idx)}
              value={item.key}
              onClick={(e) => {
                e.stopPropagation();
                item.onClick(item.key);
                handleClose();
              }}
            >
              <p style={{ ...typography.body2 }}>{item.label}</p>
            </li>
          );
        })}
      </Menu> */}
    </>
  );
}
