import { sortMeasurements } from '@/lib/utils';
import { ArrowRight, Edit, FileSymlink } from 'lucide-react';
import { useRouter } from 'next/router';
import { ScopeType } from 'src/@types/emissions';
import {
  DataCalculatorTypesEnum,
  EquipmentFragmentFragment,
  LocationFragmentFragment,
  MeasurementFragmentFragment,
  VehicleFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import CarbonUnit from 'src/components/carbon/atoms/CarbonUnit';
import DataCalculatorIcon from 'src/components/core/atoms/IconCircle';
import { Button } from 'src/components/shad-base/button';
import {
  NODE_TYPES,
  ROUTES,
  getAssetTypeFromDataCalculatorType,
  getScopeFromDataCalculatorType
} from 'src/config';
import { AssetTypeType } from 'src/hooks/store/useAssetStore';
import useDataCalculatorStore, {
  DataCalculatorStoreType
} from 'src/hooks/store/useDataCalculatorStore';
import useMeasureStore, {
  MeasureStoreType
} from 'src/hooks/store/useMeasureStore';

import { fEnum, fLocalDate } from 'src/utils/format';

export default function AssetMeasurementsCard({
  calculatorType,
  measurements,
  asset
}: {
  calculatorType: DataCalculatorTypesEnum;
  measurements: MeasurementFragmentFragment[];
  asset:
    | LocationFragmentFragment
    | VehicleFragmentFragment
    | EquipmentFragmentFragment;
}) {
  const router = useRouter();
  const { setActiveNode, setIsDetailViewExpanded } = useMeasureStore(
    (store: MeasureStoreType) => ({
      setActiveNode: store.setActiveNode,
      setIsDetailViewExpanded: store.setIsDetailViewExpanded
    })
  );
  const {
    setActiveAsset,
    setInitialMeasurement,
    setActiveCalculatorType,
    setActiveAssetType
  } = useDataCalculatorStore((store: DataCalculatorStoreType) => ({
    setActiveAsset: store.setActiveAsset,
    setInitialMeasurement: store.setInitialMeasurement,
    setActiveCalculatorType: store.setActiveCalculatorType,
    setActiveAssetType: store.setActiveAssetType
  }));

  const scope: ScopeType =
    getScopeFromDataCalculatorType(calculatorType);
  const assetType: AssetTypeType =
    getAssetTypeFromDataCalculatorType(calculatorType);
  const sortedMeasurements = sortMeasurements(measurements);

  return (
    <div
      className={
        'rounded-md border p-lg ' +
        (!(measurements.length > 0) &&
          'cursor-pointer transition-all hover:ring-1 hover:ring-primary/50')
      }
      onClick={
        measurements.length > 0
          ? null
          : () => {
              const route =
                scope == 1
                  ? ROUTES.TOOLS.SCOPE1
                  : ROUTES.TOOLS.SCOPE2;
              setActiveAssetType(assetType);
              setActiveAsset(asset);
              setActiveCalculatorType(calculatorType);
              router.push(route);
            }
      }
    >
      <div className="flex w-full flex-col justify-center">
        <div className="flex flex-nowrap items-center justify-between">
          <div className="flex flex-nowrap items-center">
            <div>
              <DataCalculatorIcon
                scope={scope}
                calculatorType={calculatorType}
              />
            </div>

            <div className="ml-md">
              <p className={measurements ? null : 'font-bold'}>
                {measurements.length > 0
                  ? measurements[0]?.name
                  : `Calculate ${fEnum(calculatorType)}`}
              </p>
            </div>
          </div>
          {!(measurements.length > 0) && <ArrowRight />}
        </div>
        <div>
          <div className=" flex flex-col">
            {sortedMeasurements.map((measurement) => {
              return (
                <div
                  className="flex flex-nowrap items-center justify-between p-sm"
                  key={measurement?.identifier}
                >
                  <div className="flex flex-nowrap items-center gap-2 whitespace-nowrap">
                    <p>{fLocalDate(measurement.startDate)}</p>
                    <p>{' - '}</p>
                    <p>{fLocalDate(measurement.endDate)}</p>
                  </div>
                  <div className="flex flex-nowrap items-center">
                    <CarbonUnit
                      emissionValueInKg={measurement?.kgCo2e}
                      className={'font-bold '}
                    />

                    {/* Redirect to measurement in footprint tree */}
                    <Button
                      size="icon"
                      variant="outline"
                      className="ml-md mr-sm"
                      onClick={() => {
                        router.push(ROUTES.INVENTORY);
                        setActiveNode({
                          type: NODE_TYPES.MEASUREMENT,
                          nodeId: measurement?.identifier,
                          path: measurement?.branch?.path
                        });
                        setIsDetailViewExpanded(true);
                      }}
                    >
                      <FileSymlink className="h-icon w-icon" />
                    </Button>

                    {/* Redirect to data calculator that spawned measurement */}
                    <Button
                      size="icon"
                      variant="outline"
                      onClick={(e) => {
                        e.stopPropagation();
                        const route =
                          scope == 1
                            ? ROUTES.TOOLS.SCOPE1
                            : ROUTES.TOOLS.SCOPE2;
                        setActiveAssetType(assetType);
                        setActiveAsset(asset);
                        setActiveCalculatorType(calculatorType);
                        setInitialMeasurement(measurement);
                        router.push(route);
                      }}
                    >
                      <Edit className="h-icon w-icon" />
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
