import { gql, useQuery } from '@apollo/client';
import {
  BranchSelectorBranchFragmentFragment,
  BranchSelectorTreeQueryQuery
} from 'src/__apolloGenerated__/graphql';
import SingleSelect from 'src/components/core/molecules/SingleSelect';

export default function BranchSelector({
  activeBranchIdentifier,
  setActiveBranchIdentifier,
  disabled = false,
  getIsValidBranch = null,
  prefixText = 'Branch: ',
  disableClear = false,
  loading,
  setBranch,
  required = false,
  size = 'sm',
  type = 'element'
}: {
  activeBranchIdentifier: string;
  setActiveBranchIdentifier: (value: string) => void;
  disabled?: boolean;
  getIsValidBranch?: (
    branch: BranchSelectorBranchFragmentFragment
  ) => boolean;
  prefixText?: string;
  loading?: boolean;
  disableClear?: boolean;
  required?: boolean;
  size?: 'sm' | 'default';
  type?: 'element' | 'form';
  // Some components will need more than just the branch identifier, so optionally we will pass a setter for the whole branch object
  setBranch?: (branch: BranchSelectorBranchFragmentFragment) => void;
}) {
  const { data: treeData, loading: branchesLoading } =
    useQuery<BranchSelectorTreeQueryQuery>(
      gql`
        query BranchSelectorTreeQuery {
          tree {
            data {
              branches {
                ...BranchSelectorBranchFragment
              }
            }
          }
        }
      `,
      {
        skip: disabled,
        fetchPolicy: 'network-only'
      }
    );
  const branches = treeData?.tree?.data
    ?.branches as BranchSelectorBranchFragmentFragment[];
  const validBranches = branches?.filter((branch) =>
    getIsValidBranch ? getIsValidBranch(branch) : true
  );

  const getBranchPath = (branchIdentifier) => {
    const branch = branches?.find(
      (branch) => branch?.identifier === branchIdentifier
    );
    const pathArray = branch?.path.split('.');

    let parentPath = pathArray?.length > 0 ? pathArray[0] + '.' : '';
    let parentPathStr = '';

    for (let i = 0; i < pathArray?.length - 1; i++) {
      const parentBranch = branches?.find(
        (branch) => branch?.path === parentPath
      );
      if (
        parentBranch &&
        parentBranch?.identifier !== branch?.identifier
      ) {
        if (parentPathStr === '') {
          parentPathStr = parentBranch.name;
        } else {
          parentPathStr += '/' + parentBranch.name;
        }
      }
      parentPath += pathArray[i + 1] + '.';
    }
    return parentPathStr;
  };

  // Branches query puts root branch at the end of the list, so we reverse it
  const reversedBranches = validBranches
    ?.map((branch) => branch)
    .reverse();

  return (
    <SingleSelect
      value={activeBranchIdentifier}
      required={required}
      disabled={disabled}
      size={size}
      setValue={(value) => {
        setActiveBranchIdentifier(value);
        if (setBranch) {
          setBranch(
            branches?.find((branch) => branch.identifier === value)
          );
        }
      }}
      loading={loading || branchesLoading}
      placeholder="Select branch..."
      commandEmptyText="No branches found."
      options={reversedBranches?.map((branch) => ({
        key: branch.identifier,
        label: branch.name
      }))}
      prefixText={type === 'form' ? '' : prefixText}
      renderOption={(branchOption) => (
        <div className="flex flex-nowrap items-center">
          <p className="body2">{branchOption.label}</p>
          <p className="caption ml-sm text-muted">
            {getBranchPath(branchOption.key)}
          </p>
        </div>
      )}
      disableClear={disableClear}
    />
  );
}
