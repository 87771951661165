// import { useTheme } from '@mui/system';
import _ from 'lodash';
import { ArrowLeft } from 'lucide-react';
import mapboxgl from 'mapbox-gl';
import { useState } from 'react';
import Map, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup
} from 'react-map-gl';
import { LocationFragmentFragment } from 'src/__apolloGenerated__/graphql';
import MultiSwitch from 'src/components/carbon/atoms/MultiSwitch';
import DetailView from 'src/components/carbon/organisms/AssetManager/DetailView';
import ListView from 'src/components/carbon/organisms/AssetManager/ListView';
import { Breadcrumbs } from 'src/components/core/atoms/Breadcrumbs';
import Skeleton from 'src/components/core/atoms/Skeleton';
import AddNewDropdownMenu from 'src/components/core/molecules/AddNewDropdownMenu';
import AreYouSureDialog from 'src/components/core/molecules/AreYouSureDialog';
import { Button } from 'src/components/shad-base/button';
import { TOKENS } from 'src/config';
import useAssetStore, {
  AssetStoreType,
  AssetTypeType
} from 'src/hooks/store/useAssetStore';
import CollapsiblePanelLayout from 'src/layouts/CollapsiblePanelLayout';
import Subnav from 'src/layouts/Subnav';
import palette from 'src/theme/palette';
import { getListItemKey } from 'src/utils/format';
import {
  getLocationDescription,
  getSecondaryLocationDescription
} from 'src/views/LocationsView';

mapboxgl.accessToken = TOKENS.MAPBOX;

export type AssetDetailsType = {
  identifier: string;
  name: string;
  description: string;
  secondaryDescription?: string;
  renderIcon?: (isHovering?: boolean) => React.ReactNode;
  detailSections: {
    title?: string;
    renderContent: () => React.ReactNode;
  }[];
};

export default function AssetManager<AssetType>({
  type,
  assets,
  setCreateDialogOpen,
  loading,
  onEdit,
  onDelete,
  getAssetDetails,
  areYouSureDialogOpenState,
  deleteLoading,
  // bottomNavButtonDisabled = false,
  bottomNavButtonText = '',
  // bottomNavContent = null,
  bottomNavOnClick = null,
  beforeYouContinueMessage
  // hideBottomNav = false
}: {
  type: AssetTypeType;
  assets: AssetType[];
  setCreateDialogOpen: (open: boolean) => void;
  loading: boolean;
  areYouSureDialogOpenState: [boolean, (open: boolean) => void];
  deleteLoading: boolean;
  onEdit?: (assetIdentifier: string) => void;
  onDelete?: (assetIdentifier: string) => void;
  getAssetDetails?: (asset: AssetType) => AssetDetailsType;
  bottomNavButtonDisabled?: boolean;
  bottomNavButtonText?: string;
  bottomNavContent?: React.ReactNode;
  bottomNavOnClick?: () => void;
  hideBottomNav?: boolean;
  beforeYouContinueMessage?: string;
}) {
  // const theme = useTheme();
  const {
    activeAssetType,
    setActiveAssetType,
    activeAssetIdentifier,
    setActiveAssetIdentifier
  } = useAssetStore((store: AssetStoreType) => ({
    activeAssetType: store.activeAssetType,
    setActiveAssetType: store.setActiveAssetType,
    activeAssetIdentifier: store.activeAssetIdentifier,
    setActiveAssetIdentifier: store.setActiveAssetIdentifier
  }));

  // Initialize Mapbox State
  const [viewportState, setViewportState] = useState({
    longitude: -100,
    latitude: 40,
    zoom: 3.5
  });
  const [selectedMarker, setSelectedMarker] =
    useState<null | LocationFragmentFragment>(null);

  const [areYouSureDialogOpen, setAreYouSureDialogOpen] =
    areYouSureDialogOpenState;

  const [activeView, setActiveView] = useState('List');

  const actionItems = [];
  if (onEdit) {
    actionItems.push({
      name: `Edit ${_.startCase(type)}`,
      onSelect: () => {
        onEdit(activeAssetIdentifier);
      }
    });
  }
  if (onDelete) {
    actionItems.push({
      name: `Delete ${_.startCase(type)}`,
      onSelect: () => {
        setAreYouSureDialogOpen(true);
      }
    });
  }
  const showDetailView =
    activeAssetIdentifier && activeAssetType === type;

  const activeAsset = assets.find((asset) => {
    const { identifier } = getAssetDetails(asset);

    return identifier === activeAssetIdentifier;
  });

  const { name: activeAssetName } = getAssetDetails(activeAsset);

  const locations = assets as LocationFragmentFragment[];

  return (
    <CollapsiblePanelLayout
      forceClose
      includePadding={false}
      bottomNavButtonText={bottomNavButtonText}
      bottomNavOnClick={bottomNavOnClick}
      beforeYouContinueMessage={beforeYouContinueMessage}
      getSubnav={() => {
        return (
          <div className="w-full">
            <Subnav
              forceClosePanel
              getSubnav={() => (
                <div className="flex w-full items-center justify-between">
                  <div className="flex flex-nowrap items-center">
                    {showDetailView && (
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={() => {
                          if (activeView == 'Map') {
                            setSelectedMarker(
                              locations.find(
                                (location) =>
                                  location.identifier ==
                                  activeAssetIdentifier
                              ) || null
                            );
                          }
                          setActiveAssetIdentifier(null);
                          setActiveAssetType(null);
                        }}
                      >
                        <ArrowLeft className="h-5 w-5" />
                      </Button>
                    )}
                    {showDetailView && (
                      <div className="ml-md">
                        <Breadcrumbs
                          items={[
                            {
                              key: type,
                              label:
                                type === 'equipment'
                                  ? 'Equipment'
                                  : _.startCase(type) + 's',
                              onClick: () => {
                                setActiveAssetType(null);
                                setActiveAssetIdentifier(null);
                              }
                            },
                            {
                              key: activeAssetIdentifier,
                              label: activeAssetName,
                              onClick: () => {
                                setActiveAssetIdentifier(null);
                              }
                            }
                          ]}
                        />
                      </div>
                    )}
                    {!showDetailView && type === 'location' && (
                      <div>
                        <MultiSwitch
                          options={[
                            { key: 'List', label: 'List' },
                            { key: 'Map', label: 'Map' }
                          ]}
                          activeOption={activeView}
                          setActiveOption={setActiveView}
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex flex-nowrap items-center justify-self-end">
                    <div className="ml-md">
                      <AddNewDropdownMenu
                        items={[
                          {
                            name: _.startCase(type),
                            onSelect: () => {
                              setCreateDialogOpen(true);
                            }
                          }
                        ]}
                      />
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        );
      }}
    >
      <div className="flex w-full flex-col flex-nowrap">
        {/* Content */}
        <div className="h-full max-h-full w-full p-xl pb-2xl">
          <div className="flex w-full justify-center">
            <div className="flex w-full flex-col">
              {loading ? (
                <div className="flex flex-col">
                  <div>
                    <Skeleton height={35} />
                  </div>
                  <div className="mt-sm">
                    <Skeleton height={65} />
                  </div>
                  <div className="mt-lg">
                    <Skeleton />
                  </div>
                </div>
              ) : showDetailView ? (
                <DetailView<AssetType>
                  activeAssetIdentifier={activeAssetIdentifier}
                  assets={assets}
                  actionItems={actionItems}
                  getAssetDetails={getAssetDetails}
                />
              ) : activeView === 'List' ? (
                <ListView<AssetType>
                  assets={assets}
                  type={type}
                  setCreateDialogOpen={setCreateDialogOpen}
                  getAssetDetails={getAssetDetails}
                />
              ) : activeView === 'Map' ? (
                <div className="flex flex-col flex-nowrap">
                  <h6 className="font-bold">Your locations</h6>
                  <p className="mt-sm max-w-paragraph text-muted">
                    Locations are physical places where your company
                    operates. This may include offices, factories, and
                    warehouses.
                  </p>

                  <div className="mt-lg h-[600px] w-full border">
                    <Map
                      {...viewportState}
                      onMove={(e) => setViewportState(e.viewState)}
                      mapStyle="mapbox://styles/mapbox/light-v11"
                    >
                      <GeolocateControl position="top-left" />
                      <FullscreenControl position="top-left" />
                      <NavigationControl position="top-left" />
                      {/* Pins */}
                      {locations.map((location) => {
                        return (
                          <Marker
                            key={getListItemKey(location.identifier)}
                            longitude={Number(location.longitude)}
                            latitude={Number(location.latitude)}
                            onClick={(e) => {
                              e.originalEvent.stopPropagation();
                              setSelectedMarker(location);
                              setViewportState({
                                latitude: Number(location.latitude),
                                longitude: Number(location.longitude),
                                zoom: 11
                              });
                            }}
                            color={palette.light.brand}
                          />
                        );
                      })}
                      {selectedMarker && (
                        <Popup
                          anchor="bottom"
                          latitude={selectedMarker.latitude}
                          longitude={selectedMarker.longitude}
                          onClose={() => {
                            setSelectedMarker(null);
                          }}
                          className="p-10"
                          maxWidth="450px"
                        >
                          <div className="flex flex-nowrap items-center">
                            <div className="flex flex-col flex-nowrap rounded-md p-sm">
                              <p className="subtitle1">
                                {selectedMarker.name}
                              </p>
                              <p className="mb-sm font-bold">
                                {getLocationDescription(
                                  selectedMarker
                                )}
                              </p>
                              <p className="text-muted">
                                {getSecondaryLocationDescription(
                                  selectedMarker
                                )}
                              </p>
                            </div>
                            <div
                              className="flex cursor-pointer flex-col flex-nowrap text-center"
                              onClick={() => {
                                setActiveAssetIdentifier(
                                  selectedMarker.identifier
                                );
                                setActiveAssetType(type);
                              }}
                            >
                              <div className="max-w-[150px]">
                                <img
                                  src={selectedMarker.icon}
                                  className="grayscale"
                                />
                              </div>
                              <div className="underline">Details</div>
                            </div>
                          </div>
                        </Popup>
                      )}
                    </Map>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {onDelete && (
          <AreYouSureDialog
            open={areYouSureDialogOpen}
            onOpenChange={setAreYouSureDialogOpen}
            onDeleteConfirm={() => {
              onDelete(activeAssetIdentifier);
              setActiveAssetIdentifier(null);
            }}
            deleteLoading={deleteLoading}
          />
        )}
      </div>
    </CollapsiblePanelLayout>
  );
}
