import dayjs from 'dayjs';
import { ArrowRight, CheckCircle, ExternalLink } from 'lucide-react';
import { useRouter } from 'next/router';
import { ScopeType } from 'src/@types/emissions';
import {
  GhgProtocolsEnum,
  MeasurementFragmentFragment,
  MeasurementIntervalUnitsEnum
} from 'src/__apolloGenerated__/graphql';
import DataCalculatorIcon from 'src/components/core/atoms/IconCircle';
import Skeleton from 'src/components/core/atoms/Skeleton';
import { Badge } from 'src/components/shad-base/badge';
import { Button } from 'src/components/shad-base/button';
import {
  ROUTES,
  getAssetTypeFromDataCalculatorType,
  getCalculatorSourceFromDataCalculatorType
} from 'src/config';
import useDataCalculatorStore, {
  DataCalculatorStoreType
} from 'src/hooks/store/useDataCalculatorStore';
import { getFormattedMeasurementInterval } from 'src/views/MeasureView/detail/MeasurementDetailView/MeasurementDetailViewPanelContent';

export default function ListView({
  setSelectedMeasurement,
  missingMeasurements,
  loadingMissingMeasurementsData,
  onOpenChange
}: {
  setSelectedMeasurement: (
    measurement: MeasurementFragmentFragment
  ) => void;
  missingMeasurements: MeasurementFragmentFragment[];
  loadingMissingMeasurementsData: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  const router = useRouter();
  const {
    setActiveAsset,
    setActiveCalculatorType,
    setActiveAssetType
  } = useDataCalculatorStore((store: DataCalculatorStoreType) => ({
    setActiveAsset: store.setActiveAsset,
    setActiveCalculatorType: store.setActiveCalculatorType,
    setActiveAssetType: store.setActiveAssetType
  }));

  return (
    <div className="flex flex-col flex-nowrap">
      {/* Measurement List */}
      {missingMeasurements?.length > 0 ? (
        <>
          <p className="mb-md mt-lg w-full">
            The following recurring measurements are expecting
            updates:
          </p>
          {loadingMissingMeasurementsData ? (
            <Skeleton count={3} />
          ) : (
            missingMeasurements?.map((measurement, idx) => {
              const formattedInterval =
                getFormattedMeasurementInterval(
                  measurement?.intervalValue,
                  measurement?.intervalUnit as MeasurementIntervalUnitsEnum
                );
              const calculatorType = measurement?.calculatorType;
              const assetType =
                getAssetTypeFromDataCalculatorType(calculatorType);
              const assetName = measurement?.[assetType]?.name;
              const calculatorSource =
                getCalculatorSourceFromDataCalculatorType(
                  calculatorType
                );

              const lastUpdated = dayjs(
                measurement?.dateOfMeasurement
              ).format('MMMM DD, YYYY');

              return (
                <div
                  key={measurement?.identifier}
                  className={
                    'group w-full cursor-pointer rounded-md border p-md hover:border-primary/50 ' +
                    (idx !== 0 && 'mt-md')
                  }
                >
                  {/* Data calculator measurement card */}
                  {calculatorType ? (
                    <div className="flex flex-nowrap justify-between">
                      <div className="mr-md">
                        <div className="flex flex-nowrap">
                          <div className="mr-sm">
                            <DataCalculatorIcon
                              scope={measurement?.scope as ScopeType}
                              size="sm"
                              calculatorType={calculatorType}
                            />
                          </div>
                          <div className="ml-sm mr-sm flex flex-col flex-nowrap">
                            <div className="flex flex-nowrap items-center">
                              <p>
                                Calculate {calculatorSource}{' '}
                                Consumption
                              </p>
                              <div className="ml-sm">
                                <Badge>
                                  Every {formattedInterval}
                                </Badge>
                              </div>
                            </div>
                            <p className="font-bold">{assetName}</p>
                            <p className="text-muted">
                              Last updated: {lastUpdated}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="opacity-0 transition-all group-hover:opacity-100">
                        <Button
                          size="sm"
                          onClick={() => {
                            const asset =
                              assetType === 'location'
                                ? measurement?.location
                                : assetType === 'vehicle'
                                  ? measurement?.vehicle
                                  : measurement?.equipment;

                            const route =
                              measurement?.scope == 1
                                ? ROUTES.TOOLS.SCOPE1
                                : ROUTES.TOOLS.SCOPE2;
                            setActiveAssetType(assetType);
                            setActiveAsset(asset);
                            setActiveCalculatorType(
                              measurement?.calculatorType
                            );
                            router.push(route);
                            onOpenChange(false);
                          }}
                        >
                          <div className="flex flex-nowrap items-center">
                            Go to Scope {measurement?.scope} Tools
                            <ExternalLink className="ml-sm h-icon w-icon" />
                          </div>
                        </Button>
                      </div>
                    </div>
                  ) : (
                    // Custom measurement card
                    <div className="flex flex-nowrap items-center justify-between">
                      <div className="flex w-full flex-col flex-nowrap">
                        <div className="flex ">
                          <div className="mr-md">
                            <DataCalculatorIcon
                              calculatorType={
                                measurement?.calculatorType
                              }
                              scope={
                                Number(
                                  measurement?.scope
                                ) as ScopeType
                              }
                              scope3Category={
                                measurement?.ghgCategory as GhgProtocolsEnum
                              }
                              size="sm"
                            />
                          </div>
                          <div className="flex flex-col flex-nowrap">
                            <div className="flex flex-nowrap items-center">
                              <p className="mr-sm">
                                {measurement?.name}
                              </p>
                              <Badge>Every {formattedInterval}</Badge>
                            </div>
                            <p className="mt-sm text-muted">
                              Last updated: {lastUpdated}
                            </p>
                          </div>
                        </div>
                      </div>
                      <Button
                        size="sm"
                        className="opacity-0 transition-all group-hover:opacity-100"
                        onClick={() => {
                          if (measurement?.calculatorType) {
                            const assetType =
                              getAssetTypeFromDataCalculatorType(
                                measurement?.calculatorType
                              );
                            const asset =
                              assetType === 'location'
                                ? measurement?.location
                                : assetType === 'vehicle'
                                  ? measurement?.vehicle
                                  : measurement?.equipment;

                            const route =
                              measurement?.scope == 1
                                ? ROUTES.TOOLS.SCOPE1
                                : ROUTES.TOOLS.SCOPE2;
                            setActiveAssetType(assetType);
                            setActiveAsset(asset);
                            setActiveCalculatorType(
                              measurement?.calculatorType
                            );
                            router.push(route);
                            onOpenChange(false);
                          } else {
                            setSelectedMeasurement(measurement);
                          }
                        }}
                      >
                        <div className="flex flex-nowrap items-center">
                          Update
                          <ArrowRight className="ml-sm h-icon w-icon" />
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
              );
            })
          )}
        </>
      ) : (
        <div className="mt-lg flex flex-col flex-nowrap items-center justify-center rounded-md bg-success/20 p-md">
          <div className="flex w-full flex-nowrap items-center justify-center">
            <CheckCircle className="mr-sm h-icon w-icon text-success" />
            <p className="font-bold text-success">Up to date!</p>
          </div>
          <p className="text-center text-success">
            You have no missing data in this reporting period.
          </p>
        </div>
      )}
    </div>
  );
}
