import FileDropzone from 'src/components/core/atoms/FileDropzone';
import { Button } from 'src/components/shad-base/button';
import { formatFileSize } from 'src/utils/format';
import { getListItemKey } from 'src/utils/format';
import {
  ImageCropper,
  ImageCropperOptionsType
} from './ImageCropper';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------
export type SupportedFileTypesType =
  | 'csv'
  | 'image'
  | 'doc'
  | 'excel'
  | 'json';

export default function FileUploader({
  handleSubmit,
  isUploading,
  filesState,
  errorState,
  forceHideButton = false,
  renderAdditionalToggles,
  supportedFileTypes = [],
  allowMultiple = false,
  showImageCropper = false,
  imageCropperOptions
}: {
  handleSubmit?: () => void;
  isUploading?: boolean;
  filesState: [
    file: File[],
    setFile: React.Dispatch<React.SetStateAction<File[]>>
  ];
  forceHideButton?: boolean;
  supportedFileTypes: SupportedFileTypesType[];
  errorState: [
    error: boolean,
    setError: React.Dispatch<React.SetStateAction<boolean>>
  ];
  renderAdditionalToggles?: () => JSX.Element;
  allowMultiple?: boolean;
  showImageCropper?: boolean;
  imageCropperOptions?: ImageCropperOptionsType;
}) {
  const [files, setFiles] = filesState;
  const [error, setError] = errorState;
  const [isSelectedFileAnImage, setIsSelectedFileAnImage] =
    useState(false);

  const acceptableTypes = [];
  if (supportedFileTypes.includes('csv'))
    acceptableTypes.push('text/csv');
  if (supportedFileTypes.includes('image'))
    acceptableTypes.push(
      ...['application/pdf', 'image/jpeg', 'image/jpg', 'image/png']
    );
  if (supportedFileTypes.includes('excel'))
    acceptableTypes.push(
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  if (supportedFileTypes.includes('json'))
    acceptableTypes.push('application/json');
  if (supportedFileTypes.includes('doc')) {
    acceptableTypes.push(
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    );
  }

  const showDropzone = allowMultiple ? true : files.length === 0;
  const renderFiles = () => {
    return files?.map((file, fileIdx) => {
      const fileUrl = URL.createObjectURL(file);
      return (
        <div className="my-md w-full" key={getListItemKey(fileIdx)}>
          <div className="flex flex-nowrap justify-between">
            <div className="flex flex-nowrap items-center">
              {file?.type?.includes('image') && (
                <img
                  src={fileUrl}
                  style={{
                    width: '35px',
                    height: '35px',
                    marginRight: 10
                  }}
                />
              )}
              <p>{file.name}</p>
              <p className="body2 ml-sm text-muted">
                {formatFileSize(file.size, 2)}
              </p>
            </div>
            <div>
              <Button
                variant="link"
                onClick={() => {
                  setFiles(files.filter((f) => f !== file));
                  URL.revokeObjectURL(fileUrl);
                }}
              >
                Remove
              </Button>
            </div>
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    if (showImageCropper) {
      setIsSelectedFileAnImage(
        files.length == 1 && files[0]?.type?.includes('image')
      );
    }
  }, [files]);

  return (
    <div className="flex w-full flex-col flex-nowrap">
      <div className="w-full">
        <div className="flex flex-col flex-nowrap">
          {/* Dropzone */}
          {showDropzone && (
            <FileDropzone
              supportedFileTypes={supportedFileTypes}
              onDrop={(droppedFiles: File[]) => {
                let error = false;
                droppedFiles.forEach((file) => {
                  if (!acceptableTypes.includes(file.type)) {
                    error = true;
                    return;
                  }
                  if (file.size > 20000000) {
                    error = true;
                    return;
                  }
                });
                setError(error);
                if (error) {
                  // Don't clear files if multiple files are allowed
                  if (allowMultiple) setFiles([...files]);
                } else {
                  if (allowMultiple) {
                    setFiles([...files, ...droppedFiles]);
                  } else {
                    setFiles(droppedFiles.slice(0, 1));
                  }
                }
              }}
            />
          )}
          {showImageCropper &&
          isSelectedFileAnImage &&
          !allowMultiple &&
          !showDropzone ? (
            <ImageCropper
              fileState={[
                files[0],
                (newFile: File) => {
                  setFiles([newFile]);
                }
              ]}
              options={imageCropperOptions}
            />
          ) : null}
          {/* File list */}
          {error ? (
            <div className="my-md">
              <p className="body2 text-destructive">
                File(s) could not be uploaded. Please ensure file
                types are supported and that they are not larger than
                20MB. If issues persist, contact support@emitiq.com.
              </p>
            </div>
          ) : (
            !allowMultiple && renderFiles()
          )}
          {allowMultiple && files.length > 0 && renderFiles()}
        </div>
      </div>

      {/* Import button */}
      {!forceHideButton && handleSubmit && (
        <div className="mt-md w-full">
          <div className="flex w-full items-center justify-end">
            {renderAdditionalToggles && renderAdditionalToggles()}
            <Button
              className="ml-sm"
              loading={isUploading}
              disabled={files?.length === 0}
              onClick={() => {
                handleSubmit();
              }}
            >
              Import
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
