import { ArrowRight, Plus, SearchIcon } from 'lucide-react';
import { useState } from 'react';
import { AssetDetailsType } from 'src/components/carbon/organisms/AssetManager';
import Input from 'src/components/core/atoms/Input';
import { Button } from 'src/components/shad-base/button';
import useAssetStore, {
  AssetStoreType,
  AssetTypeType
} from 'src/hooks/store/useAssetStore';

export function AssetCard({
  onClick,
  title,
  description,
  secondaryDescription,
  renderIcon
}: {
  title: string;
  description: string;
  secondaryDescription: string;
  renderIcon?: (isHovering: boolean) => React.ReactNode;
  onClick?: () => void;
}) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className={'w-full ' + (onClick ? 'cursor-pointer' : '')}
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="flex w-full justify-between">
        {/* Title and Description */}
        {renderIcon && (
          <div
            className={
              'max-w-[100px] rounded-md rounded-r-none border transition-all ' +
              (isHovering && onClick ? 'ring-1' : '')
            }
          >
            {renderIcon(isHovering)}
          </div>
        )}
        <div
          className={
            'flex w-full flex-nowrap items-center justify-between rounded-md border p-md transition-all ' +
            (renderIcon
              ? 'rounded-l-none border-l-transparent '
              : '') +
            (isHovering && onClick ? 'ring-l-none ring-1' : '')
          }
        >
          <div className="flex flex-col flex-nowrap">
            <div>
              <p className="subtitle2 font-bold">{title}</p>
            </div>
            <div className="mt-sm">
              <p>{description}</p>
            </div>
            <div>
              <p className="text-muted">{secondaryDescription}</p>
            </div>
          </div>
          {onClick && (
            <div>
              <Button variant="outline" size="icon">
                <ArrowRight
                  className={
                    'h-5 w-5 transition-all ' +
                    (isHovering ? 'text-primary/50 ' : '')
                  }
                />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default function ListView<AssetType>({
  assets,
  type,
  setCreateDialogOpen,
  getAssetDetails
}: {
  assets: AssetType[];
  type: AssetTypeType;
  setCreateDialogOpen: (open: boolean) => void;
  getAssetDetails?: (asset: AssetType) => AssetDetailsType;
}) {
  const { setActiveAssetIdentifier, setActiveAssetType } =
    useAssetStore((store: AssetStoreType) => ({
      setActiveAssetIdentifier: store.setActiveAssetIdentifier,
      setActiveAssetType: store.setActiveAssetType
    }));
  const [searchValue, setSearchValue] = useState('');

  const getTitle = () => {
    switch (type) {
      case 'equipment':
        if (assets?.length > 0) {
          return 'Your Equipment';
        } else {
          return 'Add your first piece of equipment';
        }
      default:
        if (assets?.length > 0) {
          return `Your ${type}s`;
        } else {
          return `Add your first ${type}`;
        }
    }
  };

  const descriptions: { [key in AssetTypeType]: string } = {
    location:
      'Locations are physical places where your company operates. This may include offices, factories, and warehouses.',
    vehicle:
      'Add your vehicle data. We’ll ask you for your information such as the vehicle type (e.g. passenger car) and what type of fuel it consumes (e.g. gasoline).',
    equipment:
      'Add your equipment data. We’ll ask you information about the equipment type (e.g. generator) and what type of fuel it consumes (e.g. gasoline).'
  };

  // Filter assets by search value
  const filteredAssets = assets.filter((asset) => {
    const { name, description, secondaryDescription } =
      getAssetDetails(asset);
    return (
      name.toLowerCase().includes(searchValue.toLowerCase()) ||
      description.toLowerCase().includes(searchValue.toLowerCase()) ||
      (secondaryDescription &&
        secondaryDescription
          .toLowerCase()
          .includes(searchValue.toLowerCase()))
    );
  });

  return (
    <>
      {/* Title/Buttons */}
      <div>
        <h6 className="font-bold">{getTitle()}</h6>
        <p className="mt-sm max-w-paragraph text-muted">
          {descriptions[type]}
        </p>
        <p className="mt-md text-muted">
          Not sure? You can always change this later.
        </p>
        <div className="mt-lg flex flex-row">
          <Button
            className="mr-sm"
            onClick={() => setCreateDialogOpen(true)}
          >
            <div>
              <Plus className="mr-sm h-icon w-icon text-white" />
            </div>
            Add new {type}
          </Button>
          {/* <LearnButton /> */}
        </div>
      </div>
      {/* Search Input */}
      {assets?.length > 0 && (
        <div className="mt-xl w-1/2">
          <div className="flex flex-row">
            <Input
              value={searchValue}
              size="default"
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={
                type === 'equipment'
                  ? 'Search equipment...'
                  : `Search ${type}s...`
              }
              startAdornment={
                <SearchIcon className="h-icon w-icon text-primary" />
              }
            />
          </div>
        </div>
      )}
      {/* Assets */}
      <div className="mt-lg w-full">
        {assets?.length > 0 ? (
          <div className="flex flex-col flex-nowrap">
            {searchValue !== '' && filteredAssets?.length === 0 ? (
              <div>
                <p>No results. Try a different search value.</p>
              </div>
            ) : (
              filteredAssets.map((asset, idx) => {
                const {
                  identifier,
                  name,
                  description,
                  secondaryDescription,
                  renderIcon
                } = getAssetDetails(asset);
                return (
                  <div
                    key={identifier}
                    className={idx !== 0 ? 'mt-md' : ''}
                  >
                    <AssetCard
                      title={name}
                      description={description}
                      secondaryDescription={secondaryDescription}
                      renderIcon={renderIcon}
                      onClick={() => {
                        setActiveAssetIdentifier(identifier);
                        setActiveAssetType(type);
                      }}
                    />
                  </div>
                );
              })
            )}
          </div>
        ) : (
          // No Assets
          <div className="flex h-full w-full flex-col ">
            <p className="subtitle1">
              {type === 'equipment'
                ? 'No equipment yet.'
                : `No ${type}s yet.`}
            </p>
            <p className="text-md mt-sm font-thin text-gray-500">
              Once you add your first{' '}
              {type === 'equipment' ? 'piece of ' : ''}
              {type}, you can start tracking your emissions.
            </p>
          </div>
        )}
      </div>
    </>
  );
}
