import {
  DataCalculatorResponseFragmentFragment,
  Scope1DataCalculatorsFragmentFragment,
  Scope2DataCalculatorsFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import Skeleton from 'src/components/core/atoms/Skeleton';
import { getListItemKey } from 'src/utils/format';

import {
  ArrowRight,
  CircleCheckBig,
  CircleDashed
} from 'lucide-react';
import {
  DataCalculatorTypesEnum,
  EquipmentFragmentFragment,
  LocationFragmentFragment,
  VehicleFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import DataCalculatorIcon from 'src/components/core/atoms/IconCircle';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from 'src/components/shad-base/accordion';
import useAssetStore, {
  AssetStoreType,
  AssetTypeType
} from 'src/hooks/store/useAssetStore';
import useDataCalculatorStore, {
  DataCalculatorStoreType
} from 'src/hooks/store/useDataCalculatorStore';
import { fEnum } from 'src/utils/format';
import { useEffect, useState } from 'react';
import { Button } from 'src/components/shad-base/button';
import { ROUTES } from 'src/config';
import { useRouter } from 'next/router';
import { ScopeType } from 'src/@types/emissions';

function AssetRow({
  response,
  assetType
}: {
  response: DataCalculatorResponseFragmentFragment;
  assetType: AssetTypeType;
}) {
  const {
    setActiveAsset,
    setActiveCalculatorType,
    setActiveAssetType
  } = useDataCalculatorStore((store: DataCalculatorStoreType) => ({
    setActiveAsset: store.setActiveAsset,
    setActiveCalculatorType: store.setActiveCalculatorType,
    setActiveAssetType: store.setActiveAssetType
  }));

  const asset = response[assetType] as
    | LocationFragmentFragment
    | VehicleFragmentFragment
    | EquipmentFragmentFragment;

  return (
    <>
      <div
        className={
          'group w-full cursor-pointer rounded-sm p-sm px-3 hover:bg-primary/10 '
        }
        onClick={() => {
          setActiveAsset(asset);
          setActiveCalculatorType(response?.calculatorType);
          setActiveAssetType(assetType);
        }}
      >
        <div className="flex items-center justify-between">
          <p>{asset?.name}</p>
          <div>
            {response?.missingMeasurementDateRanges.length === 0 ? (
              <div className="group-hover:hidden">
                <CircleCheckBig className="text-primary" />
              </div>
            ) : (
              <div className="group-hover:hidden">
                <CircleDashed className="text-muted" />
              </div>
            )}
            <div className="hidden group-hover:flex ">
              <ArrowRight className="text-primary" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function DataCalculatorAccordion({
  scope,
  responses,
  calculatorType
}: {
  responses: DataCalculatorResponseFragmentFragment[];
  scope: ScopeType;
  calculatorType: string;
}) {
  const { clearActive } = useAssetStore((store: AssetStoreType) => ({
    clearActive: store.clearActive
  }));

  const locationCalculatorTypes = [
    DataCalculatorTypesEnum.LocationNaturalGas,
    DataCalculatorTypesEnum.LocationHeatOrSteam,
    DataCalculatorTypesEnum.LocationElectricity,
    DataCalculatorTypesEnum.LocationPurchasedCooling
  ];

  const vehicleCalculatorTypes = [
    DataCalculatorTypesEnum.VehicleDistance,
    DataCalculatorTypesEnum.VehicleFuel
  ];

  const equipmentCalculatorTypes = [
    DataCalculatorTypesEnum.EquipmentFuel,
    DataCalculatorTypesEnum.EquipmentIndustrialGas,
    DataCalculatorTypesEnum.EquipmentRefrigerant
  ];

  const toEnumCase = (inputString: string) => {
    return inputString
      .split('')
      .map((character) => {
        if (character == character.toUpperCase()) {
          return '_' + character.toUpperCase();
        } else {
          return character.toUpperCase();
        }
      })
      .join('');
  };

  const getCalculatorAssetType = (type: DataCalculatorTypesEnum) => {
    if (locationCalculatorTypes.includes(type)) return 'location';
    else if (vehicleCalculatorTypes.includes(type)) return 'vehicle';
    else if (equipmentCalculatorTypes.includes(type))
      return 'equipment';
  };

  const calculatorTypeEnum = toEnumCase(
    calculatorType
  ) as DataCalculatorTypesEnum;
  const assetType = getCalculatorAssetType(calculatorTypeEnum);

  const numCompletedResponses = responses?.filter(
    (response) => response?.missingMeasurementDateRanges.length == 0
  )?.length;

  const isComplete = numCompletedResponses === responses?.length;
  const [value, setValue] = useState<string | null>(
    isComplete ? null : calculatorType
  );

  useEffect(() => {
    setValue(isComplete ? null : calculatorType);
  }, [isComplete]);

  const router = useRouter();

  return (
    <Accordion
      collapsible
      type="single"
      value={value}
      defaultValue={calculatorType}
      onValueChange={setValue}
    >
      <AccordionItem
        value={calculatorType}
        className="rounded-md border transition-all hover:ring-1 hover:ring-primary/50"
      >
        <AccordionTrigger className="w-full p-md">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center">
              <div className="mr-md">
                <DataCalculatorIcon
                  scope={scope}
                  calculatorType={calculatorTypeEnum}
                />
              </div>
              <p>{fEnum(calculatorTypeEnum)}</p>
            </div>
            {responses?.length === 0 ? (
              <div className="flex px-md">
                <Button
                  onClick={() => {
                    if (assetType === 'location') {
                      router.push(ROUTES.ORGANIZATION.LOCATIONS);
                    }
                    if (assetType === 'vehicle') {
                      router.push(ROUTES.ORGANIZATION.VEHICLES);
                    }
                    if (assetType === 'equipment') {
                      router.push(ROUTES.ORGANIZATION.EQUIPMENT);
                    }

                    // Set back to list view
                    clearActive();
                  }}
                >
                  Manage Assets
                </Button>
              </div>
            ) : (
              <div className="flex flex-nowrap items-center">
                {numCompletedResponses === responses?.length ? (
                  <CircleCheckBig className="text-primary" />
                ) : (
                  <CircleDashed className="text-muted" />
                )}
                <p className="mx-md">
                  {numCompletedResponses === responses?.length
                    ? 'Complete'
                    : `
                ${numCompletedResponses} / ${responses?.length}
                `}
                </p>
              </div>
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="flex h-full min-h-full flex-col p-md pl-[50px]">
            <div className="w-full">
              <div className="flex h-full">
                {/* Line */}
                <div className="w-[5px] rounded-sm bg-muted/10"></div>
                {/* Responses */}
                <div className="w-full pl-sm">
                  <div className="flex w-full flex-col flex-nowrap">
                    {responses?.map((response, idx) => {
                      return (
                        <div
                          className={idx ? 'mt-sm' : undefined}
                          key={getListItemKey(idx)}
                        >
                          <AssetRow
                            response={response}
                            assetType={assetType}
                          />
                        </div>
                      );
                    })}
                    {responses?.length === 0 && (
                      <div className="flex">
                        <div>
                          You're up to date! For the current reporting
                          period, no {assetType} assets in your
                          organization need this calculator.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

export default function ListView({
  scope,
  loading,
  dataCalculators
}: {
  dataCalculators:
    | Scope1DataCalculatorsFragmentFragment
    | Scope2DataCalculatorsFragmentFragment;
  loading: boolean;
  scope: ScopeType;
}) {
  const descriptions = {
    1: 'Scope 1 includes emissions from stationary combustion (e.g. natural gas consumption, fugitive emissions, mobile combustion and process emissions.',
    2: 'Scope 2 includes emissions from consumption of purchased electricity, steam, heating, or cooling.'
  };

  return (
    <>
      {/* Title/Description */}
      <h6 className="font-bold">Calculate Scope {scope} Emissions</h6>
      <p className="mt-md max-w-paragraph text-muted">
        {descriptions[scope]}
      </p>
      <div className="my-lg" />
      {/* Data Calculators */}
      {loading ? (
        <Skeleton count={2} />
      ) : (
        Object.keys(dataCalculators || {}).map((key, index) => {
          const responses =
            key == '__typename'
              ? null
              : (dataCalculators[
                  key
                ] as DataCalculatorResponseFragmentFragment[]);

          return key !== '__typename' ? (
            <div
              className={index ? 'mt-md' : undefined}
              key={getListItemKey(index)}
            >
              <DataCalculatorAccordion
                calculatorType={key}
                responses={responses}
                scope={scope}
              />
            </div>
          ) : null;
        })
      )}
    </>
  );
}
