import { gql, useQuery } from '@apollo/client';
import {
  CreateVehicleInput,
  CreateVehiclePayload,
  EditVehicleInput,
  EditVehiclePayload,
  FuelTypesEnum,
  VehicleDialogLocationsQueryQuery,
  VehicleFragmentFragment,
  VehicleTypesEnum
} from 'src/__apolloGenerated__/graphql';
import CreateAssetDialog from 'src/components/carbon/organisms/AssetManager/CreateAssetDialog';
import Form from 'src/components/core/organisms/Form';
import { toast } from 'src/hooks/shad-base/useToast';
import useAssetStore, {
  AssetStoreType
} from 'src/hooks/store/useAssetStore';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import { fEnum } from 'src/utils/format';

export default function CreateVehiclesDialog({
  open,
  onOpenChange,
  entityIdentifier,
  initialVehicle
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  entityIdentifier: string;
  initialVehicle?: VehicleFragmentFragment;
}) {
  const { activeReportingPeriodId }: SettingsStoreType = useSettingsStore();

  const { setActiveAssetIdentifier, setActiveAssetType } =
    useAssetStore((store: AssetStoreType) => ({
      setActiveAssetIdentifier: store.setActiveAssetIdentifier,
      setActiveAssetType: store.setActiveAssetType
    }));

  const { mutate: createVehicle, loading: isCreatingVehicle } =
    useBackendMutation<
      { input: CreateVehicleInput },
      CreateVehiclePayload
    >({
      mutation: gql`
        mutation CreateVehicleMutation(
          $input: CreateVehicleInput!
          $reportingPeriodIdentifier: String
        ) {
          createVehicle(input: $input) {
            ok
            errors {
              ...ErrorsFragment
            }
            vehicle {
              ...VehicleFragment
            }
          }
        }
      `,
      callbacks: {
        onSuccess: () => {
          onOpenChange(false);
          setActiveAssetIdentifier(null);
          setActiveAssetType(null);
          toast({
            title: 'Success.',
            description: 'Vehicle created.',
            variant: 'success'
          });
        }
      }
    });

  const { mutate: editVehicle, loading: isEditingVehicle } =
    useBackendMutation<
      { input: EditVehicleInput },
      EditVehiclePayload
    >({
      mutation: gql`
        mutation EditVehicleMutation(
          $input: EditVehicleInput!
          $reportingPeriodIdentifier: String
        ) {
          editVehicle(input: $input) {
            ok
            errors {
              ...ErrorsFragment
            }
            vehicle {
              ...VehicleFragment
            }
          }
        }
      `,
      callbacks: {
        onSuccess: () => {
          onOpenChange(false);
          toast({
            title: 'Success.',
            description: 'Vehicle updated.',
            variant: 'success'
          });
        }
      }
    });

  const { data: locationsData } =
    useQuery<VehicleDialogLocationsQueryQuery>(gql`
      query VehicleDialogLocationsQuery {
        locations {
          data {
            identifier
            name
          }
        }
      }
    `);
  const locations = locationsData?.locations?.data || [];

  const iconOptions = [
    {
      key: '/static/icons/assets/vehicles/car.png',
      label: 'Car'
    },
    // {
    //   key: '/static/icons/assets/vehicles/tesla.png',
    //   label: 'Tesla'
    // },
    {
      key: '/static/icons/assets/vehicles/suv.png',
      label: 'SUV'
    },
    {
      key: '/static/icons/assets/vehicles/pickup.png',
      label: 'Pickup'
    },
    // {
    //   key: '/static/icons/assets/vehicles/rivian.png',
    //   label: 'Rivian'
    // },
    {
      key: '/static/icons/assets/vehicles/cargo-van.png',
      label: 'Cargo Van'
    },
    {
      key: '/static/icons/assets/vehicles/cargo-van-sprinter.png',
      label: 'Cargo Van'
    },
    {
      key: '/static/icons/assets/vehicles/passenger-van.png',
      label: 'Passenger Van'
    },
    {
      key: '/static/icons/assets/vehicles/mail-truck.png',
      label: 'Mail Truck'
    },
    {
      key: '/static/icons/assets/vehicles/armored-truck.png',
      label: 'Armored Truck'
    },
    {
      key: '/static/icons/assets/vehicles/delivery-truck.png',
      label: 'Delivery Truck'
    },
    {
      key: '/static/icons/assets/vehicles/box-truck.png',
      label: 'Box Truck'
    },
    {
      key: '/static/icons/assets/vehicles/charter-bus.png',
      label: 'Charter Bus'
    }
  ];

  const defaultValues = {
    name: initialVehicle?.name || '',
    location: initialVehicle?.location?.identifier || '',
    make: initialVehicle?.make || '',
    model: initialVehicle?.model || '',
    year: initialVehicle?.year || '',
    fuelType: initialVehicle?.fuelType || '',
    vehicleType: initialVehicle?.vehicleType || '',
    icon: initialVehicle?.icon || iconOptions[0].key,
    numVehicles: initialVehicle?.numberOfUnits || ''
  };

  return (
    <CreateAssetDialog
      open={open}
      onOpenChange={onOpenChange}
      type="vehicle"
    >
      <div className="px-md">
        <Form
          isSubmitting={isCreatingVehicle || isEditingVehicle}
          rows={[
            {
              questions: [
                {
                  key: 'name',
                  text: 'What is the name of this vehicle?',
                  description:
                    'Give this vehicle a name to make it easier to identify later.',
                  type: 'text',
                  defaultValue: defaultValues.name,
                  required: true,
                  errorMessage: 'Please enter a name',
                  placeholder: 'E.g. Transport Van'
                }
              ]
            },
            {
              questions: [
                {
                  key: 'location',
                  text: 'What location does this vehicle belong to?',
                  type: 'single-select',
                  defaultValue: defaultValues.location,
                  required: true,
                  options:
                    locations?.map((location) => ({
                      key: location.identifier,
                      label: location.name
                    })) || []
                }
              ]
            },
            {
              questions: [
                {
                  key: 'make',
                  text: 'Make',
                  type: 'text',
                  required: true,
                  defaultValue: defaultValues.make,
                  placeholder: 'E.g. Ford'
                },
                {
                  key: 'model',
                  text: 'Model',
                  type: 'text',
                  required: true,
                  defaultValue: defaultValues.model,
                  placeholder: 'E.g. F-150'
                },
                {
                  key: 'year',
                  text: 'Year',
                  type: 'number',
                  required: true,
                  defaultValue: defaultValues.year,
                  placeholder: 'E.g. 2020'
                }
              ]
            },
            {
              questions: [
                {
                  key: 'fuel-type',
                  text: 'Fuel Type',
                  type: 'single-select',
                  required: true,
                  defaultValue: defaultValues.fuelType,
                  options: Object.values(FuelTypesEnum).map(
                    (value) => ({
                      key: value,
                      label: fEnum(value)
                    })
                  )
                },
                {
                  key: 'num-vehicles',
                  text: 'Number of Vehicles',
                  type: 'number',
                  required: true,
                  defaultValue: defaultValues.numVehicles,
                  helperMessage:
                    'If you have a fleet of this vehicle, enter the number of vehicles in the fleet. Otherwise, enter 1.',
                  placeholder: 'E.g. 10'
                }
              ]
            },
            {
              questions: [
                {
                  key: 'vehicle-type',
                  text: 'What type of vehicle is this?',
                  type: 'single-select',
                  required: true,
                  defaultValue: defaultValues.vehicleType,
                  options: Object.values(VehicleTypesEnum).map(
                    (value) => ({
                      key: value,
                      label: fEnum(value)
                    })
                  )
                }
              ]
            },
            {
              questions: [
                {
                  key: 'icon',
                  text: 'Select an icon to represent this vehicle',
                  type: 'icon',
                  required: false,
                  defaultValue: defaultValues.icon,
                  options: iconOptions
                }
              ]
            }
          ]}
          onSubmit={(values) => {
            const input = {
              locationIdentifier: values['location'],
              name: values['name'],
              make: values['make'],
              model: values['model'],
              year: values['year'],
              fuelType: values['fuel-type'],
              vehicleType: values['vehicle-type'],
              icon: values['icon'],
              numberOfUnits: values['num-vehicles'],
              calculationMethod: 'FUEL'
            };
            if (initialVehicle) {
              editVehicle({
                variables: {
                  input: {
                    vehicleIdentifier: initialVehicle.identifier,
                    ...input
                  },
                  reportingPeriodIdentifier: activeReportingPeriodId
                }
              });
            } else {
              createVehicle({
                variables: {
                  input: {
                    entityIdentifier,
                    ...input
                  },
                  reportingPeriodIdentifier: activeReportingPeriodId
                },
                refetchQueries: ['VehiclesViewQuery']
              });
            }
          }}
        />
      </div>
    </CreateAssetDialog>
  );
}
