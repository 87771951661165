import {
  ALL_QUESTION_KEYS,
  CreateMeasurementQuestionKeys,
  GET_QUESTIONS
} from 'src/components/carbon/molecules/create-measurement-forms/questions';
import Form, {
  QuestionRowType
} from 'src/components/core/organisms/Form';

export default function CreateMeasurementAutoForm({
  submitButtonText,
  onFormSubmit,
  isSubmitting,
  defaultValues,
  error,
  omitQuestionKeys
}: {
  submitButtonText: string;
  onFormSubmit: (
    values,
    submitToggleChecked: boolean,
    resetFormValues: () => void
  ) => void;
  isSubmitting: boolean;
  defaultValues?: { [key in CreateMeasurementQuestionKeys]?: string };
  error?: string;
  omitQuestionKeys?: CreateMeasurementQuestionKeys[];
}) {
  const questionsToOmit = [
    'emissionFactor',
    'value',
    'valueUnit',
    'ghgCategory',
    'ownershipFraction',
    ...(omitQuestionKeys || [])
  ];

  const rows: QuestionRowType[] = [];
  ALL_QUESTION_KEYS.forEach((key: CreateMeasurementQuestionKeys) => {
    if (!questionsToOmit.includes(key)) {
      // If the question is a date range, we need to add both the start and end date
      if (key === 'startDate') {
        rows.push({
          questions: [
            GET_QUESTIONS(defaultValues)['startDate'],
            GET_QUESTIONS(defaultValues)['endDate']
          ]
        });
        // If question is endDate, we have already added it in the startDate row
      } else if (key === 'endDate') {
        return;
      } else if (GET_QUESTIONS(defaultValues)[key]) {
        rows.push({
          questions: [GET_QUESTIONS(defaultValues)[key]]
        });
      }
    }
  });

  return (
    <div className="flex flex-col flex-nowrap">
      <Form
        rows={rows}
        submitToggleText="Create more"
        onSubmit={onFormSubmit}
        isSubmitting={isSubmitting}
        submitButtonText={submitButtonText}
      />
      <div className="mt-sm self-end">
        {error && (
          <p className="body2 mt-sm text-destructive">{error}</p>
        )}
      </div>
    </div>
  );
}
