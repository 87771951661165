import { cn } from '@/lib/utils';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';
import {
  AlertCircle,
  Columns,
  ExternalLink,
  GraduationCap,
  KeyRound,
  Lock,
  LogOut,
  Settings,
  UserRound,
  Users,
  Wallet,
  X
} from 'lucide-react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
  EntityFragmentFragment,
  MeasurementFragmentFragment,
  ReconcileSubscriptionStatusInput,
  ReconcileSubscriptionStatusPayload,
  RecurringMeasurementsMissingSubsequentDataQueryQuery,
  ReportingPeriodObject,
  SetActiveEntityInput,
  SetActiveEntityPayload,
  SidebarEntityQueryQuery,
  SurveyTemplatesEnum,
  ToReviewAggregateMeasurementsFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import ReportingPeriodSelector from 'src/components/carbon/molecules/ReportingPeriodSelector';
import DropdownMenu from 'src/components/core/atoms/DropdownMenu';
import Icon from 'src/components/core/atoms/Icon';
import Skeleton from 'src/components/core/atoms/Skeleton';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from 'src/components/shad-base/accordion';
import { Button } from 'src/components/shad-base/button';
import {
  Dialog,
  DialogContent,
  DialogFooter
} from 'src/components/shad-base/dialog';
import {
  DropdownMenu as DropdownMenuBase,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from 'src/components/shad-base/dropdown-menu';

import dayjs from 'dayjs';
import HubspotContactForm from 'src/components/marketing/HubspotContactForm';
import { ROUTES, SUBSCRIPTION_PLANS } from 'src/config';
import useAppStore from 'src/hooks/store/useAppStore';
import useAuthPersistStore from 'src/hooks/store/useAuthPersistStore';
import useDataCalculatorStore, {
  DataCalculatorStoreType
} from 'src/hooks/store/useDataCalculatorStore';
import useFootprintStore, {
  FootprintStoreType,
  HeadType
} from 'src/hooks/store/useFootprintStore';
import useMeasureStore, {
  MeasureStoreType,
  UNLOCKED_COLUMN_KEY_SCHEMA_MATCH
} from 'src/hooks/store/useMeasureStore';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import useSubscriptionStore, {
  SubscriptionStoreType
} from 'src/hooks/store/useSubcriptionStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import { SET_ACTIVE_ENTITY } from 'src/hooks/useBackendMutation/mutations';
import usePaywall from 'src/hooks/usePaywall';
import CollisionSurveyNotification from 'src/layouts/Layout/SidebarNav/CollisionSurveyNotification';
import MissingMeasurementDataDialog from 'src/layouts/Layout/SidebarNav/MissingMeasurementDataDialog';
import ReviewMeasurementsPopper from 'src/layouts/Layout/SidebarNav/ReviewMeasurementsPopper';
import { getListItemKey } from 'src/utils/format';
import getIntercom, { INTERCOM_ARTICLES } from 'src/utils/intercom';

type NavItemType = {
  key: string;
  name: string;
  onClick?: () => void;
  isSelected?: boolean;
  chip?: string;
  subItems?: {
    name: string;
    onClick: () => void;
    isSelected: boolean;
    locked?: boolean;
    icon?: React.ReactNode;
  }[];
  icon?: React.ReactNode;
  accordionOpen?: string;
  external?: boolean;
  locked?: boolean;
};

export type ToReviewMeasurementType =
  ToReviewAggregateMeasurementsFragmentFragment['toReviewAggregateMeasurements']['edges'][0]['node'];

type ActiveEntityType = EntityFragmentFragment &
  SidebarEntityQueryQuery['activeEntity']['data']['tree'];

export default function SidebarNav() {
  const router = useRouter();
  const { signout } = useAuthPersistStore();
  const { setSidebarMinimized, sidebarMinimized } = useAppStore(
    (store) => ({
      setSidebarMinimized: store.setSidebarMinimized,
      sidebarMinimized: store.sidebarMinimized
    })
  );

  const {
    activeReportingPeriodId,
    setActiveReportingPeriodId,
    setActiveReportingPeriodStartDate,
    setActiveReportingPeriodEndDate
  }: SettingsStoreType = useSettingsStore();

  const [
    missingMeasurementDataDialogOpen,
    setMissingMeasurementDataDialogOpen
  ] = useState(false);

  const { activeSubscriptionTier }: SubscriptionStoreType =
    useSubscriptionStore();

  const {
    isAuthorized: authorizedForGroupsAndPermissions,
    requiredPlan: groupsRequiredPlan,
    subtitleText: groupsSubtitleText
  } = usePaywall('groups-and-permissions');
  const {
    isAuthorized: authorizedForMembers,
    requiredPlan: membersRequiredPlan,
    subtitleText: membersSubtitleText
  } = usePaywall('team');

  const {
    setRootNodeId,
    selectedStartDate,
    selectedEndDate,
    selectedFilters
  } = useMeasureStore((store: MeasureStoreType) => ({
    setRootNodeId: store.setRootNodeId,
    selectedStartDate: store.selectedStartDate,
    selectedEndDate: store.selectedEndDate,
    selectedFilters: store.selectedFilters
  }));

  const {
    setHeads,
    selectedTree,
    setSelectedTree,
    activeEntityName,
    setActiveEntityName,
    toReviewMeasurementsTotal,
    setToReviewMeasurementsTotal
  } = useFootprintStore((store: FootprintStoreType) => ({
    activeEntityName: store.activeEntityName,
    setActiveEntityName: store.setActiveEntityName,
    toReviewMeasurementsTotal: store.toReviewMeasurementsTotal,
    setToReviewMeasurementsTotal: store.setToReviewMeasurementsTotal,
    setHeads: store.setHeads,
    selectedTree: store.selectedTree,
    setSelectedTree: store.setSelectedTree
  }));

  const {
    setPaywallDialogOpen,
    setRequiredPlan,
    setSubtitleText
  }: SubscriptionStoreType = useSubscriptionStore();

  const { clearCurrentActive } = useDataCalculatorStore(
    (store: DataCalculatorStoreType) => ({
      clearCurrentActive: store.clearCurrentActive
    })
  );

  const [navItemAccordionOpen, setNavItemAccordionOpen] = useState<{
    [key in
      | 'organization'
      | 'inventory'
      | 'mitigate'
      | 'report']: boolean;
  }>({
    organization: true,
    inventory: true,
    mitigate: true,
    report: true
  });

  const [contactSalesDialogOpen, setContactSalesDialogOpen] =
    useState(false);
  const [selectedReport, setSelectedReport] = useState<
    'TCFD' | 'CDP' | null
  >(null);
  const [hubspotFormOpen, setHubspotFormOpen] = useState(false);

  useEffect(() => {
    const newNavItemAccordionOpen = { ...navItemAccordionOpen };
    if (
      router.pathname.startsWith('/organization') &&
      navItemAccordionOpen['organization'] === false
    ) {
      newNavItemAccordionOpen['organization'] = true;
    }
    setNavItemAccordionOpen(newNavItemAccordionOpen);
  }, []);
  const { sideNavOpenState, setSideNavOpenState }: SettingsStoreType =
    useSettingsStore();

  const {
    data,
    loading: loadingEntity,
    refetch: refetchActiveEntity,
    previousData
  } = useQuery<SidebarEntityQueryQuery>(
    gql`
      query SidebarEntityQuery(
        $startDate: DateTime
        $endDate: DateTime
      ) {
        activeEntity {
          errors {
            ...ErrorsFragment
          }
          ok
          data {
            ...EntityFragment
            tree {
              head {
                ...ToReviewAggregateMeasurementsFragment
              }
              branches {
                name
                identifier
                path
              }
            }
          }
        }
        entities {
          errors {
            ...ErrorsFragment
          }
          data {
            identifier
            name
            inPublicDirectory
            tree {
              identifier
              head {
                identifier
                name
              }
            }
          }
        }
        user {
          errors {
            ...ErrorsFragment
          }
          data {
            isSuperuser
            identifier
            activeAccount {
              user {
                identifier
              }
              surveySubmissions {
                identifier
                isSubmitted
                survey {
                  identifier
                  template
                }
              }
              measureViewColumns
            }
          }
        }
        collisionSurveyInvite {
          errors {
            ...ErrorsFragment
          }
          data {
            identifier
          }
        }
      }
    `,
    {
      variables: {
        startDate: dayjs(selectedStartDate).toDate(),
        endDate: dayjs(selectedEndDate).toDate(),
        states: selectedFilters
      },
      // If one query returns an error, don't prevent the others from returning data
      errorPolicy: 'all'
    }
  );

  const {
    data: missingMeasurementData,
    loading: loadingMissingMeasurementsData,
    refetch: refetchMissingMeasurementsData
  } = useQuery<RecurringMeasurementsMissingSubsequentDataQueryQuery>(
    gql`
      query RecurringMeasurementsMissingSubsequentDataQuery(
        $startDate: DateTime
        $endDate: DateTime
        $states: [MeasurementStatesEnum!]
        $reportingPeriodIdentifier: String
      ) {
        recurringMeasurementsMissingSubsequentData {
          ok
          errors {
            ...ErrorsFragment
          }
          data {
            ...MeasurementFragment
            location {
              ...LocationFragment
            }
            vehicle {
              ...VehicleFragment
            }
            equipment {
              ...EquipmentFragment
            }
          }
        }
      }
    `,
    {
      variables: {
        reportingPeriodIdentifier: activeReportingPeriodId
      },
      fetchPolicy: 'network-only'
    }
  );

  const user = data?.user?.data;
  const entities = data?.entities?.data;
  const activeEntity = data?.activeEntity?.data as ActiveEntityType;

  const previousDataEntity = previousData?.activeEntity
    ?.data as ActiveEntityType;

  const collisionSurveySubmission =
    user?.activeAccount?.surveySubmissions?.find((submission) => {
      return (
        submission?.survey?.template === SurveyTemplatesEnum.Collision
      );
    });

  const collisionSurveyInvite = data?.collisionSurveyInvite?.data;

  const heads =
    activeEntity?.tree?.heads?.length > 0
      ? activeEntity?.tree?.heads
      : null;
  const head = activeEntity?.tree
    ?.head as ToReviewAggregateMeasurementsFragmentFragment;
  const toReviewMeasurements =
    head?.toReviewAggregateMeasurements?.edges.map(
      (edge) => edge.node
    ) as ToReviewMeasurementType[];

  const { mutate: setActiveEntity } = useBackendMutation<
    { input: SetActiveEntityInput },
    SetActiveEntityPayload
  >({
    mutation: SET_ACTIVE_ENTITY,
    callbacks: {
      onSuccess: (data) => {
        useMeasureStore.getState().setActiveNode({
          nodeId: null,
          type: null,
          path: null
        });
        useFootprintStore
          .getState()
          .setHeads(data?.entity?.tree?.heads as HeadType[]);

        if (router.pathname !== ROUTES.INVENTORY) {
          router.push(ROUTES.INVENTORY);
        }
        refetchActiveEntity();
      }
    }
  });
  const { mutate: reconcileSubscriptionStatus } = useBackendMutation<
    { input: ReconcileSubscriptionStatusInput },
    ReconcileSubscriptionStatusPayload
  >({
    mutation: gql`
      mutation ReconcileSubscriptionStatus(
        $input: ReconcileSubscriptionStatusInput!
      ) {
        reconcileSubscriptionStatus(input: $input) {
          ok
          user {
            ...UserFragment
          }
          errors {
            ...ErrorsFragment
          }
        }
      }
    `
  });
  let isViewingOtherAccount = false;

  //if the userId attached to the active account is not the same as the resolved userId,
  //and the current user is a superuser, then they are viewing another user's account
  if (
    user?.activeAccount?.user?.identifier !== user?.identifier &&
    user?.isSuperuser
  ) {
    isViewingOtherAccount = true;
  }

  // // On first load select first tree
  useEffect(() => {
    if (heads) {
      const head = heads[0];
      setRootNodeId(heads[0]?.identifier);
      if (!selectedTree?.identifier) {
        setSelectedTree({
          path: head?.path,
          identifier: head?.identifier,
          name: head?.name,
          userPermissions: head?.userPermissions
        });
        setHeads(
          heads.map((head) => {
            return {
              path: head?.path,
              identifier: head?.identifier,
              name: head?.name,
              userPermissions: head?.userPermissions
            };
          })
        );
      }
    }
    if (activeEntity?.billing?.subscriptionReconciliationRequired) {
      reconcileSubscriptionStatus({
        variables: {
          input: {
            harmonize: true
          }
        }
      });
    }
    if (activeEntity) {
      setActiveEntityName(activeEntity?.name);
      setToReviewMeasurementsTotal(
        head?.toReviewAggregateMeasurements?.totalCount
      );
      if (
        activeEntity.reportingRequirementsCompleted &&
        !activeReportingPeriodId &&
        activeEntity.reportingPeriods.length > 0
      ) {
        setActiveReportingPeriodId(
          activeEntity?.reportingPeriods[0].identifier
        );
        setActiveReportingPeriodStartDate(
          dayjs(activeEntity?.reportingPeriods[0].startDate).toDate()
        );
        setActiveReportingPeriodEndDate(
          dayjs(activeEntity?.reportingPeriods[0].endDate).toDate()
        );
      }
    }
  }, [heads, activeEntity]);

  useEffect(() => {
    useMeasureStore
      .getState()
      .setUnlockedColumnKeys(
        user?.activeAccount?.measureViewColumns?.map(
          (key: string) => UNLOCKED_COLUMN_KEY_SCHEMA_MATCH[key]
        )
      );
  }, [user]);

  const getToolsNavItem = (page: string) => {
    return {
      name: _.startCase(page),
      isSelected: router.pathname === '/tools/' + page,
      onClick: () => {
        if (router.pathname !== page) {
          router.push('/tools/' + page);
        }

        // If there is an active data calculator already set somewhere else, clear it
        if (page !== 'overview') {
          clearCurrentActive();
        }
      }
    };
  };

  const getOrganizationNavItem = (page: string) => {
    return {
      name: _.startCase(page),
      isSelected: router.pathname === '/organization/' + page,
      onClick: () => {
        if (router.pathname !== page) {
          router.push('/organization/' + page);
        }
      }
    };
  };

  const getMitigateNavItem = (page: string) => {
    return {
      name: _.startCase(page),
      isSelected: router.pathname === '/mitigate/' + page,
      onClick: () => {
        if (router.pathname !== page) {
          router.push('/mitigate/' + page);
        }
      }
    };
  };

  const getReportsNavItem = (page: string) => {
    return {
      name: _.startCase(page),
      isSelected: router.pathname === '/report/' + page,
      onClick: () => {
        if (page === 'TCFD' || page === 'CDP') {
          setContactSalesDialogOpen(true);
          setSelectedReport(page as 'TCFD' | 'CDP');
          return;
        }
        if (router.pathname !== page) {
          router.push('/report/' + page);
        }
      },
      locked: page === 'TCFD' || page === 'CDP'
    };
  };

  const navItems: NavItemType[] = [
    {
      key: 'dashboard',
      name: 'Dashboard',
      isSelected: router.pathname === ROUTES.DASHBOARD,
      onClick: () => {
        if (router.pathname !== ROUTES.DASHBOARD) {
          router.push(ROUTES.DASHBOARD);
        }
      }
    },
    {
      key: 'inventory',
      name: 'Carbon Inventory',
      isSelected: router.pathname === ROUTES.INVENTORY,
      onClick: () => {
        if (router.pathname !== ROUTES.INVENTORY) {
          router.push(ROUTES.INVENTORY);
        }
      }
    },
    {
      key: 'organization',
      name: 'Organization',
      subItems: [
        getOrganizationNavItem('locations'),
        getOrganizationNavItem('vehicles'),
        getOrganizationNavItem('equipment')
      ],
      // Propagate selection to parent if any subitem is selected
      isSelected:
        router.pathname.startsWith('/organization') &&
        !sideNavOpenState['organization'],
      accordionOpen: router.pathname.startsWith('/organization')
        ? 'organization'
        : null
    },
    {
      key: 'tools',
      name: 'Tools',
      subItems: [
        getToolsNavItem('scope1'),
        getToolsNavItem('scope2'),
        getToolsNavItem('scope3')
      ],
      // Propagate selection to parent if any subitem is selected
      isSelected:
        router.pathname.startsWith('/tools') &&
        !sideNavOpenState['tools'],
      accordionOpen: router.pathname.startsWith('/tools')
        ? 'tools'
        : null
    },
    {
      key: 'mitigate',
      name: 'Mitigate',
      subItems: [
        getMitigateNavItem('target'),
        getMitigateNavItem('strategies'),
        getMitigateNavItem('actions'),
        getMitigateNavItem('incentives')
      ],
      // Propagate selection to parent if any subitem is selected
      isSelected:
        router.pathname.startsWith('/mitigate') &&
        !navItemAccordionOpen['mitigate'],
      accordionOpen: router.pathname.startsWith('/mitigate')
        ? 'mitigate'
        : null
    },
    {
      key: 'report',
      name: 'Report',
      subItems: [
        getReportsNavItem('ESR'),
        getReportsNavItem('TCFD'),
        getReportsNavItem('CDP')

        // getMyFootprintNavItem('certify')
      ],
      // Propagate selection to parent if any subitem is selected
      isSelected:
        router.pathname.startsWith('/report') &&
        !navItemAccordionOpen['report'],
      accordionOpen: router.pathname.startsWith('/report')
        ? 'report'
        : null
    }
    // {
    //   key: 'directory',
    //   name: 'Company Directory',
    //   onClick: () => {
    //     router.push(ROUTES.DIRECTORY);
    //   },
    //   external: true
    // },
    // {
    //   key: 'open-impact-standard',
    //   name: 'Carbon Removal',
    //   chip: 'Alpha',
    //   onClick: () => {
    //     router.push(ROUTES.OPEN_IMPACT_STANDARD.LIST);
    //   },
    //   isSelected: router.pathname.includes(
    //     ROUTES.OPEN_IMPACT_STANDARD.LIST
    //   )
    // },
  ];

  const itemClassName = (isSelected) => {
    return cn(
      'flex items-center p-sm h-[35px] rounded-lg cursor-pointer hover:bg-primary/10 transition-all ',
      isSelected ? 'bg-primary/10' : ''
    );
  };

  // function getTooltipContent(name) {
  //   if (name == 'Dashboard') {
  //     return 'This is your command center for managing your sustainability journey at a high level.';
  //   }
  //   if (name == 'Carbon Inventory') {
  //     return 'These tabs let you control your footprint. Measure your impact, Reduce your emissions, Share your progress, and offset unnavoidable CO2.';
  //   }
  //   if (name == 'Settings') {
  //     return 'Manage your plan, team and integrations using these tabs.';
  //   }
  // }

  // Collision Survey Notification
  const today = dayjs().toDate();
  const contestDeadline = dayjs('2024-06-26').toDate();
  const resultsReleased = dayjs('2024-07-03').toDate();

  const isCollisionSurveyComplete =
    collisionSurveySubmission?.isSubmitted;
  const completeYourData =
    isCollisionSurveyComplete && today < contestDeadline;
  const waitForResults =
    isCollisionSurveyComplete &&
    today > contestDeadline &&
    today < resultsReleased;

  const tree =
    activeEntity?.tree as SidebarEntityQueryQuery['activeEntity']['data']['tree'];
  const purchasesBranch = tree?.branches?.find((branch) => {
    return branch?.name === 'Purchases';
  });

  const reportingPeriods =
    activeEntity?.reportingPeriods as ReportingPeriodObject[];

  const intercom = getIntercom();

  const missingMeasurements = missingMeasurementData
    ?.recurringMeasurementsMissingSubsequentData
    ?.data as MeasurementFragmentFragment[];

  return sidebarMinimized ? (
    <div
      className={
        'h-full w-left-min-sidebar border-r ' +
        (isViewingOtherAccount ? 'bg-warning/50' : '')
      }
    >
      <Button
        variant="outline"
        size="icon"
        className="mt-3"
        onClick={() => setSidebarMinimized(false)}
      >
        <Columns className="scale-75" />
      </Button>
    </div>
  ) : (
    <div
      id="contact-sales-link"
      className="relative flex h-full w-left-sidebar min-w-left-sidebar max-w-left-sidebar flex-col flex-nowrap border-r bg-background"
    >
      <div className="h-full max-h-screen w-full overflow-y-auto p-md">
        {/* Entity Name */}
        {isViewingOtherAccount && (
          <div className="absolute left-0 top-0 flex h-[30px] w-full items-center justify-center bg-warning/50">
            <p className="body2 font-bold text-warning-foreground">
              Superuser View
            </p>
          </div>
        )}
        <div
          className={
            'mb-md w-full ' + (isViewingOtherAccount ? 'mt-lg' : '')
          }
        >
          <div className="flex max-w-full flex-nowrap items-center justify-between ">
            <div className="flex max-w-full flex-nowrap items-center">
              <div
                className="cursor-pointer"
                onClick={() => router.push(ROUTES.DASHBOARD)}
              >
                <Icon size="xs" />
              </div>
              {!activeEntityName && loadingEntity && !previousData ? (
                <div className="ml-sm">
                  <Skeleton height={20} width={150} />
                </div>
              ) : entities?.length > 1 ? (
                <DropdownMenuBase>
                  <DropdownMenuTrigger>
                    <div
                      className={
                        'ml-sm w-full max-w-[150px] ' +
                        itemClassName(false)
                      }
                    >
                      <p className={'min-w-0 truncate '}>
                        {activeEntityName}
                      </p>
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="z-[600]">
                    <DropdownMenuLabel>
                      Switch Organization
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    {entities.map((entity, index) => {
                      return (
                        <DropdownMenuCheckboxItem
                          key={getListItemKey(index)}
                          checked={
                            activeEntity?.identifier ===
                            entity.identifier
                          }
                          onCheckedChange={() => {
                            setActiveEntity({
                              variables: {
                                input: {
                                  entityIdentifier: entity.identifier
                                }
                              }
                            });
                          }}
                        >
                          <div className="flex flex-nowrap items-center">
                            <p>{entity.name}</p>
                          </div>
                        </DropdownMenuCheckboxItem>
                      );
                    })}
                  </DropdownMenuContent>
                </DropdownMenuBase>
              ) : (
                <div className={'ml-sm w-full max-w-[150px] '}>
                  <p className={'min-w-0 truncate '}>
                    {loadingEntity && previousDataEntity
                      ? previousDataEntity?.name
                      : activeEntityName}
                  </p>
                </div>
              )}
            </div>
            <div>
              <Button
                variant="outline"
                size="icon"
                onClick={() => setSidebarMinimized(true)}
              >
                <X size={15} />
              </Button>
            </div>
          </div>
        </div>
        {loadingEntity ? (
          <div className="ml-sm">
            <Skeleton height={20} width={150} />
          </div>
        ) : (
          <ReportingPeriodSelector
            activeReportingPeriodId={activeReportingPeriodId}
            reportingPeriods={reportingPeriods}
            activeEntityId={activeEntity?.identifier}
            refetchActiveEntity={refetchActiveEntity}
          />
        )}

        {/* Collision Survey Notification */}
        {collisionSurveySubmission &&
        !loadingEntity &&
        (waitForResults ||
          completeYourData ||
          !isCollisionSurveyComplete) ? (
          <CollisionSurveyNotification
            isCollisionSurveyComplete={isCollisionSurveyComplete}
            waitForResults={waitForResults}
            completeYourData={completeYourData}
            surveyInviteIdentifier={collisionSurveyInvite?.identifier}
            purchasesBranch={{
              path: purchasesBranch?.path,
              identifier: purchasesBranch?.identifier
            }}
          />
        ) : null}
        {missingMeasurements?.length > 0 && (
          <div
            className={'mt-sm ' + itemClassName(false)}
            onClick={() => setMissingMeasurementDataDialogOpen(true)}
          >
            <div className="flex w-full items-center justify-between">
              <p className="text-warning">Missing Data</p>
              <AlertCircle className="h-icon w-icon text-warning" />
            </div>
          </div>
        )}
        {/* Notifications */}
        {toReviewMeasurementsTotal ? (
          <div className={' mt-sm w-full ' + itemClassName(false)}>
            <ReviewMeasurementsPopper
              toReviewMeasurements={toReviewMeasurements}
            />
          </div>
        ) : null}
        <div className="mb-sm mt-sm">
          <div
            className={itemClassName(false)}
            onClick={() => {
              intercom.showArticle(
                INTERCOM_ARTICLES['Getting Started']
              );
            }}
          >
            <div className="flex w-full items-center justify-between">
              <p>Learn</p>
              <GraduationCap className="h-icon w-icon" />
            </div>
          </div>
        </div>

        {/* Navigation Items */}
        {navItems.map((item, idx) => {
          return (
            <div
              key={getListItemKey(idx)}
              className={idx !== 0 ? 'mt-sm' : undefined}
              onClick={() => item.onClick && item.onClick()}
            >
              {item.subItems ? (
                <Accordion
                  type="multiple"
                  value={Object.keys(sideNavOpenState).reduce(
                    (acc, key) => {
                      if (sideNavOpenState[key]) {
                        acc.push(key);
                      }
                      return acc;
                    },
                    []
                  )}
                  onValueChange={(value) => {
                    setSideNavOpenState({
                      inventory: value.includes('inventory'),
                      organization: value.includes('organization'),
                      tools: value.includes('tools'),
                      mitigate: value.includes('mitigate'),
                      report: value.includes('report'),
                      settings: value.includes('settings')
                    });
                  }}
                >
                  <AccordionItem value={item.key}>
                    <AccordionTrigger
                      className={
                        'w-full ' + itemClassName(item.isSelected)
                      }
                    >
                      <p>{item.name}</p>
                    </AccordionTrigger>
                    <AccordionContent className="mt-sm p-sm pb-0">
                      {item.subItems.map((subItem, subIdx) => {
                        return (
                          <div
                            key={getListItemKey(subIdx)}
                            className={
                              (subIdx !== 0 ? 'mt-sm ' : '') +
                              itemClassName(subItem.isSelected)
                            }
                            onClick={() => subItem.onClick()}
                          >
                            <div className="flex w-full flex-nowrap items-center justify-between">
                              <p className="text-nowrap">
                                {subItem.name}
                              </p>
                              {subItem.locked && (
                                <Lock className="mr-sm h-icon min-h-icon w-icon min-w-icon" />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              ) : (
                <div
                  className={
                    'flex flex-nowrap items-center justify-between ' +
                    itemClassName(item.isSelected)
                  }
                >
                  <p>{item.name}</p>
                  <div className="flex items-center">
                    {item.chip && (
                      <div className="ml-sm flex h-[25px] items-center rounded-full bg-primary/15 px-md">
                        <p className="body2 text-primary">
                          {item.chip}
                        </p>
                      </div>
                    )}
                    {item.locked && (
                      <Lock className="ml-sm mr-sm h-icon w-icon" />
                    )}
                    {item.external && (
                      <ExternalLink className="scale-75 text-muted" />
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {/* Extra scroll space  */}
        <div className="py-lg" />
      </div>

      <div className="absolute bottom-0 flex h-[60px] w-full bg-background"></div>
      {/* Plan Chip */}
      <div className="absolute bottom-4 left-4 flex items-center justify-center rounded-full">
        <DropdownMenu
          items={[
            {
              name: 'Profile',
              key: 'profile',
              onSelect: () => {
                router.push(ROUTES.SETTINGS.OVERVIEW);
              },
              icon: <UserRound className="h-4 w-4" />
            },
            {
              name: 'Team',
              key: 'team',
              isLocked: !authorizedForMembers,
              onSelect: () => {
                if (authorizedForMembers) {
                  router.push(ROUTES.SETTINGS.TEAM);
                } else {
                  setPaywallDialogOpen(true);
                  setRequiredPlan(membersRequiredPlan);
                  setSubtitleText(membersSubtitleText);
                }
              },
              icon: <Users className="h-4 w-4" />
            },
            {
              name: 'Roles',
              key: 'roles',
              isLocked: !authorizedForGroupsAndPermissions,
              onSelect: () => {
                if (authorizedForGroupsAndPermissions) {
                  router.push(ROUTES.SETTINGS.ROLES);
                } else {
                  setPaywallDialogOpen(true);
                  setRequiredPlan(groupsRequiredPlan);
                  setSubtitleText(groupsSubtitleText);
                }
              },
              icon: <KeyRound className="h-4 w-4" />
            },
            {
              name: 'Billing',
              key: 'billing',
              onSelect: () => {
                router.push(ROUTES.SETTINGS.BILLING);
              },
              icon: <Wallet className="h-4 w-4" />
            },
            {
              name: 'Sign out',
              key: 'signout',
              icon: <LogOut className="h-4 w-4" />,
              onSelect: () => {
                signout();
              }
            }
          ]}
          trigger={
            <Button variant="outline" size="icon" className="bg-card">
              <Settings className="h-4 w-4" />
            </Button>
          }
        />
      </div>
      <div className="absolute bottom-4 right-4">
        {loadingEntity ? (
          <div className="ml-sm">
            <Skeleton
              height={30}
              width={80}
              style={{ borderRadius: 5 }}
            />
          </div>
        ) : (
          activeEntity && (
            <div
              className="p-s ml-sm flex h-[30px] cursor-pointer items-center rounded-full bg-card px-md hover:bg-accent"
              onClick={() => {
                router.push(ROUTES.SETTINGS.BILLING);
              }}
            >
              <p className="body2">
                {SUBSCRIPTION_PLANS[activeSubscriptionTier]?.name}
              </p>
            </div>
          )
        )}
      </div>
      <Dialog
        open={contactSalesDialogOpen}
        onOpenChange={setContactSalesDialogOpen}
      >
        <DialogContent>
          <div className="flex flex-col flex-nowrap items-center">
            <img
              src={`/static/misc/${selectedReport}.png`}
              className="max-w-[150px]"
            />

            <p className="mt-md">
              Contact us to start your next {selectedReport} reporting
              period.
            </p>
          </div>
          <DialogFooter>
            <div className="mt-lg flex items-center">
              <Button onClick={() => setHubspotFormOpen(true)}>
                Request demo
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog
        open={hubspotFormOpen}
        onOpenChange={setHubspotFormOpen}
      >
        <DialogContent>
          <HubspotContactForm
            region="na1"
            portalId="46198686"
            formId="f9f357db-b6c7-4d26-bd47-da4f375683a7"
          />
        </DialogContent>
      </Dialog>
      <MissingMeasurementDataDialog
        open={missingMeasurementDataDialogOpen}
        onOpenChange={setMissingMeasurementDataDialogOpen}
        loading={loadingMissingMeasurementsData}
        missingMeasurements={missingMeasurements}
        refetchMissingMeasurementsData={
          refetchMissingMeasurementsData
        }
      />
    </div>
  );
}
