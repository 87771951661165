import { gql, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { Scope1DataCalculatorsQueryQuery } from 'src/__apolloGenerated__/graphql';
import DataCalculatorManager from 'src/components/carbon/organisms/DataCalculatorManager';
import { ROUTES } from 'src/config';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';

export const SCOPE_1_DATA_CALCULATORS_QUERY_NAME =
  'Scope1DataCalculatorsQuery';

export const dataCalculatorResponseFragment = gql`
  fragment DataCalculatorResponseFragment on DataCalculatorResponseObject {
    calculatorType
    location {
      ...LocationFragment
    }
    vehicle {
      ...VehicleFragment
    }
    equipment {
      ...EquipmentFragment
    }
    measurements {
      createdAt
      identifier
      name
      value
      valueUnit
      startDate
      endDate
    }
    missingMeasurementDateRanges {
      startDate
      endDate
    }
  }
`;
export default function Scope1View() {
  const router = useRouter();
  const scope1DataCalculatorFragment = gql`
    fragment Scope1DataCalculatorsFragment on Scope1DataCalculatorsObject {
      locationNaturalGas {
        ...DataCalculatorResponseFragment
      }
      vehicleFuel {
        ...DataCalculatorResponseFragment
      }
      vehicleDistance {
        ...DataCalculatorResponseFragment
      }
      equipmentFuel {
        ...DataCalculatorResponseFragment
      }
      equipmentRefrigerant {
        ...DataCalculatorResponseFragment
      }
      equipmentIndustrialGas {
        ...DataCalculatorResponseFragment
      }
    }
  `;

  const { activeReportingPeriodId }: SettingsStoreType =
    useSettingsStore();

  const { data: scope1DataCalculatorsData, loading } =
    useQuery<Scope1DataCalculatorsQueryQuery>(
      gql`
        ${dataCalculatorResponseFragment}
        ${scope1DataCalculatorFragment}
        query Scope1DataCalculatorsQuery(
          $reportingPeriodIdentifier: String
        ) {
          dataCalculatorChecklist(
            reportingPeriodIdentifier: $reportingPeriodIdentifier
          ) {
            ok
            errors {
              ...ErrorsFragment
            }
            data {
              scope1DataCalculators {
                ...Scope1DataCalculatorsFragment
              }
            }
          }
        }
      `,
      {
        variables: {
          reportingPeriodIdentifier: activeReportingPeriodId
        }
      }
    );

  const dataCalculators =
    scope1DataCalculatorsData?.dataCalculatorChecklist?.data;

  return (
    <div>
      <DataCalculatorManager
        scope={1}
        dataCalculators={dataCalculators?.scope1DataCalculators}
        bottomNavButtonText="Calculate Scope 2 Emissions"
        bottomNavOnClick={() => {
          router.push(ROUTES.TOOLS.SCOPE2);
        }}
        loading={loading}
      />
    </div>
  );
}
