import dayjs from 'dayjs';
import { useState } from 'react';
import {
  SupportingDocumentParentTypesEnum,
  UploadSupportingDocumentInput,
  UploadSupportingDocumentPayload
} from 'src/__apolloGenerated__/graphql';
import FileUploader from 'src/components/core/molecules/FileUploader';
import {
  Dialog,
  DialogContent
} from 'src/components/shad-base/dialog';
import { useToast } from 'src/hooks/shad-base/useToast';
import useMeasureStore from 'src/hooks/store/useMeasureStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import { UPLOAD_SUPPORTING_DOCUMENT } from 'src/hooks/useBackendMutation/mutations';

export default function UploadSupportingDocumentDialog({
  open,
  onOpenChange,
  parentType,
  parentIdentifier,
  isPublic,
  sortOrder,
  refetchDocuments
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  parentType: SupportingDocumentParentTypesEnum;
  parentIdentifier: string;
  isPublic?: boolean;
  sortOrder?: number;
  refetchDocuments?: () => void;
}) {
  const { toast } = useToast();
  const { mutate: uploadDocument } = useBackendMutation<
    {
      input: UploadSupportingDocumentInput;
    },
    UploadSupportingDocumentPayload
  >({
    mutation: UPLOAD_SUPPORTING_DOCUMENT,

    callbacks: {
      onSuccess: () => {
        toast({
          title: 'Success.',
          description: 'Document uploaded.',
          variant: 'success'
        });
        onOpenChange(false);
        setIsUploading(false);
        setFile(null);
        refetchDocuments?.();
      },
      onError: () => {
        setError(true);
        setIsUploading(false);
        setFile(null);
        toast({
          title: 'Error.',
          description: 'Could not upload document.',
          variant: 'destructive'
        });
      }
    }
  });

  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState(false);

  const { selectedStartDate, selectedEndDate, selectedFilters } =
    useMeasureStore((state) => ({
      selectedStartDate: state.selectedStartDate,
      selectedEndDate: state.selectedEndDate,
      selectedFilters: state.selectedFilters
    }));

  function handleSubmit() {
    uploadDocument({
      variables: {
        input: {
          parentType,
          parentIdentifier,
          file: file,
          isPublic: isPublic,
          sortOrder: sortOrder
        },
        startDate: selectedStartDate
          ? dayjs(selectedStartDate).toDate()
          : null,
        endDate: selectedEndDate
          ? dayjs(selectedEndDate).toDate()
          : null,
        states: selectedFilters
      }
    });
    setIsUploading(true);
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-screen-md">
        <div className="w-full min-w-full max-w-full">
          <div className="flex flex-col flex-nowrap">
            {/* Title */}
            <div className="mb-md w-full">
              <div className="flex items-center justify-between">
                <p className="subtitle1">Upload Document</p>
              </div>
            </div>
            <div className="mb-md">
              <p>Upload a supporting document.</p>
            </div>
            <div className="w-full">
              <FileUploader
                handleSubmit={handleSubmit}
                isUploading={isUploading}
                filesState={[
                  file ? [file] : [],
                  (files: File[]) => setFile(files[0])
                ]}
                errorState={[error, setError]}
                supportedFileTypes={['image', 'excel', 'doc']}
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
