import { GhgProtocolsEnum } from 'src/__apolloGenerated__/graphql';
import { INTEGER_SCOPE_TO_GHG_PROTOCOLS_MAP } from 'src/backend/@types';
import SingleSelect from 'src/components/core/molecules/SingleSelect';
import { fEnum } from 'src/utils/format';

export default function GhgCategorySingleSelect({
  value,
  setValue,
  filterByScopes,
  disabled = false,
  disableClear,
  prefixText = 'GHG Category: ',
  required = false,
  type = 'element'
}: {
  value: GhgProtocolsEnum;
  setValue: (value: GhgProtocolsEnum) => void;
  filterByScopes?: string[];
  disabled?: boolean;
  disableClear?: boolean;
  prefixText?: string;
  required?: boolean;
  type?: 'element' | 'form';
}) {
  // Filter ghg protocols by emission factor scope
  const ghgCategoryOptions = Object.keys(GhgProtocolsEnum).map(
    (key) => {
      return {
        key: GhgProtocolsEnum[key],
        label: fEnum(GhgProtocolsEnum[key])
      };
    }
  );

  const filteredGroups =
    filterByScopes?.length > 0
      ? filterByScopes.map((scope) => {
          const integerScope = scope?.split('.')?.[0];
          const ghgProtocolsByScope =
            INTEGER_SCOPE_TO_GHG_PROTOCOLS_MAP[integerScope];
          return {
            key: scope,
            label: `Scope ${integerScope}`,
            childrenKeys: ghgProtocolsByScope
              ? ghgProtocolsByScope.map(
                  (key) => GhgProtocolsEnum[key]
                )
              : []
          };
        })
      : ['1', '2', '3'].map((scope) => {
          const ghgProtocolsByScope =
            INTEGER_SCOPE_TO_GHG_PROTOCOLS_MAP[scope];
          return {
            key: scope,
            label: `Scope ${scope}`,
            childrenKeys: ghgProtocolsByScope
              ? ghgProtocolsByScope.map(
                  (key) => GhgProtocolsEnum[key]
                )
              : []
          };
        });

  return (
      <SingleSelect
        value={value}
        disabled={disabled}
        disableClear={disableClear}
        setValue={setValue}
        renderOption={(option) => {
          return <p className="body2">{option.label}</p>;
        }}
        size={type === 'form' ? 'default' : 'sm'}
        required={type === 'form' ? false : required}
        groups={filteredGroups}
        options={ghgCategoryOptions}
        prefixText={type === 'form' ? undefined : prefixText}
      />
  );
}
