import { gql, useQuery } from '@apollo/client';
import {
  CreateEquipmentInput,
  CreateEquipmentPayload,
  EditEquipmentInput,
  EditEquipmentPayload,
  EquipmentDialogLocationsQueryQuery,
  EquipmentFragmentFragment,
  EquipmentSubtypesEnum,
  EquipmentTypesEnum,
  FuelTypesEnum
} from 'src/__apolloGenerated__/graphql';
import CreateAssetDialog from 'src/components/carbon/organisms/AssetManager/CreateAssetDialog';
import Form from 'src/components/core/organisms/Form';
import { useToast } from 'src/hooks/shad-base/useToast';
import useAssetStore, {
  AssetStoreType
} from 'src/hooks/store/useAssetStore';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import { fEnum } from 'src/utils/format';

export default function CreateEquipmentDialog({
  open,
  onOpenChange,
  entityIdentifier,
  initialEquipment
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  entityIdentifier: string;
  initialEquipment?: EquipmentFragmentFragment;
}) {
  const { activeReportingPeriodId }: SettingsStoreType = useSettingsStore();

  const { setActiveAssetIdentifier } = useAssetStore(
    (store: AssetStoreType) => ({
      setActiveAssetIdentifier: store.setActiveAssetIdentifier
    })
  );
  const { toast } = useToast();
  const { mutate: createEquipment, loading: creatingEquipment } =
    useBackendMutation<
      { input: CreateEquipmentInput },
      CreateEquipmentPayload
    >({
      mutation: gql`
        mutation CreateEquipmentMutation(
          $input: CreateEquipmentInput!
          $reportingPeriodIdentifier: String
        ) {
          createEquipment(input: $input) {
            ok
            errors {
              ...ErrorsFragment
            }
            equipment {
              ...EquipmentFragment
            }
          }
        }
      `,
      callbacks: {
        onSuccess: () => {
          onOpenChange(false);
          setActiveAssetIdentifier(null);
          toast({
            title: 'Success.',
            description: 'Equipment created.',
            variant: 'success'
          });
        }
      }
    });

  const { mutate: editEquipment, loading: editingEquipment } =
    useBackendMutation<
      { input: EditEquipmentInput },
      EditEquipmentPayload
    >({
      mutation: gql`
        mutation EditEquipmentMutation(
          $input: EditEquipmentInput!
          $reportingPeriodIdentifier: String
        ) {
          editEquipment(input: $input) {
            ok
            errors {
              ...ErrorsFragment
            }
            equipment {
              ...EquipmentFragment
            }
          }
        }
      `,
      callbacks: {
        onSuccess: () => {
          onOpenChange(false);
          toast({
            title: 'Success.',
            description: 'Equipment created.',
            variant: 'success'
          });
        }
      }
    });

  const { data: locationsData } =
    useQuery<EquipmentDialogLocationsQueryQuery>(gql`
      query EquipmentDialogLocationsQuery {
        locations {
          data {
            identifier
            name
          }
        }
      }
    `);
  const locations = locationsData?.locations?.data || [];
  const iconOptions = [
    {
      key: '/static/icons/assets/equipment/diesel-generator.png',
      label: 'Diesel Generator'
    },
    {
      key: '/static/icons/assets/equipment/radiator.png',
      label: 'Radiator'
    },
    {
      key: '/static/icons/assets/equipment/hydro-turbine.png',
      label: 'Hydro Turbine'
    },
    {
      key: '/static/icons/assets/equipment/fuel-cell.png',
      label: 'Fuel Cell'
    },
    {
      key: '/static/icons/assets/equipment/fish-finder.png',
      label: 'Fish Finder'
    },
    {
      key: '/static/icons/assets/equipment/gas-heater.png',
      label: 'Gas Heater'
    },
    {
      key: '/static/icons/assets/equipment/wood-stove.png',
      label: 'Wood Stove'
    },
    {
      key: '/static/icons/assets/equipment/water-heater.png',
      label: 'Water Heater'
    },
    {
      key: '/static/icons/assets/equipment/display-fridge.png',
      label: 'Display Fridge'
    },
    {
      key: '/static/icons/assets/equipment/fridge.png',
      label: 'Fridge'
    },
    {
      key: '/static/icons/assets/equipment/solar-panel.png',
      label: 'Solar'
    },
    {
      key: '/static/icons/assets/equipment/wind-turbine.png',
      label: 'Wind'
    },
    {
      key: '/static/icons/assets/equipment/tractor.png',
      label: 'Tractor'
    },
    {
      key: '/static/icons/assets/equipment/fishing-boat.png',
      label: 'Fishing Boat'
    }
  ];

  const getDefaultEnergySource = (
    initialEquipment: EquipmentFragmentFragment
  ) => {
    if (!initialEquipment) {
      return [];
    }
    const energySources = [];
    if (initialEquipment.hasFuel) {
      energySources.push('fuel');
    }
    if (initialEquipment.hasRefrigerant) {
      energySources.push('refrigerant');
    }
    if (initialEquipment.hasIndustrialGas) {
      energySources.push('industrial-gas');
    }
    return energySources;
  };

  const defaultValues = {
    name: initialEquipment?.name || '',
    equipmentType: initialEquipment?.equipmentType || '',
    subType: initialEquipment?.equipmentSubtype || '',
    fuelType: initialEquipment?.fuelType || '',
    numUnits: initialEquipment?.numberOfUnits || '',
    icon: initialEquipment?.icon || iconOptions[0].key,
    location: initialEquipment?.location?.identifier || '',
    energySources: getDefaultEnergySource(initialEquipment)
  };

  return (
    <CreateAssetDialog
      open={open}
      onOpenChange={onOpenChange}
      type="equipment"
    >
      <div className="px-md">
        <Form
          isSubmitting={creatingEquipment || editingEquipment}
          rows={[
            {
              questions: [
                {
                  key: 'name',
                  text: 'What is your equipment name?',
                  description:
                    'Give this equipment a name to make it easier to identify later.',
                  type: 'text',
                  defaultValue: defaultValues.name,
                  required: true,
                  errorMessage: 'Please enter a name',
                  placeholder: 'E.g. Gas Powered Generator'
                }
              ]
            },
            {
              questions: [
                {
                  key: 'location',
                  text: 'What location does this equipment belong to?',
                  type: 'single-select',
                  defaultValue: defaultValues.location,
                  required: true,
                  options:
                    locations?.map((location) => ({
                      key: location.identifier,
                      label: location.name
                    })) || []
                }
              ]
            },
            {
              questions: [
                {
                  key: 'equipment-type',
                  text: 'What type of equipment is this?',
                  type: 'single-select',
                  errorMessage: 'Please select an option',
                  defaultValue: defaultValues.equipmentType,
                  required: true,
                  options: Object.values(EquipmentTypesEnum).map(
                    (value) => ({
                      key: value,
                      label: fEnum(value)
                    })
                  )
                }
              ]
            },
            {
              questions: [
                {
                  key: 'sub-type',
                  dependsOnQuestionKey: 'equipment-type',
                  required: true,
                  defaultValue: defaultValues.subType,
                  type: 'single-select',
                  text: '',
                  errorMessage: 'Please select an option',
                  getQuestion: () => {
                    return {
                      text: `Please select a subtype for this equipment`,
                      options: Object.values(
                        EquipmentSubtypesEnum
                      ).map((value) => ({
                        key: value,
                        label: fEnum(value)
                      }))
                    };
                  }
                }
              ]
            },
            {
              questions: [
                {
                  key: 'fuel-type',
                  text: 'Energy Source',
                  type: 'single-select',
                  defaultValue: defaultValues.fuelType,
                  required: true,
                  options: Object.values(FuelTypesEnum).map(
                    (value) => ({
                      key: value,
                      label: fEnum(value)
                    })
                  )
                },
                {
                  key: 'num-units',
                  text: 'Number of units',
                  placeholder: 'E.g. 5',
                  type: 'number',
                  defaultValue: defaultValues.numUnits,
                  helperMessage:
                    'If you have more than one unit of this equipment, enter the number of units. Otherwise, enter 1.',
                  required: true
                }
              ]
            },
            {
              questions: [
                {
                  key: 'energy-source',
                  text: 'Please select all that apply',
                  type: 'multi-select',
                  defaultValue: defaultValues.energySources,
                  required: true,
                  options: [
                    {
                      key: 'fuel',
                      label: 'Uses Fuel'
                    },
                    {
                      key: 'refrigerant',
                      label: 'Uses Refrigerant'
                    },
                    {
                      key: 'industrial-gas',
                      label: 'Uses Industrial Gas'
                    }
                  ]
                }
              ]
            },
            {
              questions: [
                {
                  key: 'icon',
                  text: 'Select an icon to represent this equipment',
                  type: 'icon',
                  defaultValue: defaultValues.icon,
                  required: false,
                  options: iconOptions
                }
              ]
            }
          ]}
          onSubmit={(values) => {
            const input = {
              locationIdentifier: values['location'],
              name: values['name'],
              numberOfUnits: values['num-units'],
              equipmentType: values['equipment-type'],
              equipmentSubtype: values['sub-type'],
              fuelType: values['fuel-type'],
              icon: values['icon'],
              hasFuel: values['energy-source'].includes('fuel'),
              hasRefrigerant:
                values['energy-source'].includes('refrigerant'),
              hasIndustrialGas:
                values['energy-source'].includes('industrial-gas')
            };
            if (initialEquipment) {
              editEquipment({
                variables: {
                  input: {
                    equipmentIdentifier: initialEquipment.identifier,
                    ...input
                  },
                  reportingPeriodIdentifier: activeReportingPeriodId
                }
              });
            } else {
              createEquipment({
                variables: {
                  input: {
                    entityIdentifier,
                    ...input
                  },
                  reportingPeriodIdentifier: activeReportingPeriodId
                },
                refetchQueries: ['EquipmentViewQuery']
              });
            }
          }}
        />
      </div>
    </CreateAssetDialog>
  );
}
