import { MoreHorizontal } from 'lucide-react';
import { useEffect, useState } from 'react';
import CarbonUnit from 'src/components/carbon/atoms/CarbonUnit';
import Input from 'src/components/core/atoms/Input';
import Skeleton from 'src/components/core/atoms/Skeleton';
import { Button } from 'src/components/shad-base/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from 'src/components/shad-base/dropdown-menu';

export default function TitleSection({
  loading,
  canEdit,
  initialName,
  initialDescription,
  onTitleBlur,
  onDescriptionBlur,
  actionItems,
  kgCo2e
}: {
  loading: boolean;
  canEdit: boolean;
  initialName: string;
  initialDescription: string;
  onTitleBlur?: (field: string, value: string) => void;
  onDescriptionBlur?: (field: string, value: string) => void;
  actionItems?: { key: string; label: string; onClick: () => void }[];
  kgCo2e: number;
}) {
  const [name, setName] = useState(initialName || '');
  const [description, setDescription] = useState(
    initialDescription || ''
  );

  useEffect(() => {
    setName(initialName || '');
    setDescription(initialDescription || '');
  }, [initialName, initialDescription]);
  return (
    <>
      {/* Title */}
      {loading ? (
        <Skeleton height={150} />
      ) : (
        <>
          <div className="flex w-full items-center justify-between">
            <div className="flex w-full flex-nowrap items-center">
              {canEdit && onTitleBlur ? (
                <Input
                  noBorder
                  value={name}
                  className="subtitle1 border-0 p-0 py-0 shadow-none focus:border-0 "
                  onChange={(e) => setName(e.target.value)}
                  onBlur={() => {
                    onTitleBlur('name', name);
                  }}
                />
              ) : (
                <p className="subtitle1">{name}</p>
              )}
              {actionItems && (
                <div>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <div>
                        <Button
                          variant="outline"
                          size="icon"
                          className="px-2"
                        >
                          <MoreHorizontal className="scale-90" />
                        </Button>
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="z-[600]">
                      <DropdownMenuLabel>Create</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      {actionItems.map((item) => {
                        return (
                          <DropdownMenuItem
                            key={item.key}
                            onSelect={item.onClick}
                          >
                            <div className="flex flex-nowrap items-center">
                              <p>{item.label}</p>
                            </div>
                          </DropdownMenuItem>
                        );
                      })}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              )}
            </div>
            <div className="ml-sm">
              <CarbonUnit
                emissionValueInKg={kgCo2e}
                variant="subtitle1"
                className="font-bold"
              />
            </div>
          </div>
          {/* Description */}
          <div>
            {canEdit && onDescriptionBlur ? (
              <Input
                noBorder
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onBlur={() => {
                  onDescriptionBlur('description', description);
                }}
                multiline
                className="mt-md min-h-[80px] w-full border-0 p-0 shadow-none focus:border-0 "
                placeholder="Enter a description..."
              />
            ) : (
              <p
                className={
                  'mt-md max-w-paragraph ' +
                  (!description && 'text-muted')
                }
              >
                {description || 'No description provided.'}
              </p>
            )}
          </div>
        </>
      )}
    </>
  );
}
