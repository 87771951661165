import dayjs from 'dayjs';
import { ArrowLeft } from 'lucide-react';
import { ScopeType } from 'src/@types/emissions';
import {
  GhgProtocolsEnum,
  MeasurementFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import MeasurementSearcher from 'src/components/carbon/organisms/MeasurementSearcher';
import DataCalculatorIcon from 'src/components/core/atoms/IconCircle';
import { Button } from 'src/components/shad-base/button';
import {
  Dialog,
  DialogContent
} from 'src/components/shad-base/dialog';

export default function UseExistingMeasurementDialog({
  open,
  onOpenChange,
  selectedMeasurement,
  onSelectMeasurements,
  buttonIsLoading,
  onBackArrowClick
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onBackArrowClick?: () => void;
  selectedMeasurement: MeasurementFragmentFragment;
  onSelectMeasurements?: (measurementIdentifiers: string[]) => void;
  buttonIsLoading?: boolean;
}) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-screen-lg">
        <div className="relative flex flex-col flex-nowrap items-center">
          {onBackArrowClick && (
            <div className="absolute left-0 top-0">
              <Button
                onClick={onBackArrowClick}
                variant="outline"
                size="icon"
                className="mr-md"
              >
                <ArrowLeft />
              </Button>
            </div>
          )}
          <p className="subtitle1">Use Existing Measurement(s)</p>
          <p className="text-muted">
            Use existing measurements to update this recurring
            measurement.
          </p>
          <div className="mt-lg w-full">
            <div className="flex flex-nowrap items-center">
              <div className="mr-sm">
                <DataCalculatorIcon
                  calculatorType={selectedMeasurement?.calculatorType}
                  scope={
                    Number(selectedMeasurement?.scope) as ScopeType
                  }
                  scope3Category={
                    selectedMeasurement?.ghgCategory as GhgProtocolsEnum
                  }
                />
              </div>
              <div className="flex flex-col flex-nowrap">
                <p className="mt-sm font-bold">
                  {selectedMeasurement?.name}
                </p>
                <p className="text-muted ">
                  Last updated:{' '}
                  {dayjs(
                    selectedMeasurement?.dateOfMeasurement
                  ).format('MMMM DD, YYYY')}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-md w-full">
            <MeasurementSearcher
              initialFilters={{
                minDateOfMeasurement: dayjs(
                  selectedMeasurement?.dateOfMeasurement
                ).toDate()
              }}
              onSelectMeasurements={onSelectMeasurements}
              buttonIsLoading={buttonIsLoading}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
