import { SSelect } from 'src/components/core/atoms/Select';
import {
  ExpectedHeaderType,
  HeaderIndicesType
} from 'src/components/core/organisms/CSVFileUploader';
import { getListItemKey } from 'src/utils/format';

export default function CSVColumnMatcher({
  expectedHeaders,
  fileHeaders,
  headerIndicesState
}: {
  expectedHeaders: ExpectedHeaderType[];
  fileHeaders: string[];
  headerIndicesState: [
    HeaderIndicesType,
    (headerIndices: HeaderIndicesType) => void
  ];
}) {
  const [headerIndices, setHeaderIndices] = headerIndicesState;

  const maxItems = Math.max(
    expectedHeaders.length,
    fileHeaders.length
  );
  const initialItems = [];
  for (let i = 0; i < maxItems; i++) {
    if (i < fileHeaders.length) {
      initialItems.push(fileHeaders[i]);
    } else {
      initialItems.push(`No match-${i}`);
    }
  }

  const getSelectValue = (expectedHeaderIdx: number) => {
    const expectedHeader = expectedHeaders[expectedHeaderIdx];
    const fileHeaderIndex = headerIndices[expectedHeader.key];
    return fileHeaders[fileHeaderIndex] || null;
  };

  return (
    <div className="flex flex-col flex-nowrap">
      {expectedHeaders.map((expectedHeader, index) => {
        return (
          <div className="py-sm" key={getListItemKey(index)}>
            <div className="flex flex-nowrap items-center justify-between">
              <p>
                {expectedHeader.label}
                {!expectedHeader.optional && (
                  <span className="ml-1 text-destructive">*</span>
                )}
              </p>
              <div className="ml-sm w-1/2">
                <SSelect
                  options={fileHeaders
                    .filter(
                      (header) =>
                        header !== null &&
                        header !== undefined &&
                        header !== ''
                    )
                    .map((header) => ({
                      key: header,
                      label: header
                    }))}
                  value={getSelectValue(index)}
                  onValueChange={(value) => {
                    const newHeaderIndices = { ...headerIndices };
                    const newHeaderIndex = fileHeaders.indexOf(value);
                    if (newHeaderIndex === -1) {
                      newHeaderIndices[expectedHeader.key] = null;
                    } else {
                      newHeaderIndices[expectedHeader.key] =
                        newHeaderIndex;
                    }
                    setHeaderIndices(newHeaderIndices);
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
