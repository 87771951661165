import { AccordionTrigger } from '@radix-ui/react-accordion';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useState } from 'react';
import EllipsisDropdownMenu from 'src/components/core/molecules/EllipsisDropdownMenu';
import TableRowGroupRow from 'src/components/core/molecules/TableRowGroupRow';
import { ColumnType } from 'src/components/core/organisms/PaginatedTable';
import {
  Accordion,
  AccordionContent,
  AccordionItem
} from 'src/components/shad-base/accordion';
import { Button } from 'src/components/shad-base/button';
import { getListItemKey } from 'src/utils/format';

export default function TableRow<RowType, RowGroupType>({
  rowData,
  isDisabled,
  columns,
  rowMenuItems,
  onRowClick,
  activeRowId,
  isSelected,
  isHoveringState,
  expandableRows,
  getRowGroupData,
  disableHoverEffects = false
}: {
  rowData: RowType;
  isDisabled: boolean;
  columns: ColumnType<RowType, RowGroupType>;
  rowMenuItems: {
    name: string;
    onSelect: () => void;
  }[];
  onRowClick: (rowIdentifier: string) => void;
  activeRowId: string;
  isSelected?: boolean;
  isHoveringState?: [
    string | false,
    (hovering: string | false) => void
  ];
  expandableRows?: boolean;
  getRowGroupData?: (parentRowIdentifier: string) => RowGroupType[];
  disableHoverEffects?: boolean;
}) {
  let hoveringRowId = null;
  let setHoveringRowId = null;
  if (isHoveringState) {
    [hoveringRowId, setHoveringRowId] = isHoveringState;
  }
  const isHovering = hoveringRowId === rowData['identifier'];
  const isActiveRow = activeRowId === rowData['identifier'];
  const [expanded, setExpanded] = useState(null);

  const rowGroupData = getRowGroupData
    ? getRowGroupData(rowData['identifier'])
    : null;

  return (
    <Accordion
      value={expanded}
      onValueChange={(value) => setExpanded(value)}
      collapsible
      type="single"
    >
      <AccordionItem value={'row'}>
        <AccordionTrigger
          className={
            'mb-[-1px] h-row min-w-full border border-l-border transition-all ' +
            (rowGroupData ? 'pr-sm ' : '') +
            (onRowClick
              ? ' cursor-pointer hover:bg-primary/10'
              : ' cursor-default') +
            (isSelected ? ' bg-primary/10' : '')
          }
          onClick={(e) => {
            e.stopPropagation();
            onRowClick ? onRowClick(rowData['identifier']) : null;
          }}
          onMouseEnter={() => {
            if (!disableHoverEffects)
              setHoveringRowId(rowData['identifier']);
          }}
          onMouseLeave={() => {
            if (!disableHoverEffects) setHoveringRowId(false);
          }}
        >
          {/* Row title */}
          <div
            className={
              'w-full ' +
              (disableHoverEffects
                ? 'cursor-default'
                : 'cursor-pointer')
            }
          >
            <div
              className="grid h-full max-h-full w-full flex-nowrap items-center "
              style={{
                gridTemplateColumns: `${Object.keys(columns)
                  .map((key) => columns[key]?.gridTemplateFr)
                  .join(' ')}`
              }}
            >
              {Object.keys(columns).map((key, idx) => {
                return (
                  <div
                    className="relative h-full max-h-full w-full overflow-hidden text-ellipsis px-md "
                    key={getListItemKey(idx)}
                    style={{
                      gridTemplateColumns:
                        columns[key]?.gridTemplateFr
                    }}
                  >
                    <div className="flex h-full w-full flex-nowrap items-center">
                      {idx === 0 && expandableRows && rowGroupData ? (
                        <Button
                          className="mr-sm"
                          variant="outline"
                          size="icon"
                          disabled={rowGroupData?.length == 0}
                          onClick={(e) => {
                            e.stopPropagation();
                            setExpanded(expanded ? null : 'row');
                          }}
                        >
                          {expanded ? (
                            <ChevronUp className="h-icon w-icon" />
                          ) : (
                            <ChevronDown className="h-icon w-icon" />
                          )}
                        </Button>
                      ) : null}
                      <div className="w-full">
                        <div
                          className={
                            'flex items-center ' +
                            (columns[key]?.type == 'number'
                              ? 'justify-end'
                              : 'justify-start')
                          }
                        >
                          {columns[key]?.renderRowCell(
                            rowData,
                            isDisabled
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {rowMenuItems?.length > 0 &&
              isHovering &&
              !disableHoverEffects &&
              !isDisabled ? (
                <div className="absolute right-0 flex h-full max-h-full flex-nowrap items-center pr-sm">
                  <EllipsisDropdownMenu items={rowMenuItems} />
                </div>
              ) : null}
            </div>
          </div>
        </AccordionTrigger>
        {rowGroupData ? (
          <AccordionContent className="mb-0 pb-px">
            {rowGroupData.map((childRow, idx) => {
              return (
                <TableRowGroupRow<RowType, RowGroupType>
                  idx={idx}
                  key={getListItemKey(idx)}
                  rowData={childRow}
                  columns={columns}
                  parentRowHighlighted={isActiveRow}
                  parentRowIdentifier={rowData['identifier']}
                />
              );
            })}
          </AccordionContent>
        ) : null}
      </AccordionItem>
    </Accordion>
  );
}
