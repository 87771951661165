import { Control, Controller } from 'react-hook-form';

import { ChevronDown, X } from 'lucide-react';
import { MultiSelect } from 'src/components/core/molecules/MultiSelect';
import SingleSelect, {
  OptionType
} from 'src/components/core/molecules/SingleSelect';
import { FormQuestionType } from 'src/components/core/organisms/Form';
import { Button } from 'src/components/shad-base/button';
import { Input } from 'src/components/shad-base/input';
import { DatePicker } from '../../atoms/DatePicker';
import { fLocalDate, fPrecision } from 'src/utils/format';
import {
  RadioGroup,
  RadioGroupItem
} from 'src/components/shad-base/radio-group';
import {
  DECIMAL_SCOPE_TO_GHG_PROTOCOL_MAP,
  getUnitTypeFromUnit
} from 'src/backend/@types';
import UnitSingleSelect from 'src/components/carbon/atoms/UnitSingleSelect';
import {
  GhgProtocolsEnum,
  UnitTypesEnum
} from 'src/__apolloGenerated__/graphql';
import EmissionFactorSelector from 'src/components/carbon/organisms/EmissionFactorSelector';
import GhgCategorySingleSelect from 'src/components/carbon/atoms/GhgCategorySingleSelect';
import BranchSelector from 'src/components/carbon/molecules/BranchSelector';

type FormQuestionProps = {
  question: FormQuestionType;
  watch?: any;
  control: Control<any, any>;
  formValues?: any;
  options?: OptionType[];
  setFormValue?: (key: string, value: string | string[]) => void;
};

export function MultiSelectQuestion({
  question,
  control,
  options,
  setFormValue
}: FormQuestionProps) {
  return (
    <Controller
      control={control}
      name={question.key}
      render={({ field: { onChange, name, onBlur, value, ref } }) => {
        return (
          <MultiSelect
            options={options}
            checked={value}
            setChecked={(checked) => {
              onChange(checked);

              // TODO: Delete this hard-coded section and replace with more generic logic
              // (reset all follow-up questions if parent question changes)
              if (question.key === 'applicableAssetTypes') {
                setFormValue('applicableAssetSubtypes', []);
              }
            }}
            name={name}
            element="input"
            onBlur={onBlur}
            formRef={ref}
            placeholder={question.placeholder}
          />
        );
      }}
    />
  );
}

export function SingleSelectQuestion({
  question,
  control,
  watch,
  options,
  setFormValue
}: FormQuestionProps) {
  return (
    <Controller
      control={control}
      name={question.key}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <SingleSelect
            options={options}
            value={watch(value)}
            setValue={(value) => {
              // TODO: Delete this hard-coded section and replace with more generic logic
              // (reset all follow-up questions if parent question changes)
              if (question.key === 'applicableDataCalculatorType') {
                setFormValue('applicableAssetTypes', []);
                setFormValue('applicableAssetSubtypes', []);
              }

              onChange(value);
            }}
            renderCustomTrigger={() => {
              const selected = options.find(
                (option) => option.key === value
              );
              return (
                <div
                  ref={ref}
                  onBlur={onBlur}
                  className="flex h-10 cursor-pointer flex-nowrap items-center rounded-md border hover:bg-accent"
                >
                  <p
                    className={
                      'w-full truncate text-nowrap px-3 ' +
                      (selected ? '' : 'text-muted')
                    }
                  >
                    {selected
                      ? selected.label
                      : 'Select an option...'}
                  </p>
                  {selected && (
                    <div className="flex items-center px-sm">
                      <Button
                        variant="outline"
                        size="icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          onChange('');

                          // TODO: Delete this hard-coded section and replace with more generic logic
                          // (reset all follow-up questions if parent question changes)
                          if (
                            question.key ===
                            'applicableDataCalculatorType'
                          ) {
                            setFormValue('applicableAssetTypes', []);
                            setFormValue(
                              'applicableAssetSubtypes',
                              []
                            );
                          }
                        }}
                      >
                        <X className="h-icon w-icon" />
                      </Button>
                    </div>
                  )}
                  <ChevronDown className="mx-sm ml-sm h-5 w-5" />
                </div>
              );
            }}
          />
        );
      }}
    />
  );
}

export function EmbeddedSingleSelect({
  question,
  control
}: FormQuestionProps) {
  const embeddedSingleSelect = question?.embeddedSingleSelect;
  return (
    <div className="flex rounded-md border">
      {/* Base Input */}
      <Controller
        control={control}
        name={question.key}
        render={({
          field: { onChange, name, onBlur, value, ref }
        }) => {
          return (
            <Input
              value={value}
              type={question.type}
              onChange={onChange}
              placeholder={question.placeholder}
              // Disable number change on scroll for number inputs
              {...(question.type === 'number'
                ? {
                    onWheel: (
                      e: React.WheelEvent<HTMLInputElement>
                    ) => {
                      const target = e.target as HTMLInputElement;
                      target.blur();
                    }
                  }
                : undefined)}
              className="border-0 bg-transparent"
              ref={ref}
              name={name}
              onBlur={onBlur}
            />
          );
        }}
      />
      {/* Embedded Single Select */}
      <Controller
        control={control}
        name={embeddedSingleSelect.key}
        render={({ field: { onChange, onBlur, value, ref } }) => {
          const selectedOption = embeddedSingleSelect.options.find(
            (option) => option.key === value
          );
          return (
            <SingleSelect
              disableClear
              renderCustomTrigger={() => {
                return (
                  <div
                    ref={ref}
                    onBlur={onBlur}
                    className="flex cursor-pointer flex-nowrap items-center rounded-r-md border-l px-md hover:bg-accent"
                  >
                    <p
                      className={
                        'truncate text-nowrap ' +
                        (selectedOption ? '' : 'text-muted')
                      }
                    >
                      {selectedOption?.label || 'Select an option...'}
                    </p>
                    <ChevronDown className="ml-sm h-5 w-5" />
                  </div>
                );
              }}
              options={embeddedSingleSelect.options}
              value={value}
              setValue={onChange}
            />
          );
        }}
      />
    </div>
  );
}

interface DatePickerQuestionProps {
  question: FormQuestionType;
  control: Control<any>;
}

export function DatePickerQuestion({
  question,
  control
}: DatePickerQuestionProps) {
  return (
    <Controller
      control={control}
      name={question.key}
      render={({ field }) => (
        <DatePicker
          date={field.value}
          setDate={(date: Date) => field.onChange(date)}
          placeholder={question.placeholder}
          required={question.required}
          renderCustomAnchorEl={() => {
            return (
              <div className="flex flex-nowrap items-center rounded-lg border p-sm px-md hover:bg-accent">
                <p className="body2">
                  {fLocalDate(field.value) || 'Select a date...'}
                </p>
              </div>
            );
          }}
        />
      )}
    />
  );
}

export function RadioGroupQuestion({
  question,
  control,
  options
}: FormQuestionProps) {
  return (
    <Controller
      control={control}
      name={question.key}
      render={({ field: { value, onChange } }) => (
        <RadioGroup value={value} onValueChange={onChange}>
          <div className="flex flex-wrap items-center">
            {options.map((option, idx) => {
              return (
                <div
                  className={
                    'flex items-center ' + (idx !== 0 && 'ml-lg')
                  }
                >
                  <RadioGroupItem key={idx} value={option.key} />
                  <p className="ml-md">{option.label}</p>
                </div>
              );
            })}
          </div>
        </RadioGroup>
      )}
    />
  );
}

export function EmissionFactorQuestion({
  question,
  control,
  formValues,
  setFormValue
}: FormQuestionProps) {
  return (
    <Controller
      control={control}
      name={question.key}
      render={({ field: { value, onChange } }) => {
        return (
          <EmissionFactorSelector
            required
            selectedFactor={value}
            setSelectedFactor={(factor) => {
              onChange(factor);
              setFormValue(
                'valueUnit',
                factor?.unitInfo?.unit || null
              );
              const scope = Number(fPrecision(factor?.scope, 1) || 0);
              const ghgCategoryKey = factor
                ? DECIMAL_SCOPE_TO_GHG_PROTOCOL_MAP[scope]
                : null;
              const ghgCategory = ghgCategoryKey
                ? GhgProtocolsEnum[ghgCategoryKey]
                : null;
              setFormValue('ghgCategory', ghgCategory);
            }}
            size="default"
            type="form"
            prefixText="Emission Factor"
            initialUnitTypeFilters={
              getUnitTypeFromUnit(formValues?.valueUnit)
                ? ([
                    getUnitTypeFromUnit(formValues?.valueUnit)
                  ] as UnitTypesEnum[])
                : null
            }
          />
        );
      }}
    />
  );
}

export function ValueUnitQuestion({
  question,
  control,
  formValues,
  setFormValue
}: FormQuestionProps) {
  return (
    <Controller
      control={control}
      name={question.key}
      render={({ field: { value, onChange } }) => {
        const emissionFactorUnitType =
          formValues?.emissionFactor?.unitInfo?.type;
        return (
          <UnitSingleSelect
            required
            value={value}
            type="form"
            prefixText="Unit"
            setValue={(value) => {
              onChange(value);

              // If selected unit does not fall under emission factor unit type, reset emission factor
              const selectedUnitType = getUnitTypeFromUnit(value);
              if (
                selectedUnitType !== null &&
                selectedUnitType !== emissionFactorUnitType
              ) {
                setFormValue('emissionFactor', null);
              }
            }}
            filterByUnitType={
              formValues?.emissionFactor
                ? emissionFactorUnitType
                : null
            }
          />
        );
      }}
    />
  );
}
export function GhgCategoryQuestion({
  question,
  control,
  formValues
}: FormQuestionProps) {
  return (
    <Controller
      control={control}
      name={question.key}
      render={({ field: { value, onChange } }) => {
        return (
          <GhgCategorySingleSelect
            prefixText="GHG Category"
            required
            value={value}
            setValue={onChange}
            filterByScopes={
              formValues?.emissionFactor?.scope
                ? [formValues?.emissionFactor?.scope]
                : null
            }
            type="form"
          />
        );
      }}
    />
  );
}

export function BranchIdentifierQuestion({
  question,
  control
}: FormQuestionProps) {
  return (
    <Controller
      control={control}
      name={question.key}
      render={({ field: { value, onChange } }) => {
        return (
          <BranchSelector
            activeBranchIdentifier={value}
            setActiveBranchIdentifier={onChange}
            size="default"
            type="form"
          />
        );
      }}
    />
  );
}
