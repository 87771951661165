import {
  UnitTypesEnum,
  UnitsEnum
} from 'src/__apolloGenerated__/graphql';
import { UnitTypesAndUnits } from 'src/backend/@types';
import SingleSelect, {
  SingleSelectProps
} from 'src/components/core/molecules/SingleSelect';
import { fEnum, fUnit } from 'src/utils/format';

export default function UnitSingleSelect({
  value,
  setValue,
  filterByUnitType,
  disabled,
  disableClear,
  prefixText = 'Unit: ',
  required,
  type = 'element'
}: {
  value: UnitsEnum;
  setValue: (value: UnitsEnum) => void;
  filterByUnitType?: UnitTypesEnum;
  props?: SingleSelectProps;
  disabled?: boolean;
  disableClear?: boolean;
  prefixText?: string;
  required?: boolean;
  type?: 'element' | 'form';
}) {
  const unitOptions = filterByUnitType
    ? UnitTypesAndUnits[filterByUnitType].map((unit) => {
        return {
          key: unit,
          label: fUnit(unit as UnitsEnum)
        };
      })
    : Object.keys(UnitsEnum).map((unit) => {
        return {
          key: UnitsEnum[unit],
          label: fUnit(UnitsEnum[unit])
        };
      });

  const groups = filterByUnitType
    ? [
        {
          key: filterByUnitType,
          label: fEnum(filterByUnitType),
          childrenKeys: UnitTypesAndUnits[filterByUnitType]
        }
      ]
    : Object.values(UnitTypesEnum).map((key) => {
        return {
          key: key,
          label: fEnum(key),
          childrenKeys: UnitTypesAndUnits[key]
        };
      });

  return (
      <SingleSelect
        value={value}
        setValue={setValue}
        options={unitOptions}
        disabled={disabled}
        disableClear={disableClear}
        groups={groups}
        prefixText={type === 'form' ? undefined : prefixText}
        size={type === 'form' ? 'default' : 'sm'}
        required={type === 'form' ? false : required}
      />
  );
}
