import { Fragment } from 'react';
import Skeleton from 'src/components/core/atoms/Skeleton';
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator
} from 'src/components/shad-base/breadcrumbs';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from 'src/components/shad-base/dropdown-menu';
import { getListItemKey } from 'src/utils/format';

export type BreadcrumbType = {
  key: string;
  label: string;
  onClick?: () => void;
};

export function Breadcrumbs({
  items,
  loading
}: {
  items: BreadcrumbType[];
  loading?: boolean;
}) {
  const getOverflowItems = () => {
    if (items.length <= 3) {
      return null;
    }
    const firstItem = items[0];
    const lastTwoItems = items.slice(-2);
    const middleItems = items.slice(1, -2);
    return {
      firstItem,
      lastTwoItems,
      middleItems
    };
  };
  const overflowItems = getOverflowItems();

  return loading ? (
    <Skeleton height={25} width={300} />
  ) : (
    <Breadcrumb>
      <BreadcrumbList>
        {overflowItems ? (
          <>
            <BreadcrumbItem onClick={overflowItems.firstItem.onClick}>
              <p className="cursor-pointer">
                {overflowItems.firstItem.label}
              </p>
            </BreadcrumbItem>
            <BreadcrumbSeparator />

            {/* Overflow Items */}
            <BreadcrumbItem>
              <DropdownMenu>
                <DropdownMenuTrigger className="flex items-center gap-1">
                  <BreadcrumbEllipsis className="h-4 w-4" />
                  <span className="sr-only">Toggle menu</span>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                  {overflowItems.middleItems.map((item) => {
                    return (
                      <DropdownMenuItem
                        key={item.key}
                        onClick={item.onClick}
                      >
                        {item.label}
                      </DropdownMenuItem>
                    );
                  })}
                </DropdownMenuContent>
              </DropdownMenu>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            {/* Last two items */}
            {overflowItems.lastTwoItems.map((item, idx) => {
              const isLastItem =
                idx === overflowItems.lastTwoItems.length - 1;
              return (
                <Fragment key={getListItemKey(idx)}>
                  <BreadcrumbItem
                    key={item.key}
                    onClick={isLastItem ? null : item.onClick}
                  >
                    <p className={'cursor-pointer'}>{item.label}</p>
                  </BreadcrumbItem>
                  {idx < overflowItems.lastTwoItems.length - 1 && (
                    <BreadcrumbSeparator />
                  )}
                </Fragment>
              );
            })}
          </>
        ) : (
          items.map((item, idx) => {
            const isLastItem = idx === items.length - 1;
            return (
              <Fragment key={getListItemKey(idx)}>
                <BreadcrumbItem
                  key={item.key}
                  onClick={isLastItem ? null : item.onClick}
                >
                  <p className={'cursor-pointer'}>{item.label}</p>
                </BreadcrumbItem>
                {idx < items.length - 1 && <BreadcrumbSeparator />}
              </Fragment>
            );
          })
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
