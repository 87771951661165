import { Button } from 'src/components/shad-base/button';
import {
  Dialog,
  DialogContent,
  DialogFooter
} from 'src/components/shad-base/dialog';

export default function AreYouSureDialog({
  onDeleteConfirm,
  open,
  title,
  message,
  onOpenChange,
  deleteLoading
}: {
  onDeleteConfirm: () => void;
  open: boolean;
  title?: string;
  message?: string;
  onOpenChange: (open: boolean) => void;
  deleteLoading?: boolean;
}) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <div className="flex flex-col flex-nowrap">
          <p className="subtitle1">{title || 'Confirm Delete'}</p>
          <p className="mb-sm mt-md">
            {message || 'Are you sure you want to continue?'}
          </p>

          <p>This action cannot be undone.</p>
        </div>
        <DialogFooter>
          <div className="flex flex-nowrap items-center">
            <Button
              variant="outline"
              onClick={() => onOpenChange(false)}
            >
              Cancel
            </Button>
            <Button
              loading={deleteLoading}
              variant="destructive"
              onClick={() => {
                onDeleteConfirm();
              }}
              className="ml-sm"
            >
              Delete
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
