import dayjs from 'dayjs';
import { MeasurementIntervalUnitsEnum } from 'src/__apolloGenerated__/graphql';
import {
  FormQuestionType,
  FormQuestionTypeType
} from 'src/components/core/organisms/Form';
import { fEnum } from 'src/utils/format';

export const ALL_QUESTION_KEYS = [
  'branchIdentifier',
  'name',
  'emissionFactor',
  'value',
  'valueUnit',
  'ghgCategory',
  'ownershipFraction',
  'description',
  'isDateRange',
  'startDate',
  'endDate',
  'dateOfMeasurement',
  'isRecurring',
  'measurementInterval'
];

export type CreateMeasurementQuestionKeys =
  (typeof ALL_QUESTION_KEYS)[number];

// Given a set of default values, return the map of create measurement form questions (encompassing both auto and manual forms)
export const GET_QUESTIONS: (defaultValues: {
  [key in CreateMeasurementQuestionKeys]?: string;
}) => {
  [key in CreateMeasurementQuestionKeys]: FormQuestionType;
} = (defaultValues) => {
  return {
    branchIdentifier: {
      key: 'branchIdentifier',
      type: 'branch-identifier' as FormQuestionTypeType,
      text: 'Branch',
      defaultValue: defaultValues?.branchIdentifier || '',
      required: true
    },
    name: {
      key: 'name',
      type: 'text' as FormQuestionTypeType,
      text: 'Title',
      required: true,
      defaultValue: defaultValues?.name || '',
      placeholder: 'Enter a title for your measurement...'
    },
    description: {
      key: 'description',
      type: 'textarea' as FormQuestionTypeType,
      text: 'Please describe the quantity and unit of your purchase.',
      defaultValue: defaultValues?.description || '',
      required: true,
      placeholder:
        'E.g. Our business purchased 1000 kWh of electricity last month.'
    },
    isDateRange: {
      key: 'isDateRange',
      type: 'radio-group' as FormQuestionTypeType,
      text: 'Over what period of time was this measurement taken?',
      defaultValue: defaultValues?.isDateRange || 'one-date',
      options: [
        {
          key: 'one-date',
          label: 'Single date'
        },
        { key: 'date-range', label: 'Date range' }
      ],
      required: true
    },
    startDate: {
      key: 'startDate',
      type: 'date' as FormQuestionTypeType,
      text: 'Measurement start date',
      dependsOnQuestionKey: 'isDateRange',
      dependsOnQuestionValue: 'date-range',
      defaultValue: defaultValues?.startDate || null,
      getQuestion: () => {
        return {
          text: 'Measurement start date'
        };
      },
      required: false
    },
    endDate: {
      key: 'endDate',
      type: 'date' as FormQuestionTypeType,
      text: 'Measurement end date',
      dependsOnQuestionKey: 'isDateRange',
      dependsOnQuestionValue: 'date-range',
      getQuestion: () => {
        return {
          text: 'Measurement end date'
        };
      },
      defaultValue: defaultValues?.endDate || null,
      required: false
    },
    dateOfMeasurement: {
      key: 'dateOfMeasurement',
      type: 'date' as FormQuestionTypeType,
      text: 'Date of measurement',
      defaultValue:
        defaultValues?.dateOfMeasurement || dayjs().toDate(),
      required: true,
      dependsOnQuestionKey: 'isDateRange',
      dependsOnQuestionValue: 'one-date',
      getQuestion: () => {
        return {
          text: 'Date of measurement'
        };
      }
    },
    isRecurring: {
      key: 'isRecurring',
      type: 'radio-group' as FormQuestionTypeType,
      text: 'What is the input frequency of this measurement?',
      defaultValue: defaultValues?.isRecurring
        ? 'recurring'
        : 'one-time',
      required: true,
      options: [
        {
          key: 'one-time',
          label: 'One-time'
        },
        {
          key: 'recurring',
          label: 'Recurring'
        }
      ]
    },
    measurementInterval: {
      key: 'measurementInterval',
      type: 'text' as FormQuestionTypeType,
      text: 'How often does this measurement occur?',
      defaultValue: defaultValues?.measurementInterval || '',
      required: true,
      dependsOnQuestionKey: 'isRecurring',
      dependsOnQuestionValue: 'recurring',
      placeholder: 'E.g. 2',
      getQuestion: () => {
        return {
          text: 'How often do you expect this measurement to occur?'
        };
      },
      embeddedSingleSelect: {
        key: 'measurementIntervalUnit',
        defaultValue:
          defaultValues?.measurementIntervalUnit ||
          MeasurementIntervalUnitsEnum.Months,
        options: Object.values(MeasurementIntervalUnitsEnum).map(
          (value) => ({
            key: value,
            label: fEnum(value)
          })
        )
      }
    },
    emissionFactor: {
      key: 'emissionFactor',
      type: 'emission-factor' as FormQuestionTypeType,
      text: 'Emission Factor',
      defaultValue: defaultValues?.emissionFactor || '',
      required: true
    },
    value: {
      key: 'value',
      type: 'number' as FormQuestionTypeType,
      text: 'Value',
      defaultValue: defaultValues?.value || '',
      required: true,
      placeholder: 'Enter a value for your measurement...'
    },
    valueUnit: {
      key: 'valueUnit',
      type: 'value-unit' as FormQuestionTypeType,
      text: 'Unit',
      required: true,
      defaultValue: defaultValues?.valueUnit || null
    },
    ghgCategory: {
      key: 'ghgCategory',
      type: 'ghg-category' as FormQuestionTypeType,
      text: 'GHG Category',
      defaultValue: defaultValues?.ghgCategory || '',
      required: true
    },
    ownershipFraction: {
      key: 'ownershipFraction',
      type: 'number' as FormQuestionTypeType,
      text: 'Ownership Percentage',
      defaultValue: defaultValues?.ownershipFraction || '',
      required: true,
      placeholder: 'E.g. 100',
      helperMessage:
        'The percentage of ownership for this measurement.'
    }
  };
};
