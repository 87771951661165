import dayjs from 'dayjs';
import {
  MeasurementFragmentFragment,
  MeasurementIntervalUnitsEnum
} from 'src/__apolloGenerated__/graphql';
import Form, {
  FormQuestionTypeType
} from 'src/components/core/organisms/Form';
import {
  Dialog,
  DialogContent
} from 'src/components/shad-base/dialog';
import { useToast } from 'src/hooks/shad-base/useToast';
import useMeasureStore, {
  MeasureStoreType
} from 'src/hooks/store/useMeasureStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import { EDIT_MEASUREMENT } from 'src/hooks/useBackendMutation/mutations';
import { fEnum } from 'src/utils/format';

export default function EditMeasurementFrequencyDialog({
  open,
  onOpenChange,
  measurement
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  measurement: MeasurementFragmentFragment;
}) {
  const { toast } = useToast();
  const { selectedStartDate, selectedEndDate, selectedFilters } =
    useMeasureStore((store: MeasureStoreType) => ({
      selectedStartDate: store.selectedStartDate,
      selectedEndDate: store.selectedEndDate,
      selectedFilters: store.selectedFilters
    }));

  const { mutate: editMeasurement, loading: editingMeasurement } =
    useBackendMutation({
      mutation: EDIT_MEASUREMENT,
      callbacks: {
        onSuccess: () => {
          onOpenChange(false);
          toast({
            title: 'Success.',
            description:
              'The measurement frequency has been updated.',
            variant: 'success'
          });
        }
      }
    });
    
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-screen-md">
        <div className="flex flex-col flex-nowrap items-center">
          <p className="subtitle1 ">Edit Input Frequency</p>
          <p className="text-muted">
            Edit the input frequency of this measurement.
          </p>
          <div className="mt-lg w-full">
            <Form
              onSubmit={(formValues) => {
                const isRecurring =
                  formValues.isRecurring === 'recurring';
                // If no change, close dialog
                if (
                  isRecurring === measurement?.isRecurring &&
                  formValues?.measurementInterval ===
                    String(measurement?.intervalValue) &&
                  formValues?.measurementIntervalUnit ===
                    measurement?.intervalUnit
                ) {
                  onOpenChange(false);
                  return;
                }
                const intervalValue = formValues.measurementInterval
                  ? Number(formValues.measurementInterval)
                  : undefined;
                const intervalUnit =
                  formValues.measurementIntervalUnit
                    ? formValues.measurementIntervalUnit
                    : undefined;

                const interval =
                  intervalValue && intervalUnit
                    ? {
                        value: intervalValue,
                        unit: intervalUnit
                      }
                    : undefined;
                editMeasurement({
                  variables: {
                    input: {
                      measurementIdentifier: measurement?.identifier,
                      branchIdentifier:
                        measurement?.branch?.identifier,
                      measurement: {
                        isRecurring,
                        interval
                      }
                    },
                    startDate: dayjs(selectedStartDate).toDate(),
                    endDate: dayjs(selectedEndDate).toDate(),
                    states: selectedFilters
                  }
                });
              }}
              isSubmitting={editingMeasurement}
              submitButtonText="Save"
              rows={[
                {
                  questions: [
                    {
                      key: 'isRecurring',
                      type: 'radio-group' as FormQuestionTypeType,
                      text: 'What is the input frequency of this measurement?',
                      defaultValue: measurement?.isRecurring
                        ? 'recurring'
                        : 'one-time',
                      required: true,
                      options: [
                        {
                          key: 'one-time',
                          label: 'One-time'
                        },
                        {
                          key: 'recurring',
                          label: 'Recurring'
                        }
                      ]
                    }
                  ]
                },
                {
                  questions: [
                    {
                      key: 'measurementInterval',
                      type: 'text' as FormQuestionTypeType,
                      text: 'How often does this measurement occur?',
                      defaultValue: measurement?.intervalValue
                        ? String(measurement?.intervalValue)
                        : null,
                      required: true,
                      dependsOnQuestionKey: 'isRecurring',
                      dependsOnQuestionValue: 'recurring',
                      placeholder: 'E.g. 2',
                      getQuestion: () => {
                        return {
                          text: 'How often do you expect this measurement to occur?'
                        };
                      },
                      embeddedSingleSelect: {
                        key: 'measurementIntervalUnit',
                        defaultValue:
                          measurement?.intervalUnit ||
                          MeasurementIntervalUnitsEnum.Months,
                        options: Object.values(
                          MeasurementIntervalUnitsEnum
                        ).map((value) => ({
                          key: value,
                          label: fEnum(value)
                        }))
                      }
                    }
                  ]
                }
              ]}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
