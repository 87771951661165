import _ from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';
import { ScopeType } from 'src/@types/emissions';
import {
  DataCalculatorTypesEnum,
  EquipmentFragmentFragment,
  LocationFragmentFragment,
  MeasurementFragmentFragment,
  VehicleFragmentFragment
} from 'src/__apolloGenerated__/graphql';
import DataCalculatorIcon from 'src/components/core/atoms/IconCircle';
import { DATA_CALCULATOR_TYPES_BY_SCOPE, ROUTES } from 'src/config';
import useAssetStore, {
  AssetStoreType
} from 'src/hooks/store/useAssetStore';
import useDataCalculatorStore, {
  DataCalculatorStoreType
} from 'src/hooks/store/useDataCalculatorStore';
import { fEnum } from 'src/utils/format';
import { getEquipmentDescription } from 'src/views/EquipmentView';
import {
  getLocationDescription,
  getSecondaryLocationDescription
} from 'src/views/LocationsView';
import {
  getSecondaryVehicleDescription,
  getVehicleDescription
} from 'src/views/VehiclesView';

function Row({
  renderLabel,
  renderValue
}: {
  renderLabel: () => React.ReactNode;
  renderValue: () => React.ReactNode;
}) {
  return (
    <div className="flex flex-nowrap items-center">
      <div className="mr-md flex h-[40px] w-[80px] min-w-[80px] items-center">
        {renderLabel()}
      </div>
      <div className="flex h-[40px] items-center">
        {renderValue()}
      </div>
    </div>
  );
}

export default function SourceSection({
  calculatorType,
  asset,
  assetType,
  measurement
}: {
  calculatorType: DataCalculatorTypesEnum;
  asset:
    | LocationFragmentFragment
    | VehicleFragmentFragment
    | EquipmentFragmentFragment;
  assetType: 'location' | 'vehicle' | 'equipment';
  measurement: MeasurementFragmentFragment;
}) {
  const router = useRouter();
  const {
    setActiveAsset: setCalculatorAsset,
    setActiveCalculatorType,
    setInitialMeasurement,
    setActiveAssetType: setCalculatorAssetType
  } = useDataCalculatorStore((store: DataCalculatorStoreType) => ({
    setActiveAsset: store.setActiveAsset,
    setActiveCalculatorType: store.setActiveCalculatorType,
    setInitialMeasurement: store.setInitialMeasurement,
    setActiveAssetType: store.setActiveAssetType
  }));

  const { setActiveAssetType, setActiveAssetIdentifier } =
    useAssetStore((store: AssetStoreType) => ({
      setActiveAssetType: store.setActiveAssetType,
      setActiveAssetIdentifier: store.setActiveAssetIdentifier
    }));

  const getScope = (): ScopeType => {
    let scope = 0;
    Object.keys(DATA_CALCULATOR_TYPES_BY_SCOPE).forEach((key) => {
      if (
        DATA_CALCULATOR_TYPES_BY_SCOPE[key].includes(calculatorType)
      ) {
        scope = parseInt(key);
      }
    });
    return scope as ScopeType;
  };

  const assetRoutes = {
    location: ROUTES.ORGANIZATION.LOCATIONS,
    vehicle: ROUTES.ORGANIZATION.VEHICLES,
    equipment: ROUTES.ORGANIZATION.EQUIPMENT
  };

  return (
    <div className="rounded-md border p-lg">
      <div className="flex flex-col flex-nowrap">
        <p className="mb-md">Source</p>

        {/* Calculator Type */}
        <div className="mb-md">
          <Row
            renderLabel={() => (
              <DataCalculatorIcon
                scope={getScope()}
                calculatorType={calculatorType}
              />
            )}
            renderValue={() => (
              <p
                className="cursor-pointer font-bold hover:underline"
                onClick={() => {
                  const route =
                    getScope() === 1
                      ? ROUTES.TOOLS.SCOPE1
                      : ROUTES.TOOLS.SCOPE2;
                  setCalculatorAsset(asset);
                  setCalculatorAssetType(assetType);
                  setActiveCalculatorType(calculatorType);
                  setInitialMeasurement(measurement);
                  router.push(route);
                }}
              >
                {fEnum(calculatorType)}
              </p>
            )}
          />
        </div>

        {/* Link to Asset */}
        <div className="mb-md">
          <Row
            renderLabel={() => (
              <p className="text-muted">{_.startCase(assetType)}</p>
            )}
            renderValue={() => (
              <p
                className="cursor-pointer font-bold hover:underline"
                onClick={() => {
                  router.push(assetRoutes[assetType]);
                  setActiveAssetIdentifier(asset?.identifier);
                  setActiveAssetType(assetType);
                }}
              >
                {asset?.name}
              </p>
            )}
          />
        </div>

        {/* Type */}
        <div className="mb-md">
          <Row
            renderLabel={() => <p className="text-muted">Type</p>}
            renderValue={() => (
              <p>
                {assetType == 'vehicle'
                  ? getVehicleDescription(
                      asset as VehicleFragmentFragment
                    )
                  : assetType == 'location'
                    ? getLocationDescription(
                        asset as LocationFragmentFragment
                      )
                    : assetType == 'equipment'
                      ? getEquipmentDescription(
                          asset as EquipmentFragmentFragment
                        )
                      : null}
              </p>
            )}
          />
        </div>
        {/* Details */}
        {assetType == 'equipment' ? null : (
          <div>
            <Row
              renderLabel={() => (
                <p className="text-muted">Details</p>
              )}
              renderValue={() => (
                <p>
                  {assetType == 'vehicle'
                    ? getSecondaryVehicleDescription(
                        asset as VehicleFragmentFragment
                      )
                    : assetType == 'location'
                      ? getSecondaryLocationDescription(
                          asset as LocationFragmentFragment
                        )
                      : null}
                </p>
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
}
