import { CountryNamesEnum } from 'src/__apolloGenerated__/graphql';

export const COUNTRY_CAPITALS: { [key in CountryNamesEnum]: string } =
  {
    AFGHANISTAN: 'Kabul',
    ALBANIA: 'Tirana',
    ALGERIA: 'Algiers',
    ANDORRA: 'Andorra la Vella',
    ANGOLA: 'Luanda',
    ANTIGUA_AND_BARBUDA: "Saint John's",
    ARGENTINA: 'Buenos Aires',
    ARMENIA: 'Yerevan',
    // ARUBA: 'Oranjestad',
    AUSTRALIA: 'Canberra',
    AUSTRIA: 'Vienna',
    AZERBAIJAN: 'Baku',
    BAHAMAS: 'Nassau',
    BAHRAIN: 'Manama',
    BANGLADESH: 'Dhaka',
    BARBADOS: 'Bridgetown',
    // BELARUS: 'Minsk',
    BELGIUM: 'Brussels',
    BELIZE: 'Belmopan',
    BENIN: 'Porto-Novo',
    //  BERMUDA: 'Hamilton',
    BHUTAN: 'Thimphu',
    BOLIVIA: 'Sucre',
    // BONAIRE_SINT_EUSTATIUS_AND_SABA: 'Kralendijk',
    BOSNIA_AND_HERZEGOVINA: 'Sarajevo',
    BOTSWANA: 'Gaborone',
    // BOUVET_ISLAND: 'None',
    BRAZIL: 'Brasilia',
    // BRITISH_INDIAN_OCEAN_TERRITORY: 'Diego Garcia',
    BRUNEI: 'Bandar Seri Begawan',
    BULGARIA: 'Sofia',
    BURKINA_FASO: 'Ouagadougou',
    BURUNDI: 'Bujumbura',
    CABO_VERDE: 'Praia',
    CAMBODIA: 'Phnom Penh',
    CAMEROON: 'Yaoundé',
    CANADA: 'Ottawa',
    // CAYMAN_ISLANDS: 'George Town',
    CENTRAL_AFRICAN_REPUBLIC: 'Bangui',
    CHAD: "N'Djamena",
    CHILE: 'Santiago',
    CHINA: 'Beijing',
    // CHRISTMAS_ISLAND: 'Flying Fish Cove',
    // COCOS_KEELING_ISLANDS: 'West Island',
    COLOMBIA: 'Bogotá',
    COMOROS: 'Moroni',
    // COOK_ISLANDS: 'Avarua',
    COSTA_RICA: 'San José',
    COTE_D_IVOIRE: 'Yamoussoukro',
    CROATIA: 'Zagreb',
    CUBA: 'Havana',
    // CURAÇAO: 'Willemstad',
    CYPRUS: 'Nicosia',
    CZECHIA: 'Prague',
    DEMOCRATIC_REPUBLIC_OF_CONGO: 'Kinshasa',
    REPUBLIC_OF_CONGO: 'Brazzaville',
    DENMARK: 'Copenhagen',
    DJIBOUTI: 'Djibouti',
    DOMINICA: 'Roseau',
    DOMINICAN_REPUBLIC: 'Santo Domingo',
    EAST_TIMOR: 'Dili',
    ECUADOR: 'Quito',
    EGYPT: 'Cairo',
    EL_SALVADOR: 'San Salvador',
    EQUATORIAL_GUINEA: 'Malabo',
    ERITREA: 'Asmara',
    ESTONIA: 'Tallinn',
    ESWATINI: 'Mbabane',
    ETHIOPIA: 'Addis Ababa',
    // FARLAND_ISLANDS: 'Stanley',
    // FAROE_ISLANDS: 'Tórshavn',
    FIJI: 'Suva',
    FINLAND: 'Helsinki',
    FRANCE: 'Paris',
    // FRENCH_GUIANA: 'Cayenne',
    // FRENCH_POLYNESIA: 'Papeete',
    // FRENCH_SOUTHERN_TERRITORIES: 'Saint-Pierre',
    GABON: 'Libreville',
    GAMBIA: 'Banjul',
    GEORGIA: 'Tbilisi',
    GERMANY: 'Berlin',
    GHANA: 'Accra',
    // GIBRALTAR: 'Gibraltar',
    GREECE: 'Athens',
    // GREENLAND: 'Nuuk',
    GRENADA: "Saint George's",
    // GUADALOUPE: 'Basse-Terre',
    // GUAM: 'Hagåtña',
    GUATEMALA: 'Guatemala City',
    // GUERNSEY: 'Saint Peter Port',
    GUINEA: 'Conakry',
    GUINEA_BISSAU: 'Bissau',
    GUYANA: 'Georgetown',
    HAITI: 'Port-au-Prince',
    // HEARD_ISLAND_AND_MCDONALD_ISLANDS: 'London',
    // HOLY_SEE: 'Vatican City',
    HONDURAS: 'Tegucigalpa',
    // HONG_KONG: 'Hong Kong',
    HUNGARY: 'Budapest',
    ICELAND: 'Reykjavik',
    INDIA: 'New Delhi',
    INDONESIA: 'Jakarta',
    // IRAN: 'Tehran',
    IRAQ: 'Baghdad',
    IRELAND: 'Dublin',
    // ISLE_OF_MAN: 'Douglas',
    ISRAEL: 'Jerusalem',
    ITALY: 'Rome',
    JAMAICA: 'Kingston',
    JAPAN: 'Tokyo',
    // JERSEY: 'Saint Helier',
    JORDAN: 'Amman',
    KAZAKHSTAN: 'Nur-Sultan',
    KENYA: 'Nairobi',
    KIRIBATI: 'Tarawa',
    KUWAIT: 'Kuwait City',
    KYRGYZSTAN: 'Bishkek',
    LAOS: 'Vientiane',
    LATVIA: 'Riga',
    LEBANON: 'Beirut',
    LESOTHO: 'Maseru',
    LIBERIA: 'Monrovia',
    LIBYA: 'Tripoli',
    LIECHTENSTEIN: 'Vaduz',
    LITHUANIA: 'Vilnius',
    LUXEMBOURG: 'Luxembourg',
    // MACAO: 'Macau',
    // MACEDONIA: 'Skopje',
    MADAGASCAR: 'Antananarivo',
    MALAWI: 'Lilongwe',
    MALAYSIA: 'Kuala Lumpur',
    MALDIVES: 'Malé',
    MALI: 'Bamako',
    MALTA: 'Valletta',
    MARSHALL_ISLANDS: 'Majuro',
    // MARTINIQUE: 'Fort-de-France',
    MAURITANIA: 'Nouakchott',
    MAURITIUS: 'Port Louis',
    // MAYOTTE: 'Mamoudzou',
    MEXICO: 'Mexico City',
    MICRONESIA: 'Palikir',
    MOLDOVA: 'Chișinău',
    MONACO: 'Monaco',
    MONGOLIA: 'Ulaanbaatar',
    MONTENEGRO: 'Podgorica',
    // MONTserrat: 'Plymouth',
    MOROCCO: 'Rabat',
    MOZAMBIQUE: 'Maputo',
    MYANMAR: 'Naypyidaw',
    NAMIBIA: 'Windhoek',
    NAURU: 'Yaren',
    NEPAL: 'Kathmandu',
    NETHERLANDS: 'Amsterdam',
    // NEW_CALEDONIA: 'Nouméa',
    NEW_ZEALAND: 'Wellington',
    NICARAGUA: 'Managua',
    NIGER: 'Niamey',
    NIGERIA: 'Abuja',
    // NIUE: 'Alofi',
    // NORFOLK_ISLAND: 'Kingston',
    // NORTH_KOREA: 'Pyongyang',
    // NORTHERN_MARIANA_ISLANDS: 'Saipan',
    NORTH_MACEDONIA: 'Skopje',
    NORWAY: 'Oslo',
    OMAN: 'Muscat',
    PAKISTAN: 'Islamabad',
    PALAU: 'Ngerulmud',
    // PALESTINE_STATE_OF: 'Ramallah',
    PANAMA: 'Panama City',
    PAPUA_NEW_GUINEA: 'Port Moresby',
    PARAGUAY: 'Asunción',
    PERU: 'Lima',
    PHILIPPINES: 'Manila',
    // PITCAIRN: 'Adamstown',
    POLAND: 'Warsaw',
    PORTUGAL: 'Lisbon',
    // PUERTO_RICO: 'San Juan',
    QATAR: 'Doha',
    // RÉUNION: 'Saint-Denis',
    ROMANIA: 'Bucharest',
    // RUSSIAN_FEDERATION: 'Moscow',
    RWANDA: 'Kigali',
    // SAINT_BARTHÉLEMY: 'Gustavia',
    // SAINT_HELENA_ASCENSION_AND_TRISTAN_DA_CUNHA: 'Jamestown',
    SAINT_KITTS_AND_NEVIS: 'Basseterre',
    SAINT_LUCIA: 'Castries',
    // SAINT_MARTIN: 'Marigot',
    // SAINT_PIERRE_AND_MIQUELON: 'Saint-Pierre',
    SAINT_VINCENT_AND_THE_GRENADINES: 'Kingstown',
    SAMOA: 'Apia',
    SAN_MARINO: 'San Marino',
    SAO_TOME_AND_PRINCIPE: 'São Tomé',
    SAUDI_ARABIA: 'Riyadh',
    SENEGAL: 'Dakar',
    SERBIA: 'Belgrade',
    SEYCHELLES: 'Victoria',
    SIERRA_LEONE: 'Freetown',
    SINGAPORE: 'Singapore',
    // SINT_MAARTEN: 'Philipsburg',
    SLOVAKIA: 'Bratislava',
    SLOVENIA: 'Ljubljana',
    SOLOMON_ISLANDS: 'Honiara',
    SOMALIA: 'Mogadishu',
    SOUTH_AFRICA: 'Pretoria',
    // SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS: 'London',
    SOUTH_KOREA: 'Seoul',
    SOUTH_SUDAN: 'Juba',
    SPAIN: 'Madrid',
    SRI_LANKA: 'Colombo',
    SUDAN: 'Khartoum',
    SURINAME: 'Paramaribo',
    // SVALBARD_AND_JAN_MAYEN: 'Longyearbyen',
    // SWAZILAND: 'Mbabane',
    SWEDEN: 'Stockholm',
    SWITZERLAND: 'Bern',
    SYRIA: 'Damascus',
    // TAIWAN: 'Taipei',
    TAJIKISTAN: 'Dushanbe',
    TANZANIA: 'Dodoma',
    THAILAND: 'Bangkok',
    // TIMOR_LESTE: 'Dili',
    TOGO: 'Lomé',
    // TOKELAU: 'Fakaofo',
    TONGA: 'Nukuʻalofa',
    TRINIDAD_AND_TOBAGO: 'Port of Spain',
    TUNISIA: 'Tunis',
    TURKEY: 'Ankara',
    TURKMENISTAN: 'Ashgabat',
    // TURKS_AND_CAICOS_ISLANDS: 'Cockburn Town',
    TUVALU: 'Funafuti',
    UGANDA: 'Kampala',
    UKRAINE: 'Kyiv',
    UNITED_ARAB_EMIRATES: 'Abu Dhabi',
    UNITED_KINGDOM: 'London',
    UNITED_STATES: 'Washington, D.C.',
    // UNITED_STATES_MINOR_OUTLYING_ISLANDS: 'London',
    URUGUAY: 'Montevideo',
    UZBEKISTAN: 'Tashkent',
    VANUATU: 'Port Vila',
    VATICAN_CITY: 'Rome',
    VENEZUELA: 'Caracas',
    VIETNAM: 'Hanoi',
    // VIRGIN_ISLANDS_BRITISH: 'Road Town',
    // VIRGIN_ISLANDS_US: 'Charlotte Amalie',
    // WALLIS_AND_FUTUNA: 'Mata-Utu',
    // WESTERN_SAHARA: 'El Aaiún',
    YEMEN: "Sana'a",
    ZAMBIA: 'Lusaka',
    ZIMBABWE: 'Harare',
    BELARUS: 'Minsk',
    IRAN: 'Tehran', 
    NORTH_KOREA: 'Pyongyang',
    RUSSIA: 'Moscow'
  };
export type COUNTRY_CAPITALS_TYPE = keyof typeof COUNTRY_CAPITALS;

export const COUNTRY_REGIONS_NAME = {
  AFGHANISTAN: 'Wilayah/Province',
  ALBANIA: 'County',
  ALGERIA: 'Wilaya/Province',
  ANDORRA: 'Parish',
  ANGOLA: 'Province',
  ANTIGUA_AND_BARBUDA: 'Parish/Dependency',
  ARGENTINA: 'Province',
  ARMENIA: 'Marz/Region',
  //   ARUBA: 'Region',
  AUSTRALIA: 'State/Territory',
  AUSTRIA: 'State',
  //   AZERBAIJAN: 'Region',
  //   BAHAMAS: 'Parish',
  //   BAHRAIN: 'Governorate',
  //   BANGLADESH: 'Division',
  //   BARBADOS: 'Parish',
  //   BELARUS: 'Region',
  BELGIUM: 'Province/Region',
  BELIZE: 'District',
  BENIN: 'Department',
  //   BERMUDA: 'Parish',
  //   BHUTAN: 'District',
  BOLIVIA: 'Department',
  //   BONAIRE_SINT_EUSTATIUS_AND_SABA: 'District',
  //   BOSNIA_AND_HERZEGOVINA: 'Canton',
  BOTSWANA: 'District',
  //   BOUVET_ISLAND: 'Island',
  BRAZIL: 'State/District',
  //   BRITISH_INDIAN_OCEAN_TERRITORY: 'Island',
  //   BRUNEI: 'District',
  BULGARIA: 'Province/Region/Oblast',
  BURKINA_FASO: 'Region',
  BURUNDI: 'Province',
  //   CABO_VERDE: 'Province',
  //   CAMBODIA: 'Province',
  CAMEROON: 'Region',
  CANADA: 'Province/Territory',
  //   CAYMAN_ISLANDS: 'District',
  CENTRAL_AFRICAN_REPUBLIC: 'Prefecture',
  CHAD: 'Region/Mintaqah',
  CHILE: 'Region',
  CHINA: 'Province/Autonomous Region',
  COLOMBIA: 'Department',
  CONGO: 'Department',
  COSTA_RICA: 'Province',
  COTE_D_IVOIRE: 'District',
  CYPRUS: 'District/Eparchie/Kaza',
  CZECHIA: 'Region/Kraj',
  DEMOCRATIC_REPUBLIC_OF_CONGO: 'Province',
  DENMARK: 'Region',
  ECUADOR: 'Province',
  ESTONIA: 'County/Maakond',
  FINLAND: 'Region/Maakunta',
  FRANCE: 'Region',
  GABON: 'Province',
  GERMANY: 'State/Land',
  GUATEMALA: 'Department',
  HONDURAS: 'Department',
  ICELAND: 'Region',
  INDIA: 'State/Union Territory',
  INDONESIA: 'Province',
  IRELAND: 'Province',
  ISRAEL: 'District/Mehozot',
  ITALY: 'Region',
  JAPAN: 'Prefecture',
  KENYA: 'County',
  KUWAIT: 'Governorate',
  LATVIA: 'Municipality/Valstspilsēta',
  LIBYA: 'District/Shabiyah/شعبية',
  LICHTENSTEIN: 'Commune',
  LITHUANIA: 'County/Apskritis',
  LUXEMBOURG: 'Canton',
  MADAGASCAR: 'Region/Faritra',
  MALAYSIA: 'State/Federal Territory',
  MALI: 'Region/District',
  MALTA: 'Region',
  MAURITANIA: 'Region',
  MEXICO: 'State',
  MOZAMBIQUE: 'Province',
  NAMIBIA: 'Region',
  NETHERLANDS: 'Province',
  NEW_ZEALAND: 'Region',
  NICARAGUA: 'Department/Region',
  NIGER: 'Region',
  NIGERIA: 'State',
  NORWAY: 'County',
  PANAMA: 'Province/Region',
  PARAGUAY: 'Department',
  PERU: 'Department/Region',
  PHILIPPINES: 'Region',
  POLAND: 'State/Voivodeship',
  PORTUGAL: 'District/Autonomous Region',
  REPUBLIC_OF_CONGO: 'Department',
  ROMANIA: 'County/Județ',
  RWANDA: 'Province',
  QATAR: 'Municipality/Baladiyah',
  SAUDI_ARABIA: 'Region/Mintaqah',
  SINGAPORE: 'District',
  SLOVAKIA: 'Region/Kraj',
  //   SLOVENIA: 'Region',
  SPAIN: 'Autonomous Community/City',
  SOUTH_AFRICA: 'Province',
  SOUTH_KOREA: 'Province/Metropolitan City',
  SWEDEN: 'County',
  SWITZERLAND: 'Canton',
  TAIWAN: 'County/City/Municipality',
  TANZANIA: 'Region/Mikoa',
  THAILAND: 'Province/SAR',
  TURKEY: 'Province/il',
  TUNISIA: 'Governorate/Wilaya',
  UGANDA: 'Region',
  UNITED_ARAB_EMIRATES: 'Emirate',
  UNITED_KINGDOM: 'County/District/City',
  UNITED_STATES: 'State',
  URUGUAY: 'Department',
  ZAMBIA: 'Province',
  ZIMBABWE: 'Province'
};

export const AFGHANISTAN_REGIONS = {
  BADAKHSHAN: 'Badakhshan',
  BADGHIS: 'Badghis',
  BAGHLAN: 'Baghlan',
  BALKH: 'Balkh',
  BAMYAN: 'Bamyan',
  DAYKUNDI: 'Daykundi',
  FARAH: 'Farah',
  FARYAB: 'Faryab',
  GHAZNI: 'Ghazni',
  GHOR: 'Ghor',
  HELMAND: 'Helmand',
  HERAT: 'Herat',
  JOWZJAN: 'Jowzjan',
  KABUL: 'Kabul',
  KANDAHAR: 'Kandahar',
  KAPISA: 'Kapisa',
  KHOST: 'Khost',
  KUNAR: 'Kunar',
  KUNDUZ: 'Kunduz',
  LAGHMAN: 'Laghman',
  LOGAR: 'Logar',
  NANGARHAR: 'Nangarhar',
  NIMRUZ: 'Nimruz',
  NURISTAN: 'Nuristan',
  PAKTIA: 'Paktia',
  PAKTIKA: 'Paktika',
  PANJSHIR: 'Panjshir',
  PARWAN: 'Parwan',
  SAMANGAN: 'Samangan',
  SAR_E_PUL: 'Sar-e Pul',
  TAKHAR: 'Takhar',
  URUZGAN: 'Uruzgan',
  WARDAK: 'Wardak',
  ZABUL: 'Zabul'
} as const;
export type AFGHANISTAN_REGIONS_TYPE =
  keyof typeof AFGHANISTAN_REGIONS;

export const ALBANIA_REGIONS = {
  BERAT: 'Berat',
  DIBER: 'Dibër',
  DURRES: 'Durrës',
  ELBASAN: 'Elbasan',
  FIER: 'Fier',
  GJIROKASTER: 'Gjirokastër',
  KORCE: 'Korçë',
  KUKES: 'Kukës',
  LEZHE: 'Lezhë',
  SHKODER: 'Shkodër',
  TIRANE: 'Tiranë',
  VLORE: 'Vlorë'
} as const;
export type ALBANIA_REGIONS_TYPE = keyof typeof ALBANIA_REGIONS;

export const ALGERIA_REGIONS = {
  ADRAR: 'Adrar',
  AIN_DEFLA: 'Aïn Defla',
  AIN_TEMOUCHENT: 'Aïn Témouchent',
  ALGIERS: 'Algiers',
  ANNABA: 'Annaba',
  BATNA: 'Batna',
  BECHAR: 'Béchar',
  BEJAIA: 'Béjaïa',
  BENI_ABBES: 'Béni Abbès',
  BISKRA: 'Biskra',
  BLIDA: 'Blida',
  BORDJ_BADJI_MOKHTAR: 'Bordj Badji Mokhtar',
  BORDJ_BOU_ARRERIDJ: 'Bordj Bou Arréridj',
  BOUIRA: 'Bouïra',
  BOUMERDES: 'Boumerdès',
  CHLEF: 'Chlef',
  CONSTANTINE: 'Constantine',
  DJANET: 'Djanet',
  DJELFA: 'Djelfa',
  EL_BAYADH: 'El Bayadh',
  EL_MEGHAIER: 'El Meghaier',
  EL_MENIA: 'El Menia',
  EL_MGHAR: "El M'Ghair",
  EL_OUED: 'El Oued',
  EL_TAREF: 'El Taref',
  GHARDAIA: 'Ghardaïa',
  GUELMA: 'Guelma',
  ILLIZI: 'Illizi',
  IN_GUEZZAM: 'In Guezzam',
  IN_SALAH: 'In Salah',
  JIJEL: 'Jijel',
  KHENCHELA: 'Khenchela',
  LAGHOUAT: 'Laghouat',
  MASCARA: 'Mascara',
  MEDEA: 'Médéa',
  MILA: 'Mila',
  MOSTAGANEM: 'Mostaganem',
  MSILA: "M'Sila",
  NAAMA: 'Naâma',
  ORAN: 'Oran',
  OUARGLA: 'Ouargla',
  OULED_DJELLAL: 'Ouled Djellal',
  OUM_EL_BOUAGHI: 'Oum El Bouaghi',
  RELIZANE: 'Relizane',
  SAIDA: 'Saïda',
  SETIF: 'Sétif',
  SIDI_BEL_ABBES: 'Sidi Bel Abbès',
  SKIKDA: 'Skikda',
  SOUK_AHRAS: 'Souk Ahras',
  TAMANRASSET: 'Tamanrasset',
  TEBESSA: 'Tébessa',
  TIARET: 'Tiaret',
  TIMIMOUN: 'Timimoun',
  TINDOUF: 'Tindouf',
  TIPAZA: 'Tipaza',
  TISSEMSILT: 'Tissemsilt',
  TIZI_OUZOU: 'Tizi Ouzou',
  TLEMCEN: 'Tlemcen',
  TOUGGOURT: 'Touggourt'
} as const;
export type ALGERIA_REGIONS_TYPE = keyof typeof ALGERIA_REGIONS;

export const ANDORRA_REGIONS = {
  ANDORRA: 'Andorra',
  ANDORRA_LA_VIEILLE_VILLE: 'Andorra la Vella',
  CANILLO: 'Canillo',
  ENCAU: 'Encamp',
  ESCALDES_ENGORDANY: 'Escaldes-Engordany',
  LA_MASSANA: 'La Massana',
  ORDINO: 'Ordino',
  SANT_JULIA_DE_LORIA: 'Sant Julià de Lòria'
} as const;
export type ANDORRA_REGIONS_TYPE = keyof typeof ANDORRA_REGIONS;

export const ANGOLA_REGIONS = {
  BENGO: 'Bengo',
  BENGUELA: 'Benguela',
  BIE: 'Bié',
  CABINDA: 'Cabinda',
  CUANDO_CUBANGO: 'Cuando Cubango',
  CUANZA_NORTE: 'Cuanza Norte',
  CUANZA_SUL: 'Cuanza Sul',
  CUNENE: 'Cunene',
  HUAMBO: 'Huambo',
  HUILA: 'Huíla',
  LUANDA: 'Luanda',
  LUANDA_NORTE: 'Luanda Norte',
  LUANDA_SUL: 'Luanda Sul',
  MALANJE: 'Malanje',
  MOXICO: 'Moxico',
  UIGE: 'Uíge',
  ZAIRE: 'Zaire'
} as const;
export type ANGOLA_REGIONS_TYPE = keyof typeof ANGOLA_REGIONS;

export const ANTIGUA_AND_BARBUDA_REGIONS = {
  SAINT_GEORGE: 'Saint George',
  SAINT_JOHN: 'Saint John',
  SAINT_MARY: 'Saint Mary',
  SAINT_PAUL: 'Saint Paul',
  SAINT_PETER: 'Saint Peter',
  SAINT_PHILIP: 'Saint Philip',
  BARBUDA: 'Barbuda',
  REDONDA: 'Redonda'
} as const;
export type ANTIGUA_AND_BARBUDA_REGIONS_TYPE =
  keyof typeof ANTIGUA_AND_BARBUDA_REGIONS;

export const ARGENTINA_REGIONS = {
  AUTONOMOUS_CITY_OF_BUENOS_AIRES: 'Autonomous City of Buenos Aires',
  BUENOS_AIRES: 'Buenos Aires',
  CATAMARCA: 'Catamarca',
  CHACO: 'Chaco',
  CHUBUT: 'Chubut',
  CORDOBA: 'Córdoba',
  CORRIENTES: 'Corrientes',
  ENTRE_RIOS: 'Entre Ríos',
  FORMOSA: 'Formosa',
  JUJUY: 'Jujuy',
  LA_PAMPA: 'La Pampa',
  LA_RIOJA: 'La Rioja',
  MENDOZA: 'Mendoza',
  MISIONES: 'Misiones',
  NEUQUEN: 'Neuquén',
  RIO_NEGRO: 'Río Negro',
  SALTA: 'Salta',
  SAN_JUAN: 'San Juan',
  SAN_LUIS: 'San Luis',
  SANTA_CRUZ: 'Santa Cruz',
  SANTA_FE: 'Santa Fe',
  SANTIAGO_DEL_ESTERO: 'Santiago del Estero',
  TIERRA_DEL_FUEGO: 'Tierra del Fuego',
  TUCUMAN: 'Tucumán'
} as const;
export type ARGENTINA_REGIONS_TYPE = keyof typeof ARGENTINA_REGIONS;

export const ARMENIA_REGIONS = {
  ARAGATSOTN: 'Aragatsotn',
  ARARAT: 'Ararat',
  ARMAVIR: 'Armavir',
  GEGHARKUNIK: 'Gegharkunik',
  KOTAYK: 'Kotayk',
  LORI: 'Lori',
  SHIRAK: 'Shirak',
  SYUNIK: 'Syunik',
  TAVUSH: 'Tavush',
  VAYOTS_DZOR: 'Vayots Dzor',
  YEREVAN: 'Yerevan'
} as const;
export type ARMENIA_REGIONS_TYPE = keyof typeof ARMENIA_REGIONS;

export const AUSTRALIA_REGIONS = {
  NEW_SOUTH_WALES: 'New South Wales',
  VICTORIA: 'Victoria',
  QUEENSLAND: 'Queensland',
  WESTERN_AUSTRALIA: 'Western Australia',
  SOUTH_AUSTRALIA: 'South Australia',
  TASMANIA: 'Tasmania',
  AUSTRALIAN_CAPITAL_TERRITORY: 'Australian Capital Territory',
  NORTHERN_TERRITORY: 'Northern Territory',
  JERVIS_BAY_TERRITORY: 'Jervis Bay Territory',
  NORFOLK_ISLAND: 'Norfolk Island',
  CHRISTMAS_ISLAND: 'Christmas Island',
  COCOS_KEELING_ISLANDS: 'Cocos (Keeling) Islands',
  AUSTRALIAN_ANTARCTIC_TERRITORY: 'Australian Antarctic Territory',
  CORAL_SEA_ISLANDS: 'Coral Sea Islands',
  ASHMORE_AND_CARTIER_ISLANDS: 'Ashmore and Cartier Islands',
  HEARD_AND_MCDONALD_ISLANDS: 'Heard and McDonald Islands'
} as const;
export type AUSTRALIA_REGIONS_TYPE = keyof typeof AUSTRALIA_REGIONS;

export const AUSTRIA_REGIONS = {
  BURGENLAND: 'Burgenland',
  CARINTHIA: 'Kärnten/Carinthia',
  LOWER_AUSTRIA: 'Niederösterreich/Lower Austria',
  UPPER_AUSTRIA: 'Oberösterreich/Upper Austria',
  SALZBURG: 'Salzburg',
  STYRIA: 'Steiermark/Styria',
  TYROL: 'Tirol/Tyrol',
  VORARLBERG: 'Vorarlberg',
  VIENNA: 'Wien/Vienna'
} as const;
export type AUSTRIA_REGIONS_TYPE = keyof typeof AUSTRIA_REGIONS;

export const BELGIUM_REGIONS = {
  BRUSSELS_CAPITAL_REGION: 'Brussels-Capital Region',
  ANTWERP: 'Antwerp',
  EAST_FLANDERS: 'East Flanders',
  FLEMISH_BRABANT: 'Flemish Brabant',
  HAINAUT: 'Hainaut',
  LIEGE: 'Liège/Lüttich',
  LIMBURG: 'Limburg',
  LUXEMBOURG: 'Luxembourg',
  NAMUR: 'Namur',
  WALLOON_BRABANT: 'Walloon Brabant',
  WEST_FLANDERS: 'West Flanders'
} as const;
export type BELGIUM_REGIONS_TYPE = keyof typeof BELGIUM_REGIONS;

export const BELIZE_REGIONS = {
  BELIZE: 'Belize',
  CAYO: 'Cayo',
  COROZAL: 'Corozal',
  ORANGE_WALK: 'Orange Walk',
  STANN_CREEK: 'Stann Creek',
  TOLEDO: 'Toledo'
} as const;
export type BELIZE_REGIONS_TYPE = keyof typeof BELIZE_REGIONS;

export const BENIN_REGIONS = {
  ALIBORI: 'Alibori',
  ATACORA: 'Atacora',
  ATLANTIQUE: 'Atlantique',
  BORGOU: 'Borgou',
  COLLINES: 'Collines',
  COUFFO: 'Couffo',
  DONGA: 'Donga',
  LITTORAL: 'Littoral',
  MONO: 'Mono',
  OUEME: 'Ouémé',
  PLATEAU: 'Plateau',
  ZOU: 'Zou'
} as const;
export type BENIN_REGIONS_TYPE = keyof typeof BENIN_REGIONS;

export const BOLIVIA_REGIONS = {
  BENI: 'Beni',
  CHUQUISACA: 'Chuquisaca',
  COCHABAMBA: 'Cochabamba',
  LA_PAZ: 'La Paz',
  ORURO: 'Oruro',
  PANDO: 'Pando',
  POTOSI: 'Potosí',
  SANTA_CRUZ: 'Santa Cruz',
  TARIJA: 'Tarija'
} as const;
export type BOLIVIA_REGIONS_TYPE = keyof typeof BOLIVIA_REGIONS;

export const BOTSWANA_REGIONS = {
  CENTRAL: 'Central',
  CHOBE: 'Chobe',
  GHANZI: 'Ghanzi',
  KGALAGADI: 'Kgalagadi',
  KGATLENG: 'Kgatleng',
  KWENENG: 'Kwengeng',
  NORTH_EAST: 'North East',
  NORTH_WEST: 'North West',
  SOUTH_EAST: 'South East',
  SOUTHERN: 'Southern'
} as const;
export type BOTSWANA_REGIONS_TYPE = keyof typeof BOTSWANA_REGIONS;

export const BRAZIL_REGIONS = {
  ACRE: 'Acre',
  ALAGOAS: 'Alagoas',
  AMAPA: 'Amapá',
  AMAZONAS: 'Amazonas',
  BAHIA: 'Bahia',
  CEARA: 'Ceará',
  DISTRITO_FEDERAL: 'Distrito Federal',
  ESPIRITO_SANTO: 'Espírito Santo',
  GOIAS: 'Goiás',
  MARANHAO: 'Maranhão',
  MATO_GROSSO: 'Mato Grosso',
  MATO_GROSSO_DO_SUL: 'Mato Grosso do Sul',
  MINAS_GERAIS: 'Minas Gerais',
  PARA: 'Pará',
  PARAIBA: 'Paraíba',
  PARANA: 'Paraná',
  PERNAMBUCO: 'Pernambuco',
  PIAUI: 'Piauí',
  RIO_DE_JANEIRO: 'Rio de Janeiro',
  RIO_GRANDE_DO_NORTE: 'Rio Grande do Norte',
  RIO_GRANDE_DO_SUL: 'Rio Grande do Sul',
  RONDONIA: 'Rondônia',
  RORAIMA: 'Roraima',
  SANTA_CATARINA: 'Santa Catarina',
  SAO_PAULO: 'São Paulo',
  SERGIPE: 'Sergipe',
  TOCANTINS: 'Tocantins'
} as const;
export type BRAZIL_REGIONS_TYPE = keyof typeof BRAZIL_REGIONS;

export const BULGARIA_REGIONS = {
  BLAGOEVGRAD: 'Blagoevgrad',
  BURGAS: 'Burgas',
  DOBRICH: 'Dobrich',
  GABROVO: 'Gabrovo',
  HASKOVO: 'Haskovo',
  KARDZHALI: 'Kardzhali',
  KYUSTENDIL: 'Kyustendil',
  LOVECH: 'Lovech',
  MONTANA: 'Montana',
  PAZARDZHIK: 'Pazardzhik',
  PERNIK: 'Pernik',
  PLEVEN: 'Pleven',
  PLOVDIV: 'Plovdiv',
  RAZGRAD: 'Razgrad',
  RUSE: 'Ruse',
  SHUMEN: 'Shumen',
  SILISTRA: 'Silistra',
  SLIVEN: 'Sliven',
  SMOLYAN: 'Smolyan',
  SOFIA: 'Sofia City Province',
  SOFIA_PROVINCE: 'Sofia Province',
  STARA_ZAGORA: 'Stara Zagora',
  TARGOVISHTE: 'Targovishte',
  VARNA: 'Varna',
  VELIKO_TARNOVO: 'Veliko Tarnovo',
  VIDIN: 'Vidin',
  VRATSA: 'Vratsa',
  YAMBOL: 'Yambol'
} as const;
export type BULGARIA_REGIONS_TYPE = keyof typeof BULGARIA_REGIONS;

export const BURKINA_FASO_REGIONS = {
  BOUCLE_DU_MOUHOUN: 'Boucle du Mouhoun',
  CASCADES: 'Cascades',
  CENTRE: 'Centre',
  CENTRE_EST: 'Centre-Est',
  CENTRE_NORD: 'Centre-Nord',
  CENTRE_OUEST: 'Centre-Ouest',
  CENTRE_SUD: 'Centre-Sud',
  EST: 'Est',
  HAUTS_BASSINS: 'Hauts-Bassins',
  NORD: 'Nord',
  PLATEAU_CENTRAL: 'Plateau-Central',
  SAHEL: 'Sahel',
  SUD_OUEST: 'Sud-Ouest'
} as const;
export type BURKINA_FASO_REGIONS_TYPE =
  keyof typeof BURKINA_FASO_REGIONS;

export const BURUNDI_REGIONS = {
  BUBANZA: 'Bubanza',
  BUJUMBURA_MAIRIE: 'Bujumbura Mairie',
  BUJUMBURA_RURAL: 'Bujumbura Rural',
  BURURI: 'Bururi',
  CANKUZO: 'Cankuzo',
  CIBITOKE: 'Cibitoke',
  GITEGA: 'Gitega',
  KARUZI: 'Karuzi',
  KAYANZA: 'Kayanza',
  KIRUNDO: 'Kirundo',
  MAKAMBA: 'Makamba',
  MURAMVYA: 'Muramvya',
  MUYINGA: 'Muyyinga',
  MWARO: 'Mwaro',
  NGOZI: 'Ngozi',
  RUMONGE: 'Rumonge',
  RUTANA: 'Rutana',
  RUYIGI: 'Ruyigi'
} as const;
export type BURUNDI_REGIONS_TYPE = keyof typeof BURUNDI_REGIONS;

export const CAMEROON_REGIONS = {
  ADAMAOUA: 'Adamaoua',
  CENTRE: 'Centre',
  EAST: 'East/Est',
  FAR_NORTH: 'Far North/Extrême-Nord',
  LITTORAL: 'Littoral',
  NORTH: 'North/Nord',
  NORTH_WEST: 'North-West/Nord-Ouest',
  SOUTH: 'South/Sud',
  SOUTHWEST: 'South-West/Sud-Ouest',
  WEST: 'West/Ouest'
} as const;
export type CAMEROON_REGIONS_TYPE = keyof typeof CAMEROON_REGIONS;

export const CANADA_REGIONS = {
  ALBERTA: 'Alberta',
  BRITISH_COLUMBIA: 'British Columbia',
  MANITOBA: 'Manitoba',
  NEW_BRUNSWICK: 'New Brunswick',
  NEWFOUNDLAND_AND_LABRADOR: 'Newfoundland and Labrador',
  NOVA_SCOTIA: 'Nova Scotia',
  ONTARIO: 'Ontario',
  PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
  QUEBEC: 'Quebec',
  SASKATCHEWAN: 'Saskatchewan',
  NORTHWEST_TERRITORIES: 'Northwest Territories',
  NUNAVUT: 'Nunavut',
  YUKON: 'Yukon'
} as const;
export type CANADA_REGIONS_TYPE = keyof typeof CANADA_REGIONS;

export const CENTRAL_AFRICAN_REPUBLIC_REGIONS = {
  BAMINGUI_BANGORAN: 'Bamingui-Bangoran',
  BANGUI: 'Bangui',
  BASSE_KOTTO: 'Basse-Kotto',
  HAUT_MBOMOU: 'Haut-Mbomou',
  HAUTE_KOTTO: 'Haut-Kotto',
  KEMO: 'Kemo',
  LIM_PENDE: 'Lim-Pendé',
  LOBAYE: 'Lobaye',
  MAMBERE: 'Mambéré',
  MAMBERE_KADEI: 'Mambéré-Kadéï',
  MBOMOU: 'Mbomou',
  NANA_GREBIZI: 'Nana-Grébizi',
  NANA_MAMBERE: 'Nana-Mambere',
  OMBELLA_MPOKO: 'Ombella-Mpoko',
  OUAKA: 'Ouaka',
  OUHAM: 'Ouham',
  OUHAM_FAFA: 'Ouham-Fafa',
  OUHAM_PENDÉ: 'Ouham-Pendé',
  SANGHA_MBAERE: 'Sangah-Mbaéré',
  VAKAGA: 'Vakaga'
} as const;
export type CENTRAL_AFRICAN_REPUBLIC_REGIONS_TYPE =
  keyof typeof CENTRAL_AFRICAN_REPUBLIC_REGIONS;

export const CHAD_REGIONS = {
  BAHR_EL_GAZAL: 'Bahr el Gazal',
  BATHA: 'Batha',
  BORKOU: 'Borkou',
  CHARI_BAGUIRMI: 'Chari-Baguirmi',
  EAST_ENNEDI: 'East Ennedi',
  EAST_MAYO_KEBBI: 'East Mayo-Kebbi',
  EASTERN_LOGONE: 'Eastern Logone',
  GUERA: 'Guéra',
  HADJER_LAMIS: 'Hadjer Lamis',
  KANEM: 'Kanem',
  LAKE: 'Lake',
  MANDOUL: 'Mandoul',
  MIDDLE_CHARI: 'Middle Chari',
  N_DJAMENA: "N'Djamena City",
  SALAMAT: 'Salamat',
  SILA: 'Sila',
  TANDJILE: 'Tandjilé',
  TIBESTI: 'Tibesti',
  WADAI: 'Waddai',
  WADI_FIRA: 'Wadi Fira',
  WEST_ENNEDI: 'West Ennedi',
  WEST_MAYO_KEBBI: 'West Mayo-Kebbi',
  WESTERN_LOGONE: 'Western Logone'
} as const;
export type CHAD_REGIONS_TYPE = keyof typeof CHAD_REGIONS;

export const CHILE_REGIONS = {
  ANTOFAGASTA: 'Antofagasta',
  ARAUCANIA: 'Araucanía',
  ARICA_Y_PARINACOTA: 'Arica y Parinacota',
  ATACAMA: 'Atacama',
  AYSEN: 'Aysén',
  BIOBIO: 'Biobío',
  COQUIMBO: 'Coquimbo',
  LIBERTADOR_GENERAL_BERNARDO_O_HIGGINS:
    "Libertador General Bernardo O'Higgins",
  LOS_LAGOS: 'Los Lagos',
  LOS_RIOS: 'Los Ríos',
  MAGALLANES: 'Magallanes y Antártica Chilena',
  MAULE: 'Maule',
  NUBLE: 'Ñuble',
  SANTIAGO_METROPOLITAN: 'Santiago Metropolitan',
  TARAPACA: 'Tarapacá',
  VALPARAISO: 'Valparaíso'
} as const;
export type CHILE_REGIONS_TYPE = keyof typeof CHILE_REGIONS;

export const CHINA_REGIONS = {
  ANHUI: 'Anhui',
  BEIJING: 'Beijing',
  CHONGQING: 'Chongqing',
  FUJIAN: 'Fujian',
  GANSU: 'Gansu',
  GUANGDONG: 'Guangdong',
  GUANGXI: 'Guangxi',
  GUIZHOU: 'Guizhou',
  HAINAN: 'Hainan',
  HEBEI: 'Hebei',
  HEILONGJIANG: 'Heilongjiang',
  HENAN: 'Henan',
  HONG_KONG: 'Hong Kong',
  HUBEI: 'Hubei',
  HUNAN: 'Hunan',
  INNER_MONGOLIA: 'Inner Mongolia/Nei Menggu',
  JIANGSU: 'Jiangsu',
  JIANGXI: 'Jiangxi',
  JILIN: 'Jilin',
  LIAONING: 'Liaoning',
  MACAO: 'Macao/Aomen',
  NINGXIA: 'Ningxia',
  QINGHAI: 'Qinghai',
  SHAANXI: 'Shaanxi',
  SHANDONG: 'Shandong',
  SHANGHAI: 'Shanghai',
  SHANXI: 'Shanxi',
  SICHUAN: 'Sichuan',
  TIANJIN: 'Tianjin',
  TIBET: 'Tibet/Xizang',
  XINJIANG: 'Xinjiang',
  YUNNAN: 'Yunnan',
  ZHEJIANG: 'Zhejiang'
} as const;
export type CHINA_REGIONS_TYPE = keyof typeof CHINA_REGIONS;

export const COLOMBIA_REGIONS = {
  AMAZONIA: 'Amazonia',
  ANTIOQUIA: 'Antioquia',
  ARAUCA: 'Arauca',
  ATLANTICO: 'Atlántico',
  BOGOTA: 'Bogotá Capital District',
  BOLIVAR: 'Bolívar',
  BOYACA: 'Boyacá',
  CALDAS: 'Caldas',
  CAQUETA: 'Caquetá',
  CASANARE: 'Casanare',
  CAUCA: 'Cauca',
  CESAR: 'Cesar',
  CHOCO: 'Choco',
  CORDOBA: 'Córdoba',
  CUNDINAMARCA: 'Cundinamarca',
  GUAINIA: 'Guainía',
  GUAVIARE: 'Guaviare',
  HUILA: 'Huila',
  LA_GUAJIRA: 'La Guajira',
  MAGDALENA: 'Magdalena',
  META: 'Meta',
  NARINO: 'Nariño',
  NORTH_SANTANDER: 'North Santander',
  PUTUMAYO: 'Putumayo',
  QUINDIO: 'Quindío',
  RISARALDA: 'Risaralda',
  SAN_ANDRES: 'San Andrés Province',
  SANTANDER: 'Santander',
  SUCRE: 'Sucre',
  TOLIMA: 'Tolima',
  CAUCA_VALLEY: 'Cauca Valley',
  VAUPES: 'Vaupés',
  VICHADA: 'Vichada'
} as const;
export type COLOMBIA_REGIONS_TYPE = keyof typeof COLOMBIA_REGIONS;

export const COSTA_RICA_REGIONS = {
  ALAJUELA: 'Alajuela',
  CARTAGO: 'Cartago',
  GUANACASTE: 'Guanacaste',
  HEREDIA: 'Heredia',
  LIMON: 'Limón',
  PUNTARENAS: 'Puntarenas',
  SAN_JOSE: 'San José'
} as const;
export type COSTA_RICA_REGIONS_TYPE = keyof typeof COSTA_RICA_REGIONS;

export const COTE_D_IVOIRE_REGIONS = {
  ABIDJAN: 'Abidjan',
  BANDAMA_VALLEY: 'Bandama Valley',
  COMOE: 'Comoe',
  DENGUELE: 'Denguele',
  GOH_DJIBOUA: 'Goh-Djiboua',
  LAGOONS: 'Lagoons',
  LAVES: 'Laves',
  LOWER_SASSANDRA: 'Lower Sassandra',
  MOUNTAINS: 'Mountains',
  SASSANDRA_MARAHOUE: 'Sassandra-Marahoue',
  SAVANNAHS: 'Savannahs',
  WOROBA: 'Woroba',
  YAMOUSSOUKRO: 'Yamoussoukro',
  ZANZAN: 'Zanzan'
} as const;
export type COTE_D_IVOIRE_REGIONS_TYPE =
  keyof typeof COTE_D_IVOIRE_REGIONS;

export const CYPRUS_REGIONS = {
  FAMAGUSTA: 'Famagusta/Αμμόχωστος/Mağusa',
  KYRENIA: 'Kyrenia/Κερύvεια/Girne',
  LARNACA: 'Larnaca/Λάρνακα/Larnaka',
  LIMASSOL: 'Limassol/Λεμεσός/Leymasun',
  NICOSIA: 'Nicosia/Λευκωσία/Lefkoşa',
  PAPHOS: 'Paphos/Πάφος/Baf'
} as const;
export type CYPRUS_REGIONS_TYPE = keyof typeof CYPRUS_REGIONS;

export const CZECHIA_REGIONS = {
  CENTRAL_BOHEMIA: 'Central Bohemia/Středočeský kraj',
  HRADEC_KRALOVE: 'Hradec Králové/Královéhradecký kraj',
  KARLOVY_VARY: 'Karlovy Vary/Karlovarský kraj',
  LIBEREC: 'Liberec/Liberecký kraj',
  MORAVIA_SILESIA: 'Moravia-Silesia/Moravskoslezský kraj',
  OLOMOUC: 'Olomouc/Olomoucký kraj',
  PARDUBICE: 'Pardubice/Pardubický kraj',
  PLZEŇ: 'Plzeň/Plzeňský kraj',
  PRAGUE: 'Prague/Praha',
  SOUTH_BOHEMIA: 'South Bohemia/Jihočeský kraj',
  SOUTH_MORAVIA: 'South Moravia/Jihomoravský kraj',
  USTI_NAD_LABEM: 'Ústí nad Labem/Ústecký kraj',
  VYSOCINA: 'Vysočina/Kraj Vysočina',
  ZLIN: 'Zlín/Zlínský kraj'
} as const;
export type CZECHIA_REGIONS_TYPE = keyof typeof CZECHIA_REGIONS;

export const DEMOCRATIC_REPUBLIC_OF_CONGO_REGIONS = {
  CENTRAL_KASAI: 'Central Kasai/Kasaï Central',
  CENTRAL_KONGO: 'Central Kongo',
  EASTERN_KASAI: 'Eastern Kasai/Kasaï-Oriental',
  EQUATOR: 'Equator/Équateur',
  ITURI: 'Ituri',
  KASAI: 'Kasai',
  KINSHASA: 'Kinshasa',
  KWANGO: 'Kwango',
  KWILU: 'Kwilu',
  LOMAMI: 'Lomami',
  LOWER_UELE: 'Lower Uele/Bas-Uélé',
  LUALABA: 'Lualaba',
  MAI_NDOMBE: 'Mai-Ndombe',
  MANIEMA: 'Maniema',
  MONGALA: 'Mongala',
  NORTH_KIVU: 'North Kivu',
  NORTH_UBANGI: 'North Ubangi',
  SANKURU: 'Sankuru',
  SOUTH_KIVU: 'South Kivu',
  SOUTH_UBANGI: 'South Ubangi',
  TANGANYIKA: 'Tanganyika',
  TSHOPO: 'Tshopo',
  TSHUAPA: 'Tshuapa',
  UPPER_KATANGA: 'Upper Katanga/Haut-Katanga',
  UPPER_LOMAMI: 'Upper Lomami/Haut-Lomami',
  UPPER_UELE: 'Upper Uele/Haut-Uélé'
} as const;
export type DEMOCRATIC_REPUBLIC_OF_CONGO_REGIONS_TYPE =
  keyof typeof DEMOCRATIC_REPUBLIC_OF_CONGO_REGIONS;

export const REPUBLIC_OF_CONGO_REGIONS = {
  BLACK_POINT: 'Black Point',
  BOUENZA: 'Bouenza',
  BRAZZAVILLE: 'Brazzaville',
  CUVETTE: 'Cuvette',
  KOUILU: 'Kouilou',
  LEKOUMOU: 'Lekoumou',
  LIKOUALA: 'Likouala',
  NIARI: 'Niari',
  PLATEAUX: 'Plateaux',
  POOL: 'Pool',
  SANGHA: 'Sangha',
  WEST_CUVETTE: 'West Cuvette'
} as const;
export type REPUBLIC_OF_CONGO_REGIONS_TYPE =
  keyof typeof REPUBLIC_OF_CONGO_REGIONS;

export const DENMARK_REGIONS = {
  CAPITAL_REGION: 'Capital Region/Hovedstaden',
  CENTRAL_DENMARK: 'Central Denmark/Midtjylland',
  NORTH_DENMARK: 'North Denmark/Nordjylland',
  SOUTHERN_DENMARK: 'Southern Denmark/Syddanmark',
  ZEALAND: 'Zealand/Sjælland'
} as const;
export type DENMARK_REGIONS_TYPE = keyof typeof DENMARK_REGIONS;

export const ECUADOR_REGIONS = {
  AZUAY: 'Azuay',
  BOLIVAR: 'Bolívar',
  CANAR: 'Cañar',
  CARCHI: 'Carchi',
  CHIMBORAZO: 'Chimborazo',
  COTOPAXI: 'Cotopaxi',
  THE_GOLD: 'The Gold/El Oro',
  ESMERALDAS: 'Esmeraldas',
  GALAPAGOS: 'Galápagos',
  GUAYAS: 'Guayas',
  IMBABURA: 'Imbabura',
  LOJA: 'Loja',
  THE_RIVERS: 'The Rivers/Los Ríos',
  MANABI: 'Manabí',
  MORONA_SANTIAGO: 'Morona Santiago',
  NAPO: 'Napa',
  ORELLANA: 'Orellana',
  PASTAZA: 'Pastaza',
  PICHINCHA: 'Pichincha',
  SAINT_HELEN: 'Saint Helen/Santa Elena',
  SAINT_DOMINIC: 'Saint Dominic/Santa Domingo de los Tsáchilas',
  SUCUMBIOS: 'Sucumbíos',
  TUNGURAHUA: 'Tungurahua',
  ZAMORA_CHINCHIPE: 'Zamora Chinchipe'
} as const;
export type ECUADOR_REGIONS_TYPE = keyof typeof ECUADOR_REGIONS;

export const ESTONIA_REGIONS = {
  HARJU: 'Harju/Harjumaa',
  HIIU: 'Hiiu/Hiiumaa',
  IDA_VIRU: 'Ida-Viru/Ida-Virumaa',
  JARVA: 'Järva/Järvamaa',
  JOGEVA: 'Jõgeva/Jõgevamaa',
  LAANE: 'Lääne/Läänemaa',
  LAANE_VIRU: 'Lääne-Viru/Lääne-Virumaa',
  PARNU: 'Pärnu/Pärnumaa',
  POLVA: 'Põlva/Põlvamaa',
  RAPLA: 'Rapla/Raplamaa',
  SAARE: 'Saare/Saaremaa',
  TARTU: 'Tartu/Tartumaa',
  VALGA: 'Valga/Valgamaa',
  VILJANDI: 'Viljandi/Viljandimaa',
  VORU: 'Võru/Võrumaa'
} as const;
export type ESTONIA_REGIONS_TYPE = keyof typeof ESTONIA_REGIONS;

export const FINLAND_REGIONS = {
  ALAND: 'Åland/Ahvenanmaa',
  CENTRAL_FINLAND: 'Central Finland/Keski-Suomi',
  CENTRAL_OSTROBOTHNIA: 'Central Ostrobothnia/Keski-Pohjanmaa',
  KAINUU: 'Kainuu',
  KYMENLAAKSO: 'Kymenlaakso',
  LAPLAND: 'Lapland/Lappi',
  NORTH_KARELIA: 'North Karelia/Pohjois-Karjala',
  NORTHERN_OSTROBOTHNIA: 'Northern Ostrobothnia/Pohjois-Pohjanmaa',
  NORTHERN_SAVONIA: 'Northern Savonia/Pohjois-Savo',
  OSTROBOTHNIA: 'Ostrobothnia/Pohjanmaa',
  PAIJANNE_TAVASTIA: 'Päijänne Tavastia/Päijät-Häme',
  PIRKANMAA: 'Pirkanmaa',
  SATAKUNTA: 'Satakunta',
  SOUTH_KARELIA: 'South Karelia/Etelä-Karjala',
  SOUTHERN_OSTROBOTHNIA: 'Southern Ostrobothnia/Etelä-Pohjanmaa',
  SOUTHERN_SAVONIA: 'Southern Savonia/Etelä-Savo',
  SOUTHWEST_FINLAND: 'Southwest Finland/Varsinais-Suomi',
  TAVASTIA_PROPER: 'Tavastia Proper/Kanta-Häme',
  UUSIMAA: 'Uusimaa'
} as const;
export type FINLAND_REGIONS_TYPE = keyof typeof FINLAND_REGIONS;

export const FRANCE_REGIONS = {
  AUVERGNE_RHONE_ALPS: 'Auvergne-Rhône-Alpes',
  BRITTANY: 'Brittany/Bretagne',
  BURGUNDY_FREE_COUNTY:
    'Burgundy-Free County/Bourgogne-Franche-Comté',
  CENTER_LOIRE_VALLEY: 'Centre-Loire Valley/Centre-Val de Loire',
  CORSICA: 'Corsica/Corse',
  GREAT_EAST: 'Great East/Grand Est',
  LOIRE_COUNTY: 'Loire County/Pays-de-la-Loire',
  NEW_ACQUITANIA: 'New Aquitaine/Nouvelle-Aquitaine',
  NORMANDY: 'Normandy/Normandie',
  OCCITANIA: 'Occitania/Occitanie',
  PARIS: 'Paris/Île-de-France',
  PROVENCE_ALPS_FRENCH_RIVIERA:
    'Provence-Alpes-French Riviera/Provence-Alpes-Côte-d’Azur',
  UPPER_FRANCE: 'Upper France/Hauts-de-France'
} as const;
export type FRANCE_REGIONS_TYPE = keyof typeof FRANCE_REGIONS;

export const GABON_REGIONS = {
  ESTUARY: 'Estuary/Estuaire',
  MARITIME_OGOOU: 'Maritime Ogooué/Ogooué-Maritime',
  MIDDLE_OGOOU: 'Middle Ogooué/Moyen-Ogooué',
  NGOUNIE: 'Ngounié',
  NYANGA: 'Nyanga',
  OGOOUE_IVINDO: 'Ogooué-Ivindo',
  OGOOUE_LOLO: 'Ogooué-Lolo',
  UPPER_OGOOU: 'Upper Ogooué/Haut-Ogooué',
  WOLEU_NTEM: 'Woleu-Ntem'
} as const;
export type GABON_REGIONS_TYPE = keyof typeof GABON_REGIONS;

export const GERMANY_REGIONS = {
  BADEN_WURTTTEMBERG: 'Baden-Württemberg',
  BAVARIA: 'Bavaria/Bayern',
  BERLIN: 'Berlin',
  BRANDENBURG: 'Brandenburg',
  BREMEN: 'Bremen',
  HAMBURG: 'Hamburg',
  HESSE: 'Hesse/Hessen',
  MECKLENBURG_WESTERN_POMERANIA:
    'Mecklenburg-Western Pomerania/Mecklenburg-Vorpommern',
  LOWER_SAXONY: 'Lower Saxony/Niedersachsen',
  NORTH_RHINE_WESTPHALIA:
    'North Rhine-Westphalia/Nordrhein-Westfalen',
  RHINELAND_PFALZ: 'Rheinland-Pfalz',
  SAARLAND: 'Saarland',
  SACHSEN: 'Saxony/Sachsen',
  SACHSEN_ANHALT: 'Saxony-Anhalt/Sachsen-Anhalt',
  SCHLESWIG_HOLSTEIN: 'Schleswig-Holstein',
  THURINGIA: 'Thuringia/Thüringen'
} as const;
export type GERMANY_REGIONS_TYPE = keyof typeof GERMANY_REGIONS;

export const GUATEMALA_REGIONS = {
  CHIMALTENANGO: 'Chimaltenango',
  CHIQUIMULA: 'Chiquimula',
  EL_PROGRESO: 'El Progreso',
  ESCUINTLA: 'Escuintla',
  GUATEMALA: 'Guatemala',
  HUEHUETENANGO: 'Huehuetenango',
  IZABAL: 'Izabal',
  JALAPA: 'Jalapa',
  JUTIAPA: 'Jutiapa',
  LOWER_VERAPAZ: 'Lower Verapaz/Baja Verapaz',
  PETEN: 'Petén',
  QUETZALTENANGO: 'Quetzaltenango',
  QUICHE: 'Quiché',
  RETALHULEU: 'Retalhuleu',
  SACATEPEQUEZ: 'Sacatepéquez',
  SAINT_MARK: 'Saint Mark/San Marcos',
  SAINT_ROSE: 'Saint Rose/Santa Rosa',
  SOLOLA: 'Solola',
  SUCHITEPEQUEZ: 'Suchitepéquez',
  TOTALAPAN: 'Totonicapán',
  UPPER_VERAPAZ: 'Upper Verapaz/Alto Verapaz',
  ZACAPA: 'Zacapa'
} as const;
export type GUATEMALA_REGIONS_TYPE = keyof typeof GUATEMALA_REGIONS;

export const HONDURAS_REGIONS = {
  ATLANTIDA: 'Atlántida',
  CHOLUTECA: 'Choluteca',
  COLON: 'Colón',
  COMAYAGUA: 'Comayagua',
  COPAN: 'Copán',
  CORTES: 'Cortés',
  EL_PARAISO: 'El Paraíso',
  FRANCISCO_MORAZAN: 'Francisco Morazán',
  GRACIAS_A_DIOS: 'Gracias a Dios',
  INTIBUCA: 'Intibuca',
  ISLAS_DE_LA_BAHIA: 'Islas de la Bahía',
  LA_PAZ: 'La Paz',
  LEMPIRA: 'Lempira',
  OLANCHO: 'Olancho',
  SANTA_BARBARA: 'Santa Bárbara',
  VALLE: 'Valle',
  YORO: 'Yoro'
} as const;
export type HONDURAS_REGIONS_TYPE = keyof typeof HONDURAS_REGIONS;

export const ICELAND_REGIONS = {
  CAPITAL_REGION: 'Capital Region/Höfuðborgarsvæði',
  EASTERN_REGION: 'Eastern Region/Austurland',
  NORTHEASTERN_REGION: 'Northeastern Region/Norðurland eystra',
  NORTHWESTERN_REGION: 'Northwestern Region/Norðurland vestra',
  SOUTHERN_PENINSULA: 'Southern Peninsula/Suðurnes',
  SOUTHERN_REGION: 'Southern Region/Suðurland',
  WESTERN_REGION: 'Western Region/Vesturland',
  WESTFJORDS: 'Westfjords/Vestfirðir'
} as const;
export type ICELAND_REGIONS_TYPE = keyof typeof ICELAND_REGIONS;

export const INDIA_REGIONS = {
  ANDAMAN_AND_NICOBAR_ISLANDS: 'Andaman and Nicobar Islands',
  ANDHRA_PRADESH: 'Andhra Pradesh',
  ARUNACHAL_PRADESH: 'Arunachal Pradesh',
  ASSAM: 'Assam',
  BIHAR: 'Bihar',
  CHANDIGARH: 'Chandigarh',
  CHHATTISGARH: 'Chhattisgarh',
  DADRA_NAGAR_HAVELI_DAMAN_DIU:
    'Dadra and Nagar Haveli and Daman and Diu',
  DELHI: 'Delhi',
  GOA: 'Goa',
  GUJARAT: 'Gujarat',
  HARYANA: 'Haryana',
  HIMACHAL_PRADESH: 'Himachal Pradesh',
  JAMMU_AND_KASHMIR: 'Jammu and Kashmir',
  JHARKHAND: 'Jharkhand',
  KARNATAKA: 'Karnataka',
  KERALA: 'Kerala',
  LADAKH: 'Ladakh',
  LAKSHADWEEP: 'Lakshadweep',
  MADHYA_PRADESH: 'Madhya Pradesh',
  MAHARASHTRA: 'Maharashtra',
  MANIPUR: 'Manipur',
  MEGHALAYA: 'Meghalaya',
  MIZORAM: 'Mizoram',
  NAGALAND: 'Nagaland',
  ODISHA: 'Odisha',
  PUDUCHERRY: 'Puducherry/Pondicherry',
  PUNJAB: 'Punjab',
  RAJASTHAN: 'Rajasthan',
  SIKKIM: 'Sikkim',
  TAMIL_NADU: 'Tamil Nadu',
  TELANGANA: 'Telangana',
  TRIPURA: 'Tripura',
  UTTAR_PRADESH: 'Uttar Pradesh',
  UTTARAKHAND: 'Uttarakhand',
  WEST_BENGAL: 'West Bengal'
} as const;
export type INDIA_REGIONS_TYPE = keyof typeof INDIA_REGIONS;

export const INDONESIA_REGIONS = {
  ACEH: 'Aceh',
  BALI: 'Bali',
  BANGKA_BELITUNG: 'Bangka Belitung',
  BANTEN: 'Banten',
  BENGKULU: 'Bengkulu',
  CENTRAL_JAVA: 'Central Java/Jawa Tengah',
  CENTRAL_KALIMANTAN: 'Central Kalimantan/Kalimantan Tengah',
  CENTRAL_PAPUA: 'Central Papua/Papua Tengah',
  CENTRAL_SULAWESI: 'Central Sulawesi/Sulawesi Tengah',
  EAST_JAVA: 'East Java/Jawa Timur',
  EAST_KALIMANTAN: 'East Kalimantan/Kalimantan Timur',
  EAST_NUSA_TENGGARA: 'East Nusa Tenggara/Nusa Tenggara Timur',
  GORONTALO: 'Gorontalo',
  HIGHLANDS_PAPUA: 'Highlands Papua/Papua Pegunungan',
  JAKARTA: 'Jakarta',
  JAMBI: 'Jambi',
  LAMPUNG: 'Lampung',
  MALUKU: 'Maluku',
  NORTH_KALIMANTAN: 'North Kalimantan/Kalimantan Utara',
  NORTH_MALUKU: 'North Maluku/Maluku Utara',
  NORTH_SULAWESI: 'North Sulawesi/Sulawesi Utara',
  NORTH_SUMATRA: 'North Sumatra/Sumatera Utara',
  PAPUA: 'Papua',
  RIAU: 'Riau',
  RIAU_ISLANDS: 'Riau Islands',
  SOUTH_KALIMANTAN: 'South Kalimantan/Kalimantan Selatan',
  SOUTH_PAPUA: 'South Papua/Papua Selatan',
  SOUTH_SULAWESI: 'South Sulawesi/Sulawesi Selatan',
  SOUTH_SUMATRA: 'South Sumatra/Sumatera Selatan',
  SOUTHEAST_SULAWESI: 'Southeast Sulawesi/Sulawesi Tenggara',
  SOUTHWEST_PAPUA: 'Southwest Papua/Papua Barat',
  WEST_JAVA: 'West Java/Jawa Barat',
  WEST_KALIMANTAN: 'West Kalimantan/Kalimantan Barat',
  WEST_PAPUA: 'West Papua/Papua Barat',
  WEST_NUSA_TENGGARA: 'West Nusa Tenggara/Nusa Tenggara Barat',
  WEST_SULAWESI: 'West Sulawesi/Sulawesi Barat',
  WEST_SUMATRA: 'West Sumatra/Sumatera Barat',
  YOGYAKARTA: 'Yogyakarta'
} as const;
export type INDONESIA_REGIONS_TYPE = keyof typeof INDONESIA_REGIONS;

export const IRELAND_REGIONS = {
  CONNACHT: 'Connacht',
  LEINSTER: 'Leinster',
  MUNSTER: 'Munster',
  ULSTER: 'Ulster'
} as const;
export type IRELAND_REGIONS_TYPE = keyof typeof IRELAND_REGIONS;

export const ISRAEL_REGIONS = {
  CENTRAL: 'Central/מחוז המרכז',
  HAIFA: 'Haifa/מחוז חיפה',
  JERUSALEM: 'Jerusalem/מחוז ירושלים',
  NORTHERN: 'Northern/מחוז הצפון',
  SOUTHERN: 'Southern/מחוז הדרום',
  TEL_AVIV: 'Tel Aviv/מחוז תל אביב',
  JUDEA_AND_SAMARIA: 'Judea and Samaria'
} as const;
export type ISRAEL_REGIONS_TYPE = keyof typeof ISRAEL_REGIONS;

export const ITALY_REGIONS = {
  ABRUZZO: 'Abruzzo',
  AOSTA: 'Aosta Valley',
  APULIA: 'Apulia/Puglia',
  BASILICATA: 'Basilicata',
  CALABRIA: 'Calabria',
  CAMPANIA: 'Campania',
  EMILIA_ROMAGNA: 'Emilia-Romagna',
  FRIULI_VENEZIA_GIULIA: 'Friuli-Venezia Giulia',
  LAZIO: 'Lazio',
  LIGURIA: 'Liguria',
  LOMBARDIA: 'Lombardy',
  MARCHE: 'Marche',
  MOLISE: 'Molise',
  PIEDMONT: 'Piedmont',
  SARDINIA: 'Sardinia',
  SICILY: 'Sicily',
  TRENTINO_SOUTH_TYROL: 'Trentino-South Tyrol',
  TUSCANY: 'Tuscany',
  UMBRIA: 'Umbria',
  VENETO: 'Veneto'
} as const;
export type ITALY_REGIONS_TYPE = keyof typeof ITALY_REGIONS;

export const JAPAN_REGIONS = {
  AICHI: 'Aichi',
  AKITA: 'Akita',
  AOMORI: 'Aomori',
  CHIBA: 'Chiba',
  EHIME: 'Ehime',
  FUKUI: 'Fukui',
  FUKUOKA: 'Fukuoka',
  FUKUSHIMA: 'Fukushima',
  GIFU: 'Gifu',
  GUNMA: 'Gunma',
  HIROSHIMA: 'Hiroshima',
  HOKKAIDO: 'Hokkaido',
  HYOGO: 'Hyogo',
  IBARAKI: 'Ibaraki',
  ISHIKAWA: 'Ishikawa',
  IWATE: 'Iwate',
  KAGAWA: 'Kagawa',
  KAGOSHIMA: 'Kagoshima',
  KANAGAWA: 'Kanagawa',
  KOCHI: 'Kochi',
  KUMAMOTO: 'Kumamoto',
  KYOTO: 'Kyoto',
  MIE: 'Mie',
  MIYAGI: 'Miyagi',
  MIYAZAKI: 'Miyazaki',
  NAGANO: 'Nagano',
  NAGASAKI: 'Nagasaki',
  NARA: 'Nara',
  NIIGATA: 'Niigata',
  OITA: 'Oita',
  OKAYAMA: 'Okayama',
  OKINAWA: 'Okinawa',
  OSAKA: 'Osaka',
  SAGA: 'Saga',
  SAITAMA: 'Saitama',
  SHIGA: 'Shiga',
  SHIMANE: 'Shimane',
  SHIZUOKA: 'Shizuoka',
  TOCHIGI: 'Tochigi',
  TOKUSHIMA: 'Tokushima',
  TOKYO: 'Tokyo',
  TOTTORI: 'Tottori',
  TOYAMA: 'Toyama',
  WAKAYAMA: 'Wakayama',
  YAMAGATA: 'Yamagata',
  YAMAGUCHI: 'Yamaguchi',
  YAMANASHI: 'Yamanashi'
} as const;
export type JAPAN_REGIONS_TYPE = keyof typeof JAPAN_REGIONS;

export const KENYA_REGIONS = {
  BARINGO: 'Baringo',
  BOMET: 'Bomet',
  BUKOMA: 'Bukoma',
  BUNGOMA: 'Bungoma',
  BUSIA: 'Busia',
  ELGEYO_MARAKWET: 'Elgeyo/Marakwet',
  EMBU: 'Embu',
  GARISSA: 'Garissa',
  HOMA_BAY: 'Homa Bay',
  ISIOLO: 'Isiolo',
  KAJIADO: 'Kajiado',
  KAKAMEGA: 'Kakamega',
  KERICHO: 'Kericho',
  KIAMBU: 'Kiambu',
  KILIFI: 'Kilifi',
  KIRINYAGA: 'Kirinyaga',
  KISII: 'Kisii',
  KISUMU: 'Kisumu',
  KITUI: 'Kitui',
  KWANZA: 'Kwanza',
  KWALE: 'Kwale',
  LAIKIPIA: 'Laikipia',
  LAMU: 'Lamu',
  MACHAKOS: 'Makakos',
  MAKUENI: 'Makueni',
  MANDERA: 'Mandera',
  MARSABIT: 'Marsabit',
  MERU: 'Meru',
  MIGORI: 'Migori',
  MOMBASA: 'Mombasa',
  MURANG_A: "Murang'a",
  NAIROBI: 'Nairobi City',
  NAKURU: 'Nakuru',
  NANDI: 'Nandi',
  NAROK: 'Narok',
  NYAMIRA: 'Nyamira',
  NYANDARUA: 'Nyandarua',
  NYERI: 'Nyeri',
  SAMBURU: 'Samburu',
  SIAYA: 'Siaya',
  TAITA_TAVETA: 'Taita/Taveta',
  TANA_RIVER: 'Tana River',
  THARAKA_NITHI: 'Tharaka-Nithi',
  TRANS_NZOIA: 'Trans Nzoia',
  TURKANA: 'Turkana',
  UASIN_GISHU: 'Uasin Gishu',
  VIHIGA: 'Vihiga',
  WAJIR: 'Wajir',
  WEST_POKOT: 'West Pokot'
} as const;
export type KENYA_REGIONS_TYPE = keyof typeof KENYA_REGIONS;

export const KUWAIT_REGIONS = {
  AHMADI: 'Ahmadi/الأحمدي',
  FARWANIYA: 'Farwaniya/الفروانية',
  JAHRA: 'Jahra/الجهراء',
  CAPITAL: 'Kuwait City/العاصمة',
  HAWALLI: 'Hawalli/حولي',
  MUBARAK_AL_KABEER: 'Mubarak Al-Kabeer/مبارك الكبير'
} as const;
export type KUWAIT_REGIONS_TYPE = keyof typeof KUWAIT_REGIONS;

export const LATVIA_REGIONS = {
  AIZKRAUKLE: 'Aizkraukle',
  ALUKSNE: 'Alūksne',
  AUGSDA: 'Augšdaugava',
  ADAZI: 'Ādaži',
  BALVI: 'Balvi',
  BAUSKA: 'Bauska',
  CESIS: 'Cēsis',
  DAUGAVPILS: 'Daugavpils',
  DIENVIDKURZEME: 'Dienvidkurzeme',
  DOBELE: 'Dobele',
  GULBENE: 'Gulbene',
  JELGAVA: 'Jelgava',
  JELGAVA_CITY: 'Jelgava City',
  JEKABPILS: 'Jēkabpils',
  JURMALA: 'Jūrmala',
  KRASLAVA: 'Krāslava',
  KULDIGAS: 'Kuldīga',
  KEKAVA: 'Ķekava',
  LIEPAJA: 'Liepāja',
  LIMBAZI: 'Limbaži',
  LIVANI: 'Līvani',
  LUDZA: 'Ludza',
  MADONA: 'Madona',
  MARUPE: 'Mārupe',
  OGRE: 'Ogre',
  OLAINE: 'Olaine',
  PREILI: 'Preiļi',
  REZEKNE: 'Rēzekne',
  REZEKNE_CITY: 'Rēzekne City',
  RIGA: 'Rīga',
  ROPAZI: 'Ropaži',
  SALASPILS: 'Salaspils',
  SALDUS: 'Saldus',
  SAULKRSTI: 'Saulkrasti',
  SIGULDA: 'Sigulda',
  SMILTENE: 'Smiltene',
  TALSI: 'Talsi',
  TUKUMS: 'Tukums',
  VALKA: 'Valka',
  VALMIERA: 'Valmiera',
  VARAKLANI: 'Varakļāni',
  VENTSPILS: 'Ventspils',
  VENTSPILS_CITY: 'Ventspils City'
} as const;
export type LATVIA_REGIONS_TYPE = keyof typeof LATVIA_REGIONS;

export const LIBYA_REGIONS = {
  BUTNAN: 'Butnan/Al Buţnān/البطنان',
  GREEN_MOUNTAIN: 'Green Mountain/Al Jabal al Akhḑar/الجبل الأخضر',
  WESTERN_MOUNTAIN:
    'Western Mountain/Al Jabal al Gharbī/الجبل الغربي',
  JAFARA: 'Jafara/Al Jafārah/الجفارة',
  JUFRA: 'Jufra/Al Jufrah/الجفرة',
  KUFRA: 'Kufra/Al Kufrah/الكفرة',
  MEADOWS: 'Meadows/Al Marj/المرج',
  MURQUB: 'Murqub/Al Marqab/المرقب',
  OASES: 'Oases/Al Wāḩāt/الواحات',
  NUQAT_AL_KHAMS: 'Nuqat Al Khams/An Nuqāţ al Khams/النقاط الخمس',
  ZAWIYA: 'Zawiya/Az Zāwiyah/الزاوية',
  BENGHAZI: 'Bengazi/Banghāzī/بنغازي',
  DERNA: 'Derna/Darnah/درنة',
  GHAT: 'Ghat/Ghāt/غات',
  MISRATA: 'Misrata/Mişrātah/مصراته',
  MURZUQ: 'Murzuq/Murzuq/مرزق',
  NALUT: 'Nalut/Nālūt/نالوت',
  SABHA: 'Sabha/Sabhā/سبها',
  SIRTE: 'Sirte/Surt/سرت',
  TRIPOLI: 'Tripoli/Ţarābulus/طرابلس',
  WADI_AL_HAYAA: 'Wadi Al Hayaa/Wādī al Ḩayāt/وادي الحياة',
  WADI_ASH_SHATI: 'Wadi Ash Shati/Wādī ash Shāţi/وادي الشاطئ'
} as const;
export type LIBYA_REGIONS_TYPE = keyof typeof LIBYA_REGIONS;

export const LIECHTENSTEIN_REGIONS = {
  BALZERS: 'Balzers',
  ESCHEN: 'Eschen',
  GAMPRIN: 'Gamprin',
  MAUREN: 'Mauren',
  PLANKEN: 'Planken',
  RUGGELL: 'Ruggell',
  SCHAAN: 'Schaan',
  SCHELLENBERG: 'Schellenberg',
  TRIESEN: 'Triesen',
  TRIESENBERG: 'Triesenberg',
  VADUZ: 'Vaduz'
} as const;
export type LIECHTENSTEIN_REGIONS_TYPE =
  keyof typeof LIECHTENSTEIN_REGIONS;

export const LITHUANIA_REGIONS = {
  ALYTUS: 'Alytus',
  KAUNAS: 'Kaunas',
  KLAIPEDA: 'Klaipeda',
  MARIJAMPOLE: 'Marijampolė',
  PANEVEZYS: 'Panevėžys',
  SIAULIAI: 'Šiauliai',
  TAURAGE: 'Tauragė',
  TELSIAI: 'Telšiai',
  UTENA: 'Utena',
  VILNIUS: 'Vilnius'
} as const;
export type LITHUANIA_REGIONS_TYPE = keyof typeof LITHUANIA_REGIONS;

export const LUXEMBOURG_REGIONS = {
  CAPELLEN: 'Capellen',
  CLERF: 'Clerf/Clervaux',
  DIEKIRCH: 'Diekirch',
  ECHTERNACH: 'Echternach/Iechternach',
  ESCH_AN_DER_ALZETTE:
    'Esh an der Alzette/Esch-sur-Alzette/Esch-Uelzecht',
  GREVENMACHER: 'Grevenmacher',
  LUXEMBURG: 'Luxemburg/Lëtzebuerg',
  MERSCH: 'Mersch',
  REDINGEN: 'Redingen/Redange/Réiden-Atert',
  REMICH: 'Remich',
  VIANDEN: 'Vianden',
  WILTZ: 'Wiltz/Wolz'
} as const;
export type LUXEMBOURG_REGIONS_TYPE = keyof typeof LUXEMBOURG_REGIONS;

export const MADAGASCAR_REGIONS = {
  ALAOTRA_MANGORO: 'Alaotra Mangoro',
  AMORONI_MANIA: 'Amoroni Mania',
  ANALAMANGA: 'Analamanga',
  ANALANJIROFO: 'Analanjirofo',
  ANDROY: 'Androy',
  ANOSY: 'Anosy',
  ATSIMO_ANDREFANA: 'Atsimo-Andrefana',
  ATSIMO_ATSINANANA: 'Atsimo-Atsinanana',
  ATSINANANA: 'Atsinanana',
  BETSIBOKA: 'Betsiboka',
  BOENY: 'Boeny',
  BONGOLAVA: 'Bongolava',
  DIANA: 'Diana',
  HAUTE_MATSIATRA: 'Haute Matsiatra',
  IHOROMBE: 'Ihorombe',
  ITASY: 'Itasy',
  MELAKY: 'Melaky',
  MENABE: 'Menabe',
  SAVA: 'Sava',
  SOFIA: 'Sofia',
  VAKINANKARATRA: 'Vakinankaratra',
  VATOVAVY_FITOVINANY: 'Vatovavy Fitovinany'
} as const;
export type MADAGASCAR_REGIONS_TYPE = keyof typeof MADAGASCAR_REGIONS;

export const MALAYSIA_REGIONS = {
  JOHOR: 'Johor',
  KEDAH: 'Kedah',
  KELANTAN: 'Kelantan',
  MELAKA: 'Melaka',
  NEGERI_SEMBILAN: 'Negeri Sembilan',
  PAHANG: 'Pahang',
  PERAK: 'Perak',
  PERLIS: 'Perlis',
  PULAU_PINANG: 'Pulau Pinang',
  SABAH: 'Sabah',
  SARAWAK: 'Sarawak',
  SELANGOR: 'Selangor',
  TERENGGANU: 'Terengganu',
  WILAYAH_PERSEKUTUAN_KUALA_LUMPUR:
    'Wilayah Persekutuan Kuala Lumpur',
  WILAYAH_PERSEKUTUAN_LABUAN: 'Wilayah Persekutuan Labuan',
  WILAYAH_PERSEKUTUAN_PUTRAJAYA: 'Wilayah Persekutuan Putrajaya'
} as const;
export type MALAYSIA_REGIONS_TYPE = keyof typeof MALAYSIA_REGIONS;

export const MALI_REGIONS = {
  BAMAKO: 'Bamako',
  GAO: 'Gao',
  KAYES: 'Kayes',
  KIDAL: 'Kidal',
  KOULIKORO: 'Koulikoro',
  MENAKA: 'Menaka',
  MOPTI: 'Mopti',
  SEGOU: 'Segou',
  SIKASSO: 'Sikasso',
  TAOUDENNIT: 'Taoudénit',
  TOMBOUCTOU: 'Tombouctou'
} as const;
export type MALI_REGIONS_TYPE = keyof typeof MALI_REGIONS;

export const MALTA_REGIONS = {
  EASTERN: 'Eastern',
  GOZO: 'Gozo',
  NORTHERN: 'Northern',
  PORT: 'Port',
  SOUTHERN: 'Southern',
  WESTERN: 'Western'
} as const;
export type MALTA_REGIONS_TYPE = keyof typeof MALTA_REGIONS;

export const MAURITANIA_REGIONS = {
  ADRAR: 'Adrar/أدرار',
  ASSABA: 'Assaba/لعصابة',
  BRAKNA: 'Brakna/لبراكنة',
  DAKHLET_NOUADHIBOU: 'Dakhlet Nouadhibou/داخلة نواذيبو',
  GORGOL: 'Gorgol/كوركول',
  GUIDIMAKA: 'Guidimaka/غيديماغا',
  NODH_ECH_CHARGUI: 'Nodh Ech Chargui/الحوض الشرقي',
  NODH_EL_GHARBI: 'Nodh El Gharbi/الحوض الغربي',
  INCHIRI: 'Inchiri/إينشيري',
  NOUAKCHOTT_NORD: 'Nouakchott Nord/نواكشوط الشمالية',
  NOUAKCHOTT_OUEST: 'Nouakchott Ouest/نواكشوط الغربية',
  NOUAKCHOTT_SUD: 'Nouakchott Sud/نواكشوط الجنوبية',
  TAGANT: 'Tagant/تكانت',
  TIRIS_ZEMMOUR: 'Tiris Zemmour/تيرس زمور',
  TRARZA: 'Trarza/الترارزه'
} as const;
export type MAURITANIA_REGIONS_TYPE = keyof typeof MAURITANIA_REGIONS;

export const MEXICO_REGIONS = {
  AGUASCALIENTES: 'Aguascalientes',
  BAJA_CALIFORNIA: 'Baja California',
  BAJA_CALIFORNIA_SUR: 'Baja California Sur',
  CAMPECHE: 'Campeche',
  CHIAPAS: 'Chiapas',
  CHIHUAHUA: 'Chihuahua',
  COAHUILA: 'Coahuila',
  COLIMA: 'Colima',
  DURANGO: 'Durango',
  GUANAJUATO: 'Guanajuato',
  GUERRERO: 'Guerrero',
  HIDALGO: 'Hidalgo',
  JALISCO: 'Jalisco',
  MEXICO_CITY: 'México City',
  MICHOACAN: 'Michoacán',
  MORELOS: 'Morelos',
  NAYARIT: 'Nayarit',
  NUEVO_LEON: 'Nuevo León',
  OAXACA: 'Oaxaca',
  PUEBLA: 'Puebla',
  QUERETARO: 'Querétaro',
  QUINTANA_ROO: 'Quintana Roo',
  SAN_LUIS_POTOSI: 'San Luis Potosí',
  SINALOA: 'Sinaloa',
  SONORA: 'Sonora',
  TABASCO: 'Tabasco',
  TAMAULIPAS: 'Tamaulipas',
  TLAXCALA: 'Tlaxcala',
  VERACRUZ: 'Veracruz',
  YUCATAN: 'Yucatán',
  ZACATECAS: 'Zacatecas'
} as const;
export type MEXICO_REGIONS_TYPE = keyof typeof MEXICO_REGIONS;

export const MOZAMBIQUE_REGIONS = {
  CABO_DELGADO: 'Cabo Delgado',
  GAZA: 'Gaza',
  INHAMBANE: 'Inhambane',
  MANICA: 'Manica',
  MAPUTO: 'Maputo City',
  NAMPULA: 'Nampula',
  NIASSA: 'Niaissa',
  SOFALA: 'Sofala',
  TETE: 'Tete',
  ZAMBEZIA: 'Zambezia'
} as const;
export type MOZAMBIQUE_REGIONS_TYPE = keyof typeof MOZAMBIQUE_REGIONS;

export const NAMIBIA_REGIONS = {
  ERONGO: 'Erongo',
  HARDAP: 'Hardap',
  KARAS: 'Karas',
  KAWANGO_EAST: 'Kawango East',
  KAWANGO_WEST: 'Kawango West',
  KHOMAS: 'Khomas',
  KUNENE: 'Kunene',
  OHANGWENA: 'Ohangwena',
  OMAHEKE: 'Omaheke',
  OMUSATI: 'Omusati',
  OSHANA: 'Oshana',
  OSHIKOTO: 'Oshikoto',
  OTJOZONDJUPA: 'Otjozondjupa',
  ZAMBESI: 'Zambezi'
} as const;
export type NAMIBIA_REGIONS_TYPE = keyof typeof NAMIBIA_REGIONS;

export const NETHERLANDS_REGIONS = {
  DRENTH: 'Drenthe',
  FLEVOLAND: 'Flevoland',
  FRIESLAND: 'Friesland',
  GELDERLAND: 'Gelderland',
  GRONINGEN: 'Groningen',
  LIMBURG: 'Limburg',
  NORTH_BRABANT: 'North Brabant',
  NORTH_HOLLAND: 'North Holland',
  OVERIJSSEL: 'Overijssel',
  SOUTH_HOLLAND: 'South Holland',
  UTRECHT: 'Utrecht',
  ZEELAND: 'Zeeland'
} as const;
export type NETHERLANDS_REGIONS_TYPE =
  keyof typeof NETHERLANDS_REGIONS;

export const NEW_ZEALAND_REGIONS = {
  AUCKLAND: 'Auckland',
  BAY_OF_PLENTY: 'Bay of Plenty',
  CANTERBURY: 'Canterbury',
  CHATHAM_ISLANDS: 'Chatham Islands',
  GISBORNE: 'Gisborne',
  HAWKES_BAY: "Hawke's Bay",
  MANAWATU_WANGANUI: 'Manawatu-Wanganui',
  MARLBOROUGH: 'Marlborough',
  NELSON: 'Nelson',
  NORTHLAND: 'Northland',
  OTAGO: 'Otago',
  SOUTHLAND: 'Southland',
  TARANAKI: 'Taranaki',
  TASMAN: 'Tasman',
  WAIKATO: 'Waikato',
  WELLINGTON: 'Wellington',
  WEST_COAST: 'West Coast'
} as const;
export type NEW_ZEALAND_REGIONS_TYPE =
  keyof typeof NEW_ZEALAND_REGIONS;

export const NICARAGUA_REGIONS = {
  BOACO: 'Boaco',
  CARAZO: 'Carazo',
  CHINANDEGA: 'Chinandega',
  CHONTALES: 'Chontales',
  ESTELI: 'Esteli',
  GRANADA: 'Granada',
  JINOTEGA: 'Jinotega',
  LEON: 'Leon',
  MADRIZ: 'Madriz',
  MANAGUA: 'Managua',
  MASAYA: 'Masaya',
  MATAGALPA: 'Matagalpa',
  NORTH_CARIBBEAN_REGION: 'North Caribbean Region',
  NUEVA_SEGOVIA: 'Nueva Segovia',
  RIVAS: 'Rivas',
  RIO_SAN_JUAN: 'Rio San Juan',
  SOUTH_CARIBBEAN_REGION: 'South Caribbean Region'
} as const;
export type NICARAGUA_REGIONS_TYPE = keyof typeof NICARAGUA_REGIONS;

export const NIGER_REGIONS = {
  AGADEZ: 'Agadez',
  DIFFA: 'Diffa',
  DOSSO: 'Dosso',
  MARADI: 'Maradi',
  NIAMEY: 'Niamey',
  TAHOUA: 'Tahoua',
  TILLABERI: 'Tillaberi',
  ZINDER: 'Zinder'
} as const;
export type NIGER_REGIONS_TYPE = keyof typeof NIGER_REGIONS;

export const NIGERIA_REGIONS = {
  ABIA: 'Abia',
  ABUJA: 'Abuja',
  ADAMAWA: 'Adamawa',
  AKWA_IBOM: 'Akwa Ibom',
  ANAMBRA: 'Anambra',
  BAUCHI: 'Bauchi',
  BAYELSA: 'Bayelsa',
  BENUE: 'Benue',
  BORNO: 'Borno',
  CROSS_RIVER: 'Cross River',
  DELTA: 'Delta',
  EBONYI: 'Ebonyi',
  EDO: 'Edo',
  EKITI: 'Ekiti',
  ENUGU: 'Enugu',
  GOMBE: 'Gombe',
  IMO: 'Imo',
  JIGAWA: 'Jigawa',
  KADUNA: 'Kaduna',
  KANO: 'Kano',
  KATSINA: 'Katsina',
  KEBBI: 'Kebbi',
  KOGI: 'Kogi',
  KWARA: 'Kwara',
  LAGOS: 'Lagos',
  NASARAWA: 'Nasarawa',
  NIGER: 'Niger',
  OGUN: 'Ogun',
  ONDO: 'Ondo',
  OSUN: 'Osun',
  OYO: 'Oyo',
  PLATEAU: 'Plateau',
  RIVERS: 'Rivers',
  SOKOTO: 'Sokoto',
  TARABA: 'Taraba',
  YOBE: 'Yobe',
  ZAMFARA: 'Zamfara'
} as const;
export type NIGERIA_REGIONS_TYPE = keyof typeof NIGERIA_REGIONS;

export const NORWAY_REGIONS = {
  AGDER: 'Agder',
  INLAND: 'Inland/Innlandet',
  JAN_MAYEN: 'Jan Mayen',
  MORE_AND_ROMSDAL: 'More and Romsdal',
  NORTHLAND: 'Northland',
  OSLO: 'Oslo',
  ROGALAND: 'Rogaland',
  SVALBARD: 'Svalbard',
  TROMS_AND_FINNMARK: 'Troms and Finnmark',
  TRONDELAG: 'Trondelag',
  VESTFOLD_AND_TELEMARK: 'Vestfold and Telemark',
  WESTLAND: 'Westland',
  VIKEN: 'Viken'
} as const;
export type NORWAY_REGIONS_TYPE = keyof typeof NORWAY_REGIONS;

export const PANAMA_REGIONS = {
  BOCAS_DEL_TORO: 'Bocas del Toro',
  CHIRIQUI: 'Chiriquí',
  COCLE: 'Cocle',
  COLON: 'Colón',
  DARIEN: 'Darién',
  EMBERA: 'Embera',
  GUNA_YALA: 'Guna Yala/Kuna Yala',
  HERRERA: 'Herrera',
  LOS_SANTOS: 'Los Santos',
  NASO_TJER_DI: 'Naso Tjër Di',
  NGABE_BUGLE: 'Ngäbe-Bugle',
  PANAMA: 'Panama',
  VERAGUAS: 'Veraguas',
  WEST_PANAMA: 'West Panama'
} as const;
export type PANAMA_REGIONS_TYPE = keyof typeof PANAMA_REGIONS;

export const PARAGUAY_REGIONS = {
  AMAMBAY: 'Amambay',
  ASUNCION: 'Asunción',
  BOQUERON: 'Boquerón',
  CAAGUAZU: 'Caaguazú',
  CAAAZAPA: 'Caazapá',
  CANINDEYU: 'Canindeyú',
  CENTRAL: 'Central',
  CONCEPCION: 'Concepción',
  CORDILLERA: 'Cordillera',
  GUAIRA: 'Guaíra',
  ITAPUA: 'Itapúa',
  MISIONES: 'Misiones',
  NEEMBUCU: 'Neembucu',
  PARAGUARI: 'Paraguarí',
  PRESIDENT_HAYES: 'President Hayes',
  SAINT_PETER: 'Saint Peter/San Pedro',
  UPPER_PARAGUAY: 'Upper Paraguay/Alto Paraguay',
  UPPER_PARANA: 'Upper Paraná/Alto Paraná'
} as const;
export type PARAGUAY_REGIONS_TYPE = keyof typeof PARAGUAY_REGIONS;

export const PERU_REGIONS = {
  AMAZONAS: 'Amazonas',
  ANCASH: 'Ancash',
  APURIMAC: 'Apurimac',
  AREQUIPA: 'Arequipa',
  AYACUCHO: 'Ayacucho',
  CAJAMARCA: 'Cajamarca',
  CALLAO: 'Callao',
  CUSCO: 'Cusco',
  HUANCAVELICA: 'Huancavelica',
  ICA: 'Ica',
  JUNIN: 'Junin',
  LA_LIBERTAD: 'La Libertad',
  LAMBAYEQUE: 'Lambayeque',
  LIMA: 'Lima',
  LORETO: 'Loreto',
  MADRE_DE_DIOS: 'Madre de Dios',
  MOQUEGUA: 'Moquegua',
  MUNICIPALIDAD_METROPOLITANA_DE_LIMA:
    'Municipalidad Metropolitana de Lima',
  PASCO: 'Pasco',
  PIURA: 'Piura',
  PUNO: 'Puno',
  SAN_MARTIN: 'San Martin',
  TACNA: 'Tacna',
  TUMBES: 'Tumbes',
  UCAYALI: 'Ucayali'
} as const;
export type PERU_REGIONS_TYPE = keyof typeof PERU_REGIONS;

export const PHILIPPINES_REGIONS = {
  AUTONOMOUS_REGION_IN_MINDANAO:
    'Autonomous Region in Muslim Mindanao',
  BICOL: 'Bicol',
  CAGAYAN_VALLEY: 'Cagayan Valley',
  CALABARZON: 'Calabarzon',
  CARAGA: 'Caraga',
  CENTRAL_LUZON: 'Central Luzon',
  CENTRAL_VISAYAS: 'Central Visayas',
  CORDILLERA_ADMINISTRATIVE_REGION:
    'Cordillera Administrative Region',
  DAVAO: 'Davao',
  EASTERN_VISAYAS: 'Eastern Visayas',
  MIMAROPA: 'Mimaropa',
  NATIONAL_CAPITAL_REGION: 'National Capital Region',
  NORTHERN_MINDANAO: 'Northern Mindanao',
  SOCCSKSARGEN: 'Soccsksargen',
  WESTERN_MINDANAO: 'Western Mindanao',
  ZAMBOANGA_PENINSULA: 'Zamboanga Peninsula'
} as const;
export type PHILIPPINES_REGIONS_TYPE =
  keyof typeof PHILIPPINES_REGIONS;

export const POLAND_REGIONS = {
  GREATER_POLAND: 'Greater Poland/Wielkopolskie',
  KUYAVIAN_POMERANIAN: 'Kuyavian-Pomeranian/Kujawsko-Pomorskie',
  LESSER_POLAND: 'Lesser Poland/Małopolskie',
  LODZ: 'Lodz/Łódzkie',
  LOWER_SILESIAN: 'Lower Silesian/Dolnośląskie',
  LUBLIN: 'Lublin/Lubelskie',
  LUBUSZ: 'Lubusz/Lubuskie',
  MASOVIAN: 'Masovian/Mazowieckie',
  OPOLE: 'Opole/Opolskie',
  PODLASKIE: 'Podlaskie',
  POMERANIAN: 'Pomeranian/Pomorskie',
  SILESIAN: 'Silesian/Śląskie',
  SUBCARPATHIAN: 'Subcarpathian/Podkarpackie',
  HOLY_CROSS: 'Holy Cross/Świętokrzyskie',
  WARMIAN_MASURIAN: 'Warmian-Masurian/Warmińsko-Mazurskie',
  WEST_POMERANIAN: 'West Pomeranian/Zachodniopomorskie'
} as const;
export type POLAND_REGIONS_TYPE = keyof typeof POLAND_REGIONS;

export const PORTUGAL_REGIONS = {
  AVEIRO: 'Aveiro',
  AZORES: 'Azores/Região Autónoma dos Açores',
  BEJA: 'Beja',
  BRAGA: 'Braga',
  BRAGANCA: 'Bragança',
  CASTELO_BRANCO: 'Castelo Branco/White Castle',
  COIMBRA: 'Coimbra',
  EVORA: 'Évora',
  FARO: 'Faro',
  GUARDA: 'Guarda',
  LEIRIA: 'Leiria',
  LISBON: 'Lisboa/Lisbon',
  PORTALEGRE: 'Portalegre',
  PORTO: 'Porto',
  MADEIRA: 'Madeira/Região Autónoma da Madeira',
  SANTAREM: 'Santarém',
  SETUBAL: 'Setúbal',
  VIANA_DO_CASTELO: 'Viana do Castelo',
  VILA_REAL: 'Vila Real/Royal Town',
  VISEU: 'Viseu'
} as const;
export type PORTUGAL_REGIONS_TYPE = keyof typeof PORTUGAL_REGIONS;

export const ROMANIA_REGIONS = {
  ALBA: 'Alba',
  ARAD: 'Arad',
  ARGES: 'Arges',
  BACAU: 'Bacau',
  BIHOR: 'Bihor',
  BISTRITA_NASAUD: 'Bistrita-Nasaud',
  BOTOSANI: 'Botosani',
  BRASOV: 'Brasov',
  BRAILA: 'Braila',
  BUCHAREST: 'Bucharest/București',
  BUZAU: 'Buzau',
  CARAS_SEVERIN: 'Caras-Severin',
  CALARASI: 'Calarasi',
  CLUJ: 'Cluj',
  CONSTANTA: 'Constanta',
  COVASNA: 'Covasna',
  DOLJ: 'Dolj',
  GALATI: 'Galati',
  GIURGIU: 'Giurgiu',
  GORJ: 'Gorj',
  HARGHITA: 'Harghita',
  HUNEDOARA: 'Hunedoara',
  IALOMITA: 'Ialomita',
  IASI: 'Iasi',
  ILFOV: 'Ilfov',
  MARAMURES: 'Maramures',
  MEHEDINTI: 'Mehedinti',
  MURES: 'Mures',
  NEAMT: 'Neamt',
  OLT: 'Olt',
  PRAHOVA: 'Prahova',
  SATU_MARE: 'Satu Mare',
  SALAJ: 'Salaj',
  SIBIU: 'Sibiu',
  SUCEAVA: 'Suceava',
  TELEORMAN: 'Teleorman',
  TIMIS: 'Timis',
  TULCEA: 'Tulcea',
  VALCEA: 'Valcea',
  VASLUI: 'Vaslui',
  VRANCEA: 'Vrancea'
} as const;
export type ROMANIA_REGIONS_TYPE = keyof typeof ROMANIA_REGIONS;

export const RWANDA_REGIONS = {
  CITY_OF_KIGALI: 'City of Kigali/Umujyi wa Kigali',
  EASTERN: 'Eastern/Iburasirazuba',
  NORTHERN: 'Northern/Amajyaruguru',
  SOUTHERN: 'Southern/Amajyepfo',
  WESTERN: 'Western/Iburengerazuba'
} as const;
export type RWANDA_REGIONS_TYPE = keyof typeof RWANDA_REGIONS;

export const QATAR_REGIONS = {
  DAAYEN: 'Daayen/الضعاين',
  DOHA: 'Doha/الدوحة',
  KHOR: 'Khor/الخور والذخيرة',
  NORTH: 'North/ٱلشَّمَال',
  RAYYAN: 'Rayaan/الريان',
  SHAHANIYA: 'Shahaniya/الشحانية',
  UMM_SALAL: 'Umm Salal/أم صلال',
  WAKRA: 'Wakra/الوكرة'
} as const;
export type QATAR_REGIONS_TYPE = keyof typeof QATAR_REGIONS;

export const SAUDI_ARABIA_REGIONS = {
  ASIR: 'Asir/عَسِيْر',
  BAHA: 'Baha/ٱلْبَاحَة',
  EASTERN: 'Eastern/الشرقية',
  HAIL: 'Hail/حَائِل',
  JIZAN: 'Jizan/جازان',
  JOUF: 'Jouf/الجوف',
  MECCA: 'Mecca/مكة',
  MEDINA: 'Medina/ٱلْمَدِيْنَة ٱلْمُنَوَّرَة',
  NAJRAN: 'Najran/نجران',
  NORTHERN_BORDERS: 'Northern Borders/الحدود الشمالية',
  QASSIM: 'Qassim/القصيم',
  RIYADH: 'Riyadh/الرياض',
  TABUK: 'Tabuk/تَبُوْك'
} as const;
export type SAUDI_ARABIA_REGIONS_TYPE =
  keyof typeof SAUDI_ARABIA_REGIONS;

export const SINGAPORE_REGIONS = {
  CENTRAL: 'Central/新加坡中部/மத்திய சிங்கப்பூர்',
  NORTH_EAST: 'North East/Timur Laut/东北/வடகிழக்கு',
  NORTH_WEST: 'North West/Barat Laut/西北/வடமேற்கு',
  SOUTH_EAST: 'South East/Tenggara/东南/தென்கிழக்கு',
  SOUTH_WEST: 'South West/Barat daya/西南/தென்மேற்கு'
} as const;
export type SINGAPORE_REGIONS_TYPE = keyof typeof SINGAPORE_REGIONS;

export const SLOVAKIA_REGIONS = {
  BANSKA_BYSTRICA: 'Banská Bystrica/Banskobystrický kraj',
  BRATISLAVA: 'Bratislava/Bratislavský kraj',
  KOSICE: 'Košice/Košický kraj',
  NITRA: 'Nitra/Nitriansky kraj',
  PRESOV: 'Prešov/Prešovský kraj',
  TRENCIN: 'Trenčin/Trenčiansky kraj',
  TRNAVA: 'Trnava/Trnavský kraj',
  ZILINA: 'Žilina/Žilinský kraj'
} as const;
export type SLOVAKIA_REGIONS_TYPE = keyof typeof SLOVAKIA_REGIONS;

export const SPAIN_REGIONS = {
  ANDALUSIA: 'Andalusia/Andalucía',
  ARAGON: 'Aragon/Aragón',
  ASTURIAS: 'Asturias/Principado de Asturias',
  BALEARIC_ISLANDS: 'Balearic Islands/Islas Baleares',
  BASQUE_COUNTRY: 'Basque Country/País Vasco',
  CANARY_ISLANDS: 'Canary Islands/Islas Canarias',
  CANTABRIA: 'Cantabria',
  CASTILE_AND_LEON: 'Castile and Leon/Castilla y León',
  CASTILE_LA_MANCHA: 'Castile-La Mancha/Castilla-La Mancha',
  CATALONIA: 'Catalonia/Catalunya',
  CEUTA: 'Ceuta',
  CHARTERED_COMMUNITY_OF_NAVARRA:
    'Charterred Community of Navarra/Comunidad Foral de Navarra',
  COMMUNITY_OF_MADRID: 'Community of Madrid/Comunidad de Madrid',
  EXTREMADURA: 'Extremadura',
  GALICIA: 'Galicia',
  LA_RIOJA: 'La Rioja',
  MELILLA: 'Melilla',
  REGION_OF_MURCIA: 'Region of Murcia/Región de Murcia',
  VALENCIAN_COMMUNITY: 'Valencian Community/Comunidad Valenciana'
} as const;
export type SPAIN_REGIONS_TYPE = keyof typeof SPAIN_REGIONS;

export const SOUTH_AFRICA_REGIONS = {
  EASTERN_CAPE: 'Eastern Cape',
  FREE_STATE: 'Free State',
  GAUTENG: 'Gauteng',
  KWAZULU_NATAL: 'KwaZulu-Natal',
  LIMPOPO: 'Limpopo',
  MPUMALANGA: 'Mpumalanga',
  NORTH_WEST: 'North West',
  NORTHERN_CAPE: 'Northern Cape',
  WESTERN_CAPE: 'Western Cape'
} as const;
export type SOUTH_AFRICA_REGIONS_TYPE =
  keyof typeof SOUTH_AFRICA_REGIONS;

export const SOUTH_KOREA_REGIONS = {
  BUSAN: 'Busan/부산광역시',
  DAEGU: 'Daegu/대구',
  DAEJEON: 'Daejeon/대전',
  GANGWON: 'Gangwon/강원특별자치도',
  GWANGJU: 'Gwangju/광주광역시',
  GYEONGGI: 'Gyeonggi/경기도',
  INCHEON: 'Incheon/인천',
  JEJU: 'Jeju/제주도',
  NORTH_CHUNGCHEON: 'North Chungcheong/Chungbuk/충청북도',
  NORTH_GYEONGSANG: 'North Gyeongsang/Gyeongbuk/경상북도',
  NORTH_JEOLLA: 'North Jeolla/Jeonbuk/전북특별자치도',
  SEJONG: 'Sejong/세종',
  SEOUL: 'Seoul/서울',
  SOUTH_CHUNGCHEON: 'South Chungcheong/Chungnam/충청남도',
  SOUTH_GYEONGSANG: 'South Gyeongsang/Gyeongnam/경상남도',
  SOUTH_JEOLLA: 'South Jeolla/Jeonnam/전라남도',
  ULSAN: 'Ulsan/울산시'
} as const;
export type SOUTH_KOREA_REGIONS_TYPE =
  keyof typeof SOUTH_KOREA_REGIONS;

export const SWEDEN_REGIONS = {
  BLEKINGE: 'Blekinge',
  DALARNAA: 'Dalarna',
  GAVLEBORG: 'Gävleborg',
  GOTLAND: 'Gotland',
  HALLAND: 'Halland',
  JAMTLAND: 'Jämtland',
  JONKOPING: 'Jönköping',
  KALMAR: 'Kalmar',
  KRONOBERG: 'Kronoberg',
  NORRBOTTEN: 'Norrbotten',
  OREBRO: 'Örebro',
  OSTERGOTLAND: 'Östergötland',
  SCANIA: 'Scania/Skåne län',
  SODERMANLAND: 'Södermanland',
  STOCKHOLM: 'Stockholm',
  UPPSALA: 'Uppsala',
  VARMLAND: 'Värmland',
  VASTERBOTTEN: 'Västerbotten',
  VASTMANLAND: 'Västmanland',
  VASTRA_GOTLAND: 'Västra Götaland',
  WESTERN_NORTHLAND: 'Western Northland/Västernorrland'
} as const;
export type SWEDEN_REGIONS_TYPE = keyof typeof SWEDEN_REGIONS;

export const SWITZERLAND_REGIONS = {
  AARGAU: 'Aargau',
  APPENZELL_AUSSERRHODEN: 'Appenzell Ausserrhoden',
  APPENZELL_INNERRHODEN: 'Appenzell Innerrhoden',
  BASEL_LANDSCHAFT: 'Basel-Landschaft',
  BASEL_STADT: 'Basel-Stadt',
  BERN: 'Bern',
  FRIBOURG: 'Fribourg',
  GENEVA: 'Geneva',
  GLARUS: 'Glarus',
  GRAUBUNDEN: 'Graubünden',
  JURA: 'Jura',
  LUCERNE: 'Luzern',
  NEUCHATEL: 'Neuchâtel',
  NIDWALDEN: 'Nidwalden',
  OWALDEN: 'Obwalden',
  SCHAFFHAUSEN: 'Schaffhausen',
  SCHWYZ: 'Schwyz',
  SOLOTHURN: 'Solothurn',
  ST_GALLEN: 'St. Gallen',
  THURGAU: 'Thurgau',
  TICINO: 'Ticino',
  URI: 'Uri',
  VALAIS: 'Valais/Wallis',
  VAUD: 'Vaud',
  ZUG: 'Zug/Zoug',
  ZURICH: 'Zürich'
} as const;
export type SWITZERLAND_REGIONS_TYPE =
  keyof typeof SWITZERLAND_REGIONS;

export const TAIWAN_REGIONS = {
  CHANGHUA: 'Changhua/彰化',
  CHIAYI_CITY: 'Chiayi City/嘉義市',
  CHIAYI_COUNTY: 'Chiayi County/嘉義縣',
  HSINCHU_CITY: 'Hsinchu City/新竹市',
  HSINCHU_COUNTY: 'Hsinchu County/新竹縣',
  HUALIEN: 'Hualien/花蓮縣',
  KAOHSIUNG: 'Kaohsiung/高雄市',
  KEELUNG: 'Keelung/基隆市',
  KINMEN: 'Kinmen/金門縣',
  LIENCHIANG: 'Lienchiang/連江縣',
  MIAOLI: 'Miaoli/苗栗縣',
  NANTOU: 'Nantou/南投縣',
  NEW_TAIPEI: 'New Taipei/新北市',
  PENGHU: 'Peng-hu/澎湖縣',
  PINGTUNG: 'Pingtung/屏東縣',
  TAICHUNG: 'Taichung/台中市',
  TAINAN: 'Tainan/台南市',
  TAIPEI: 'Taipei/台北市',
  TAITUNG: 'Taitung/臺東',
  TAOYUAN: 'Taoyuan/桃園市',
  YILAN: 'Yilan/宜蘭縣',
  YUNLIN: 'Yunlin/雲林縣'
} as const;
export type TAIWAN_REGIONS_TYPE = keyof typeof TAIWAN_REGIONS;

export const TANZANIA_REGIONS = {
  ARUSHA: 'Arusha',
  DAR_ES_SALAAM: 'Dar es Salaam',
  DODOMA: 'Dodoma',
  GEITA: 'Geita',
  IRINGA: 'Iringa',
  KAGERA: 'Kagera',
  KASKAZINI_PEMBA: 'Pemba North/Kaskazini Pemba',
  KASKAZINI_UNGUJA: 'Zanzibar North/Kaskazini Unguja',
  KATAVI: 'Katavi',
  KIGOMA: 'Kigoma',
  KILIMANJARO: 'Kilimanjaro',
  KUSINI_PEMBA: 'Pemba South/Kusini Pemba',
  KUSINI_UNGUJA: 'Zanzibar South/Kusini Unguja',
  LINDI: 'Lindi',
  MANYARA: 'Manyara',
  MARA: 'Mara',
  MBEYA: 'Mbeya',
  MJINI_MAGHARIBI: 'Zanzibar West/Mjini Magharibi',
  MOROGORO: 'Morogoro',
  MTWARA: 'Mtwara',
  MWANZA: 'Mwanza',
  NJOMBE: 'Njombe',
  PWANI: 'Pwani',
  RUKWA: 'Rukwa',
  RUVUMA: 'Ruvuma',
  SHINYANGA: 'Shinyanga',
  SIMIYU: 'Simiyu',
  SINGIDA: 'Singida',
  SONGWE: 'Songwe',
  TABORA: 'Tabora',
  TANGA: 'Tanga'
} as const;
export type TANZANIA_REGIONS_TYPE = keyof typeof TANZANIA_REGIONS;

export const THAILAND_REGIONS = {
  BANGKOK: 'Bangkok/กรุงเทพมหานคร',
  AMNAT_CHAROEN: 'Amnat Charoen/อำนาจเจริญ',
  ANG_THONG: 'Ang Thong/อ่างทอง',
  BUENG_KAN: 'Bueng Kan/บึงกาฬ',
  BURI_RAM: 'Buri Ram/บุรีรัมย์',
  CHACHOENGSAO: 'Chachoengsao/ฉะเชิงเทรา',
  CHAI_NAT: 'Chai Nat/ชัยนาท',
  CHAIYAPHUM: 'Chaiyaphum/ชัยภูมิ',
  CHANTHABURI: 'Chanthaburi/จันทบุรี',
  CHIANG_MAI: 'Chiang Mai/เชียงใหม่',
  CHIANG_RAI: 'Chiang Rai/เชียงราย',
  CHON_BURI: 'Chon Buri/ชลบุรี',
  CHUMPHON: 'Chumphon/ชุมพร',
  KALASIN: 'Kalasin/กาฬสินธุ์',
  KAMPHAENG_PHET: 'Kamphaeng Phet/กำแพงเพชร',
  KANCHANABURI: 'Kanchanaburi/กาญจนบุรี',
  KHON_KAEN: 'Khon Kaen/ขอนแก่น',
  KRABI: 'Krabi/กระบี่',
  LAMPANG: 'Lampang/ลำปาง',
  LAMPHUN: 'Lampun/ลำพูน',
  LOEI: 'Loei/เลย',
  LOP_BURI: 'Lop Buri/ลพบุรี',
  MAE_HONG_SON: 'Mae Hong Son/แม่ฮ่องสอน',
  MAHA_SARAKHAM: 'Maha Sarakham/มหาสารคาม',
  MUKDAHAN: 'Mukdahan/มุกดาหาร',
  NAKHON_NAYOK: 'Nakhon Nayok/นครนายก',
  NAKHON_PATHOM: 'Nakhon Pathom/จังหวัดนครปฐม',
  NAKHON_PHANOM: 'Nakhon Phanom/นครพนม',
  NAKHON_RATCHASIMA: 'Nakhon Ratchasima/นครราชสีมา',
  NAKHON_SAWAN: 'Nakhon Sawan/นครสวรรค์',
  NAKHON_SI_THAMMARAT: 'Nakhon Si Thammarat/จังหวัดนครศรีธรรมราช',
  NAN: 'Nan/น่าน',
  NARATHIWAT: 'Narathiwat/นราธิวาส',
  NONG_BUA_LAM_PHU: 'Nong Bua Lam Phu/หนองบัวลำภู',
  NONG_KHAI: 'Nong Khai/หนองคาย',
  NONTHABURI: 'Nonthaburi/นนทบุรี',
  PATHUM_THANI: 'Pathum Thani/ปทุมธานี',
  PATTANI: 'Pattani/ปัตตานี',
  PHANGNGA: 'Phangnga/พังงา',
  PHATTHALUNG: 'Phatthalung/พัทลุง',
  PHATTHAYA: 'Phatthaya/พัทยา',
  PHAYAO: 'Phayao/พะเยา',
  PHETCHABUN: 'Phetchabun/เพชรบูรณ์',
  PHETCHABURI: 'Phetchaburi/เพชรบุรี',
  PHICHIT: 'Phichit/พิจิตร',
  PHITSANULOK: 'Phitsanulok/พิษณุโลก',
  PHRA_NAKHON_SI_AYUTTHAYA:
    'Phra Nakhon Si Ayutthaya/พระนครศรีอยุธยา',
  PHRAE: 'Phrae/แพร่',
  PHUKET: 'Phuket/ภูเก็ต',
  PRACHIN_BURI: 'Prachin Buri/ปราจีนบุรี',
  PRACHUAP_KHIRI_KHAN: 'Prachuap Khiri Khan/ประจวบคีรีขันธ์',
  RANONG: 'Ranong/ระนอง',
  RATCHABURI: 'Ratchaburi/ราชบุรี',
  RAYONG: 'Rayong/ระยอง',
  ROI_ET: 'Roi Et/ร้อยเอ็ด',
  SA_KAEO: 'Sa Kaeo/สระแก้ว',
  SAKON_NAKHON: 'Sakon Nakhon/สกลนคร',
  SAMUT_PRAKAN: 'Samut Prakan/สมุทรปราการ',
  SAMUT_SAKHON: 'Samut Sakhon/สมุทรสาคร',
  SAMUT_SONGKRAM: 'Samut Songkhram/สมุทรสงคราม',
  SARABURI: 'Saraburi/สระบุรี',
  SATUN: 'Satun/สตูล',
  SI_SA_KET: 'Si Sa Ket/ศรีสะเกษ',
  SING_BURI: 'Sing Buri/สิงห์บุรี',
  SONGKHLA: 'Songkhla/สงขลา',
  SUKHOTHAI: 'Sukhothai/สุโขทัย',
  SUPHAN_BURI: 'Suphan Buri/สุพรรณบุรี',
  SURAT_THANI: 'Surat Thani/สุราษฎร์ธานี',
  SURIN: 'Surin/สุรินทร์',
  TAK: 'Tak/ตาก',
  TRANG: 'Trang/ตรัง',
  TRAT: 'Trat/ตราด',
  UBON_RATCHATHANI: 'Ubon Ratchathani/อุบลราชธานี',
  UDON_THANI: 'Udon Thani/อุดรธานี',
  UTHAI_THANI: 'Uthai Thani/อุทัยธานี',
  UTTARADIT: 'Uttaradit/อุตรดิตถ์',
  YALA: 'Yala/ยะลา',
  YASOTHON: 'Yasothon/ยโสธร'
} as const;
export type THAILAND_REGIONS_TYPE = keyof typeof THAILAND_REGIONS;

export const TURKEY_REGIONS = {
  ADANA: 'Adana',
  ADIYAMAN: 'Adıyaman',
  AFYONKARAHISAR: 'Afyonkarahisar',
  AGRI: 'Ağrı',
  AKSARAY: 'Aksaray',
  AMASYA: 'Amasya',
  ANKARA: 'Ankara',
  ANTALYA: 'Antalya',
  ARDAHAN: 'Ardahan',
  ARTVIN: 'Artvin',
  AYDIN: 'Aydın',
  BALIKESIR: 'Balıkesir',
  BARTIN: 'Bartın',
  BATMAN: 'Batman',
  BAYBURT: 'Bayburt',
  BILECIK: 'Bilecik',
  BINGOL: 'Bingöl',
  BITLIS: 'Bitlis',
  BOLU: 'Bolu',
  BURDUR: 'Burdur',
  BURSA: 'Bursa',
  CANAKKALE: 'Çanakkale',
  CANKIRI: 'Çankırı',
  CORUM: 'Çorum',
  DENIZLI: 'Denizli',
  DIYARBAKIR: 'Diyarbakır',
  DUZCE: 'Düzce',
  EDIRNE: 'Edirne',
  ELAZIG: 'Elazığ',
  ERZINCAN: 'Erzincan',
  ERZURUM: 'Erzurum',
  ESKISEHIR: 'Eskişehir',
  GAZIANTEP: 'Gaziantep',
  GIRESUN: 'Giresun',
  GUMUSHANE: 'Gümüşhane',
  HAKKARI: 'Hakkari',
  HATAY: 'Hatay',
  IGDIR: 'Igdir',
  ISPARTA: 'Isparta',
  ISTANBUL: 'İstanbul',
  IZMIR: 'İzmir',
  KAHRAMANMARAS: 'Kahramanmaraş',
  KARABÜK: 'Karabük',
  KARAMAN: 'Karaman',
  KARS: 'Kars',
  KASTAMONU: 'Kastamonu',
  KAYSERI: 'Kayseri',
  KIRIKKALE: 'Kırıkkale',
  KIRKLARELİ: 'Kırklareli',
  KIRSEHIR: 'Kırşehir',
  KILIS: 'Kilis',
  KOCAELI: 'Kocaeli',
  KONYA: 'Konya',
  KUTAHYA: 'Kütahya',
  MALATYA: 'Malatya',
  MANISA: 'Manisa',
  MARDIN: 'Mardin',
  MERSIN: 'Mersin',
  MUGLA: 'Muğla',
  MUS: 'Muş',
  NEVSEHIR: 'Nevşehir',
  NIGDE: 'Niğde',
  ORDU: 'Ordu',
  OSMANIYE: 'Osmaniye',
  RIZE: 'Rize',
  SAKARYA: 'Sakarya',
  SAMSUN: 'Samsun',
  SIIRT: 'Siirt',
  SINOP: 'Sinop',
  SIVAS: 'Sivas',
  TEKIRDAG: 'Tekirdağ',
  TOKAT: 'Tokat',
  TRABZON: 'Trabzon',
  TUNCELI: 'Tunceli',
  USAK: 'Uşak',
  VAN: 'Van',
  YALOVA: 'Yalova',
  YOZGAT: 'Yozgat',
  ZONGULDAK: 'Zonguldak'
} as const;
export type TURKEY_REGIONS_TYPE = keyof typeof TURKEY_REGIONS;

export const TUNISIA_REGIONS = {
  BEJA: 'Beja/باجة',
  BEN_AROUS: 'Ben Arous/بن عروس',
  BIZERTE: 'Bizerte/بنزرت',
  GABES: 'Gabès/ڨابس',
  GAFSA: 'Gafsa/ڨفصة',
  JENDOUBA: 'Jendouba/جندوبة',
  KAIROUAN: 'Kairouan/قيروان',
  KASSERINE: 'Kasserine/ڨصرين',
  KEBILI: 'Kebili/ڨبلي',
  L_ARIANA: "L'Ariana/أريانة",
  LA_MANOUBA: 'La Manouba/منوبة',
  LE_KEF: 'Le Kef/الكاف',
  MAHDIA: 'Mahdia/المهدية',
  MEDENINE: 'Medenine/مدنين',
  MONASTIR: 'Monastir/المنستير',
  NABEUL: 'Nabeul/نابل',
  SFAX: 'Sfax/صفاقس',
  SIDI_BOUZID: 'Sidi Bouzid/سيدي بوزيد',
  SILIANA: 'Siliana/سليانة',
  SOUSSE: 'Sousse/سوسة',
  TATAOUINE: 'Tataouine/تطاوين',
  TOZEUR: 'Tozeur/توزر',
  TUNIS: 'Tunis/تونس',
  ZAGHOUAN: 'Zaghouan/زغوان'
} as const;
export type TUNISIA_REGIONS_TYPE = keyof typeof TUNISIA_REGIONS;

export const UGANDA_REGIONS = {
  CENTRAL: 'Central',
  EASTERN: 'Eastern',
  NORTHERN: 'Northern',
  WESTERN: 'Western'
} as const;
export type UGANDA_REGIONS_TYPE = keyof typeof UGANDA_REGIONS;

export const UNITED_ARAB_EMIRATES_REGIONS = {
  ABU_DHABI: 'Abu Dhabi/أبو ظبي',
  AJMAN: 'Ajman/عجمانّ',
  DUBAI: 'Dubai/دبي',
  FUJAIRAH: 'Fujairah/الفجيرة',
  RAS_AL_KHAIMAH: 'Ras Al Khaimah/رأس الخيمة',
  SHARJAH: 'Sharjah/الشارقةّ',
  UMM_AL_QUWAIN: 'Umm Al Quwain/أمّ القيوين'
} as const;
export type UNITED_ARAB_EMIRATES_REGIONS_TYPE =
  keyof typeof UNITED_ARAB_EMIRATES_REGIONS;

export const UNITED_KINGDOM_REGIONS = {
  CAMBRIDGESHIRE: 'Cambridgeshire',
  CUMBRIA: 'Cumbria',
  DERBYSHIRE: 'Derbyshire',
  DEVON: 'Devon',
  DORSET: 'Dorset',
  EAST_SUSSEX: 'East Sussex',
  ESSEX: 'Essex',
  GLOUCESTERSHIRE: 'Gloucestershire',
  HAMPSHIRE: 'Hampshire',
  HERTFORDSHIRE: 'Hertfordshire',
  KENT: 'Kent',
  LANCASHIRE: 'Lancashire',
  LEICESTERSHIRE: 'Leicestershire',
  LINCOLNSHIRE: 'Lincolnshire',
  NORFOLK: 'Norfolk',
  NORTH_YORKSHIRE: 'North Yorkshire',
  NOTTINGHAMSHIRE: 'Nottinghamshire',
  OXFORDSHIRE: 'Oxfordshire',
  SOMERSET: 'Somerset',
  STAFFORDSHIRE: 'Staffordshire',
  SUFFOLK: 'Suffolk',
  SURREY: 'Surrey',
  WARWICKSHIRE: 'Warwickshire',
  WEST_SUSSEX: 'West Sussex',
  WORCESTERSHIRE: 'Worcestershire',
  LONDON: 'London',
  ANTRIM_AND_NEWTONABBEY: 'Antrim and Newtownabbey',
  ARDS_AND_NORTH_DOWN: 'Ards and North Down',
  ARMAGH_BANBRIDGE_AND_CRAIGAVON:
    'Armagh City, Banbridge and Craigavon',
  BELFAST: 'Belfast City',
  CAUSEWAY_COAST_AND_GLENS: 'Causeway Coast and Glens',
  DERRY_AND_STRABANE: 'Derry City and Strabane',
  FERMANAGH_AND_OMAGH: 'Fermanagh and Omagh',
  LISBURN_AND_CASTLEREAGH: 'Lisburn and Castlereagh',
  MID_AND_EAST_ANTRIM: 'Mid and East Antrim',
  MID_ULSTER: 'Mid Ulster',
  NEWRY_MOURNE_AND_DOWN: 'Newry, Mourne and Down',
  ABERDEEN_CITY: 'Aberdeen City',
  ABERDEENSHIRE: 'Aberdeenshire',
  ANGUS: 'Angus',
  ARGYLL_AND_BUTE: 'Argyll and Bute',
  CLACKMANNANSHIRE: 'Clackmannanshire',
  DUMFRIES_AND_GALLOWAY: 'Dumfries and Galloway',
  DUNDEE_CITY: 'Dundee City',
  EAST_AYRSHIRE: 'East Ayrshire',
  EAST_DUNBARTONSHIRE: 'East Dunbartonshire',
  EAST_LOTHIAN: 'East Lothian',
  EAST_RENFREWSHIRE: 'East Renfrewshire',
  EDINBURGH: 'Edinburgh City',
  EILEAN_SIAR: 'Eilean Siar',
  FALKIRK: 'Falkirk',
  FIFE: 'Fife',
  GLASGOW_CITY: 'Glasgow City',
  HIGHLAND: 'Highland',
  INVERCLYDE: 'Inverclyde',
  MIDLOTHIAN: 'Midlothian',
  MORAY: 'Moray',
  NORTH_AYRSHIRE: 'North Ayrshire',
  NORTH_LANARKSHIRE: 'North Lanarkshire',
  ORKNEY: 'Orkney Islands',
  PERTH_AND_KINROSS: 'Perth and Kinross',
  RENFREWSHIRE: 'Renfrewshire',
  SCOTTISH_BORDERS: 'Scottish Borders',
  SHETLAND: 'Shetland Islands',
  SOUTH_AYRSHIRE: 'South Ayrshire',
  SOUTH_LANARKSHIRE: 'South Lanarkshire',
  STIRLING: 'Stirling',
  WEST_DUNBARTONSHIRE: 'West Dunbartonshire',
  WEST_LOTHIAN: 'West Lothian',
  BLAENAU_GWENT: 'Blaenau Gwent',
  BRIDGEND: 'Bridgend',
  CAERPHILLY: 'Caerphilly',
  CARDIFF: 'Cardiff',
  CARMARTHENSHIRE: 'Carmarthenshire',
  CEREDIGION: 'Ceredigion',
  CONWY: 'Conwy',
  DENBIGHSHIRE: 'Denbighshire',
  FLINTSHIRE: 'Flintshire',
  GWYNEDD: 'Gwynedd',
  ISLE_OF_ANGLESEY: 'Isle of Anglesey',
  MERTHYR_TYDFIL: 'Merthyr Tydfil',
  MONMOUTHSHIRE: 'Monmouthshire',
  NEATH_PORT_TALBOT: 'Neath Port Talbot',
  NEWPORT: 'Newport',
  PEMBROKESHIRE: 'Pembrokeshire',
  POWYS: 'Powys',
  RHONDDA_CYNON_TAFF: 'Rhondda Cynon Taff',
  SWANSEA: 'Swansea',
  TORFAEN: 'Torfaen',
  VALE_OF_GLAMORGAN: 'Vale of Glamorgan',
  WREXHAM: 'Wrexham'
} as const;
export type UNITED_KINGDOM_REGIONS_TYPE =
  keyof typeof UNITED_KINGDOM_REGIONS;

export const UNITED_STATES_REGIONS = {
  ALABAMA: 'Alabama',
  ALASKA: 'Alaska',
  ARIZONA: 'Arizona',
  ARKANSAS: 'Arkansas',
  CALIFORNIA: 'California',
  COLORADO: 'Colorado',
  CONNECTICUT: 'Connecticut',
  DELAWARE: 'Delaware',
  FLORIDA: 'Florida',
  GEORGIA: 'Georgia',
  HAWAII: 'Hawaii',
  IDAHO: 'Idaho',
  ILLINOIS: 'Illinois',
  INDIANA: 'Indiana',
  IOWA: 'Iowa',
  KANSAS: 'Kansas',
  KENTUCKY: 'Kentucky',
  LOUISIANA: 'Louisiana',
  MAINE: 'Maine',
  MARYLAND: 'Maryland',
  MASSACHUSETTS: 'Massachusetts',
  MICHIGAN: 'Michigan',
  MINNESOTA: 'Minnesota',
  MISSISSIPPI: 'Mississippi',
  MISSOURI: 'Missouri',
  MONTANA: 'Montana',
  NEBRASKA: 'Nebraska',
  NEVADA: 'Nevada',
  NEW_HAMPSHIRE: 'New Hampshire',
  NEW_JERSEY: 'New Jersey',
  NEW_MEXICO: 'New Mexico',
  NEW_YORK: 'New York',
  NORTH_CAROLINA: 'North Carolina',
  NORTH_DAKOTA: 'North Dakota',
  OHIO: 'Ohio',
  OKLAHOMA: 'Oklahoma',
  OREGON: 'Oregon',
  PENNSYLVANIA: 'Pennsylvania',
  RHODE_ISLAND: 'Rhode Island',
  SOUTH_CAROLINA: 'South Carolina',
  SOUTH_DAKOTA: 'South Dakota',
  TENNESSEE: 'Tennessee',
  TEXAS: 'Texas',
  UTAH: 'Utah',
  VERMONT: 'Vermont',
  VIRGINIA: 'Virginia',
  WASHINGTON: 'Washington',
  WEST_VIRGINIA: 'West Virginia',
  WISCONSIN: 'Wisconsin',
  WYOMING: 'Wyoming'
} as const;
export type UNITED_STATES_REGIONS_TYPE =
  keyof typeof UNITED_STATES_REGIONS;

export const URUGUAY_REGIONS = {
  ARTIGAS: 'Artigas',
  CANELONES: 'Canelones',
  CERRO_LARGO: 'Cerro Largo',
  COLONIA: 'Colonia',
  DURAZNO: 'Durazno',
  FLORES: 'Flores',
  FLORIDA: 'Florida',
  LAVALLEJA: 'Lavalleja',
  MALDONADO: 'Maldonado',
  MONTEVIDEO: 'Montevideo',
  PAYSANDU: 'Paysandú',
  RIO_NEGRO: 'Río Negro',
  RIVERA: 'Rivera',
  ROCHA: 'Rocha',
  SALTO: 'Salto',
  SAN_JOSE: 'San José',
  SORIANO: 'Soriano',
  TACUAREMBO: 'Tacuarembó',
  TREINTA_Y_TRES: 'Treinta y Tres'
} as const;
export type URUGUAY_REGIONS_TYPE = keyof typeof URUGUAY_REGIONS;

export const ZAMBIA_REGIONS = {
  CENTRAL: 'Central',
  COPPERBELT: 'Copperbelt',
  EASTERN: 'Eastern',
  LUAPULA: 'Luapula',
  LUSAKA: 'Lusaka',
  MUCHINGA: 'Muchinga',
  NORTH_WESTERN: 'North Western',
  NORTHERN: 'Northern',
  SOUTHERN: 'Southern',
  WESTERN: 'Western'
} as const;
export type ZAMBIA_REGIONS_TYPE = keyof typeof ZAMBIA_REGIONS;

export const ZIMBABWE_REGIONS = {
  BULAWAYO: 'Bulawayo',
  HARARE: 'Harare',
  MANICALAND: 'Manicaland',
  MASHONALAND_CENTRAL: 'Mashonaland Central',
  MASHONALAND_EAST: 'Mashonaland East',
  MASHONALAND_WEST: 'Mashonaland West',
  MASVINGO: 'Masvingo',
  MATABELELAND_NORTH: 'Matabeleland North',
  MATABELELAND_SOUTH: 'Matabeleland South',
  MIDLANDS: 'Midlands'
} as const;
export type ZIMBABWE_REGIONS_TYPE = keyof typeof ZIMBABWE_REGIONS;

export const COUNTRY_REGIONS = {
  AFGHANISTAN: AFGHANISTAN_REGIONS,
  ALBANIA: ALBANIA_REGIONS,
  ALGERIA: ALGERIA_REGIONS,
  ANDORRA: ANDORRA_REGIONS,
  ANGOLA: ANGOLA_REGIONS,
  ANTIGUA_AND_BARBUDA: ANTIGUA_AND_BARBUDA_REGIONS,
  ARGENTINA: ARGENTINA_REGIONS,
  ARMENIA: ARMENIA_REGIONS,
  //   ARUBA: 'Region',
  AUSTRALIA: AUSTRALIA_REGIONS,
  AUSTRIA: AUSTRIA_REGIONS,
  //   AZERBAIJAN: 'Region',
  //   BAHAMAS: 'Parish',
  //   BAHRAIN: 'Governorate',
  //   BANGLADESH: 'Division',
  //   BARBADOS: 'Parish',
  //   BELARUS: 'Region',
  BELGIUM: BELGIUM_REGIONS,
  BELIZE: BELIZE_REGIONS,
  BENIN: BENIN_REGIONS,
  //   BERMUDA: 'Parish',
  //   BHUTAN: 'District',
  BOLIVIA: BOLIVIA_REGIONS,
  //   BONAIRE_SINT_EUSTATIUS_AND_SABA: 'District',
  //   BOSNIA_AND_HERZEGOVINA: 'Canton',
  BOTSWANA: BOTSWANA_REGIONS,
  //   BOUVET_ISLAND: 'Island',
  BRAZIL: BRAZIL_REGIONS,
  //   BRITISH_INDIAN_OCEAN_TERRITORY: 'Island',
  //   BRUNEI: 'District',
  BULGARIA: BULGARIA_REGIONS,
  BURKINA_FASO: BURKINA_FASO_REGIONS,
  BURUNDI: BURUNDI_REGIONS,
  //   CABO_VERDE: 'Province',
  //   CAMBODIA: 'Province',
  CAMEROON: CAMEROON_REGIONS,
  CANADA: CANADA_REGIONS,
  //   CAYMAN_ISLANDS: 'District',
  CENTRAL_AFRICAN_REPUBLIC: CENTRAL_AFRICAN_REPUBLIC_REGIONS,
  CHAD: CHAD_REGIONS,
  CHILE: CHILE_REGIONS,
  CHINA: CHINA_REGIONS,
  COLOMBIA: COLOMBIA_REGIONS,
  COSTA_RICA: COSTA_RICA_REGIONS,
  COTE_D_IVOIRE: COTE_D_IVOIRE_REGIONS,
  CYPRUS: CYPRUS_REGIONS,
  CZECHIA: CZECHIA_REGIONS,
  DEMOCRATIC_REPUBLIC_OF_CONGO: DEMOCRATIC_REPUBLIC_OF_CONGO_REGIONS,
  REPUBLIC_OF_CONGO: REPUBLIC_OF_CONGO_REGIONS,
  DENMARK: DENMARK_REGIONS,
  ECUADOR: ECUADOR_REGIONS,
  ESTONIA: ESTONIA_REGIONS,
  FINLAND: FINLAND_REGIONS,
  FRANCE: FRANCE_REGIONS,
  GABON: GABON_REGIONS,
  GERMANY: GERMANY_REGIONS,
  GUATEMALA: GUATEMALA_REGIONS,
  HONDURAS: HONDURAS_REGIONS,
  ICELAND: ICELAND_REGIONS,
  INDIA: INDIA_REGIONS,
  INDONESIA: INDONESIA_REGIONS,
  IRELAND: IRELAND_REGIONS,
  ISRAEL: ISRAEL_REGIONS,
  ITALY: ITALY_REGIONS,
  JAPAN: JAPAN_REGIONS,
  KENYA: KENYA_REGIONS,
  KUWAIT: KUWAIT_REGIONS,
  LATVIA: LATVIA_REGIONS,
  LIBYA: LIBYA_REGIONS,
  LIECHTENSTEIN: LIECHTENSTEIN_REGIONS,
  LITHUANIA: LITHUANIA_REGIONS,
  LUXEMBOURG: LUXEMBOURG_REGIONS,
  MADAGASCAR: MADAGASCAR_REGIONS,
  MALAYSIA: MALAYSIA_REGIONS,
  MALI: MALI_REGIONS,
  MALTA: MALTA_REGIONS,
  MAURITANIA: MAURITANIA_REGIONS,
  MEXICO: MEXICO_REGIONS,
  MOZAMBIQUE: MOZAMBIQUE_REGIONS,
  NAMIBIA: NAMIBIA_REGIONS,
  NETHERLANDS: NETHERLANDS_REGIONS,
  NEW_ZEALAND: NEW_ZEALAND_REGIONS,
  NICARAGUA: NICARAGUA_REGIONS,
  NIGER: NIGER_REGIONS,
  NIGERIA: NIGERIA_REGIONS,
  NORWAY: NORWAY_REGIONS,
  PANAMA: PANAMA_REGIONS,
  PARAGUAY: PARAGUAY_REGIONS,
  PERU: PERU_REGIONS,
  PHILIPPINES: PHILIPPINES_REGIONS,
  POLAND: POLAND_REGIONS,
  PORTUGAL: PORTUGAL_REGIONS,
  ROMANIA: ROMANIA_REGIONS,
  RWANDA: RWANDA_REGIONS,
  QATAR: QATAR_REGIONS,
  SAUDI_ARABIA: SAUDI_ARABIA_REGIONS,
  SINGAPORE: SINGAPORE_REGIONS,
  SLOVAKIA: SLOVAKIA_REGIONS,
  SPAIN: SPAIN_REGIONS,
  SOUTH_AFRICA: SOUTH_AFRICA_REGIONS,
  SOUTH_KOREA: SOUTH_KOREA_REGIONS,
  SWEDEN: SWEDEN_REGIONS,
  SWITZERLAND: SWITZERLAND_REGIONS,
  TAIWAN: TAIWAN_REGIONS,
  TANZANIA: TANZANIA_REGIONS,
  THAILAND: THAILAND_REGIONS,
  TURKEY: TURKEY_REGIONS,
  TUNISIA: TUNISIA_REGIONS,
  UGANDA: UGANDA_REGIONS,
  UNITED_ARAB_EMIRATES: UNITED_ARAB_EMIRATES_REGIONS,
  UNITED_KINGDOM: UNITED_KINGDOM_REGIONS,
  UNITED_STATES: UNITED_STATES_REGIONS,
  URUGUAY: URUGUAY_REGIONS,
  ZAMBIA: ZAMBIA_REGIONS,
  ZIMBABWE: ZIMBABWE_REGIONS
} as const;
export type COUNTRY_REGIONS_TYPE = typeof COUNTRY_REGIONS;

