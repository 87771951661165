import {
  Tabs,
  TabsList,
  TabsTrigger
} from 'src/components/shad-base/tabs';
import { getListItemKey } from 'src/utils/format';

// ----------------------------------------------------------------------

type OptionType = {
  key: string;
  label: string;
};

export default function MultiSwitch({
  options,
  activeOption,
  setActiveOption,
  disabled = false
}: {
  options: OptionType[];
  activeOption: string;
  setActiveOption: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
}) {
  return (
    <div>
      <Tabs
        value={activeOption}
        defaultValue={activeOption}
        onValueChange={(option) => setActiveOption(option)}
      >
        <TabsList>
          {options.map((option, idx) => {
            return (
              <TabsTrigger
                value={option.key}
                disabled={disabled}
                key={getListItemKey(idx)}
              >
                {option.label}
              </TabsTrigger>
            );
          })}
        </TabsList>
      </Tabs>
    </div>
  );
}
