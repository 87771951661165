import { gql, useQuery } from '@apollo/client';
import {
  StrategyFragmentFragment,
  StrategySingleSelectQueryQuery
} from 'src/__apolloGenerated__/graphql';
import SingleSelect from 'src/components/core/molecules/SingleSelect';

export default function StrategySingleSelect({
  value,
  setValue,
  disabled = false,
  disableClear,
  loading,
  prefixText = 'Strategy: ',
  required = false
}: {
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  disableClear?: boolean;
  loading?: boolean;
  prefixText?: string;
  required?: boolean;
}) {
  const { data: entityData, loading: strategiesLoading } =
    useQuery<StrategySingleSelectQueryQuery>(
      gql`
        query StrategySingleSelectQuery {
          activeEntity {
            ok
            data {
              target {
                strategies {
                  ...StrategyFragment
                }
              }
            }
          }
        }
      `,
      {
        skip: disabled,
        fetchPolicy: 'network-only'
      }
    );
  const strategies = entityData?.activeEntity?.data?.target
    ?.strategies as StrategyFragmentFragment[];
  return (
    <SingleSelect
      value={value}
      disabled={disabled}
      disableClear={disableClear}
      setValue={setValue}
      renderOption={(option) => {
        return <p className="body2">{option.label}</p>;
      }}
      required={required}
      options={strategies?.map((strategy) => ({
        key: strategy.identifier,
        label: strategy.title
      }))}
      loading={loading || strategiesLoading}
      placeholder="Select strategy..."
      commandEmptyText="No strategies found."
      prefixText={prefixText}
    />
  );
}
