// import {
//   FormControl,
//   ListSubheader,
//   Select as MSelect,
//   SelectChangeEvent
// } from '@mui/material';
import { useState } from 'react';
import { getListItemKey } from 'src/utils/format';
import {
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
  Select as ShadSelect
} from 'src/components/shad-base/select';
import { X } from 'lucide-react';
import dayjs from 'dayjs';
// import { useSettingsStore, {
//   SettingsStoreType
// } from 'src/hooks/store/useSettingsStore';
// import { alpha, getPalette } from 'src/theme/palette';
// import typography from 'src/theme/typography';
// import IconButton from './IconButton';

// ----------------------------------------------------------------------

// export default function Select({
//   groups,
//   values,
//   value,
//   label,
//   multiple = false,
//   fullWidth = false,
//   onOpen = null,
//   onChange,
//   onClose = null,
//   variant = 'outlined',
//   // renderInputValue = null,
//   renderMenuItems = null,
//   name,
//   size = 'md',
//   disabled,
//   minWidth = 50,
//   error = false,
//   onClear,
//   style,
//   className
// }: {
//   groups?: {
//     title: string;
//     values: { key: string; title: string }[];
//   }[];
//   values: { [key: string]: string };
//   value?: string | string[]; // key of values
//   label?: string;
//   fullWidth?: boolean;
//   multiple?: boolean;
//   variant?: 'standard' | 'outlined' | 'filled';
//   size?: 'xs' | 'sm' | 'md';
//   onOpen?: (event) => void;
//   onChange: (event: SelectChangeEvent) => void;
//   name?: string;
//   onClose?: () => void;
//   renderInputValue?: (value: string) => React.ReactNode; //render value in input field (accepts key of values)
//   renderMenuItems?: (value: string) => React.ReactNode; // render labels in dropdown menu (accepts value of values)
//   disabled?: boolean;
//   minWidth?: number;
//   error?: boolean;
//   helperText?: string;
//   style?: CSSProperties;
//   onClear?: () => void;
//   className?: string;
// }) {
//   const { themeMode }: SettingsStoreType = useSettingsStore();
//   const palette = getPalette({ themeMode });
//   const [open, setOpen] = useState(false);
//   const groupedItems: ReactNode[] = [];
//   groups?.map((group, grIdx) => {
//     groupedItems.push(
//       <ListSubheader key={getListItemKey(grIdx * 100)}>
//         <p style={{ ...typography.body2 }} color={palette.info.light}>
//           {group.title}
//         </p>
//       </ListSubheader>
//     );
//     group.values.map((valueObj, itemIdx) => {
//       groupedItems.push(
//         <li
//           className="selected:bg-[color:--li-bg-color-selected] selected:hover:bg-[color:--li-bg-color-selected-hover] list-none px-md py-sm hover:cursor-pointer hover:bg-[color:--li-bg-color-hover]"
//           key={getListItemKey(grIdx * 100 + itemIdx + 1)}
//           value={valueObj.key}
//           style={{
//             ['--li-bg-color-selected' as string]: alpha(
//               palette.info.dark,
//               0.4
//             ),
//             ['--li-bg-color-hover' as string]: alpha(
//               palette.info.dark,
//               0.3
//             ),
//             ['--li-bg-color-selected-hover' as string]: alpha(
//               palette.info.dark,
//               0.3
//             )
//           }}
//         >
//           {renderMenuItems ? (
//             renderMenuItems(valueObj.title)
//           ) : (
//             <p style={{ ...typography.body2 }}>{valueObj.title}</p>
//           )}
//         </li>
//       );
//       return valueObj.key;
//     });
//     return group.title;
//   });
//   const anyValues = Object.keys(values || {}).length > 0;

//   return (
//     <div style={{ width: '100%', ...style }}>
//       <FormControl sx={{ width: fullWidth ? '100%' : null }}>
//         <label
//           // shrink
//           color={error ? 'error' : 'primary'}
//           className="relative block overflow-hidden text-ellipsis whitespace-nowrap"
//           style={{
//             fontSize: '16px',
//             transition:
//               'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
//             transform: 'translate(0, -1.5px) scale(0.75)'
//             // color: theme.palette.text.primary,
//           }}
//         >
//           {label}
//         </label>
//         <MSelect
//           {...(value &&
//             onClear && {
//               // When the user has selected a value, the button is displayed, and the select indicator is removed.
//               endAdornment: (
//                 <IconButton
//                   onMouseDown={(event) => {
//                     // stops the popup from appearing when this button is clicked
//                     event.stopPropagation();
//                   }}
//                   onClick={() => {
//                     onClear && onClear();
//                   }}
//                   className="mx-md"
//                   style={{ transform: 'scale(0.8)' }}
//                 >
//                   <X />
//                 </IconButton>
//               ),
//               indicator: null
//             })}
//           value={anyValues ? value : null}
//           multiple={multiple}
//           label={label}
//           error={error}
//           open={open}
//           variant={variant}
//           name={name}
//           onOpen={(e) => {
//             if (onOpen) {
//               onOpen(e);
//             } else {
//               setOpen(true);
//             }
//           }}
//           // text ellipsis inside select component
//           renderValue={(selected) => {
//             return <>{values[selected as string]}</>;
//           }}
//           onClose={() => {
//             setOpen(false);
//             onClose && onClose();
//           }}
//           MenuProps={{
//             sx: {
//               '& .MuiList-root': {
//                 backgroundColor: palette.background.default,
//                 border: 1,
//                 borderColor: palette.border.main,
//                 borderRadius: 1.5
//               }
//             }
//           }}
//           onChange={(event: SelectChangeEvent<string>) => {
//             if (anyValues) {
//               onChange(event);
//             }
//           }}
//           sx={{
//             '& .MuiInputBase-input': {
//               textOverflow: 'ellipsis',
//               maxWidth: '100%',
//               ...typography.body2
//             },
//             '& .MuiInputBase-input.Mui-disabled': {
//               WebkitTextFillColor: '#343436 !important'
//             },
//             '& .MuiSvgIcon-root': {
//               color: disabled ? '#343436 !important' : 'inherit'
//             },
//             minWidth: minWidth,
//             width: '100%',
//             height:
//               size == 'xs' ? '42px' : size == 'sm' ? '50px' : '60px'
//           }}
//           size={size == 'sm' ? 'small' : 'medium'}
//           disabled={disabled || !anyValues}
//           className={className}
//         >
//           {groups
//             ? groupedItems
//             : values
//               ? Object.keys(values)?.map((key, idx) => {
//                   return (
//                     <li
//                       className="selected:bg-[color:--li-bg-color-selected] selected:hover:bg-[color:--li-bg-color-selected-hover] list-none px-md py-sm hover:cursor-pointer hover:bg-[color:--li-bg-color-hover]"
//                       key={getListItemKey(idx)}
//                       value={key}
//                       style={{
//                         ['--li-bg-color-selected' as string]: alpha(
//                           palette.info.dark,
//                           0.4
//                         ),
//                         ['--li-bg-color-hover' as string]: alpha(
//                           palette.info.dark,
//                           0.3
//                         ),
//                         ['--li-bg-color-selected-hover' as string]:
//                           alpha(palette.info.dark, 0.3)
//                       }}
//                     >
//                       {renderMenuItems ? (
//                         renderMenuItems(values[key])
//                       ) : (
//                         <p style={{ ...typography.body2 }}>
//                           {values[key]}
//                         </p>
//                       )}
//                     </li>
//                   );
//                 })
//               : null}
//         </MSelect>
//       </FormControl>
//     </div>
//   );
// }
export default {};

export function SSelect({
  options,
  value,
  placeholder,
  onValueChange,
  label
}: {
  options: {
    key: string;
    label: string;
  }[];
  value: string;
  onValueChange: (newValue) => void;
  placeholder?: string;
  label?: string;
}) {
  // Really dumb solution to a major bug with ShadCN Select component
  // Key is used to force rerender of the component when the value is cleared, otherwise the value is not cleared.
  const [key, setKey] = useState(+dayjs().toDate());
  return (
    <div className="flex flex-col flex-nowrap">
      {label && <p className="caption mb-sm ml-sm">{label}</p>}
      <div className="flex h-9 w-full items-center rounded-md border border-input bg-transparent py-2">
        <ShadSelect
          onValueChange={onValueChange}
          value={value}
          key={key}
        >
          <SelectTrigger className="ml-1">
            <SelectValue placeholder={placeholder || 'Select...'} />
          </SelectTrigger>
          {/* z-[1300] is just enough to make dropdown appear above the Shad Dialog */}
          <SelectContent className="z-[3300]">
            <SelectGroup>
              {label && <SelectLabel>{label}</SelectLabel>}
              {options.map((option, index) => {
                return (
                  <SelectItem
                    key={getListItemKey(index)}
                    value={option.key || null}
                  >
                    {option.label}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
          {value && (
            <X
              onClick={() => {
                onValueChange(null);
                // Force rerender of component to clear value
                setKey(+dayjs().toDate());
              }}
              className="mr-sm scale-75 opacity-80"
            />
          )}
        </ShadSelect>
      </div>
    </div>
  );
}
