import { AssetDetailsType } from 'src/components/carbon/organisms/AssetManager';
import { ItemType } from 'src/components/core/atoms/DropdownMenu';
import EllipsisDropdownMenu from 'src/components/core/molecules/EllipsisDropdownMenu';
import { getListItemKey } from 'src/utils/format';

export default function DetailView<AssetType>({
  activeAssetIdentifier,
  assets,
  actionItems,
  getAssetDetails
}: {
  activeAssetIdentifier: string;
  assets: AssetType[];
  actionItems: ItemType[];
  getAssetDetails(asset: AssetType): AssetDetailsType;
}) {
  const activeAsset = assets.find((asset) => {
    const { identifier } = getAssetDetails(asset);
    return identifier === activeAssetIdentifier;
  });

  const {
    name,
    description,
    secondaryDescription,
    renderIcon,
    detailSections
  } = getAssetDetails(activeAsset);

  return (
    <>
      {/* Title and Descriptions */}
      <div className="flex flex-nowrap ">
        {renderIcon && (
          <div className="mr-lg max-w-[110px] rounded-md border">
            {renderIcon()}
          </div>
        )}
        <div>
          <div className="flex flex-col flex-nowrap">
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center">
                <h6 className="font-bold">{name}</h6>
                {actionItems.length > 0 && (
                  <div className="ml-md">
                    <EllipsisDropdownMenu items={actionItems} />
                  </div>
                )}
              </div>
            </div>
            <p className="mt-sm">{description}</p>
            <p className="text-muted">{secondaryDescription}</p>
          </div>
        </div>
      </div>
      {/* Sections */}
      {detailSections.map((section, idx) => {
        return (
          <div className="mt-xl" key={getListItemKey(idx)}>
            {section.title && (
              <div className="mb-md flex flex-col flex-nowrap">
                <p className="subtitle1 font-bold">{section.title}</p>
              </div>
            )}
            <div>{section.renderContent()}</div>
          </div>
        );
      })}
    </>
  );
}
