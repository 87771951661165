import { useState } from 'react';
import { CiFileOn } from 'react-icons/ci';
import { FaRegFilePdf } from 'react-icons/fa';
import {
  DeleteSupportingDocumentInput,
  DeleteSupportingDocumentPayload,
  EditSupportingDocumentInput,
  EditSupportingDocumentPayload,
  SupportingDocumentFragmentFragment,
  SupportingDocumentParentTypesEnum
} from 'src/__apolloGenerated__/graphql';
import EllipsisDropdownMenu from 'src/components/core/molecules/EllipsisDropdownMenu';
import { Button } from 'src/components/shad-base/button';
import { toast } from 'src/hooks/shad-base/useToast';
import useMeasureStore, {
  MeasureStoreType
} from 'src/hooks/store/useMeasureStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import {
  DELETE_SUPPORTING_DOCUMENT,
  EDIT_SUPPORTING_DOCUMENT
} from 'src/hooks/useBackendMutation/mutations';
import { formatFileSize, getListItemKey } from 'src/utils/format';
import UploadDocumentDialog from '../MeasureView/dialogs/UploadSupportingDocumentDialog';
import { Badge } from 'src/components/shad-base/badge';

export default function SupportingDocumentsSection({
  documents,
  canEdit,
  parentType,
  parentIdentifier,
  refetchDocuments
}: {
  documents: SupportingDocumentFragmentFragment[];
  parentType: SupportingDocumentParentTypesEnum;
  parentIdentifier: string;
  canEdit: boolean;
  refetchDocuments?: () => void;
}) {
  const { selectedStartDate, selectedEndDate, selectedFilters } =
    useMeasureStore((store: MeasureStoreType) => ({
      selectedStartDate: store.selectedStartDate,
      selectedEndDate: store.selectedEndDate,
      selectedFilters: store.selectedFilters
    }));

  const [uploadDocumentDialogOpen, setUploadDocumentDialogOpen] =
    useState(false);

  const { mutate: editSupportingDocument } = useBackendMutation<
    {
      input: EditSupportingDocumentInput;
    },
    EditSupportingDocumentPayload
  >({
    mutation: EDIT_SUPPORTING_DOCUMENT,
    callbacks: {
      onSuccess: (data) => {
        const isPublic = data.supportingDocument.isPublic;
        toast({
          title: 'Success.',
          description:
            'Document has been ' +
            (isPublic ? '' : 'un') +
            'published successfully.',
          variant: 'success'
        });
      },
      onError: () => {
        toast({
          title: 'Success.',
          description: 'Document state could not be changed',
          variant: 'success'
        });
      }
    }
  });

  const { mutate: deleteSupportingDocument } = useBackendMutation<
    {
      input: DeleteSupportingDocumentInput;
    },
    DeleteSupportingDocumentPayload
  >({
    mutation: DELETE_SUPPORTING_DOCUMENT,
    callbacks: {
      onSuccess: () => {
        toast({
          title: 'Success.',
          description: 'Document deleted.',
          variant: 'success'
        });
        refetchDocuments && refetchDocuments();
      },
      onError: () => {
        toast({
          title: 'Error.',
          description: 'Could not delete document.',
          variant: 'destructive'
        });
      }
    }
  });

  const deleteAllFiles = () => {
    documents.map((document: SupportingDocumentFragmentFragment) =>
      deleteSupportingDocument({
        variables: {
          input: {
            documentIdentifier: document?.identifier
          },
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          states: selectedFilters
        }
      })
    );
  };
  const setAllPublic = (isPublic = true) => {
    documents.map((document: SupportingDocumentFragmentFragment) =>
      editSupportingDocument({
        variables: {
          input: {
            documentIdentifier: document?.identifier,
            isPublic: isPublic
          },
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          states: selectedFilters
        },
        optimisticResponse: {
          editSupportingDocument: {
            supportingDocument: {
              identifier: document?.identifier,
              isPublic: isPublic
            }
          }
        }
      })
    );
  };
  const setAllPrivate = () => {
    setAllPublic(false);
  };

  return (
    <div className="grow self-stretch rounded-md border p-lg">
      <div className="">
        <div className="mb-lg flex flex-row flex-nowrap items-center justify-between">
          <div className="mr-lg">
            <p>Supporting Documents</p>
          </div>
          {canEdit ? (
            <div className="flex flex-nowrap items-center">
              <Button
                className="mr-sm"
                size="sm"
                onClick={() => setUploadDocumentDialogOpen(true)}
              >
                Upload File
              </Button>
              {documents?.length > 0 ? (
                <div className="ml-sm">
                  <EllipsisDropdownMenu
                    items={[
                      {
                        key: 'delete-all',
                        name: 'Delete All',
                        onSelect: () => deleteAllFiles()
                      },
                      {
                        key: 'set-all-public',
                        name: 'Set All Public',
                        onSelect: () => setAllPublic()
                      },
                      {
                        key: 'set-all-private',
                        name: 'Set All Private',
                        onSelect: () => setAllPrivate()
                      }
                    ]}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        {documents?.length > 0 ? (
          <div className="flex flex-col flex-nowrap">
            {documents.map(
              (
                document: SupportingDocumentFragmentFragment,
                documentIdx
              ) => {
                return (
                  <div
                    key={getListItemKey(document?.identifier)}
                    className={
                      ' flex flex-nowrap items-center justify-between ' +
                      (documentIdx !== 0 && 'mt-sm')
                    }
                  >
                    <div className="flex flex-nowrap items-center">
                      <div className="mr-md">
                        {document?.file?.name.endsWith('.pdf') ? (
                          <FaRegFilePdf className="mx-auto inline-block" />
                        ) : document?.file?.name.endsWith('.jpg') ||
                          document?.file?.name.endsWith('.jpeg') ||
                          document?.file?.name.endsWith('.png') ? (
                          // <BsFileImage className="mr-md inline-block" />
                          <img
                            src={document?.file?.url}
                            className="inline-block aspect-square h-full max-h-[28px] rounded-sm object-contain"
                          />
                        ) : (
                          <CiFileOn className="mx-auto inline-block" />
                        )}
                      </div>
                      <a
                        className="cursor-pointer hover:underline"
                        href={document?.file?.url}
                        target="_blank"
                      >
                        <p>{document?.file?.name}</p>
                      </a>
                      <p className="ml-sm text-nowrap">
                        ({formatFileSize(document?.file?.size, 2)})
                      </p>
                    </div>
                    <div className="flex flex-nowrap items-center">
                      <Badge
                        variant={
                          document?.isPublic ? 'default' : 'outline'
                        }
                      >
                        {document?.isPublic ? 'public' : 'private'}
                      </Badge>
                      {canEdit && (
                        <div className="ml-md">
                          <EllipsisDropdownMenu
                            items={[
                              {
                                name:
                                  'Set ' +
                                  (document?.isPublic
                                    ? 'Private'
                                    : 'Public'),
                                onSelect: () => {
                                  editSupportingDocument({
                                    variables: {
                                      input: {
                                        documentIdentifier:
                                          document?.identifier,
                                        isPublic: !document?.isPublic
                                      },
                                      startDate: selectedStartDate,
                                      endDate: selectedEndDate,
                                      states: selectedFilters
                                    },
                                    optimisticResponse: {
                                      editSupportingDocument: {
                                        supportingDocument: {
                                          identifier:
                                            document?.identifier,
                                          isPublic:
                                            !document?.isPublic
                                        }
                                      }
                                    }
                                  });
                                }
                              },
                              {
                                name: 'Delete',
                                onSelect: () => {
                                  deleteSupportingDocument({
                                    variables: {
                                      input: {
                                        documentIdentifier:
                                          document?.identifier
                                      },
                                      startDate: selectedStartDate,
                                      endDate: selectedEndDate,
                                      states: selectedFilters
                                    }
                                  });
                                }
                              }
                            ]}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        ) : (
          <p className="text-muted">No supporting documents.</p>
        )}
      </div>
      <UploadDocumentDialog
        open={uploadDocumentDialogOpen}
        onOpenChange={setUploadDocumentDialogOpen}
        parentType={parentType}
        parentIdentifier={parentIdentifier}
        sortOrder={documents.length}
        isPublic={false}
        refetchDocuments={refetchDocuments}
      />
    </div>
  );
}
