import { gql, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { Scope2DataCalculatorsQueryQuery } from 'src/__apolloGenerated__/graphql';
import DataCalculatorManager from 'src/components/carbon/organisms/DataCalculatorManager';
import { ROUTES } from 'src/config';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import { dataCalculatorResponseFragment } from 'src/views/Scope1View';

export const SCOPE_2_DATA_CALCULATORS_QUERY_NAME =
  'Scope2DataCalculatorsQuery';

export default function Scope1View() {
  const router = useRouter();
  const scope2DataCalculatorFragment = gql`
    fragment Scope2DataCalculatorsFragment on Scope2DataCalculatorsObject {
      locationElectricity {
        ...DataCalculatorResponseFragment
      }
      locationHeatOrSteam {
        ...DataCalculatorResponseFragment
      }
      locationPurchasedCooling {
        ...DataCalculatorResponseFragment
      }
    }
  `;

  const { activeReportingPeriodId }: SettingsStoreType = useSettingsStore();
  
  const { data: scope2DataCalculatorsData, loading } =
    useQuery<Scope2DataCalculatorsQueryQuery>(
      gql`
        ${dataCalculatorResponseFragment}
        ${scope2DataCalculatorFragment}
        query Scope2DataCalculatorsQuery(
          $reportingPeriodIdentifier: String
        ) {
          dataCalculatorChecklist(
            reportingPeriodIdentifier: $reportingPeriodIdentifier
          ) {
            ok
            errors {
              ...ErrorsFragment
            }
            data {
              scope2DataCalculators {
                ...Scope2DataCalculatorsFragment
              }
            }
          }
        }
      `,
      {
        variables: {
          reportingPeriodIdentifier: activeReportingPeriodId
        }
      }
    );

  const dataCalculators =
    scope2DataCalculatorsData?.dataCalculatorChecklist?.data;

  return (
    <div>
      <DataCalculatorManager
        scope={2}
        dataCalculators={dataCalculators?.scope2DataCalculators}
        bottomNavButtonText="Calculate Scope 3 Emissions"
        bottomNavOnClick={() => {
          router.push(ROUTES.TOOLS.SCOPE3);
        }}
        loading={loading}
      />
    </div>
  );
}
