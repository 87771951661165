import { useCallback, useEffect, useState } from 'react';

const useResize = ({
  minSize,
  maxSize,
  offset = 0,
  vertical,
  rightAnchor = false,
  ref,
  callback
}: {
  minSize: number;
  maxSize?: number;
  offset?: number;
  vertical?: boolean;
  rightAnchor?: boolean;
  ref: React.MutableRefObject<HTMLElement>;
  callback?: () => void;
}): {
  enableResize: () => void;
  isResizing: boolean;
} => {
  const [isResizing, setIsResizing] = useState(false);
  const previousWindowSize = vertical
    ? window?.innerHeight
    : window?.innerWidth;

  const enableResize = useCallback(() => {
    setIsResizing(true);
  }, [setIsResizing]);

  const disableResize = useCallback(() => {
    setIsResizing(false);
  }, [setIsResizing]);

  const resize = useCallback(
    (e: MouseEvent) => {
      if (isResizing) {
        const newSize = vertical ? e.clientY - offset : e.clientX - offset;

        // not tested with offset
        const anchoredSize = rightAnchor
          ? previousWindowSize - newSize
          : newSize;

        ref.current.style.width = `${
          minSize <= anchoredSize && anchoredSize <= maxSize
            ? anchoredSize
            : anchoredSize < minSize
            ? minSize
            : maxSize
        }px`;
        if (callback) {
          callback();
        }
      }
    },
    [minSize, isResizing]
  );

  useEffect(() => {
    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', disableResize);

    return () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', disableResize);
    };
  }, [disableResize, resize]);

  return { enableResize, isResizing };
};

export default useResize;
