import useAppStore, {
  AppStoreType
} from 'src/hooks/store/useAppStore';
import Subnav from 'src/layouts/Subnav';

export default function DetailViewLayout({
  getSubnav,
  children,
  getPanelContent
}: {
  getSubnav: () => React.ReactNode;
  children: React.ReactNode;
  getPanelContent: () => React.ReactNode;
}) {
  const { sidebarMinimized } = useAppStore((store: AppStoreType) => ({
    sidebarMinimized: store.sidebarMinimized
  }));
  return (
    <div className="flex h-full flex-col flex-nowrap">
      {/* Subnav */}
      <div>
        <Subnav getSubnav={getSubnav} forceClosePanel />
      </div>
      <div className="flex w-full flex-nowrap">
        {/* Children */}
        <div
          className={
            'flex max-h-page-height-subnav justify-center overflow-y-auto p-xl ' +
            (sidebarMinimized
              ? 'w-double-min-sidebar-page'
              : 'w-double-sidebar-page')
          }
        >
          <div className="w-full max-w-screen-xl">{children}</div>
        </div>
        {/* Panel */}
        <div className="fixed right-0 h-page-height-subnav w-right-sidebar border-l p-md">
          {getPanelContent()}
        </div>
      </div>
    </div>
  );
}
