import { gql, useLazyQuery, useQuery } from '@apollo/client';
import _ from 'lodash';
import { Lock, Plus, SearchIcon, X } from 'lucide-react';
import { useState } from 'react';
import {
  CustomEmissionFactorsQueryQuery,
  EmissionFactorFragmentFragment,
  EmissionFactorSearchQueryQuery,
  EmissionFactorTypesEnum,
  UnitTypesEnum
} from 'src/__apolloGenerated__/graphql';
import { UnitTypesAndUnits } from 'src/backend/@types';
import MultiSwitch from 'src/components/carbon/atoms/MultiSwitch';
import FilterChip from 'src/components/core/atoms/FilterChip';
import Input from 'src/components/core/atoms/Input';
import Link from 'src/components/core/atoms/Link';
import Skeleton from 'src/components/core/atoms/Skeleton';
import Filterbar, {
  FilterType
} from 'src/components/core/molecules/Filterbar';
import SingleSelect from 'src/components/core/molecules/SingleSelect';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from 'src/components/shad-base/accordion';
import { Button } from 'src/components/shad-base/button';
import useSubscriptionStore, {
  SubscriptionStoreType
} from 'src/hooks/store/useSubcriptionStore';
import usePaywall from 'src/hooks/usePaywall';
import { fEnum, fPrecision, fUnit } from 'src/utils/format';
import AddNewEmissionFactorDialog from 'src/views/MeasureView/dialogs/AddNewEmissionFactorDialog';

export default function EmissionFactorSelector({
  selectedFactor,
  setSelectedFactor,
  loading = false,
  prefixText = 'Emission Factor: ',
  initialUnitTypeFilters,
  initialFactorTypeFilters,
  unitType,
  defaultAccordionOpen = false,
  disableCloseOnSelect = false,
  placeholder,
  required = false,
  size = 'default',
  type = 'element'
}: {
  selectedFactor: EmissionFactorFragmentFragment;
  setSelectedFactor: (value: EmissionFactorFragmentFragment) => void;
  loading?: boolean;
  prefixText?: string;
  initialUnitTypeFilters?: UnitTypesEnum[];
  initialFactorTypeFilters?: EmissionFactorTypesEnum[];
  unitType?: UnitTypesEnum;
  defaultAccordionOpen?: boolean;
  disableCloseOnSelect?: boolean;
  placeholder?: string;
  required?: boolean;
  type?: 'element' | 'form';
  size?: 'sm' | 'default';
}) {
  const { isAuthorized, requiredPlan, subtitleText } = usePaywall(
    'custom-emission-factors'
  );
  const {
    setPaywallDialogOpen,
    setRequiredPlan,
    setSubtitleText
  }: SubscriptionStoreType = useSubscriptionStore();

  const [
    addNewEmissionFactorDialogOpen,
    setAddNewEmissionFactorDialogOpen
  ] = useState(false);

  const [accordionOpen, setAccordionOpen] = useState<string | null>(
    defaultAccordionOpen ? 'factor' : null
  );
  const [searchValue, setSearchValue] = useState('');
  const [activeView, setActiveView] = useState<'Custom' | 'Database'>(
    isAuthorized ? 'Custom' : 'Database'
  );

  const factorTypeFilterOptions = Object.values(
    EmissionFactorTypesEnum
  ).map((factorType) => ({
    key: factorType,
    label: fEnum(factorType)
  }));

  const unitTypeFilterOptions = Object.values(UnitTypesEnum).map(
    (unitType) => ({
      key: unitType,
      label: fEnum(unitType)
    })
  );

  const [factorFilters, setFactorFilters] = useState(
    initialFactorTypeFilters || Object.values(EmissionFactorTypesEnum)
  );
  const [unitTypeFilters, setUnitTypeFilters] = useState(
    initialUnitTypeFilters || Object.values(UnitTypesEnum)
  );

  const {
    data: customEmissionFactorsData,
    loading: loadingCustomEmissionFactors,
    refetch: refetchCustomEmissionFactors
  } = useQuery<CustomEmissionFactorsQueryQuery>(
    gql`
      query CustomEmissionFactorsQuery {
        customEmissionFactors {
          ok
          data {
            ...EmissionFactorFragment
          }
          errors {
            ...ErrorsFragment
          }
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }
  );

  const customEmissionFactors = customEmissionFactorsData
    ?.customEmissionFactors?.data as EmissionFactorFragmentFragment[];

  const [
    searchEmissionFactors,
    {
      data: emissionFactorResults,
      loading: loadingEmissionFactors,
      networkStatus
    }
  ] = useLazyQuery<EmissionFactorSearchQueryQuery>(
    gql`
      query EmissionFactorSearchQuery(
        $query: String!
        $units: [UnitsEnum!]!
        $factorTypes: [EmissionFactorTypesEnum!]!
      ) {
        emissionFactorOptions(
          query: $query
          units: $units
          factorTypes: $factorTypes
        ) {
          ok
          data {
            query
            categories {
              ...EmissionFactorFragment
            }
            companies {
              ...EmissionFactorFragment
            }
            products {
              ...EmissionFactorFragment
            }
            transportation {
              ...EmissionFactorFragment
            }
            entity {
              ...EmissionFactorFragment
            }
            others {
              ...EmissionFactorFragment
            }
          }
          errors {
            ...ErrorsFragment
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }
  );

  const results = emissionFactorResults?.emissionFactorOptions?.data;
  const resultQuery = results?.query;

  const allResults = [
    ...(results?.others || []),
    ...(results?.categories || []),
    ...(results?.companies || []),
    ...(results?.products || []),
    ...(results?.transportation || []),
    ...(results?.entity || [])
  ] as EmissionFactorFragmentFragment[];

  const customEmissionFactorOptions = [];
  customEmissionFactors?.forEach((factor) => {
    if (unitType) {
      if (factor.unitInfo.type === unitType) {
        customEmissionFactorOptions.push({
          key: factor.identifier,
          label: factor.name
        });
      }
    } else {
      customEmissionFactorOptions.push({
        key: factor.identifier,
        label: factor.name
      });
    }
  });

  const search = () => {
    let units = [];
    // Map actual units from unit types
    if (unitType) {
      units = [...UnitTypesAndUnits[unitType]];
    } else {
      unitTypeFilters.forEach((unitType) => {
        units.push(...(UnitTypesAndUnits[unitType] || []));
      });
    }

    // Search emission factors
    searchEmissionFactors({
      variables: {
        query: searchValue,
        units: units,
        factorTypes: factorFilters
      }
    });
  };
  const noResults =
    searchValue === resultQuery && allResults.length === 0;

  const filterbarFilters: FilterType[] = [
    {
      key: 'factorTypes',
      label: 'Factor Types: ',
      options: factorTypeFilterOptions,
      checked: factorFilters,
      setChecked: setFactorFilters as (checked: string[]) => void
    }
  ];
  if (!unitType) {
    filterbarFilters.push({
      key: 'unitTypes',
      label: 'Unit Types: ',
      options: unitTypeFilterOptions,
      checked: unitTypeFilters,
      setChecked: setUnitTypeFilters as (checked: string[]) => void
    });
  }

  return loading ? (
    <Skeleton height={25} />
  ) : (
    <div className="flex flex-col flex-nowrap">
      <Accordion
        type="single"
        collapsible
        value={accordionOpen}
        onValueChange={(value) => setAccordionOpen(value)}
        className="rounded-md border px-md"
      >
        <AccordionItem value="factor">
          <AccordionTrigger
            className={
              'flex w-full cursor-pointer items-center rounded-md bg-background ' +
              (size == 'sm' ? 'h-small-button' : 'h-10')
            }
          >
            <div className="flex w-full flex-nowrap items-center justify-between">
              <div className="flex max-w-[90%] flex-nowrap items-center">
                {required && type === 'element' && (
                  <p className="body2 text-destructive">*&nbsp;</p>
                )}
                {type === 'element' && (
                  <p className="caption mr-sm text-nowrap">
                    {prefixText}
                  </p>
                )}
                {selectedFactor?.rate ? (
                  <p className="caption truncate text-primary">
                    {fPrecision(selectedFactor.rate, 4)} kgCO2e/
                    {fUnit(selectedFactor.unitInfo?.unit)}&nbsp; (
                    {selectedFactor.name})
                  </p>
                ) : (
                  <p
                    className={
                      'text-muted ' + (size == 'sm' ? 'caption' : '')
                    }
                  >
                    {placeholder || 'Select factor...'}
                  </p>
                )}
              </div>
              <div>
                {selectedFactor?.rate ? (
                  <X
                    className="mx-sm h-4 w-4"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedFactor(null);
                    }}
                  />
                ) : null}
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div
              className="flex flex-col flex-nowrap "
              onKeyDown={(e) => {
                if (e.key === 'Enter' && searchValue) search();
              }}
            >
              {/* Active View Tabs */}
              <div className="mb-md flex w-full justify-between">
                <div className="flex flex-nowrap items-center">
                  <div className="mr-sm">
                    <MultiSwitch
                      options={[
                        { key: 'Custom', label: 'Custom' },
                        { key: 'Database', label: 'Database' }
                      ]}
                      activeOption={activeView}
                      setActiveOption={setActiveView}
                    />
                  </div>
                  {unitType && (
                    <FilterChip
                      prefix="Unit Type: "
                      text={fEnum(unitType)}
                    />
                  )}
                </div>
                <Button variant="outline" size="sm" className="ml-sm">
                  <div
                    className="flex flex-nowrap items-center"
                    onClick={() => {
                      if (isAuthorized) {
                        setAddNewEmissionFactorDialogOpen(true);
                      } else {
                        setPaywallDialogOpen(true);
                        setRequiredPlan(requiredPlan);
                        setSubtitleText(subtitleText);
                      }
                    }}
                  >
                    {isAuthorized ? (
                      <Plus className="mr-sm h-4 w-4" />
                    ) : (
                      <Lock className="mr-sm h-4 w-4" />
                    )}
                    New
                  </div>
                </Button>
              </div>
              {activeView === 'Custom' ? (
                <div className="flex items-center">
                  <SingleSelect
                    options={customEmissionFactorOptions}
                    loading={
                      loadingCustomEmissionFactors ||
                      networkStatus === 4 // 4 is refetching
                    }
                    value={
                      customEmissionFactorOptions.find((option) => {
                        return (
                          option.key === selectedFactor?.identifier
                        );
                      })
                        ? selectedFactor?.identifier
                        : null
                    }
                    setValue={(value) => {
                      const isAlreadySelected =
                        selectedFactor?.identifier === value;
                      if (isAlreadySelected) {
                        setSelectedFactor(null);
                      } else {
                        setSelectedFactor(
                          customEmissionFactors?.find(
                            (factor) => factor.identifier === value
                          ) || null
                        );
                      }

                      if (
                        !isAlreadySelected &&
                        !disableCloseOnSelect
                      ) {
                        setAccordionOpen(null);
                      }
                    }}
                    placeholder="Select custom emission factor..."
                  />
                </div>
              ) : (
                <>
                  <div className="flex items-center">
                    <Input
                      value={searchValue}
                      size="sm"
                      onChange={(e) => setSearchValue(e.target.value)}
                      className="caption bg-background"
                      placeholder="Search emission factors..."
                    />
                    <Button
                      size="sm"
                      className="ml-sm"
                      variant="outline"
                      loading={loadingEmissionFactors}
                      disabled={
                        !searchValue || loadingEmissionFactors
                      }
                      onClick={() => {
                        if (searchValue) search();
                      }}
                    >
                      <div className="flex flex-nowrap items-center">
                        {!loading && (
                          <SearchIcon className="mr-2 h-4 w-4" />
                        )}
                        Search
                      </div>
                    </Button>
                  </div>
                  <div className="mt-sm">
                    <div className="flex items-center">
                      <Filterbar filters={filterbarFilters} />
                    </div>
                  </div>

                  {noResults ? (
                    <div className="px-sm">
                      <p className="caption mt-md text-muted">
                        No emission factors found. Try adjusting your
                        filters or using a different search keyword.
                      </p>
                    </div>
                  ) : (
                    results &&
                    searchValue &&
                    searchValue === resultQuery && (
                      <>
                        <div className="mt-md px-sm">
                          <p className="caption text-muted">
                            Results ({allResults?.length})
                          </p>
                        </div>
                        <div className="px-sm">
                          {allResults?.map((factor, index) => {
                            const scope =
                              factor.scope?.split('.')?.[0];
                            const factorScope = scope
                              ? `Scope ${scope}`
                              : '';
                            return (
                              <Accordion type="single" collapsible>
                                <AccordionItem value={factor.name}>
                                  <AccordionTrigger className="mt-sm w-full">
                                    <div
                                      key={index}
                                      className="flex w-full flex-nowrap items-center justify-between"
                                    >
                                      <div className="flex items-center">
                                        <p className="body2 text-start">
                                          {factor.name}
                                        </p>
                                        <p className="body2 ml-md text-muted">
                                          {_.startCase(
                                            factor?.unitInfo?.type.toLocaleLowerCase()
                                          )}{' '}
                                          [
                                          {fUnit(
                                            factor?.unitInfo?.unit
                                          )}
                                          ]
                                        </p>
                                      </div>
                                      <div className="mx-sm rounded-sm border px-sm py-1">
                                        <p className="body2">
                                          {factorScope}
                                        </p>
                                      </div>
                                    </div>
                                  </AccordionTrigger>
                                  <AccordionContent>
                                    <div className="flex w-full flex-nowrap justify-between px-sm">
                                      <div>
                                        <div className="flex flex-col flex-nowrap">
                                          {/* Unit Type */}
                                          <p className="caption mb-sm">
                                            {fEnum(
                                              factor.unitInfo.type
                                            )}
                                          </p>
                                          {/* Reference Organization and Country */}
                                          <div className="flex items-center">
                                            {factor.reference
                                              ?.organization && (
                                              <p className="caption text-muted">
                                                {
                                                  factor.reference
                                                    ?.organization
                                                }
                                                {factor.reference
                                                  ?.country && ', '}
                                              </p>
                                            )}
                                            {factor.reference
                                              ?.country && (
                                              <p className="caption text-muted">
                                                {
                                                  factor.reference
                                                    ?.country
                                                }
                                              </p>
                                            )}
                                          </div>
                                          {/* Reference Url */}
                                          {factor.reference?.url && (
                                            <div className="max-w-1/2 mt-sm">
                                              <Link
                                                variant="caption"
                                                href={
                                                  factor.reference.url
                                                }
                                                target="_blank"
                                              >
                                                <p>See Reference</p>
                                              </Link>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      {/* Apply/Rate */}
                                      <div>
                                        <div className="flex flex-col flex-nowrap">
                                          <Button
                                            variant={
                                              selectedFactor?.identifier ===
                                              factor.identifier
                                                ? 'outline'
                                                : 'default'
                                            }
                                            size="sm"
                                            onClick={() => {
                                              setSelectedFactor(
                                                selectedFactor?.identifier ===
                                                  factor.identifier
                                                  ? null
                                                  : factor
                                              );

                                              // Selecting a new factor collapses accordion
                                              if (
                                                selectedFactor?.identifier !==
                                                  factor.identifier &&
                                                !disableCloseOnSelect
                                              ) {
                                                setAccordionOpen(
                                                  null
                                                );
                                              }
                                            }}
                                          >
                                            {selectedFactor?.identifier ===
                                            factor.identifier
                                              ? 'Deselect'
                                              : 'Apply'}
                                          </Button>
                                          <p className="caption mt-sm">
                                            {fPrecision(
                                              factor.rate,
                                              4
                                            )}
                                            &nbsp;kgCO2e/
                                            {fUnit(
                                              factor.unitInfo.unit
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </AccordionContent>
                                </AccordionItem>
                              </Accordion>
                            );
                          })}
                        </div>
                      </>
                    )
                  )}
                </>
              )}
            </div>
            <AddNewEmissionFactorDialog
              open={addNewEmissionFactorDialogOpen}
              onOpenChange={setAddNewEmissionFactorDialogOpen}
              unitType={unitType}
              rerouteOnSuccess={false}
              onSuccess={(data) => {
                if (data?.emissionFactor) {
                  setSelectedFactor(
                    data?.emissionFactor as EmissionFactorFragmentFragment
                  );
                  if (!disableCloseOnSelect) setAccordionOpen(null);
                  if (activeView === 'Database') {
                    setActiveView('Custom');
                  }
                } else {
                  refetchCustomEmissionFactors();
                }
              }}
            />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
