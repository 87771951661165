import {
  BatteryCharging,
  Building,
  Car,
  CarFront,
  CircleDollarSign,
  CircleHelp,
  CloudDownload,
  CloudUpload,
  Factory,
  Fan,
  Flame,
  Fuel,
  Lamp,
  Landmark,
  PlaneTakeoff,
  Recycle,
  Refrigerator,
  ShoppingCart,
  Store,
  Trash,
  Waves,
  Zap
} from 'lucide-react';
import { ScopeType } from 'src/@types/emissions';
import {
  DataCalculatorTypesEnum,
  GhgProtocolsEnum
} from 'src/__apolloGenerated__/graphql';

export const SCOPE_3_CATEGORIES_ICONS = (size: 'sm' | 'default') => {
  const sizeClass = size === 'sm' ? 'h-4 w-4' : 'h-5 w-5';
  const bgClass = ' text-scope-3-foreground ';
  return {
    [GhgProtocolsEnum.PurchasedGoodsAndServices]: (
      <ShoppingCart className={bgClass + sizeClass} />
    ),
    [GhgProtocolsEnum.UpstreamTransportationAndDistribution]: (
      <CloudUpload className={bgClass + sizeClass} />
    ),
    [GhgProtocolsEnum.DownstreamTransportationAndDistribution]: (
      <CloudDownload className={bgClass + sizeClass} />
    ),
    [GhgProtocolsEnum.BusinessTravel]: (
      <PlaneTakeoff className={bgClass + sizeClass} />
    ),
    [GhgProtocolsEnum.CapitalGoods]: (
      <Landmark className={bgClass + sizeClass} />
    ),
    [GhgProtocolsEnum.WasteGeneratedInOperations]: (
      <Trash className={bgClass + sizeClass} />
    ),
    [GhgProtocolsEnum.EmployeeCommuting]: (
      <CarFront className={bgClass + sizeClass} />
    ),
    [GhgProtocolsEnum.ProcessingOfSoldProducts]: (
      <Factory className={bgClass + sizeClass} />
    ),
    [GhgProtocolsEnum.UseOfSoldProducts]: (
      <Lamp className={bgClass + sizeClass} />
    ),
    [GhgProtocolsEnum.EndOfLifeTreatmentOfSoldProducts]: (
      <Recycle className={bgClass + sizeClass} />
    ),
    [GhgProtocolsEnum.Franchises]: (
      <Store className={bgClass + sizeClass} />
    ),
    [GhgProtocolsEnum.FuelAndEnergyRelatedActivities]: (
      <BatteryCharging className={bgClass + sizeClass} />
    ),
    [GhgProtocolsEnum.DownstreamLeasedAssets]: (
      <Building className={bgClass + sizeClass} />
    ),
    [GhgProtocolsEnum.Investments]: (
      <CircleDollarSign className={bgClass + sizeClass} />
    ),
    [GhgProtocolsEnum.Other]: (
      <CircleHelp className={bgClass + sizeClass} />
    )
  };
};

export const DATA_CALCULATOR_ICONS = (size: 'sm' | 'default') => {
  const sizeClass = size === 'sm' ? 'h-4 w-4' : 'h-5 w-5';
  return {
    [DataCalculatorTypesEnum.LocationNaturalGas]: (
      <Flame className={'text-scope-1-foreground ' + sizeClass} />
    ),
    [DataCalculatorTypesEnum.VehicleFuel]: (
      <Fuel className={'text-scope-1-foreground ' + sizeClass} />
    ),
    [DataCalculatorTypesEnum.VehicleDistance]: (
      <Car className={'text-scope-1-foreground ' + sizeClass} />
    ),
    [DataCalculatorTypesEnum.EquipmentFuel]: (
      <Fuel className={'text-scope-1-foreground ' + sizeClass} />
    ),
    [DataCalculatorTypesEnum.EquipmentRefrigerant]: (
      <Refrigerator
        className={'text-scope-1-foreground ' + sizeClass}
      />
    ),
    [DataCalculatorTypesEnum.EquipmentIndustrialGas]: (
      <Flame className={'text-scope-1-foreground ' + sizeClass} />
    ),
    [DataCalculatorTypesEnum.LocationElectricity]: (
      <Zap className={'text-scope-2-foreground ' + sizeClass} />
    ),
    [DataCalculatorTypesEnum.LocationHeatOrSteam]: (
      <Waves className={'text-scope-2-foreground ' + sizeClass} />
    ),
    [DataCalculatorTypesEnum.LocationPurchasedCooling]: (
      <Fan className={'text-scope-2-foreground ' + sizeClass} />
    )
  };
};

export default function DataCalculatorIcon({
  scope,
  calculatorType,
  scope3Category,
  size = 'default'
}: {
  scope: ScopeType;
  calculatorType?: DataCalculatorTypesEnum;
  scope3Category?: GhgProtocolsEnum;
  size?: 'sm' | 'default';
}) {
  const iconBgClass =
    scope == 1
      ? 'bg-scope-1/30'
      : scope == 2
        ? 'bg-scope-2/30'
        : scope == 3
          ? 'bg-scope-3/30'
          : '';

  const sizeClass =
    size === 'sm' ? 'h-[32px] w-[32px] ' : 'h-[40px] w-[40px] ';
  return (
    <div
      className={
        'flex items-center justify-center rounded-full ' +
        sizeClass +
        iconBgClass
      }
    >
      {scope == 3
        ? SCOPE_3_CATEGORIES_ICONS(size)[scope3Category]
        : DATA_CALCULATOR_ICONS(size)[calculatorType]}
    </div>
  );
}
