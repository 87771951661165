import { ArrowLeft } from 'lucide-react';
import CSVFileUploader, {
  HeaderIndicesType
} from 'src/components/core/organisms/CSVFileUploader';
import { Button } from 'src/components/shad-base/button';
import { ModeType } from 'src/views/MeasureView/dialogs/AddNewEmissionFactorDialog';

export default function CSVMode({
  handleSubmit,
  setMode
}: {
  handleSubmit: (
    file: File,
    headerIndices: HeaderIndicesType,
    headerRow: number
  ) => void;
  setMode: (mode: ModeType) => void;
}) {
  return (
    <div className="flex w-full flex-col flex-nowrap">
      {/* Title */}
      <div className="mb-sm flex flex-nowrap items-center">
        <Button
          variant="outline"
          size="icon"
          onClick={() => setMode('description')}
        >
          <ArrowLeft className="h-icon w-icon" />
        </Button>
        <p className="subtitle1 ml-md">
          Bulk Upload Emission Factors{' '}
        </p>
      </div>
      {/* CSV Uploader */}
      <CSVFileUploader
        onSubmit={handleSubmit}
        expectedHeaders={[
          { key: 'name', label: 'Name' },
          { key: 'description', label: 'Description' },
          { key: 'unit', label: 'Unit' },
          { key: 'scope', label: 'Scope' },
          { key: 'is_public', label: 'Is Public' },
          { key: 'value', label: 'Value' },
          {
            key: 'reference_organization',
            label: 'Reference Organization'
          },
          { key: 'reference_country', label: 'Reference Country' },
          { key: 'reference_year', label: 'Reference Year' },
          { key: 'reference_source', label: 'Reference Source' },
          { key: 'reference_url', label: 'Reference URL' }
        ]}
      />
    </div>
  );
}
