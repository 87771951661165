import { ChevronRight, Columns } from 'lucide-react';
import { Button } from 'src/components/shad-base/button';
import useAppStore from 'src/hooks/store/useAppStore';

export default function Subnav({
  getSubnav,
  forceClosePanel
}: {
  getSubnav: () => React.ReactNode;
  forceClosePanel?: boolean;
}) {
  const { panelMinimized, setPanelMinimized } = useAppStore(
    (store) => ({
      panelMinimized: store.panelMinimized,
      setPanelMinimized: store.setPanelMinimized
    })
  );

  return (
    <div className="z-page h-topbar w-full flex-nowrap border-b bg-background">
      <div className="flex min-h-full flex-nowrap items-center justify-between px-sm">
        {/* Subnav */}
        <div className="w-full">
          <div className="flex flex-nowrap items-center">
            <div className="w-full">{getSubnav()}</div>
            {!forceClosePanel &&
              (panelMinimized ? (
                <Button
                  onClick={() => setPanelMinimized(false)}
                  variant="outline"
                  size="icon"
                  className="ml-sm"
                >
                  <Columns className="scale-75" />
                </Button>
              ) : (
                <Button
                  onClick={() => setPanelMinimized(true)}
                  variant="outline"
                  size="icon"
                  className="ml-sm"
                >
                  <ChevronRight className="scale-75" />
                </Button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
