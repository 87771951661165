import { useState } from 'react';

export default function ExpandableList({
  title,
  items
}: {
  title: string;
  items: { key: string; label: string; onClick?: () => void }[];
}) {
  const [seeAll, setSeeAll] = useState(false);

  return (
    <div className="flex flex-col">
      <p className="mb-sm text-muted">{title}</p>
      {items?.length > 5 ? (
        <>
          {seeAll
            ? items?.map((item) => <p key={item.key}>{item.label}</p>)
            : items?.slice(0, 5)?.map((item) => (
                <p
                  key={item.key}
                  onClick={item.onClick}
                  className={
                    item.onClick && 'cursor-pointer hover:underline '
                  }
                >
                  {item.label}
                </p>
              ))}
          <p
            className={
              'mt-sm cursor-pointer  underline ' +
              (seeAll ? '' : 'text-primary')
            }
            onClick={() => setSeeAll(!seeAll)}
          >
            {seeAll ? 'See Less' : 'See All'}
          </p>
        </>
      ) : (
        items?.map((item) => {
          return (
            <p
              key={item.key}
              onClick={item.onClick}
              className={
                item.onClick && 'cursor-pointer hover:underline '
              }
            >
              {item.label}
            </p>
          );
        })
      )}
    </div>
  );
}
