import dayjs from 'dayjs';
import { ArrowLeft } from 'lucide-react';
import { useState } from 'react';
import { ScopeType } from 'src/@types/emissions';
import {
  CreateMeasurementFromQueryInput,
  CreateMeasurementFromQueryPayload,
  CreateMeasurementPayload,
  GhgProtocolsEnum,
  MeasurementEditInputObject,
  MeasurementFragmentFragment,
  MeasurementStatesEnum
} from 'src/__apolloGenerated__/graphql';
import MultiSwitch from 'src/components/carbon/atoms/MultiSwitch';
import CreateMeasurementAutoForm from 'src/components/carbon/molecules/create-measurement-forms/CreateMeasurementAutoForm';
import CreateMeasurementManualForm from 'src/components/carbon/molecules/create-measurement-forms/CreateMeasurementManualForm';
import DataCalculatorIcon from 'src/components/core/atoms/IconCircle';
import { Button } from 'src/components/shad-base/button';
import {
  Dialog,
  DialogContent
} from 'src/components/shad-base/dialog';
import { NODE_TYPES } from 'src/config';
import { useToast } from 'src/hooks/shad-base/useToast';
import useMeasureStore, {
  MeasureStoreType
} from 'src/hooks/store/useMeasureStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import {
  CREATE_MEASUREMENT,
  CREATE_MEASUREMENT_FROM_QUERY
} from 'src/hooks/useBackendMutation/mutations';

export default function CreateSubsequentMeasurementDialog({
  open,
  onOpenChange,
  selectedMeasurement,
  onBackButtonClick,
  refetchQueriesOnSuccess,
  onCreateSuccess
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  selectedMeasurement: MeasurementFragmentFragment;
  onBackButtonClick?: () => void;
  refetchQueriesOnSuccess?: string[];
  onCreateSuccess?: () => void;
}) {
  const { toast } = useToast();
  const {
    setActiveNode,
    selectedStartDate,
    selectedEndDate,
    selectedFilters
  } = useMeasureStore((store: MeasureStoreType) => ({
    setActiveNode: store.setActiveNode,
    selectedStartDate: store.selectedStartDate,
    selectedEndDate: store.selectedEndDate,
    selectedFilters: store.selectedFilters
  }));

  const [mode, setMode] = useState<'manual' | 'auto'>('manual');
  const [error, setError] = useState(null);

  const {
    mutate: createMeasurementFromQuery,
    loading: createMeasurementFromQueryIsLoading
  } = useBackendMutation<
    {
      input: CreateMeasurementFromQueryInput;
      startDate: Date;
      endDate: Date;
      states: MeasurementStatesEnum[];
    },
    CreateMeasurementFromQueryPayload
  >({
    mutation: CREATE_MEASUREMENT_FROM_QUERY
  });

  const {
    mutate: createMeasurement,
    loading: createMeasurementIsLoading
  } = useBackendMutation<
    {
      input: {
        branchIdentifier: string;
        measurement: MeasurementEditInputObject;
      };
      startDate: Date;
      endDate: Date;
      states: MeasurementStatesEnum[];
    },
    CreateMeasurementPayload
  >({
    mutation: CREATE_MEASUREMENT
  });
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-screen-md ">
        <div className="relative flex flex-col flex-nowrap items-center">
          {onBackButtonClick && (
            <div className="absolute left-0 top-0">
              <Button
                onClick={onBackButtonClick}
                variant="outline"
                size="icon"
                className="mr-md"
              >
                <ArrowLeft />
              </Button>
            </div>
          )}
          <p className="subtitle1">Create Subsequent Measurement</p>
          <p className="text-muted">
            Update your recurring measurement.
          </p>
          {/* MultiSwitch */}
          <div className="mt-sm">
            <MultiSwitch
              options={[
                {
                  key: 'auto',
                  label: 'Auto'
                },
                {
                  key: 'manual',
                  label: 'Manual'
                }
              ]}
              activeOption={mode}
              setActiveOption={(value) =>
                setMode(value as 'auto' | 'manual')
              }
            />
          </div>
          {/* Measurement Details */}
          <div className="mt-lg w-full">
            <div className="flex flex-nowrap items-center">
              <div className="mr-sm">
                <DataCalculatorIcon
                  calculatorType={selectedMeasurement?.calculatorType}
                  scope={
                    Number(selectedMeasurement?.scope) as ScopeType
                  }
                  scope3Category={
                    selectedMeasurement?.ghgCategory as GhgProtocolsEnum
                  }
                />
              </div>
              <div className="flex flex-col flex-nowrap">
                <p className="mt-sm font-bold">
                  Updating: {selectedMeasurement?.name}
                </p>
                <p className="text-muted ">
                  Last updated:{' '}
                  {dayjs(
                    selectedMeasurement?.dateOfMeasurement
                  ).format('MMMM DD, YYYY')}
                </p>
              </div>
            </div>
          </div>
          {/* Forms */}
          <div className="mt-md w-full">
            {mode === 'auto' ? (
              <CreateMeasurementAutoForm
                omitQuestionKeys={['branchIdentifier']}
                defaultValues={{
                  isRecurring: 'recurring',
                  measurementInterval:
                    selectedMeasurement?.intervalValue.toString(),
                  measurementIntervalUnit:
                    selectedMeasurement?.intervalUnit
                }}
                onFormSubmit={(
                  formValues,
                  submitToggleChecked,
                  reset
                ) => {
                  const isDateRange =
                    formValues.isDateRange === 'date-range';
                  const intervalValue = formValues.measurementInterval
                    ? Number(formValues.measurementInterval)
                    : undefined;
                  const intervalUnit =
                    formValues.measurementIntervalUnit
                      ? formValues.measurementIntervalUnit
                      : undefined;

                  const interval =
                    intervalValue && intervalUnit
                      ? {
                          value: intervalValue,
                          unit: intervalUnit
                        }
                      : undefined;

                  createMeasurementFromQuery({
                    variables: {
                      input: {
                        previousMeasurementIdentifiers: [
                          selectedMeasurement.identifier
                        ],
                        name: formValues.name,
                        query: formValues.description,
                        branchIdentifier:
                          selectedMeasurement.branch.identifier,
                        interval,
                        isRecurring: true,
                        startDate: isDateRange
                          ? formValues.startDate
                          : undefined,
                        endDate: isDateRange
                          ? formValues.endDate
                          : undefined,
                        dateOfMeasurement: isDateRange
                          ? undefined
                          : formValues.dateOfMeasurement
                            ? formValues.dateOfMeasurement
                            : undefined
                      },
                      startDate: selectedStartDate
                        ? dayjs(selectedStartDate).toDate()
                        : null,
                      endDate: selectedEndDate
                        ? dayjs(selectedEndDate).toDate()
                        : null,
                      states: selectedFilters
                    },
                    onCompleted: (response) => {
                      const data =
                        response?.createMeasurementFromQuery;
                      if (data?.measurement) {
                        setActiveNode({
                          nodeId: data?.measurement?.identifier,
                          type: NODE_TYPES.MEASUREMENT,
                          path: data?.branch?.path
                        });
                        toast({
                          title: 'Measurement created',
                          description:
                            'Measurement created successfully',
                          variant: 'success'
                        });
                      }

                      if (submitToggleChecked) {
                        reset();
                      } else {
                        onOpenChange(false);
                        onCreateSuccess?.();
                      }
                    },
                    onError: () => {
                      setError(
                        'Unable to create measurement, make sure you have a quantity, unit, and subject in your description.'
                      );
                    }
                  });
                }}
                error={error}
                isSubmitting={createMeasurementFromQueryIsLoading}
                submitButtonText="Create Measurement"
              />
            ) : (
              <CreateMeasurementManualForm
                defaultValues={{
                  isRecurring: 'recurring',
                  measurementInterval:
                    selectedMeasurement?.intervalValue.toString(),
                  measurementIntervalUnit:
                    selectedMeasurement?.intervalUnit
                }}
                omitQuestionKeys={['branchIdentifier']}
                onFormSubmit={(
                  formValues,
                  submitToggleChecked,
                  resetFormValues
                ) => {
                  const isDateRange =
                    formValues.isDateRange === 'date-range';
                  const intervalValue = formValues.measurementInterval
                    ? Number(formValues.measurementInterval)
                    : undefined;
                  const intervalUnit =
                    formValues.measurementIntervalUnit
                      ? formValues.measurementIntervalUnit
                      : undefined;

                  const interval =
                    intervalValue && intervalUnit
                      ? {
                          value: intervalValue,
                          unit: intervalUnit
                        }
                      : undefined;

                  createMeasurement({
                    variables: {
                      input: {
                        branchIdentifier:
                          selectedMeasurement?.branch?.identifier,
                        emissionFactorIdentifier:
                          formValues?.emissionFactor?.identifier,
                        previousMeasurementIdentifiers: [
                          selectedMeasurement?.identifier
                        ],
                        measurement: {
                          name: formValues.name,
                          value: formValues.value,
                          unit: formValues.valueUnit,
                          startDate: isDateRange
                            ? formValues.startDate
                            : undefined,
                          endDate: isDateRange
                            ? formValues.endDate
                            : undefined,
                          dateOfMeasurement: isDateRange
                            ? undefined
                            : formValues.dateOfMeasurement
                              ? formValues.dateOfMeasurement
                              : undefined,
                          ownershipFraction:
                            formValues.ownershipFraction
                              ? Number(formValues.ownershipFraction) /
                                100
                              : undefined,
                          ghgCategory: formValues.ghgCategory,
                          isRecurring: true,
                          interval
                        }
                      },
                      startDate: selectedStartDate
                        ? dayjs(selectedStartDate).toDate()
                        : null,
                      endDate: selectedEndDate
                        ? dayjs(selectedEndDate).toDate()
                        : null,
                      states: selectedFilters
                    },
                    onCompleted: (response) => {
                      const data = response?.createMeasurement;
                      if (data?.measurement) {
                        setActiveNode({
                          nodeId: data?.measurement?.identifier,
                          type: NODE_TYPES.MEASUREMENT,
                          path: data?.branch?.path
                        });
                      }
                      if (submitToggleChecked) {
                        resetFormValues();
                      } else {
                        onCreateSuccess?.();
                        onOpenChange(false);
                      }
                    },
                    refetchQueries: refetchQueriesOnSuccess,
                    awaitRefetchQueries:
                      refetchQueriesOnSuccess?.length > 0
                  });
                }}
                isSubmitting={createMeasurementIsLoading}
                submitButtonText="Create Subsequent Measurement"
              />
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
