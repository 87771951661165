import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useEffect, useReducer, useRef, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import {
  DataCalculatorTypesEnum,
  DetailViewActiveEntityQueryQuery,
  EnergyUnitsEnum,
  EquipmentFragmentFragment,
  IndustrialGasTypesEnum,
  LocationFragmentFragment,
  MeasurementIntervalUnitsEnum,
  PurchasedCoolingTypesEnum,
  RefrigerantTypesEnum,
  ReportingPeriodObject,
  VehicleDistanceTravelledEnum,
  VehicleFragmentFragment,
  VolumeUnitsEnum,
  WeightUnitsEnum
} from 'src/__apolloGenerated__/graphql';
import { AssetCard } from 'src/components/carbon/organisms/AssetManager/ListView';
import { DatePicker } from 'src/components/core/atoms/DatePicker';
import Form, {
  FormQuestionTypeType,
  QuestionRowType
} from 'src/components/core/organisms/Form';
import { Badge } from 'src/components/shad-base/badge';
import { ROUTES } from 'src/config';
import { useToast } from 'src/hooks/shad-base/useToast';
import useAssetStore, {
  AssetStoreType
} from 'src/hooks/store/useAssetStore';
import useDataCalculatorStore, {
  DataCalculatorStoreType
} from 'src/hooks/store/useDataCalculatorStore';
import useMeasureStore, {
  MeasureStoreType
} from 'src/hooks/store/useMeasureStore';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import useBackendMutation from 'src/hooks/useBackendMutation';
import { fEnum, fLocalDate, getListItemKey } from 'src/utils/format';
import { getEquipmentDescription } from 'src/views/EquipmentView';
import {
  getLocationDescription,
  getSecondaryLocationDescription
} from 'src/views/LocationsView';
import { getFormattedMeasurementInterval } from 'src/views/MeasureView/detail/MeasurementDetailView/MeasurementDetailViewPanelContent';
import { SCOPE_1_DATA_CALCULATORS_QUERY_NAME } from 'src/views/Scope1View';
import { SCOPE_2_DATA_CALCULATORS_QUERY_NAME } from 'src/views/Scope2View';
import {
  getSecondaryVehicleDescription,
  getVehicleDescription
} from 'src/views/VehiclesView';

const mutations = {
  [DataCalculatorTypesEnum.LocationNaturalGas]: gql`
    mutation CreateOrEditLocationNaturalGasCalculator(
      $input: CreateOrEditLocationNaturalGasCalculatorInput!
    ) {
      createOrEditLocationNaturalGasCalculator(input: $input) {
        ok
        errors {
          ...ErrorsFragment
        }
        location {
          identifier
          name
        }
        measurement {
          identifier
          name
          kgCo2e
        }
      }
    }
  `,
  [DataCalculatorTypesEnum.LocationElectricity]: gql`
    mutation CreateOrEditLocationElectricityCalculator(
      $input: CreateOrEditLocationElectricityCalculatorInput!
    ) {
      createOrEditLocationElectricityCalculator(input: $input) {
        ok
        errors {
          ...ErrorsFragment
        }
        location {
          identifier
          name
        }
        measurement {
          identifier
          name
          kgCo2e
        }
      }
    }
  `,
  [DataCalculatorTypesEnum.LocationHeatOrSteam]: gql`
    mutation CreateOrEditLocationHeatOrSteamCalculator(
      $input: CreateOrEditLocationHeatOrSteamCalculatorInput!
    ) {
      createOrEditLocationHeatOrSteamCalculator(input: $input) {
        ok
        errors {
          ...ErrorsFragment
        }
        location {
          identifier
          name
        }
        measurement {
          identifier
          name
          kgCo2e
        }
      }
    }
  `,
  [DataCalculatorTypesEnum.LocationPurchasedCooling]: gql`
    mutation CreateOrEditLocationPurchasedCoolingCalculator(
      $input: CreateOrEditLocationPurchasedCoolingCalculatorInput!
    ) {
      createOrEditLocationPurchasedCoolingCalculator(input: $input) {
        ok
        errors {
          ...ErrorsFragment
        }
        location {
          identifier
          name
        }
        measurement {
          identifier
          name
          kgCo2e
        }
      }
    }
  `,
  [DataCalculatorTypesEnum.VehicleFuel]: gql`
    mutation CreateOrEditVehicleFuelCalculator(
      $input: CreateOrEditVehicleFuelCalculatorInput!
    ) {
      createOrEditVehicleFuelCalculator(input: $input) {
        ok
        errors {
          ...ErrorsFragment
        }
        vehicle {
          identifier
          name
        }
        measurement {
          identifier
          name
          kgCo2e
        }
      }
    }
  `,
  [DataCalculatorTypesEnum.VehicleDistance]: gql`
    mutation CreateOrEditVehicleDistanceCalculator(
      $input: CreateOrEditVehicleDistanceCalculatorInput!
    ) {
      createOrEditVehicleDistanceCalculator(input: $input) {
        ok
        errors {
          ...ErrorsFragment
        }
        vehicle {
          identifier
          name
        }
        measurement {
          identifier
          name
          kgCo2e
        }
      }
    }
  `,
  [DataCalculatorTypesEnum.EquipmentFuel]: gql`
    mutation CreateOrEditEquipmentFuelCalculator(
      $input: CreateOrEditEquipmentFuelCalculatorInput!
    ) {
      createOrEditEquipmentFuelCalculator(input: $input) {
        ok
        errors {
          ...ErrorsFragment
        }
        equipment {
          identifier
          name
        }
        measurement {
          identifier
          name
          kgCo2e
        }
      }
    }
  `,
  [DataCalculatorTypesEnum.EquipmentIndustrialGas]: gql`
    mutation CreateOrEditEquipmentIndustrialGasCalculator(
      $input: CreateOrEditEquipmentIndustrialGasCalculatorInput!
    ) {
      createOrEditEquipmentIndustrialGasCalculator(input: $input) {
        ok
        errors {
          ...ErrorsFragment
        }
        equipment {
          identifier
          name
        }
        measurement {
          identifier
          name
          kgCo2e
        }
      }
    }
  `,
  [DataCalculatorTypesEnum.EquipmentRefrigerant]: gql`
    mutation CreateOrEditEquipmentRefrigerantCalculator(
      $input: CreateOrEditEquipmentRefrigerantCalculatorInput!
    ) {
      createOrEditEquipmentRefrigerantCalculator(input: $input) {
        ok
        errors {
          ...ErrorsFragment
        }
        equipment {
          identifier
          name
        }
        measurement {
          identifier
          name
          kgCo2e
        }
      }
    }
  `
};

const UnitLabels = {
  [EnergyUnitsEnum.Kwh]: 'kWh - kilowatt hours',
  [EnergyUnitsEnum.Mwh]: 'MWh - megawatt hours',
  [EnergyUnitsEnum.Gj]: 'GJ - gigajoules',
  [EnergyUnitsEnum.Mj]: 'MJ - megajoules',
  [EnergyUnitsEnum.Kj]: 'KJ - kilojoules',
  [EnergyUnitsEnum.Therm]: 'Therm - thermal unit',
  [EnergyUnitsEnum.Btu]: 'Btu - British thermal unit',
  [EnergyUnitsEnum.Cal]: 'cal - calorie',
  [EnergyUnitsEnum.Wh]: 'Wh - watt hour',
  [VehicleDistanceTravelledEnum.M]: 'm - meters',
  [VehicleDistanceTravelledEnum.Km]: 'km - kilometers',
  [VehicleDistanceTravelledEnum.Mi]: 'mi - miles',
  [VehicleDistanceTravelledEnum.Ft]: 'ft - feet',
  [WeightUnitsEnum.Kg]: 'kg - kilograms',
  [WeightUnitsEnum.G]: 'g - grams',
  [WeightUnitsEnum.Ton]: 'ton - metric ton',
  [WeightUnitsEnum.Tonne]: 'tonne - metric tonne',
  [WeightUnitsEnum.Lbs]: 'lbs - pounds',
  [WeightUnitsEnum.Oz]: 'oz - ounces',
  [WeightUnitsEnum.Gr]: 'gr - grains',
  [RefrigerantTypesEnum.R404A]: 'R404A - HFC',
  [RefrigerantTypesEnum.R407A]: 'R407A - HFC',
  [RefrigerantTypesEnum.R407B]: 'R407B - HFC',
  [RefrigerantTypesEnum.R407C]: 'R407C - HFC',
  [RefrigerantTypesEnum.R407D]: 'R407D - HFC',
  [RefrigerantTypesEnum.R410A]: 'R410A - HFC',
  [RefrigerantTypesEnum.R410B]: 'R410B - HFC',
  [RefrigerantTypesEnum.R507A]: 'R507A - HFC',
  [RefrigerantTypesEnum.R508A]: 'R508A - HFC',
  [RefrigerantTypesEnum.R508B]: 'R508B - HFC',
  [VolumeUnitsEnum.L]: 'L - liters',
  [VolumeUnitsEnum.Yd3]: 'yd3 - cubic yards',
  [VolumeUnitsEnum.Ml]: 'mL - milliliters',
  [VolumeUnitsEnum.Cm3]: 'cm3 - cubic centimeters',
  [VolumeUnitsEnum.Cy]: 'cy - cubic yards',
  [VolumeUnitsEnum.UsGal]: 'US gal - US gallons',
  [VolumeUnitsEnum.ImpGal]: 'Imp gal - Imperial gallons',
  [IndustrialGasTypesEnum.CarbonDioxide]: 'CO2 - Carbon Dioxide',
  [IndustrialGasTypesEnum.Methane]: 'CH4 - Methane',
  [IndustrialGasTypesEnum.NitrousOxide]: 'N2O - Nitrous Oxide',
  [IndustrialGasTypesEnum.SulphurHexafluoride]:
    'SF6 - Sulfur Hexafluoride',
  [IndustrialGasTypesEnum.NitrogenTrifluoride]:
    'NF3 - Nitrogen Triflouride'
};

export function DetailView() {
  const router = useRouter();
  const { toast } = useToast();
  const {
    setActiveAssetType: setAssetManagerAssetType,
    setActiveAssetIdentifier: setAssetManagerActiveAssetIdentifier
  }: AssetStoreType = useAssetStore();
  const {
    activeAsset,
    initialMeasurement,
    activeCalculatorType,
    activeAssetType,
    clearCurrentActive
  } = useDataCalculatorStore((store: DataCalculatorStoreType) => ({
    activeAsset: store.activeAsset,
    initialMeasurement: store.initialMeasurement,
    activeCalculatorType: store.activeCalculatorType,
    activeAssetType: store.activeAssetType,
    clearCurrentActive: store.clearCurrentActive
  }));

  const activeCalculator = activeAsset.dataCalculators.find(
    (calculator) => calculator.calculatorType === activeCalculatorType
  );

  const mostRecentMeasurement = activeCalculator?.measurements
    ?.map((m) => m)
    ?.sort((a, b) =>
      dayjs(b.dateOfMeasurement).diff(
        dayjs(a.dateOfMeasurement),
        'days'
      )
    )[0];

  const [
    isEditingMeasurementInterval,
    setIsEditingMeasurementInterval
  ] = useState<boolean>(mostRecentMeasurement ? false : true);

  useEffect(() => {
    setIsEditingMeasurementInterval(
      mostRecentMeasurement ? false : true
    );
  }, [mostRecentMeasurement]);

  const mutationCallbacks = {
    onSuccess: () => {
      toast({
        title: 'Success.',
        description: 'Emissions calculated.',
        variant: 'success'
      });
      clearCurrentActive();
    }
  };

  // Mutations
  const {
    mutate: mutateLocationNaturalGas,
    loading: loadingLocationNaturalGas
  } = useBackendMutation({
    mutation: mutations[DataCalculatorTypesEnum.LocationNaturalGas],
    callbacks: mutationCallbacks
  });

  const {
    mutate: mutateLocationElectricity,
    loading: loadingLocationElectricity
  } = useBackendMutation({
    mutation: mutations[DataCalculatorTypesEnum.LocationElectricity],
    callbacks: mutationCallbacks
  });

  const {
    mutate: mutateLocationHeatOrSteam,
    loading: loadingLocationHeatOrSteam
  } = useBackendMutation({
    mutation: mutations[DataCalculatorTypesEnum.LocationHeatOrSteam],
    callbacks: mutationCallbacks
  });

  const {
    mutate: mutateLocationPurchasedCooling,
    loading: loadingLocationPurchasedCooling
  } = useBackendMutation({
    mutation:
      mutations[DataCalculatorTypesEnum.LocationPurchasedCooling],
    callbacks: mutationCallbacks
  });

  const { mutate: mutateVehicleFuel, loading: loadingVehicleFuel } =
    useBackendMutation({
      mutation: mutations[DataCalculatorTypesEnum.VehicleFuel],
      callbacks: mutationCallbacks
    });

  const {
    mutate: mutateVehicleDistance,
    loading: loadingVehicleDistance
  } = useBackendMutation({
    mutation: mutations[DataCalculatorTypesEnum.VehicleDistance],
    callbacks: mutationCallbacks
  });

  const {
    mutate: mutateEquipmentFuel,
    loading: loadingEquipmentFuel
  } = useBackendMutation({
    mutation: mutations[DataCalculatorTypesEnum.EquipmentFuel],
    callbacks: mutationCallbacks
  });

  const {
    mutate: mutateEquipmentIndustrialGas,
    loading: loadingEquipmentIndustrialGas
  } = useBackendMutation({
    mutation:
      mutations[DataCalculatorTypesEnum.EquipmentIndustrialGas],
    callbacks: mutationCallbacks
  });

  const {
    mutate: mutateEquipmentRefrigerant,
    loading: loadingEquipmentRefrigerant
  } = useBackendMutation({
    mutation: mutations[DataCalculatorTypesEnum.EquipmentRefrigerant],
    callbacks: mutationCallbacks
  });

  const getLoadingByCalculatorType = {
    [DataCalculatorTypesEnum.LocationNaturalGas]:
      loadingLocationNaturalGas,
    [DataCalculatorTypesEnum.LocationElectricity]:
      loadingLocationElectricity,
    [DataCalculatorTypesEnum.LocationHeatOrSteam]:
      loadingLocationHeatOrSteam,
    [DataCalculatorTypesEnum.LocationPurchasedCooling]:
      loadingLocationPurchasedCooling,
    [DataCalculatorTypesEnum.VehicleFuel]: loadingVehicleFuel,
    [DataCalculatorTypesEnum.VehicleDistance]: loadingVehicleDistance,
    [DataCalculatorTypesEnum.EquipmentFuel]: loadingEquipmentFuel,
    [DataCalculatorTypesEnum.EquipmentIndustrialGas]:
      loadingEquipmentIndustrialGas,
    [DataCalculatorTypesEnum.EquipmentRefrigerant]:
      loadingEquipmentRefrigerant
  };

  const descriptions = {
    location: getLocationDescription(
      activeAsset as LocationFragmentFragment
    ),
    vehicle: getVehicleDescription(
      activeAsset as VehicleFragmentFragment
    ),
    equipment: getEquipmentDescription(
      activeAsset as EquipmentFragmentFragment
    )
  };

  const secondaryDescriptions = {
    location: getSecondaryLocationDescription(
      activeAsset as LocationFragmentFragment
    ),
    vehicle: getSecondaryVehicleDescription(
      activeAsset as VehicleFragmentFragment
    ),
    equipment: getEquipmentDescription(
      activeAsset as EquipmentFragmentFragment
    )
  };

  const [startDate, setStartDate] = useState(
    initialMeasurement?.startDate || null
  );
  const [endDate, setEndDate] = useState(
    initialMeasurement?.endDate || null
  );

  const rowsByDataCalculatorType = {
    [DataCalculatorTypesEnum.LocationNaturalGas]: [
      {
        questions: [
          {
            key: 'value',
            type: 'number' as FormQuestionTypeType,
            text: `How much natural gas did ${activeAsset?.name} consume in the period specified above?`,
            placeholder: 'E.g. 1000',
            defaultValue: initialMeasurement?.value || null,
            required: true,
            embeddedSingleSelect: {
              key: 'valueUnit',
              defaultValue:
                initialMeasurement?.valueUnit || VolumeUnitsEnum.L,
              options: Object.values(VolumeUnitsEnum).map(
                (value) => ({
                  key: value,
                  label: UnitLabels[value]
                })
              ),
              helperText: 'Must select a unit'
            }
          }
        ]
      }
    ],
    [DataCalculatorTypesEnum.LocationHeatOrSteam]: [
      {
        questions: [
          {
            key: 'value',
            type: 'number' as FormQuestionTypeType,
            text: `How much heat or steam did ${activeAsset?.name} consume in the period specified above?`,
            placeholder: 'E.g. 1000',
            defaultValue: initialMeasurement?.value || null,
            required: true,
            embeddedSingleSelect: {
              key: 'valueUnit',
              defaultValue:
                initialMeasurement?.valueUnit || EnergyUnitsEnum.Kwh,
              options: Object.values(EnergyUnitsEnum).map(
                (value) => ({
                  key: value,
                  label: UnitLabels[value]
                })
              ),
              helperText: 'Must select a unit'
            }
          }
        ]
      }
    ],
    [DataCalculatorTypesEnum.LocationElectricity]: [
      {
        questions: [
          {
            key: 'value',
            type: 'number' as FormQuestionTypeType,
            text: `How much electricity did ${activeAsset?.name} consume in the period specified above?`,
            placeholder: 'E.g. 1000',
            defaultValue: initialMeasurement?.value || null,
            required: true,
            embeddedSingleSelect: {
              key: 'valueUnit',
              defaultValue:
                initialMeasurement?.valueUnit || EnergyUnitsEnum.Kwh,
              options: Object.values(EnergyUnitsEnum).map(
                (value) => ({
                  key: value,
                  label: UnitLabels[value]
                })
              ),
              helperText: 'Must select a unit'
            }
          }
        ]
      }
    ],
    [DataCalculatorTypesEnum.LocationPurchasedCooling]: [
      {
        questions: [
          {
            key: 'value',
            type: 'number' as FormQuestionTypeType,
            text: `How much purchased cooling did ${activeAsset?.name} consume in the period specified above?`,
            placeholder: 'E.g. 1000',
            defaultValue: initialMeasurement?.value || null,
            required: true,
            embeddedSingleSelect: {
              key: 'valueUnit',
              defaultValue:
                initialMeasurement?.valueUnit || EnergyUnitsEnum.Kwh,
              options: Object.values(EnergyUnitsEnum).map(
                (value) => ({
                  key: value,
                  label: UnitLabels[value]
                })
              ),
              helperText: 'Must select a unit'
            }
          }
        ]
      },
      {
        questions: [
          {
            key: 'purchasedCoolingType',
            type: 'single-select' as FormQuestionTypeType,
            text: `What type of purchased cooling does ${activeAsset?.name} use?`,
            defaultValue:
              initialMeasurement?.purchasedCoolingType || null,
            required: true,
            options: Object.values(PurchasedCoolingTypesEnum).map(
              (value) => ({
                key: value,
                label: fEnum(value)
              })
            )
          }
        ]
      }
    ],
    [DataCalculatorTypesEnum.VehicleFuel]: [
      {
        questions: [
          {
            key: 'value',
            type: 'number' as FormQuestionTypeType,
            text: `How much fuel did ${activeAsset?.name} consume in the period specified above?`,
            placeholder: 'E.g. 1000',
            defaultValue: initialMeasurement?.value || null,
            required: true,
            embeddedSingleSelect: {
              key: 'valueUnit',
              defaultValue:
                initialMeasurement?.valueUnit || VolumeUnitsEnum.L,
              options: Object.values(VolumeUnitsEnum).map(
                (value) => ({
                  key: value,
                  label: UnitLabels[value]
                })
              ),
              helperText: 'Must select a unit'
            }
          }
        ]
      }
    ],
    [DataCalculatorTypesEnum.VehicleDistance]: [
      {
        questions: [
          {
            key: 'value',
            type: 'number' as FormQuestionTypeType,
            text: `How far did ${activeAsset?.name} travel in the period specified above?`,
            placeholder: 'E.g. 1000',
            defaultValue: initialMeasurement?.value || null,
            required: true,
            embeddedSingleSelect: {
              key: 'valueUnit',
              defaultValue:
                initialMeasurement?.valueUnit ||
                VehicleDistanceTravelledEnum.Km,
              options: Object.values(
                VehicleDistanceTravelledEnum
              ).map((value) => ({
                key: value,
                label: UnitLabels[value]
              })),
              helperText: 'Must select a unit'
            }
          }
        ]
      }
    ],
    [DataCalculatorTypesEnum.EquipmentFuel]: [
      {
        questions: [
          {
            key: 'value',
            type: 'number' as FormQuestionTypeType,
            text: `How much fuel did ${activeAsset?.name} consume in the period specified above?`,
            placeholder: 'E.g. 1000',
            defaultValue: initialMeasurement?.value || null,
            required: true,
            embeddedSingleSelect: {
              key: 'valueUnit',
              defaultValue:
                initialMeasurement?.valueUnit || VolumeUnitsEnum.L,
              options: Object.values(VolumeUnitsEnum).map(
                (value) => ({
                  key: value,
                  label: UnitLabels[value]
                })
              ),
              helperText: 'Must select a unit'
            }
          }
        ]
      }
    ],
    [DataCalculatorTypesEnum.EquipmentIndustrialGas]: [
      {
        questions: [
          {
            key: 'value',
            type: 'number' as FormQuestionTypeType,
            text: `How much industrial gas did ${activeAsset?.name} consume in the period specified above?`,
            placeholder: 'E.g. 1000',
            defaultValue: initialMeasurement?.value || null,
            required: true,
            embeddedSingleSelect: {
              key: 'valueUnit',
              defaultValue:
                initialMeasurement?.valueUnit || WeightUnitsEnum.Kg,
              options: Object.values(WeightUnitsEnum).map(
                (value) => ({
                  key: value,
                  label: UnitLabels[value]
                })
              ),
              helperText: 'Must select a unit'
            }
          }
        ]
      },
      {
        questions: [
          {
            key: 'industrialGasType',
            type: 'single-select' as FormQuestionTypeType,
            text: `What type of industrial gas does ${activeAsset?.name} use?`,
            defaultValue: initialMeasurement?.industrialGas || null,
            required: true,
            options: Object.values(IndustrialGasTypesEnum).map(
              (value) => ({
                key: value,
                label: UnitLabels[value]
              })
            )
          }
        ]
      }
    ],
    [DataCalculatorTypesEnum.EquipmentRefrigerant]: [
      {
        questions: [
          {
            key: 'value',
            type: 'number' as FormQuestionTypeType,
            text: `How much refrigerant did ${activeAsset?.name} consume in the period specified above?`,
            placeholder: 'E.g. 1000',
            defaultValue: initialMeasurement?.value || null,
            required: true,
            embeddedSingleSelect: {
              key: 'valueUnit',
              defaultValue:
                initialMeasurement?.valueUnit || WeightUnitsEnum.Kg,
              options: Object.values(WeightUnitsEnum).map(
                (value) => ({
                  key: value,
                  label: UnitLabels[value]
                })
              ),
              helperText: 'Must select a unit'
            }
          }
        ]
      },
      {
        questions: [
          {
            key: 'refrigerantType',
            type: 'single-select' as FormQuestionTypeType,
            text: `What type of refrigerant does ${activeAsset?.name} use?`,
            defaultValue: initialMeasurement?.refrigerantType || null,
            required: true,
            options: Object.values(RefrigerantTypesEnum).map(
              (value) => ({
                key: value,
                label: UnitLabels[value]
              })
            )
          }
        ]
      }
    ]
  };

  const getQuestionRowsByCalculatorType = (
    calculatorType: DataCalculatorTypesEnum
  ) => {
    const rows: QuestionRowType[] =
      rowsByDataCalculatorType[calculatorType];

    // Only show measurement interval question if user has decided to edit
    if (isEditingMeasurementInterval) {
      rows.unshift({
        questions: [
          {
            key: 'measurementInterval',
            type: 'text' as FormQuestionTypeType,
            text: `How often do you want to calculate ${fEnum(calculatorType)} emissions for ${activeAsset?.name}?`,
            defaultValue: mostRecentMeasurement?.intervalValue || '1',
            required: true,
            placeholder: 'E.g. 2',
            embeddedSingleSelect: {
              key: 'measurementIntervalUnit',
              defaultValue:
                mostRecentMeasurement?.intervalUnit ||
                MeasurementIntervalUnitsEnum.Months,
              options: Object.values(
                MeasurementIntervalUnitsEnum
              ).map((value) => ({
                key: value,
                label: fEnum(value)
              }))
            },
            helperMessage:
              'This is the interval at which we will remind you to complete your emissions data.'
          }
        ]
      });
    }

    return rows;
  };

  const {
    selectedStartDate,
    selectedEndDate,
    selectedFilters
  }: MeasureStoreType = useMeasureStore();
  const { data, loading: loadingActiveEntity } =
    useQuery<DetailViewActiveEntityQueryQuery>(
      gql`
        query DetailViewActiveEntityQuery {
          activeEntity {
            errors {
              ...ErrorsFragment
            }
            ok
            data {
              # ...EntityFragment
              ... on EntityObject {
                id
                identifier
                reportingPeriods {
                  id
                  identifier
                  startDate
                  endDate
                }
              }
            }
          }
        }
      `,
      {
        variables: {
          startDate: dayjs(selectedStartDate).toDate(),
          endDate: dayjs(selectedEndDate).toDate(),
          states: selectedFilters
        }
      }
    );
  const activeEntity = data?.activeEntity?.data;
  const reportingPeriods =
    activeEntity?.reportingPeriods as ReportingPeriodObject[];
  const { activeReportingPeriodId }: SettingsStoreType =
    useSettingsStore();
  const activeReportingPeriod =
    reportingPeriods?.find(
      (reportingPeriod) =>
        reportingPeriod.identifier === activeReportingPeriodId
    ) || (reportingPeriods?.length ? reportingPeriods[0] : null);
  const reportingPeriod = activeReportingPeriod;

  // a page refresher for when another reportingPeriod has been selected
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => forceUpdate(), [activeReportingPeriodId]);

  const thisYear = dayjs().year();
  const firstDayOfThisYear = dayjs()
    .date(1)
    .year(thisYear)
    .month(0)
    .hour(0);
  const lastDayOfThisYear = dayjs()
    .date(1)
    .year(thisYear)
    .month(12)
    .hour(0);
  const timeGap = {
    complete: [],
    incomplete: []
  };
  const missingMeasurementDateRanges =
    activeCalculator?.missingMeasurementDateRanges;
  if (reportingPeriod && missingMeasurementDateRanges?.length) {
    const lastIndex = missingMeasurementDateRanges.length - 1;
    // Add start of reporting period
    if (
      reportingPeriod?.startDate !==
      missingMeasurementDateRanges[0]?.startDate
    ) {
      timeGap.incomplete.push({
        date: reportingPeriod?.startDate,
        timestamp: dayjs(reportingPeriod?.startDate).valueOf(),
        incomplete: 1
      });
      const missingStartDate =
        missingMeasurementDateRanges[0]?.startDate;
      timeGap.incomplete.push({
        date: missingStartDate,
        timestamp: dayjs(missingStartDate).valueOf(),
        incomplete: 1
      });
    }
    missingMeasurementDateRanges?.forEach((gap, idx) => {
      const isLast = idx === lastIndex;
      // Add start of missing measurement date range
      timeGap.incomplete.push({
        date: gap.startDate,
        timestamp: dayjs(gap.startDate).valueOf(),
        missing: 1
      });
      // Add end of missing measurement date range
      timeGap.incomplete.push({
        date: gap.endDate,
        timestamp: dayjs(gap.endDate).valueOf(),
        missing: 1
      });
      if (!isLast) {
        // Add start of incomplete measurement date range
        const missingEndDate = dayjs(gap.endDate);
        timeGap.incomplete.push({
          date: missingEndDate.add(1, 'day').format('YYYY-MM-DD'),
          timestamp: missingEndDate.valueOf() + 1,
          incomplete: 1
        });
        // Add end of incomplete measurement date range
        const nextMissingStartDate = dayjs(
          missingMeasurementDateRanges[idx + 1].startDate
        );
        timeGap.incomplete.push({
          date: nextMissingStartDate
            .subtract(1, 'day')
            .format('YYYY-MM-DD'),
          timestamp: nextMissingStartDate.valueOf() - 1,
          incomplete: 1
        });
      }
    });
    // Add end of reporting period
    if (
      reportingPeriod?.endDate !==
      missingMeasurementDateRanges[lastIndex]?.endDate
    ) {
      const missingEndDate =
        missingMeasurementDateRanges[lastIndex]?.endDate;
      timeGap.incomplete.push({
        date: missingEndDate,
        timestamp: dayjs(missingEndDate).valueOf(),
        incomplete: 1
      });
      timeGap.incomplete.push({
        date: reportingPeriod?.endDate,
        timestamp: dayjs(reportingPeriod?.endDate).valueOf(),
        incomplete: 1
      });
    }
  } else {
    timeGap.complete = [
      {
        date: reportingPeriod?.startDate || firstDayOfThisYear,
        timestamp: dayjs(
          reportingPeriod?.startDate || firstDayOfThisYear
        ).valueOf(),
        complete: 1
      },
      {
        date: reportingPeriod?.endDate || lastDayOfThisYear,
        timestamp: dayjs(
          reportingPeriod?.endDate || lastDayOfThisYear
        ).valueOf(),
        complete: 1
      }
    ];
  }

  const CHART_COLORS = {
    complete: '#3b82f6',
    missing: '#6b7280',
    incomplete: '#3b82f6'
  };
  const pointsRef = useRef([]);

  return (
    <>
      <div className="flex flex-col flex-nowrap">
        <h6 className="font-bold">
          Calculate {fEnum(activeCalculatorType)}
        </h6>
        <p className="mt-sm text-muted">
          Provide information about {activeAsset?.name} to calculate
          emissions for this {activeAssetType}.
        </p>
        {/* activeAsset Card */}
        <div className="mt-xl rounded-md border">
          <div className="flex flex-nowrap">
            <AssetCard
              title={activeAsset?.name}
              description={descriptions[activeAssetType]}
              secondaryDescription={
                secondaryDescriptions[activeAssetType]
              }
              onClick={() => {
                // Redirect to asset manager page and set active asset
                setAssetManagerActiveAssetIdentifier(
                  activeAsset.identifier
                );
                setAssetManagerAssetType(activeAssetType);
                const route =
                  activeAssetType === 'location'
                    ? ROUTES.ORGANIZATION.LOCATIONS
                    : activeAssetType === 'vehicle'
                      ? ROUTES.ORGANIZATION.VEHICLES
                      : ROUTES.ORGANIZATION.EQUIPMENT;

                router.push(route);
              }}
              renderIcon={
                activeAsset?.icon
                  ? () => (
                      <img
                        src={activeAsset.icon}
                        className="grayscale"
                      />
                    )
                  : undefined
              }
            />
          </div>
        </div>
        {/* Questions */}
      </div>
      <div className="mt-xl">
        <div className="flex flex-col">
          {activeCalculator && !loadingActiveEntity ? (
            <div className="w-full">
              <ResponsiveContainer width="100%" height={100}>
                <AreaChart
                  // width={500}
                  // height={200}
                  data={
                    timeGap.complete.length
                      ? timeGap.complete
                      : timeGap.incomplete
                  }
                  margin={{
                    top: 0,
                    right: 30,
                    left: 20,
                    bottom: 0
                  }}
                  onClick={
                    activeCalculator?.missingMeasurementDateRanges
                      ?.length
                      ? (e) => {
                          if (
                            e?.activePayload?.length &&
                            e.activePayload[0].name == 'missing'
                          ) {
                            for (
                              let i = 0;
                              i < pointsRef.current.length;
                              i += 2
                            ) {
                              const startPoint = pointsRef.current[i];
                              const endPoint =
                                pointsRef.current[i + 1];
                              if (
                                startPoint.y === 0 &&
                                endPoint.y === 0 &&
                                startPoint.payload?.missing === 1 &&
                                endPoint.payload?.missing === 1 &&
                                startPoint.x <= e.chartX &&
                                e.chartX <= endPoint.x
                              ) {
                                setStartDate(
                                  dayjs(
                                    startPoint.payload.date
                                  ).toDate()
                                );
                                setEndDate(
                                  dayjs(
                                    endPoint.payload.date
                                  ).toDate()
                                );
                              }
                            }
                          }
                        }
                      : null
                  }
                >
                  <defs>
                    <linearGradient
                      id="colorComplete"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="0%"
                        stopColor={CHART_COLORS.complete}
                        stopOpacity={1}
                      />
                      <stop
                        offset="100%"
                        stopColor={CHART_COLORS.complete}
                        stopOpacity={0.3}
                      />
                    </linearGradient>
                    <linearGradient
                      id="colorMissing"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="0%"
                        stopColor={CHART_COLORS.missing}
                        stopOpacity={1}
                      />
                      <stop
                        offset="100%"
                        stopColor={CHART_COLORS.missing}
                        stopOpacity={0.3}
                      />
                    </linearGradient>
                    <linearGradient
                      id="colorIncomplete"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="0%"
                        stopColor={CHART_COLORS.incomplete}
                        stopOpacity={1}
                      />
                      <stop
                        offset="100%"
                        stopColor={CHART_COLORS.incomplete}
                        stopOpacity={0.3}
                      />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="timestamp"
                    tickFormatter={(timestamp) => {
                      return dayjs(timestamp).format('YYYY MMM DD');
                    }}
                    type="number"
                    domain={['dataMin', 'dataMax']}
                    scale="time"
                  />
                  <YAxis tickLine={false} tickCount={0} width={40} />
                  {/* <Tooltip /> */}
                  {timeGap.complete.length ? (
                    <Area
                      type="monotone"
                      dataKey="complete"
                      stroke={CHART_COLORS.complete}
                      fillOpacity={1}
                      fill="url(#colorComplete)"
                    />
                  ) : (
                    <>
                      <Area
                        type="monotone"
                        dataKey="missing"
                        stroke={CHART_COLORS.missing}
                        fillOpacity={1}
                        fill="url(#colorMissing)"
                        className="cursor-pointer"
                        onClick={(e) => {
                          pointsRef.current =
                            'points' in e
                              ? (e['points'] as unknown[])
                              : [];
                        }}
                      />
                      <Area
                        type="monotone"
                        dataKey="incomplete"
                        stroke={CHART_COLORS.incomplete}
                        fillOpacity={1}
                        fill="url(#colorIncomplete)"
                      />
                    </>
                  )}
                </AreaChart>
              </ResponsiveContainer>
            </div>
          ) : null}
          {activeCalculator?.missingMeasurementDateRanges?.length >
            0 && (
            <p className="mt-md">
              {activeAsset?.name} is missing{' '}
              {fEnum(activeCalculatorType)} measurements for the
              following periods of time:
            </p>
          )}
          {activeCalculator?.missingMeasurementDateRanges?.map(
            (dateRange, dateRangeIdx) => {
              return (
                <div
                  className="flex"
                  key={getListItemKey(dateRangeIdx)}
                >
                  <div
                    onClick={() => {
                      setStartDate(
                        dayjs(dateRange.startDate).toDate()
                      );
                      setEndDate(dayjs(dateRange.endDate).toDate());
                    }}
                    className="mt-md flex items-center gap-3 rounded-lg border p-sm px-md hover:cursor-pointer hover:border-blue-500"
                  >
                    <p className="whitespace-nowrap">
                      {fLocalDate(
                        dayjs(dateRange.startDate).toDate()
                      )}
                    </p>
                    <p> to </p>
                    <p className="whitespace-nowrap">
                      {fLocalDate(dayjs(dateRange.endDate).toDate())}
                    </p>
                  </div>
                </div>
              );
            }
          )}
          <p className="pt-lg">
            What period of time will this measurement cover?
          </p>
          <div className="mb-lg mt-sm flex w-full flex-col items-center md:flex-row">
            <div>
              <DatePicker
                required
                date={startDate}
                minDate={dayjs('2000-01-01').toDate()}
                maxDate={dayjs('2050-01-01').toDate()}
                setDate={(newDate) => {
                  setStartDate(newDate);
                }}
                label="Start Date: "
              />
            </div>
            <div className="ml-sm">
              <DatePicker
                required
                date={endDate}
                minDate={
                  startDate
                    ? dayjs(startDate).toDate()
                    : dayjs('2000-01-01').toDate()
                }
                maxDate={dayjs('2050-01-01').toDate()}
                setDate={(newDate) => {
                  setEndDate(newDate);
                }}
                label={'End Date: '}
              />
            </div>
          </div>
        </div>
        {/* Measurement Interval */}
        {mostRecentMeasurement && !isEditingMeasurementInterval && (
          <>
            <p className="mb-sm">
              How often do you want to calculate{' '}
              {fEnum(activeCalculatorType)} emissions for{' '}
              {activeAsset?.name}?
            </p>
            <div className="mb-md flex flex-nowrap items-center">
              <Badge>
                Every{' '}
                {getFormattedMeasurementInterval(
                  mostRecentMeasurement.intervalValue,
                  mostRecentMeasurement.intervalUnit
                )}
              </Badge>
              <p
                className="body2 ml-sm cursor-pointer underline"
                onClick={() => setIsEditingMeasurementInterval(true)}
              >
                Change
              </p>
            </div>
          </>
        )}
        <Form
          rows={getQuestionRowsByCalculatorType(activeCalculatorType)}
          submitButtonDisabled={!startDate || !endDate}
          onSubmit={(values) => {
            const intervalValue = values.measurementInterval
              ? Number(values.measurementInterval)
              : undefined;
            const intervalUnit = values.measurementIntervalUnit
              ? values.measurementIntervalUnit
              : undefined;

            const measurementInterval =
              intervalValue && intervalUnit
                ? {
                    value: intervalValue,
                    unit: intervalUnit
                  }
                : undefined;

            switch (activeCalculatorType) {
              case DataCalculatorTypesEnum.LocationNaturalGas:
                mutateLocationNaturalGas({
                  variables: {
                    input: {
                      locationIdentifier: activeAsset.identifier,
                      value: values.value,
                      valueUnit: values.valueUnit,
                      measurementStartDate: startDate,
                      measurementEndDate: endDate,
                      measurementInterval
                    }
                  },
                  refetchQueries: [
                    SCOPE_1_DATA_CALCULATORS_QUERY_NAME
                  ],
                  awaitRefetchQueries: true
                });
                break;
              case DataCalculatorTypesEnum.LocationElectricity:
                mutateLocationElectricity({
                  variables: {
                    input: {
                      locationIdentifier: activeAsset.identifier,
                      value: values.value,
                      valueUnit: values.valueUnit,
                      measurementStartDate: startDate,
                      measurementEndDate: endDate,
                      measurementInterval
                    }
                  },
                  refetchQueries: [
                    SCOPE_2_DATA_CALCULATORS_QUERY_NAME
                  ],
                  awaitRefetchQueries: true
                });
                break;
              case DataCalculatorTypesEnum.LocationHeatOrSteam:
                mutateLocationHeatOrSteam({
                  variables: {
                    input: {
                      locationIdentifier: activeAsset.identifier,
                      value: values.value,
                      valueUnit: values.valueUnit,
                      measurementStartDate: startDate,
                      measurementEndDate: endDate,
                      measurementInterval
                    }
                  },
                  refetchQueries: [
                    SCOPE_2_DATA_CALCULATORS_QUERY_NAME
                  ],
                  awaitRefetchQueries: true
                });
                break;
              case DataCalculatorTypesEnum.LocationPurchasedCooling:
                mutateLocationPurchasedCooling({
                  variables: {
                    input: {
                      locationIdentifier: activeAsset.identifier,
                      value: values.value,
                      valueUnit: values.valueUnit,
                      purchasedCoolingType:
                        values.purchasedCoolingType,
                      measurementStartDate: startDate,
                      measurementEndDate: endDate,
                      measurementInterval
                    }
                  },
                  refetchQueries: [
                    SCOPE_2_DATA_CALCULATORS_QUERY_NAME
                  ],
                  awaitRefetchQueries: true
                });
                break;
              case DataCalculatorTypesEnum.VehicleFuel:
                mutateVehicleFuel({
                  variables: {
                    input: {
                      vehicleIdentifier: activeAsset.identifier,
                      value: values.value,
                      valueUnit: values.valueUnit,
                      fuelType: activeAsset.fuelType,
                      percentageChargedAwayFromLocation:
                        values.percentageChargedAwayFromLocation,
                      measurementStartDate: startDate,
                      measurementEndDate: endDate,
                      measurementInterval
                    }
                  },
                  refetchQueries: [
                    SCOPE_1_DATA_CALCULATORS_QUERY_NAME
                  ],
                  awaitRefetchQueries: true
                });
                break;
              case DataCalculatorTypesEnum.VehicleDistance:
                mutateVehicleDistance({
                  variables: {
                    input: {
                      vehicleIdentifier: activeAsset.identifier,
                      value: values.value,
                      valueUnit: values.valueUnit,
                      measurementStartDate: startDate,
                      measurementEndDate: endDate,
                      measurementInterval
                    }
                  },
                  refetchQueries: [
                    SCOPE_1_DATA_CALCULATORS_QUERY_NAME
                  ],
                  awaitRefetchQueries: true
                });
                break;
              case DataCalculatorTypesEnum.EquipmentFuel:
                mutateEquipmentFuel({
                  variables: {
                    input: {
                      equipmentIdentifier: activeAsset.identifier,
                      value: values.value,
                      valueUnit: values.valueUnit,
                      fuelType: activeAsset.fuelType,
                      measurementStartDate: startDate,
                      measurementEndDate: endDate,
                      measurementInterval
                    }
                  },
                  refetchQueries: [
                    SCOPE_1_DATA_CALCULATORS_QUERY_NAME
                  ],
                  awaitRefetchQueries: true
                });
                break;
              case DataCalculatorTypesEnum.EquipmentIndustrialGas:
                mutateEquipmentIndustrialGas({
                  variables: {
                    input: {
                      equipmentIdentifier: activeAsset.identifier,
                      value: values.value,
                      valueUnit: values.valueUnit,
                      industrialGas: values.industrialGasType,
                      measurementStartDate: startDate,
                      measurementEndDate: endDate,
                      measurementInterval
                    }
                  },
                  refetchQueries: [
                    SCOPE_1_DATA_CALCULATORS_QUERY_NAME
                  ],
                  awaitRefetchQueries: true
                });
                break;
              case DataCalculatorTypesEnum.EquipmentRefrigerant:
                mutateEquipmentRefrigerant({
                  variables: {
                    input: {
                      equipmentIdentifier: activeAsset.identifier,
                      value: values.value,
                      valueUnit: values.valueUnit,
                      refrigerantType: values.refrigerantType,
                      measurementStartDate: startDate,
                      measurementEndDate: endDate,
                      measurementInterval
                    }
                  },
                  refetchQueries: [
                    SCOPE_1_DATA_CALCULATORS_QUERY_NAME
                  ],
                  awaitRefetchQueries: true
                });
                break;
            }
          }}
          isSubmitting={
            getLoadingByCalculatorType[activeCalculatorType]
          }
          submitButtonText={'Calculate Emissions'}
        />
      </div>
    </>
  );
}
